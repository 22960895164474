<template>
  <div class="content">
    <div class="container-fluid container-fixed-lg">
      <div class="row">
        <div class="col-12">
          <div class="card card-default">
            <div class="card-header separator">
              <div class="card-title">
                <ol class="breadcrumb no-margin no-padding">
                  <li class="breadcrumb-item">
                    <router-link
                      :to="{ name: 'profile' }"
                      class="no-margin no-padding"
                      >My Profile</router-link
                    >
                  </li>
                </ol>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!--<form class="p-t-15" role="form">-->
                  <b-form @submit="onSubmit" class="form p-t-15">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="user_firstname">First Name</label>
                          <input
                            id="user_firstname"
                            type="text"
                            name="fname"
                            v-model="fname"
                            class="form-control"
                            required=""
                            aria-required="true"
                          />
                        </div>
                        <!--<label id="fname-error" class="error" for="fname">This field is required.</label>-->
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="user_lastname">Last Name</label>
                          <input
                            id="user_lastname"
                            type="text"
                            name="lname"
                            v-model="lname"
                            class="form-control"
                            required=""
                            aria-required="true"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="user_email">Email</label>
                          <input
                            id="user_email"
                            type="email"
                            name="email"
                            v-model="email"
                            class="form-control"
                            required=""
                            aria-required="true"
                          />
                        </div>
                      </div>
                      <div class="col-md-6" v-if="email != verify_email">
                        <div class="form-group">
                          <label for="user_verifyemail">Confirm Email</label>
                          <input
                            id="user_verifyemail"
                            type="email"
                            name="email"
                            v-model="verify_email"
                            placeholder=""
                            class="form-control"
                            required=""
                            aria-required="true"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row m-t-10">
                      <div class="col-lg-6"></div>
                      <div class="col-lg-6">
                        <b-button
                          type="submit"
                          class="btn btn-primary btn-cons m-t-10"
                          >Update my account</b-button
                        >
                      </div>
                    </div>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fname: "",
      lname: "",
      email: "",
      verify_email: "",
    };
  },
  created: function () {
    this.$store.dispatch("getUserAttributes").then(() => {
      this.fname =
        "name" in this.$store.state.user.attributes
          ? this.$store.state.user.attributes.name
          : "";
      this.lname =
        "family_name" in this.$store.state.user.attributes
          ? this.$store.state.user.attributes.family_name
          : "";
      this.email =
        "email" in this.$store.state.user.attributes
          ? this.$store.state.user.attributes.email
          : "";
      this.verify_email = this.email;
    });
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.$store
        .dispatch("updateUserAttributes", {
          name: this.fname,
          family_name: this.lname,
        })
        .then(() => {
          this.fname =
            "name" in this.$store.state.user.attributes
              ? this.$store.state.user.attributes.name
              : "";
          this.lname =
            "family_name" in this.$store.state.user.attributes
              ? this.$store.state.user.attributes.family_name
              : "";
          this.email =
            "email" in this.$store.state.user.attributes
              ? this.$store.state.user.attributes.email
              : "";
          this.verify_email = this.email;
        });
    },
  },
};
</script>
