<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-transparent">
        <div class="card-header">
          <div class="pull-left">
            <input
              type="text"
              v-model="search"
              placeholder="Search .."
              class="form-control"
            />
          </div>
          <div class="pull-right">
            <!--<button @click.stop="openModal({},'new')" aria-label="Add User" class="btn btn-lg btn-primary btn-cons">-->
            <!--  <i class="pg-icon">add</i> Add User-->
            <!--</button>-->
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <div
              id="detailedTable_wrapper"
              class="dataTables_wrapper no-footer"
            >
              <table
                class="table table-hover table-condensed table-detailed dataTable no-footer"
                id="detailedTable"
                role="grid"
              >
                <tbody>
                  <tr
                    role="row"
                    v-for="g in $store.state.allGrantees"
                    :key="g.pk"
                    v-show="searchShow(g)"
                    class="user-params-tr"
                  >
                    <td class="even p-b-0">
                      <table
                        class="table table-inline"
                        style="margin-top: -35px"
                      >
                        <tbody>
                          <tr role="row" @click.stop="collapseToogle(g)">
                            <td class="v-align-middle">{{ g.grantee_name }}</td>
                          </tr>
                          <tr
                            class="row-details"
                            v-if="collapseToogles['g-' + g.pk]"
                          >
                            <td>
                              <table class="table table-inline">
                                <thead>
                                  <tr role="row">
                                    <!--<th>Grant Id</th>-->
                                    <th>Funding Stream</th>
                                    <th>Cohort</th>
                                    <th>Providers</th>
                                    <th>Grantee Users</th>
                                    <th>Provider Users</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="gt in filterGrants(g.pk)"
                                    :key="gt.pk"
                                    style="border-bottom: solid 1px grey"
                                  >
                                    <!--<td>{{gt.pk}}</td>-->
                                    <td>
                                      <p>
                                        {{
                                          $store.state.allFundingStreams.find(
                                            (f) => f.pk == gt.fk_funding_streams
                                          ).funding_stream_name
                                        }}
                                      </p>
                                    </td>
                                    <td>
                                      <p>{{ gt.gr_cohort }}</p>
                                    </td>
                                    <td>
                                      <p
                                        v-for="pr in filterProviders(gt)"
                                        :key="pr.pk"
                                      >
                                        {{ pr.provider_name }}
                                      </p>
                                    </td>
                                    <td>
                                      <p
                                        v-for="ug in filterUsers(gt).grantee"
                                        :key="ug.pk"
                                      >
                                        <router-link
                                          :to="{
                                            name: 'admin-users',
                                            params: {
                                              search: getUserEmail(
                                                ug.cognito_id
                                              ).email,
                                            },
                                          }"
                                        >
                                          {{
                                            getUserEmail(ug.cognito_id).email
                                          }}
                                        </router-link>
                                      </p>
                                    </td>
                                    <td>
                                      <p
                                        v-for="up in filterUsers(gt).provider"
                                        :key="up.pk"
                                      >
                                        <router-link
                                          :to="{
                                            name: 'admin-users',
                                            params: {
                                              search: getUserEmail(
                                                up.cognito_id
                                              ).email,
                                            },
                                          }"
                                        >
                                          {{
                                            getUserEmail(up.cognito_id).email
                                          }}
                                        </router-link>
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { API, Auth } from 'aws-amplify';

export default {
  name: "grantee",
  data() {
    return {
      search: "",
      collapseToogles: {},
    };
  },
  created() {},
  methods: {
    filterUsers(gt) {
      return {
        grantee: this.$store.state.allUserAssociations.filter(
          (a) => a.fk_grants == gt.pk && a.fk_roles == 1
        ),
        provider: this.$store.state.allUserAssociations.filter(
          (a) => a.fk_grants == gt.pk && a.fk_roles == 2
        ),
      };
    },
    filterProviders(gt) {
      return this.$store.state.allProviders.filter((p) => p.fk_grants == gt.pk);
    },
    filterGrants(gpk) {
      return this.$store.state.allGrants.filter((gr) => gr.fk_grantees == gpk);
    },
    collapseToogle(_g) {
      var _collapse = "g-" + _g.pk;
      this.$set(
        this.collapseToogles,
        _collapse,
        _collapse in this.collapseToogles
          ? !this.collapseToogles[_collapse]
          : true
      );
      if (this.collapseToogles[_collapse]) {
        // this.getUserAssociations(user)
      }
    },
    searchShow(_g) {
      return (
        this.search.replace(/\s/g, "").length == 0 ||
        (this.search.replace(/\s/g, "").length > 0 &&
          "grantee_name" in _g &&
          _g.grantee_name.toLowerCase().includes(this.search.toLowerCase()))
      );
    },
    getUserEmail(cognito_id) {
      let user = this.$store.state.allUsers.find((u) => u.sub == cognito_id);
      return user || { email: "Not found" };
    },
  },
};
</script>

<style scoped lang="scss"></style>
