import Vue from "vue";
import Vuex from "vuex";
import { API, Auth } from "aws-amplify";
import VueCookies from "vue-cookies";

import helpers from "./helpers";

Vue.use(Vuex);

function initialState() {
  return {
    ready: false,
    countDownDistance: -1,
    alertMsg: "",
    reportingPeriodData: {},
    reportingPeriodStr: {
      // Don't need to set these manually anymore - the values are derived from
      // looking up dates in the database based on store.state.reportingPeriodName
      // (e.g. "Summer 2020", "Winter 2021", ...)
    },
    user: {
      name: "",
      family_name: "",
      full_name: "",
    },
    allUsers: [],
    allRoles: [],
    allGrantees: [],
    allGrants: [],
    allUserAssociations: [],
    allProviders: [],
    allFundingStreams: [],
    allCohorts: [
      {
        cohort: "NA",
      },
      {
        cohort: "FY2018",
      },
      {
        cohort: "FY2019",
      },
      {
        cohort: "FY2020",
      },
    ],
    modals: {
      addProgram: {
        show: false,
        editMode: false,
        providerId: -1,
        pk_program_model: -1,
        name: "",
      },
    },
    granteeId: 0,
    grantees: [{ id: 0, name: "" }],
    granteeSubmissionData: [],
    providerSubmissionData: [],
    programSubmissionData: [],
    programEntrySurveyUploads: [],
    programExitSurveyUploads: [],
    reportingPeriodName: "Summer 2024",
    isDashboardClosed: false,
    isWinterSeason: false,
  };
}

export default new Vuex.Store({
  state: initialState,
  mutations: {
    reset(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    // app init data load ready
    setReadyState(state) {
      state.ready = true;
    },
    // user attributes
    setCognitoUser(state, cognitoUser) {
      if ("Attributes" in cognitoUser) {
        cognitoUser.Attributes.forEach((attr) => {
          cognitoUser[attr.Name] = attr.Value;
        });
      } else {
        Object.keys(cognitoUser.attributes).forEach((key) => {
          cognitoUser[key] = cognitoUser.attributes[key];
        });
      }
      // set defaults for name and family name
      cognitoUser.name = "name" in cognitoUser ? cognitoUser.name : "";
      cognitoUser.family_name =
        "family_name" in cognitoUser ? cognitoUser.family_name : "";
      cognitoUser.full_name = cognitoUser.name + " " + cognitoUser.family_name;
      state.user = cognitoUser;
    },
    setUserGrantees(state, userGrantees) {
      state.grantees = userGrantees;
      let _saved_granteeId = VueCookies.get("granteeId");
      if (
        !!_saved_granteeId &&
        state.grantees.findIndex((x) => x.id == Number(_saved_granteeId)) >= 0
      ) {
        state.granteeId = VueCookies.get("granteeId");
      } else if (!state.granteeId) {
        state.granteeId = state.grantees[0].id;
        VueCookies.set("granteeId", state.granteeId, -1);
      }
    },
    updateAlertMsg(state, error) {
      state.alertMsg =
        !!error && "message" in error
          ? error.message
          : "An error has occurred, please contact support.";
    },
    setAllUsers(state, data) {
      state.allUsers = data;
    },
    setAllFundingStreams(state, data) {
      state.allFundingStreams = data;
    },
    setAllGrantees(state, data) {
      state.allGrantees = data;
    },
    setAllGrants(state, data) {
      state.allGrants = data;
    },
    setAllUserAssociations(state, data) {
      state.allUserAssociations = data;
    },
    setAllProviders(state, data) {
      state.allProviders = data;
    },
    setReportingPeriod(state, data) {
      if (data.length === 1) {
        // set data into store to pull fk_reporting_periods and date values into data submissions
        state.reportingPeriodData = data[0];

        // construct text date intervals (e.g. "July 1, 2020 through December 31, 2020") for page headers
        let months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        let levels = ["gr", "prov", "prog", "prog_reach", "en", "ex"];

        var reportingPeriodStr = {};

        levels.forEach(function (level) {
          let dateStart = new Date(data[0][level + "_reporting_period_start"]);
          let dateStop = new Date(data[0][level + "_reporting_period_stop"]);

          let monthStart = months[dateStart.getUTCMonth()];
          let monthStop = months[dateStop.getUTCMonth()];

          reportingPeriodStr[
            level
          ] = `${monthStart} ${dateStart.getUTCDate()}, ${dateStart.getUTCFullYear()} through
                                       ${monthStop} ${dateStop.getUTCDate()}, ${dateStop.getUTCFullYear()}`;

          if (level === "prov") {
            let year = dateStart.getFullYear();
            state.reportingPeriodYears = `${year.toString()}-${(
              year + 1
            ).toString()}`;
          }
        });

        state.reportingPeriodStr = reportingPeriodStr;
      }
    },
    setAllRoles(state, data) {
      state.allRoles = data;
    },
    setGranteeSubmissionData(state, data) {
      state.granteeSubmissionData = data;
    },
    setProviderSubmissionData(state, data) {
      const index = state.providerSubmissionData.findIndex(
        (p) => p.fk_providers === data[0].fk_providers
      );
      if (index === -1) {
        state.providerSubmissionData.push(data[0]);
      } else {
        state.providerSubmissionData.splice(index, 1, data[0]);
      }
    },
    setProgramSubmissionData(state, data) {
      const index = state.programSubmissionData.findIndex(
        (p) => p.fk_programs === data[0].fk_programs
      );
      if (index === -1) {
        state.programSubmissionData.push(data[0]);
      } else {
        state.programSubmissionData.splice(index, 1, data[0]);
      }
    },
    setProgramUploads(state, payload) {
      const target = `program${payload.target}SurveyUploads`;
      state[target] = payload.data.map((item) => item.fk_programs);
    },
    updateCountDown(state, data) {
      state.countDownDistance = data;
    },
  },
  actions: {
    // User - get attributes
    getUserAttributes({ commit }) {
      return new Promise((resolve) => {
        Auth.currentAuthenticatedUser({ bypassCache: true }).then(
          (setCognitoUser) => {
            commit("setCognitoUser", setCognitoUser);
            resolve(setCognitoUser);
          }
        );
      });
    },
    // User - update attributes
    updateUserAttributes({ dispatch, state }, attrs) {
      return new Promise((resolve) => {
        Auth.updateUserAttributes(state.user, attrs).then(() => {
          dispatch("getUserAttributes").then(() => {
            resolve();
          });
          // Auth.currentAuthenticatedUser({ bypassCache: true }).then({})
        });
      });
    },
    // global data
    getAllUsers({ commit }) {
      return new Promise((resolve, reject) => {
        API.get("webapi", "/users").then((response) => {
          if (response.success) {
            let _users_data = response.data.Users.map((u) => {
              u.Attributes.forEach((attr) => {
                u[attr.Name] = attr.Value;
              });
              u.full_name = u.name + " " + u.family_name;
              return u;
            });
            commit("setAllUsers", _users_data);
            resolve(response, _users_data);
          } else {
            reject(response);
          }
        });
      });
    },
    getAllRoles({ commit }) {
      API.get("webapi", "/admin/roles").then((response) => {
        if (response.success) commit("setAllRoles", response.data);
      });
    },
    getAllFundingStreams({ commit }) {
      API.get("webapi", "/admin/funding_streams").then((response) => {
        if (response.success) commit("setAllFundingStreams", response.data);
      });
    },
    getAllGrantees({ commit }) {
      API.get("webapi", "/admin/grantees").then((response) => {
        if (response.success) commit("setAllGrantees", response.data);
      });
    },
    getAllGrants({ commit }) {
      API.get("webapi", "/admin/grants").then((response) => {
        if (response.success) commit("setAllGrants", response.data);
      });
    },
    getAllUserAssociations({ commit }) {
      API.get("webapi", "/admin/user_associations").then((response) => {
        if (response.success) commit("setAllUserAssociations", response.data);
      });
    },
    getAllProviders({ commit }) {
      API.get("webapi", "/admin/providers").then((response) => {
        if (response.success) commit("setAllProviders", response.data);
      });
    },
    getReportingPeriod({ commit, state }) {
      API.get(
        "webapi",
        `/reporting-periods/${state.reportingPeriodName}/reduce`
      ).then((response) => {
        if (response.success) commit("setReportingPeriod", response.data);
      });
    },
    getUserAssociations({ commit, state }, username) {
      return new Promise((resolve, reject) => {
        if (!username) username = state.user.username;
        API.get("webapi", "/user-associations/" + username)
          .then((response) => {
            if (!response.success) {
              reject(response);
              commit("updateAlertMsg", response.error);
            } else {
              commit("setUserGrantees", response.data);
              resolve(response);
            }
          })
          .catch((error) => {
            console.error(error);
            // commit('updateAlertMsg', error);
            reject(error);
          });
      });
    },
    // user initial app data
    getInitData({ dispatch, commit }, cognitoUser) {
      return new Promise((resolve, reject) => {
        // set user data
        commit("setCognitoUser", cognitoUser);

        dispatch("getReportingPeriod");

        // geta
        dispatch("getUserAssociations", cognitoUser.username)
          .then((response) => {
            if (!response.success) {
              // try again
              var _getInit = function () {
                dispatch("getInitData", cognitoUser);
              };
              setTimeout(_getInit, 3000);
              return;
            }

            // let grant = response.data.find(
            //   (grantee) => grantee.role == "Grantee"
            // );
            // get additional init data for Admins and Grantees
            if (helpers.isAdmin(cognitoUser)) {
              dispatch("getAllUsers");
              dispatch("getAllGrantees");
              dispatch("getAllProviders");
              dispatch("getAllGrants");
              dispatch("getAllUserAssociations");
            }

            dispatch("getAllRoles");
            dispatch("getAllFundingStreams");

            resolve(response);
            commit("setReadyState", true);
          })
          .catch((error) => {
            reject(error);

            // try again
            var _getInit = function () {
              dispatch("getInitData", cognitoUser);
            };
            setTimeout(_getInit, 3000);
          });

        // Promise.all([promise1, promise2, promise3]).then((values) => {
        //  commit('setReadyState', true)
        // });
        //
        // app.get('/user-associations/:user_id', function(req, res) {
        // TODO - get all init data
        // once all data is ready
      });
    },
    // add Program
    addProgram({ dispatch, state }, addProgram) {
      return new Promise((resolve, reject) => {
        API.post(
          "webapi",
          "/providers/" + state.modals.addProgram.providerId + "/programs",
          {
            body: {
              // program_model_id: _program_model_id
              program_name: addProgram.name,
            },
          }
        ).then((response) => {
          if (response.success) {
            dispatch("getUserAssociations");
            resolve(response);
          } else {
            // state.modals.addProgram.errorMsg=('message' in response.error) ? response.error.message : 'An error has occurred, please contact support.'
            reject(response);
          }
        });
      });
    },
    // delete Program
    deleteProgram({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        API.del(
          "webapi",
          "/providers/" + data.provider_id + "/programs/" + data.program_id
        ).then((response) => {
          if (response.success) {
            dispatch("getUserAssociations");
            resolve(response);
          } else {
            // state.modals.addProgram.errorMsg=('message' in response.error) ? response.error.message : 'An error has occurred, please contact support.'
            reject(response);
          }
        });
      });
    },

    deleteSubmissionData(_, data) {
      return new Promise((resolve, reject) => {
        API.del("webapi", `${data.path}`)
          .then((response) => {
            if (!response.success) {
              reject(response);
            } else {
              resolve(response);
            }
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      });
    },

    getSubmissionData({ commit }, data) {
      return new Promise((resolve, reject) => {
        API.get("webapi", `${data.path}`)
          .then((response) => {
            if (!response.success) {
              // commit('updateAlertMsg', response.error);
              reject(response);
            } else {
              if (data.target === "grantee") {
                commit("setGranteeSubmissionData", response.data);
              } else if (data.target === "provider" && response.data.length) {
                commit("setProviderSubmissionData", response.data);
              } else if (data.target === "program" && response.data.length) {
                commit("setProgramSubmissionData", response.data);
              }
              resolve(response);
            }
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      });
    },

    getFileUploadData({ commit }) {
      API.get("webapi", "/program-file-entry-uploads")
        .then((response) => {
          if (response.success) {
            commit({
              type: "setProgramUploads",
              data: response.data,
              target: "Entry",
            });
          }
        })
        .catch((error) => {
          console.error("[getFileUploadData] entry survey error: ", error);
        });
      API.get("webapi", "/program-file-exit-uploads")
        .then((response) => {
          if (response.success) {
            commit({
              type: "setProgramUploads",
              data: response.data,
              target: "Exit",
            });
          }
        })
        .catch((error) =>
          console.error("[getFileUploadData] exit survey error: ", error)
        );
    },

    // forms actions
    submitForm({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        API.put("webapi", `${data.path}`, { body: data.data })
          .then((response) => {
            const payload = JSON.parse(response.data.Payload);
            if (!payload.success) {
              // commit('updateAlertMsg', response.error);
              reject(response);
            } else {
              if (data.target === "grantee" || data.target === "provider") {
                dispatch("getSubmissionData", {
                  path: data.getSubmissionPath,
                  target: data.target,
                }).catch((error) =>
                  console.error("[submitForm] getSubmissionData error: ", error)
                );
              }
              // commit('setFormsData', {formName: data.formName, 'responseData': response.Payload});
              resolve(response);
            }
          })
          .catch((error) => {
            console.error("[submitForm] error: ", error);
            reject(error);
          });
      });
    },
  },
});
