<template>
  <component :is="layout" v-if="loaded">
    <router-view />
  </component>
  <div v-else class="full-height sm-p-t-50 align-items-center d-md-flex">
    <!--loading-->
    <div class="col-md-12 text-center">
      <div class="progress-circle-indeterminate m-t-45" style=""></div>
    </div>
  </div>
</template>

<script>
import { Auth, Hub } from "aws-amplify";
import { components } from "aws-amplify-vue";

// import awsconfig from './aws-config'

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/theme/pages/scss/themes/light/light.scss";

import helpers from "./helpers";
import mixin from "./mixin";

const defaultLayout = "portal";

export default {
  name: "app",
  mixins: [mixin],
  components: {
    ...components,
  },
  data() {
    return {
      countDownDate: null,
      loaded: false,
    };
  },
  created() {
    //
    Hub.listen("auth", ({ payload: { event } }) => {
      switch (event) {
        case "signIn":
          this.initData();
          break;
        case "signOut":
          // this.$disconnect()
          // this.$store.dispatch('clearState')
          this.$router.push({ name: "login" });
          break;
        case "customOAuthState":
          break;
      }
    });
    // _.debounce(initData, 250)
    this.initData();
    // begin mouse move tracking to increase session timeout
    var _this = this;
    document.addEventListener("mousemove", () => {
      _this.countDownDate = new Date(
        new Date().getTime() + 120 * 60000
      ).getTime();
    });
    // remove loading animation for login view
    if (this.$router.currentRoute.name === "login") this.loaded = true;
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    },
  },
  watch: {
    $route(to) {
      let _next = this.beforeRouteEnter(to);
      if (_next) this.$router.push(_next);
    },
    grantee() {
      this.loaded = true;
      let _next = this.beforeRouteEnter(this.$route);
      if (_next) this.$router.push(_next);
    },
  },

  methods: {
    beforeRouteEnter(to) {
      // evaluate if route/parent have restrictions
      if (
        this.isACF(this.$store.state.user) &&
        !(to.name == "dashboard" || to.name == "logout" || to.name == "landing")
      ) {
        return { name: "dashboard" };
      } else if (to.matched[0].meta?.restricted) {
        // evaluate portal and bashboard access
        if (
          to.matched[0].meta.restricted?.access &&
          ((to.matched[0].meta.restricted.access == "portal" &&
            (!this.grantee.has_portal_access || !this.grantee.gr_active)) ||
            (to.matched[0].meta.restricted.access == "dashboard" &&
              !this.grantee.has_dashboard_access))
        ) {
          return { name: "landing" };
        }
        // evaluate access by role
        if (
          to.matched[0].meta.restricted?.role &&
          to.matched[0].meta.restricted.role != this.grantee.role.toLowerCase()
        ) {
          return { name: "landing" };
        }
        // evaluate access by group
        if (
          to.matched[0].meta.restricted?.group &&
          ((to.matched[0].meta.restricted.group == "admin" &&
            !this.isAdmin(this.$store.state.user)) ||
            (to.matched[0].meta.restricted.group == "acf" &&
              !this.isACF(this.$store.state.user)))
        ) {
          return { name: "landing" };
        }
      }
      // users in ACF group can only access the dashboard and logout views
      return false;
    },
    initData() {
      Auth.currentAuthenticatedUser({ bypassCache: true })
        .then(async (user) => {
          if (
            (!("phone_number" in user.attributes) ||
              ("preferredMFA" in user &&
                user.preferredMFA != "SMS_MFA" &&
                user.preferredMFA != "NOMFA")) &&
            !(user.attributes["custom:metadata"] === "securityScan")
          ) {
            if (
              !this.$router.currentRoute ||
              this.$router.currentRoute.name != "login"
            ) {
              this.$router.push({ name: "login" });
            }
            return;
          }

          if (
            !this.$router.currentRoute ||
            this.$router.currentRoute.name === "login" ||
            (!["login", "logout", "landing"].includes(
              this.$router.currentRoute.name
            ) &&
              !helpers.isPortalOpen(this.$store.state.user))
          ) {
            helpers.isACF(this.$store.state.user)
              ? this.$router.push({ name: "dashboard" })
              : this.$router.push({ name: "landing" });
          }
          //
          this.$store.dispatch("getInitData", user).then(() => {
            this.beginCountdown();
          });
          //
        })
        .catch((err) => {
          console.error(err);
          this.$router.push({ name: "login" });
        });
    },
    beginCountdown() {
      this.countDownDate = new Date(
        new Date().getTime() + 120 * 60000 + 1
      ).getTime();
      // Update the count down every 1 second
      var _this = this;
      var x = setInterval(function () {
        var now = new Date().getTime();
        // Find the distance between now and the count down date
        var distance = _this.countDownDate - now;
        _this.$store.commit("updateCountDown", distance);

        // logout when the count down is over
        if (distance < 0) {
          clearInterval(x);
          _this.$router.push({ name: "logout" });
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
body {
  height: 100%;
  font-family: Arial;
  font-size: 14px;
  text-transform: none;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.active {
}

.card-header a:not(.btn) {
  color: black !important;
  opacity: 1 !important;
}

.table thead tr th {
  color: black !important;
  opacity: 1 !important;
}

.table thead tr th {
  color: black !important;
  opacity: 1 !important;
}

.nav-tabs > li > a {
  color: black !important;
  opacity: 1 !important;
}

.form-group label:not(.error) {
  color: black !important;
  opacity: 1 !important;
  font-size: 18px !important;
}

p {
  color: black !important;
  opacity: 1 !important;
}

label {
  font-family: Arial !important;
  opacity: 1 !important;
}

.table tbody tr td {
  font-size: 18px;
}

.card.card-default > .card-header {
  color: black !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Arial !important;
}

.material-icons {
  font-size: inherit;
}

.card .card-header + .card-body {
  font-family: Arial;
  padding-top: 10px;
  color: black !important;
  font-size: 18px;
}

.srae-title {
  font-family: Arial;
  font-size: 36px;
  text-align: left;
}

.srae-subtitle {
  font-family: Arial;
  font-size: 22px;
  text-align: left;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #ffffff;
}

.form-group label:not(.error) {
  font-family: Arial;
  font-size: 14px;
  text-transform: none;
}

.card .card-header .card-title {
  opacity: 1 !important;
  padding: 2px !important;
  font-family: Arial;
  font-size: 20px;
  text-transform: none;
}

.custom-select {
  height: 100%;
  font-family: Arial;
  font-size: 18px;
  text-transform: none;
}

.form-dropdown {
  padding-bottom: 10px;
}

.invalid-input {
  border-color: #dc3545;
}

.form-control {
  font-size: 18px;
}

.error-dark {
  color: #ad0000;
}

form legend {
  font-size: 18px;
  padding-top: 12px;
}
.program_reach_td {
  word-wrap: break-word !important;
  white-space: pre-wrap !important;
  text-overflow: visible !important;
}

label,
input,
button,
select,
textarea {
  font-size: 18px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

a:focus {
  outline: auto !important;
}

.header .profile-dropdown-toggle:focus {
  -webkit-box-shadow: 0px 0px 0px 4px rgba(6, 18, 35, 0.14);
  box-shadow: 0px 0px 0px 4px rgba(6, 18, 35, 0.14);
}

@media (max-width: 991px) {
  .header .brand {
    height: 48px;
    max-width: 200px;
  }

  .page-sidebar {
    z-index: 20;
  }

  .sidebar-header {
    visibility: hidden;
  }
}

.error-message {
  color: #ad0000;
}

.modal-backdrop {
  opacity: 0.3;
}

@media print {
  .no-print {
    display: none;
  }
}
</style>
