<template>
  <div
    class="app-wrapper fixed-header menu-pin"
    v-bind:class="{ 'sidebar-open sidebar-visible': sidebarActive }"
  >
    <div class="page-container no-padding">
      <div class="header">
        <div class="">
          <div class="brand inline">
            <img
              src="@/assets/SRAEPAS_Logo.png"
              align="right"
              class="brand"
              alt="Sexual Risk Avoidance Education - Performance Analysis Study"
              width="225"
            />
          </div>
          <!-- 
          <router-link
            v-if="
              !isACF() &&
              (grantee.role == 'Grantee' || isAdmin()) &&
              canAccessPortal
            "
            to="/grantee"
            class="btn btn-link text-primary m-l-35"
            v-bind:class="{ active: $route.name != 'dashboard' }"
          >
            Portal
          </router-link>
          <router-link
            v-else-if="
              !isACF() && grantee.role == 'Provider' && canAccessPortal
            "
            to="/providers"
            class="btn btn-link text-primary m-l-35"
            v-bind:class="{ active: $route.name != 'dashboard' }"
          >
            Portal
          </router-link>
          -->
          <router-link
            v-if="!isACF() && canAccessPortal"
            :to="
              grantee.role == 'Grantee' || isAdmin()
                ? '/grantee'
                : grantee.role == 'Provider'
                ? '/providers'
                : '/landing'
            "
            class="btn btn-link text-primary m-l-35"
            v-bind:class="{ active: $route.name != 'dashboard' }"
          >
            Portal
          </router-link>
          <router-link
            to="/dashboard"
            class="btn btn-link text-primary m-l-10"
            v-if="grantee.has_dashboard_access"
            v-bind:class="{
              active: $router.currentRoute.name === 'dashboard',
              disabled: !canAccessDashboard,
              'm-l-10': canAccessPortal,
              'm-l-35': !canAccessPortal,
            }"
          >
            Dashboard
          </router-link>

          <span v-if="countDownTime">
            Your session will end after {{ countDownTime }} of inactivity
          </span>
        </div>
        <div class="d-flex align-items-center">
          <div
            class="pull-left p-r-10 fs-14 font-heading d-lg-inline-block d-none m-l-20"
            @click="viewProfileDropdown = !viewProfileDropdown"
          >
            <span class="semi-bold">{{ $store.state.user.name }} </span>
            <span class="text-master">
              {{ $store.state.user.family_name }}</span
            >
          </div>
          <div class="dropdown pull-right d-md-inline-block d-none">
            <button
              class="profile-dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              @click="viewProfileDropdown = !viewProfileDropdown"
            >
              <span
                class="thumbnail-wrapper d32 circular inline bg-master-light p-t-5"
                >{{ $store.state.user.name[0]
                }}{{ $store.state.user.family_name[0] }}
              </span>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right profile-dropdown show"
              role="menu"
              v-if="viewProfileDropdown"
              @click="viewProfileDropdown = false"
            >
              <a class="dropdown-item"
                ><span
                  >Signed in as <br /><b>{{
                    $store.state.user.full_name
                  }}</b></span
                ></a
              >
              <div class="dropdown-divider"></div>
              <!--<router-link to="/profile" class="dropdown-item">My Account<i class="pg-settings_small"></i></router-link>-->
              <router-link
                v-if="$store.state.grantees.length > 1"
                to="/landing"
                class="dropdown-item"
                >Switch Grants</router-link
              >
              <div class="dropdown-divider"></div>
              <!--<router-link to="/about" class="dropdown-item">About</router-link>-->
              <router-link
                to="/logout"
                class="clearfix bg-master-lighter dropdown-item"
              >
                Signout
                <i class="pg-power"></i
              ></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="page-content-wrapper">
        <b-alert
          :show="alertDismissCountDown"
          variant="danger"
          dismissible
          fade
          @dismissed="dismissAlert"
          class="content"
          @dismiss-count-down="alertCountDownChanged"
        >
          <span v-html="this.$store.state.alertMsg"></span>
        </b-alert>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../mixin";
import helpers from "../helpers";

export default {
  mixins: [mixin],
  data() {
    return {
      sidebarActive: false,
      myEvaluationsSubMenu: false,
      viewProfileDropdown: false,
      alertDismissSecs: 5,
      alertDismissCountDown: 0,
    };
  },
  computed: {
    canAccessPortal() {
      return (
        this.grantee.has_portal_access &&
        helpers.isPortalOpen(this.$store.state.user)
      );
    },
    canAccessDashboard() {
      return (
        !this.$store.state.isDashboardClosed ||
        helpers.isACF(this.$store.state.user) ||
        helpers.isAdmin(this.$store.state.user)
      );
    },
    route() {
      return this.$route.path;
    },
    mprLogoBorder() {
      return this.route === "/about" ? "mpr-logo" : "";
    },
    alertMsg() {
      return this.$store.state.alertMsg;
    },
    countDownTime() {
      let hours = Math.floor(
        this.$store.state.countDownDistance / (1000 * 60 * 60)
      );
      let minutes = Math.floor(
        (this.$store.state.countDownDistance % (1000 * 60 * 60)) / (1000 * 60)
      );
      let seconds = Math.floor(
        (this.$store.state.countDownDistance % (1000 * 60)) / 1000
      );

      if (hours == 0 && minutes == 0) {
        return `${seconds}s`;
      } else {
        return `${hours}h and ${minutes}m`;
      }
    },
  },
  watch: {
    alertMsg() {
      if (this.alertMsg === "") this.$store.commit("alertMsg", "");
      else this.alertDismissCountDown = this.alertDismissSecs;
    },
  },
  methods: {
    dismissAlert() {
      this.$store.commit("alertMsg", "");
    },
    alertCountDownChanged(alertDismissCountDown) {
      this.alertDismissCountDown = alertDismissCountDown;
    },
  },
};
</script>

<style lang="scss">
@media only screen and (min-width: 980px) {
  body.menu-pin .header .brand {
    width: 225px;
    padding-right: 5px;
    padding-left: 15px;
  }
  body.menu-pin .header img {
    padding: 10px;
  }
}
</style>
