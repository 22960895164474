<template>
  <div class="row">
    <div class="col-12 no-padding">
      <div class="card card-transparent">
        <div class="card-header p-t-5">
          <!--<div class="card-title">-->
          <div class="pull-left">
            <router-link
              :to="{ name: 'providers' }"
              class="btn btn-lg btn-block btn-primary btn-icon-left"
            >
              <i class="pg-icon md-18">arrow_left</i>
              <span>Return to Provider Table</span>
            </router-link>
          </div>
          <div class="pull-right">
            <button
              @click="showAddProgram()"
              class="btn btn-lg btn-block btn-primary btn-icon-left pull-right"
              v-if="servedYouth"
            >
              <i class="pg-icon">add</i> Add Program
            </button>
          </div>
          <!--</div>-->
        </div>
        <div class="card-body">
          <div v-if="!servedYouth">
            <label
              ><i
                >No program-level data is required for providers that did not
                serve youth during the reporting period</i
              ></label
            >
          </div>
          <div class="table-responsive" v-if="servedYouth">
            <div
              id="condensedTable_wrapper"
              class="dataTables_wrapper no-footer"
            >
              <table
                class="table table-hover table-condensed dataTable no-footer"
                id="condensedTable"
                role="grid"
              >
                <thead>
                  <tr role="row">
                    <th rowspan="1" colspan="2">Program Model Name</th>
                    <th rowspan="1" colspan="1">
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <span>Program Active?</span>
                        <b-button
                          v-b-tooltip.hover.right="
                            'If the program was not active during this reporting period, no other data will be requested for that program during this reporting period, and the program will not be included in the data submission validation.'
                          "
                          size="sm"
                          >?</b-button
                        >
                      </div>
                    </th>
                    <th rowspan="1" colspan="1">Enter/Edit Program Data</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="program in provider.programs"
                    :key="program.id + keyChanger"
                    role="row"
                    class="odd-even"
                  >
                    <td class="v-align-middle semi-bold" colspan="2">
                      {{ program.name }}
                    </td>
                    <td>
                      <b-form-select
                        v-if="!!programSubmissions[program.id]"
                        v-model="programSubmissions[program.id].prog_active"
                        :id="`checkbox-provider-${program.id}`"
                        @change="
                          checkIfSettingInactive(
                            provider,
                            program,
                            programSubmissions[program.id].prog_active
                          )
                        "
                        class="active-selector"
                        :options="[
                          {
                            value: null,
                            text: 'Select Option',
                            disabled: true,
                          },
                          { value: false, text: 'No' },
                          { value: true, text: 'Yes' },
                        ]"
                      >
                      </b-form-select>
                    </td>
                    <td class="v-align-middle text-center">
                      <router-link
                        :to="{
                          name: 'program-data',
                          params: { pid: $route.params.pid, pmid: program.id },
                        }"
                        v-bind:class="{
                          disabled:
                            programSubmissions[program.id] &&
                            (programSubmissions[program.id].prog_active ==
                              false ||
                              programSubmissions[program.id].prog_active ===
                                null),
                        }"
                      >
                        <i class="material-icons">create</i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="footer-msg">
          On the Provider-Level Information page, you indicated that you
          currently have
          <strong>{{ prov_num_program_models_implemented || "no" }}</strong>
          programs.
        </div>
      </div>
    </div>
    <b-modal
      v-model="confirmDelete.show"
      title="Delete program model data?"
      hide-footer
    >
      <div class="row">
        <div class="col-12 text-center">
          <h6>
            Are you sure you wish to delete program model data for
            <strong>{{ confirmDelete.program_name }}</strong
            >?
          </h6>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 text-left">
          <button
            @click="confirmDelete.show = false"
            aria-label="Cancel delete program model"
            type="button"
            class="btn btn-lg btn-primary"
          >
            Cancel
          </button>
        </div>
        <div class="col-md-6 text-right">
          <button
            @click="deleteProgram(confirmDelete.program_id)"
            aria-label="Delete program model"
            class="btn btn-lg btn-primary"
          >
            Delete
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="confirmSetInactiveModal.show"
      title="Set program as inactive?"
      hide-footer
    >
      <div class="row">
        <div class="col-12 text-center">
          <h6>Are you sure you wish to mark this program as inactive?</h6>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 text-left">
          <button
            @click="
              cancelUpdateProgramActiveStatus(confirmSetInactiveModal.program)
            "
            aria-label="Cancel Set Program Inactive"
            type="button"
            class="btn btn-lg btn-primary"
          >
            No
          </button>
        </div>
        <div class="col-md-6 text-right">
          <button
            @click="
              updateProgramActiveStatus(
                confirmSetInactiveModal.provider,
                confirmSetInactiveModal.program,
                false
              )
            "
            aria-label="Set Program Inactive"
            class="btn btn-lg btn-primary"
          >
            Yes
          </button>
        </div>
      </div>
    </b-modal>

    <confirmModal
      :show="confirmModal.show"
      :headerTitle="confirmModal.headerTitle"
      :bodyText="confirmModal.bodyText"
      :close="confirmModalClose"
    />
  </div>
</template>

<script>
import mixin from "../../mixin";

import confirmModal from "../../components/confirm-modal.vue";

export default {
  mixins: [mixin],
  components: {
    confirmModal,
  },
  data() {
    return {
      keyChanger: 0,
      servedYouth: false,
      confirmSetInactiveModal: {
        show: false,
        provider: null,
        program: null,
      },
      confirmDelete: {
        show: false,
        program_name: "",
        program_id: "",
      },
      confirmModal: {
        show: false,
        headerTitle: "",
        bodyText: "",
      },
      programSubmissions: {},
      prov_num_program_models_implemented: 0,
    };
  },
  computed: {
    isWinterSeason: function () {
      return this.$store.state.isWinterSeason;
    },
  },
  created() {
    this.getProviderData();
  },
  methods: {
    checkIfSettingInactive(provider, program, is_active) {
      let selectedProgramSubmissionData = this.programSubmissions[program.id];
      let hasEnteredData =
        Object.keys(selectedProgramSubmissionData).length > 1 &&
        Object.keys(selectedProgramSubmissionData).find(
          (key) =>
            key.includes("prog_") && selectedProgramSubmissionData[key] !== null
        );
      if (hasEnteredData && is_active == false) {
        this.confirmSetInactiveModal = {
          show: true,
          provider,
          program,
        };
      } else {
        this.updateProgramActiveStatus(provider, program, is_active);
      }
    },
    cancelUpdateProgramActiveStatus(program) {
      this.programSubmissions[program.id].prog_active = true;
      this.confirmSetInactiveModal.show = false;
    },
    updateProgramActiveStatus(provider, program, is_active) {
      this.confirmSetInactiveModal.show = false;
      let form = {
        formName: "programreach",
        formData: [
          {
            tableName: "program_submissions",
            tableData: [
              [
                {
                  name: "provider_name",
                  value: {
                    stringValue: "Test provider",
                  },
                },
                {
                  name: "grantee_name",
                  value: {
                    stringValue: "Test grantee",
                  },
                },
                {
                  name: "prog_active",
                  value: {
                    booleanValue: is_active,
                  },
                },
              ],
            ],
          },
        ],
      };

      this.$store
        .dispatch("submitForm", {
          action: "submit",
          path: `/grantees/${this.grantee.id}/providers/${provider.id}/programs/${program.id}/reach/submissions/${this.$store.state.reportingPeriodData.pk}`,
          data: form,
        })
        .then((r) => {
          this.status_msg = r;
        });
    },
    openConfirmDeleteModal(program_id, program_name) {
      this.confirmDelete = {
        show: true,
        program_name,
        program_id,
      };
    },
    deleteProgram(program_id) {
      this.$store
        .dispatch("deleteProgram", {
          provider_id: this.$route.params.pid,
          program_id: program_id,
        })
        .then(() => {
          this.keyChanger++;
          this.confirmDelete.show = false;
          this.confirmModal.headerTitle = "Program Model Deleted";
          this.confirmModal.bodyText = `Program Model <i>${this.confirmDelete.program_name}</i> deleted successfully.`;
          this.confirmModal.show = true;
        });
    },
    confirmModalClose() {
      this.confirmModal.show = false;
    },
    getProviderData() {
      this.$store
        .dispatch("getSubmissionData", {
          path: `/providers/${this.$route.params.pid}/submissions/${this.$store.state.reportingPeriodData.pk}`,
          target: "provider",
        })
        .then((r) => {
          if (r.data.records.length > 0) {
            var colnames = r.data.columnMetadata.map((obj) => obj.name);
            var record = r.data.records[0];
            var value;

            for (var i = 0; i < colnames.length; i++) {
              //r.data.records[0].map(obj => Object.values(obj)[0]);
              var colname = colnames[i];

              if (Object.keys(record[i]).includes("isNull")) {
                value = null;
              } else {
                value = Object.values(record[i])[0];
              }

              if (colname === "prov_provider_serve_youth") {
                this.servedYouth = value;
              }

              if (colname === "prov_num_program_models_implemented") {
                this.prov_num_program_models_implemented = value;
              }
            }
          }

          if (this.provider.programs) {
            this.provider.programs.forEach((program) => {
              this.programSubmissions = {
                ...this.programSubmissions,
                [program.id]: {
                  prog_active: null,
                },
              };
              this.$store
                .dispatch("getSubmissionData", {
                  path: `/grantees/${this.grantee.id}/providers/${this.$route.params.pid}/programs/${program.id}/reach/submissions/${this.$store.state.reportingPeriodData.pk}/reduce`,
                })
                .then((r) => {
                  if (r.data.program_reach && r.data.program_reach[0]) {
                    this.programSubmissions = {
                      ...this.programSubmissions,
                      [program.id]: r.data.program_reach[0],
                    };
                  }
                });
            });
          }
        });
    },

    showAddProgram() {
      this.$store.state.modals.addProgram.providerId = this.$route.params.pid;
      this.$store.state.modals.addProgram.editMode = false;
      this.$store.state.modals.addProgram.show = true;
    },
    showEditProgram() {
      this.$store.state.modals.addProgram.providerId = this.$route.params.pid;
      this.$store.state.modals.addProgram.editMode = true;
      this.$store.state.modals.addProgram.show = true;
    },
  },
  watch: {
    provider(curr, prev) {
      if (
        curr.programs &&
        (!prev.programs || curr.programs.length > prev.programs.length)
      ) {
        this.getProviderData();
        this.getAllProviderSubmissionData();
      }
    },

    "$store.state.reportingPeriodData": function () {
      this.getProviderData();
      this.getAllProviderSubmissionData();
    },
  },
};
</script>
<style>
.footer-msg {
  text-align: center;
  margin-bottom: 18px;
  font-size: 18px;
}
.delete-modal-icon {
  color: #541d76;
  cursor: pointer;
}

.disabled {
  color: grey;
  opacity: 0.5;
  pointer-events: none;
}

.not-allowed {
  cursor: not-allowed;
}

.table.table-condensed tbody tr td .active-selector {
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}
</style>
