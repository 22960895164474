<template>
  <div class="content">
    <div class="container-fluid container-fixed-lg">
      <!--Header Info-->
      <div class="row">
        <div class="col-12">
          <div class="card card-default">
            <div class="card-header separator">
              <div class="card-title">
                <ol class="breadcrumb no-margin no-padding">
                  <li class="breadcrumb-item">Survey Data</li>
                </ol>
              </div>
            </div>
            <div class="card-body">
              <h1 class="srae-title">Upload Participant Survey Data</h1>
              <h4>
                <b class="srae-subtitle">Grantee Name:</b> {{ grantee.name }}
              </h4>
              <h4><b class="srae-subtitle">Cohort:</b> {{ grantee.cohort }}</h4>
              <h4>
                <b class="srae-subtitle">Funding Stream:</b>
                {{ grantee.funding_stream }}
              </h4>
              <h4>
                <b class="srae-subtitle">Report Period:</b>
                {{ $store.state.reportingPeriodStr.en }}
              </h4>
              <div class="row">
                <div class="col-9">
                  Users MUST update the Program Provider Table and Program Model
                  Table AND answer the program-level screener questions (on the
                  Program-level Data page) BEFORE uploading survey data files.
                  This information allows the Portal to determine whether
                  participant entry and/or exit survey data are expected for
                  each program.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body no-padding">
        <ul
          class="nav nav-tabs nav-tabs-simple d-none d-md-flex d-lg-flex d-xl-flex"
        >
          <li class="nav-item">
            <router-link
              :to="{ name: 'upload-entry' }"
              class="d-flex align-items-center"
              v-bind:class="{
                active: $router.currentRoute.name !== 'upload-exit',
              }"
            >
              <span>Entry survey data</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'upload-exit' }"
              class="d-flex align-items-center"
              v-bind:class="{
                active: $router.currentRoute.name === 'upload-exit',
              }"
            >
              <span>Exit survey data</span>
            </router-link>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane sm-no-padding slide-left active">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../../mixin";

export default {
  mixins: [mixin],
};
</script>
