<template>
  <div class="row">
    <div class="col-12 no-padding">
      <div class="card card-transparent">
        <!--Card Header-->
        <div class="card-header p-t-5">
          <div class="card-title">
            <div class="col-xs-12">
              <router-link
                :to="{ name: 'programs', params: { pid: provider.id } }"
                class="btn btn-lg btn-block btn-primary btn-icon-left"
              >
                <i class="pg-icon md-18">arrow_left</i>
                <span>Return to Program Model Table</span>
              </router-link>
            </div>
          </div>
        </div>
        <!--Card Body-->
        <div class="card-body no-padding">
          <ul
            class="nav nav-tabs nav-tabs-simple d-none d-md-flex d-lg-flex d-xl-flex"
          >
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'program-data',
                  params: { pid: $route.params.pid, pmid: $route.params.pmid },
                }"
                class="d-flex align-items-center"
                v-bind:class="{
                  active: $router.currentRoute.name === 'program-data',
                }"
              >
                <span
                  >Program-level measures of structure, cost, and support for
                  program implementation</span
                >
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'program-reach',
                  params: { pid: $route.params.pid, pmid: $route.params.pmid },
                }"
                class="d-flex align-items-center"
                v-bind:class="{
                  active: $router.currentRoute.name === 'program-reach',
                }"
              >
                <span
                  >Program-level measures of attendance, reach, and dosage</span
                >
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'program-entry-exit',
                  params: { pid: $route.params.pid, pmid: $route.params.pmid },
                }"
                class="d-flex align-items-center"
                v-bind:class="{
                  active: $router.currentRoute.name === 'program-entry-exit',
                }"
              >
                <span>Information about survey administration</span>
              </router-link>
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane sm-no-padding slide-left active">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../../mixin";

export default {
  mixins: [mixin],
  data() {
    return {};
  },
  methods: {},
  computed: {
    isWinterSeason: function () {
      return this.$store.state.isWinterSeason;
    },
  },
};
</script>
<style scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

li.not-allowed {
  cursor: not-allowed;
}
</style>
