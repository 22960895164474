<template>
  <div class="content">
    <div class="container-fluid container-fixed-lg">
      <div class="row">
        <div class="col-12">
          <div class="card card-default">
            <div class="card-header separator">
              <div class="card-title">
                <ol class="breadcrumb no-margin no-padding">
                  <li class="breadcrumb-item">
                    <router-link
                      :to="{ name: 'providers' }"
                      class="no-margin no-padding"
                      >Admin</router-link
                    >
                  </li>
                  <li
                    class="breadcrumb-item"
                    v-if="$route.name === 'admin-users'"
                  >
                    <router-link
                      :to="{ name: 'admin-users' }"
                      class="no-margin no-padding"
                    >
                      Users
                    </router-link>
                  </li>
                  <li
                    class="breadcrumb-item"
                    v-if="$route.name === 'admin-grantees'"
                  >
                    <router-link
                      :to="{ name: 'admin-grantees' }"
                      class="no-margin no-padding"
                    >
                      Grantees
                    </router-link>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.table.table-condensed tbody tr td,
.table.table-condensed tbody tr td :not(.dropdown-default),
.table.table-condensed thead tr th {
  white-space: normal !important;
}
</style>
