<template>
  <div class="content">
    <div class="container-fluid container-fixed-lg">
      <!--Header Info-->
      <div class="row">
        <div class="col-12">
          <div class="card card-default">
            <div class="card-header separator">
              <div class="card-title">
                <ol class="breadcrumb no-margin no-padding">
                  <li class="breadcrumb-item">
                    <router-link
                      :to="{ name: 'grantee' }"
                      class="no-margin no-padding"
                      >Grantee Data</router-link
                    >
                  </li>
                </ol>
              </div>
            </div>
            <div class="card-body">
              <h1 class="srae-title">
                Grantee-Level
                {{
                  isWinterSeason
                    ? "Information"
                    : "Measures of Structure, Cost, and Support"
                }}
              </h1>
              <h4>
                <b class="srae-subtitle">Grantee Name:</b> {{ grantee.name }}
              </h4>

              <h4><b class="srae-subtitle">Cohort:</b> {{ grantee.cohort }}</h4>

              <h4>
                <b class="srae-subtitle">Funding Stream:</b>
                {{ grantee.funding_stream }}
              </h4>

              <h4>
                <b class="srae-subtitle">Report Period:</b>
                {{ $store.state.reportingPeriodStr.gr }}
                <b-button
                  v-if="!isWinterSeason"
                  v-b-tooltip.hover
                  :title="`All grantees should submit data for the same reporting period—the federal grant year, ${$store.state.reportingPeriodStr.gr}—even if it does not align with the grantee organization’s fiscal year.`"
                  size="sm"
                  >?</b-button
                >
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="isWinterSeason">
        <div class="card card-default" id="winter-season-card">
          <div class="card-body">
            <p>
              Welcome to the Sexual Risk Avoidance Education (SRAE) Performance
              Measures Portal (SPMP). Some SRAE performance measures are
              submitted annually each summer, and others are submitted twice a
              year, in winter and summer. Because measures of structure, cost,
              and support for program implementation are submitted annually, the
              only information to record on this page is the number of providers
              that were active during the
              {{ $store.state.reportingPeriodStr.gr }} reporting period. After
              entering this number, proceed to the Program Provider Table page.
            </p>
          </div>
        </div>
      </div>
      <!-- Grantee Data Form-->
      <div class="row">
        <div class="col-12">
          <div v-if="errors.length || showstoppers.length">
            <p><b>Please correct the following error(s):</b></p>
            <ul>
              <li
                v-for="showstopper in showstoppers"
                v-bind:key="showstopper"
                class="error-message"
              >
                {{ showstopper }}
              </li>
              <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
            </ul>
          </div>
          <b-form role="form">
            <div class="row" v-if="!isWinterSeason">
              <div class="col-lg-6">
                <div class="row">
                  <div class="card card-default">
                    <div class="card-header separator">
                      <div class="card-title">
                        SRAE operational status related to COVID-19
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="form-group row">
                        <div class="col-8">
                          <label for="gr_interrupt_ops_covid"
                            >Did the grantee experience any interruptions of
                            SRAE administrative operations during the reporting
                            period due to COVID-19?</label
                          >
                        </div>
                        <div class="col-1"></div>
                        <div class="col-3">
                          <b-form-select
                            @change="dataUpdated()"
                            :class="{
                              'invalid-input': this.formErrors
                                .gr_interrupt_ops_covid.errors,
                            }"
                            v-model="
                              granteeSubmissionData.gr_interrupt_ops_covid
                            "
                            id="gr_interrupt_ops_covid"
                            :options="yn"
                          />
                        </div>
                      </div>
                      <div
                        class="form-group row error-message"
                        v-if="this.formErrors.gr_interrupt_ops_covid.errors"
                      >
                        {{ this.formErrors.gr_interrupt_ops_covid.message }}
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="form-group row">
                        <div class="col-8">
                          <label for="gr_interrupt_services_covid"
                            >Did the grantee experience any interruptions of
                            SRAE services to youth during the reporting period
                            due to COVID-19?</label
                          >
                        </div>
                        <div class="col-1"></div>
                        <div class="col-3">
                          <b-form-select
                            @change="dataUpdated()"
                            :class="{
                              'invalid-input': this.formErrors
                                .gr_interrupt_services_covid.errors,
                            }"
                            v-model="
                              granteeSubmissionData.gr_interrupt_services_covid
                            "
                            id="gr_interrupt_services_covid"
                            :options="yn"
                          />
                        </div>
                      </div>
                      <div
                        class="form-group row error-message"
                        v-if="
                          this.formErrors.gr_interrupt_services_covid.errors
                        "
                      >
                        {{
                          this.formErrors.gr_interrupt_services_covid.message
                        }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="card card-default">
                    <div class="card-header separator">
                      <div class="card-title">Grantee staff</div>
                    </div>
                    <div class="card-body">
                      <div class="form-group row">
                        <div class="col-9">
                          <label for="gr_grantee_staff_num"
                            >Number of grantee staff involved in overseeing SRAE
                          </label>
                        </div>
                        <div class="col-1">
                          <b-button
                            v-b-tooltip.hover
                            title="This measure should include grantee staff, such as SRAE program directors and program coordinators, who were directly responsible for administering, managing, and overseeing the SRAE program. Do not include grantee staff who provide programming directly to youth but do not oversee SRAE in this measure. Those staff should be counted in the measure of facilitators on the program provider data page. If staff play both roles, they should be included in both measures."
                            size="sm"
                            >?</b-button
                          >
                        </div>
                        <div class="col-2">
                          <input
                            v-model="granteeSubmissionData.gr_grantee_staff_num"
                            id="gr_grantee_staff_num"
                            @input="addThousandComma('gr_grantee_staff_num')"
                            @blur="checkForm()"
                            type="text"
                            class="form-control"
                            :class="{
                              'invalid-input':
                                formErrors.gr_grantee_staff_num.errors,
                            }"
                            required=""
                          />
                        </div>
                      </div>
                      <div
                        class="form-group row error-message"
                        v-if="this.formErrors.gr_grantee_staff_num.errors"
                      >
                        {{ this.formErrors.gr_grantee_staff_num.message }}
                      </div>

                      <div class="form-group row">
                        <div class="col-9">
                          <label for="gr_grantee_ftes_num"
                            >Number of grantee full-time equivalents (FTEs)
                            involved in overseeing SRAE</label
                          >
                        </div>
                        <div class="col-1">
                          <b-button
                            v-b-tooltip.hover
                            title='For example, a grantee with two staff who each spend half-time working on SRAE would enter "2" for the number of grantee staff and "1.0" for the number of FTEs involved in overseeing SRAE'
                            size="sm"
                            >?</b-button
                          >
                        </div>
                        <div class="col-2">
                          <input
                            v-model="granteeSubmissionData.gr_grantee_ftes_num"
                            id="gr_grantee_ftes_num"
                            @input="addThousandCommaDec('gr_grantee_ftes_num')"
                            @blur="checkForm()"
                            type="text"
                            class="form-control"
                            :class="{
                              'invalid-input':
                                formErrors.gr_grantee_ftes_num.errors,
                            }"
                            required=""
                          />
                        </div>
                      </div>
                      <div
                        class="form-group row error-message"
                        v-if="this.formErrors.gr_grantee_ftes_num.errors"
                      >
                        {{ this.formErrors.gr_grantee_ftes_num.message }}
                      </div>

                      <div
                        class="form-group row error-message"
                        v-if="
                          this.showstoppers.includes(
                            'Number of FTEs cannot exceed total number of staff.'
                          )
                        "
                      >
                        Number of FTEs cannot exceed total number of staff.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="card card-default">
                  <div class="card-header separator">
                    <div class="card-title">SRAE grant funding</div>
                  </div>

                  <div class="card-body">
                    <div class="form-group row">
                      <div class="col-8">
                        <label for="gr_srae_grant_funding"
                          >Enter total dollar amount of SRAE grant funding
                          obligated (including any carryover funds) during the
                          reporting period</label
                        >
                      </div>
                      <div class="col-4">
                        <input
                          v-model="granteeSubmissionData.gr_srae_grant_funding"
                          id="gr_srae_grant_funding"
                          @input="addThousandComma('gr_srae_grant_funding')"
                          @blur="checkForm()"
                          type="text"
                          class="form-control"
                          :class="{
                            'invalid-input':
                              formErrors.gr_srae_grant_funding.errors,
                          }"
                          required=""
                        />
                      </div>
                    </div>
                    <div
                      class="form-group row error-message"
                      v-if="this.formErrors.gr_srae_grant_funding.errors"
                    >
                      {{ this.formErrors.gr_srae_grant_funding.message }}
                    </div>
                  </div>
                </div>

                <div class="card card-default">
                  <div class="card-header separator">
                    <div class="card-title">
                      Percentage of total SRAE grant funding obligated for:
                      <b-button
                        v-b-tooltip.hover
                        title="Percentages must sum to 100%."
                        size="sm"
                        >?</b-button
                      >
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row form-group">
                      <div class="col-10">
                        <label for="gr_pct_direct_service"
                          >Direct service provision (youth programming)</label
                        >
                      </div>
                      <div class="col-2">
                        <input
                          v-model="granteeSubmissionData.gr_pct_direct_service"
                          id="gr_pct_direct_service"
                          @input="addThousandCommaDec('gr_pct_direct_service')"
                          type="number"
                          class="form-control"
                          :class="{
                            'invalid-input':
                              formErrors.gr_pct_direct_service.errors,
                          }"
                          required=""
                        />
                      </div>
                      <div
                        class="form-group row error-message"
                        v-if="this.formErrors.gr_pct_direct_service.errors"
                      >
                        {{ this.formErrors.gr_pct_direct_service.message }}
                      </div>
                    </div>

                    <div class="row form-group">
                      <div class="col-10">
                        <label for="gr_pct_training"
                          >Training, technical assistance, and monitoring
                          conducted at the grantee level</label
                        >
                      </div>
                      <div class="col-2">
                        <input
                          v-model="granteeSubmissionData.gr_pct_training"
                          id="gr_pct_training"
                          @input="addThousandCommaDec('gr_pct_training')"
                          type="number"
                          class="form-control"
                          :class="{
                            'invalid-input': formErrors.gr_pct_training.errors,
                          }"
                          required=""
                        />
                      </div>
                      <div
                        class="form-group row error-message"
                        v-if="this.formErrors.gr_pct_training.errors"
                      >
                        {{ this.formErrors.gr_pct_training.message }}
                      </div>
                    </div>

                    <div class="row form-group">
                      <div class="col-10">
                        <label for="gr_pct_evaluation"
                          >Evaluation and/or research</label
                        >
                      </div>
                      <div class="col-2">
                        <input
                          v-model="granteeSubmissionData.gr_pct_evaluation"
                          id="gr_pct_evaluation"
                          @input="addThousandCommaDec('gr_pct_evaluation')"
                          type="number"
                          class="form-control"
                          :class="{
                            'invalid-input':
                              formErrors.gr_pct_evaluation.errors,
                          }"
                          required=""
                        />
                      </div>
                      <div
                        class="form-group row error-message"
                        v-if="this.formErrors.gr_pct_evaluation.errors"
                      >
                        {{ this.formErrors.gr_pct_evaluation.message }}
                      </div>
                    </div>

                    <div class="row form-group">
                      <div class="col-10">
                        <label for="gr_pct_admin_purposes"
                          >Retained for administrative purposes at the grantee
                          level</label
                        >
                      </div>
                      <div class="col-2">
                        <input
                          v-model="granteeSubmissionData.gr_pct_admin_purposes"
                          id="gr_pct_admin_purposes"
                          @input="addThousandCommaDec('gr_pct_admin_purposes')"
                          type="number"
                          class="form-control"
                          :class="{
                            'invalid-input':
                              formErrors.gr_pct_admin_purposes.errors,
                          }"
                          required=""
                        />
                      </div>
                      <div
                        class="form-group row error-message"
                        v-if="this.formErrors.gr_pct_admin_purposes.errors"
                      >
                        {{ this.formErrors.gr_pct_admin_purposes.message }}
                      </div>
                    </div>

                    <div
                      class="row form-group error-message"
                      v-if="
                        this.errors.includes('Percentages do not sum to 100%.')
                      "
                    >
                      Percentages do not sum to 100.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="!isWinterSeason">
              <div class="card card-default">
                <div class="card-header separator">
                  <div class="card-title">Grantee observation</div>
                </div>
                <div class="card-body row">
                  <div class="col-md-12 col-lg-6">
                    <div class="row grantee-select-row">
                      <div class="col-8 grantee-select">
                        <label for="gr_grantee_designee_observed"
                          >Did grantee or its designee observe program delivery
                          to monitor quality and fidelity to program
                          models?</label
                        >
                      </div>
                      <div class="col-4">
                        <b-form-select
                          @change="dataUpdated()"
                          :class="{
                            'invalid-input': this.formErrors
                              .gr_grantee_designee_observed.errors,
                          }"
                          v-model="
                            granteeSubmissionData.gr_grantee_designee_observed
                          "
                          id="gr_grantee_designee_observed"
                          :options="yn"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-6"
                    v-show="granteeSubmissionData.gr_grantee_designee_observed"
                  >
                    <fieldset class="col-12">
                      <legend>
                        Type of organization that conducted observations:
                      </legend>
                      <div>
                        <b-form-checkbox
                          @change="dataUpdated()"
                          v-model="granteeSubmissionData.gr_grantee_obsvs"
                          name="grantee_org_type"
                          id="gr_grantee_obsvs"
                        >
                          Grantee
                        </b-form-checkbox>
                      </div>
                      <div>
                        <b-form-checkbox
                          @change="dataUpdated()"
                          v-model="granteeSubmissionData.gr_developer_obsvs"
                          name="grantee_org_type"
                          id="gr_developer_obsvs"
                        >
                          Developer
                        </b-form-checkbox>
                      </div>
                      <div>
                        <b-form-checkbox
                          @change="dataUpdated()"
                          v-model="granteeSubmissionData.gr_ta_partner_obsvs"
                          name="grantee_org_type"
                          id="gr_ta_partner_obsvs"
                        >
                          Training or technical assistance partner
                        </b-form-checkbox>
                      </div>
                      <div>
                        <b-form-checkbox
                          @change="dataUpdated()"
                          v-model="granteeSubmissionData.gr_eval_partner_obsvs"
                          name="grantee_org_type"
                          id="gr_eval_partner_obsvs"
                        >
                          Evaluation partner
                        </b-form-checkbox>
                      </div>
                      <div>
                        <b-form-checkbox
                          @change="dataUpdated()"
                          v-model="
                            granteeSubmissionData.gr_program_providers_obsvs
                          "
                          name="grantee_org_type"
                          id="gr_program_providers_obsvs"
                        >
                          Program provider
                        </b-form-checkbox>
                      </div>

                      <div
                        class="row form-group error-message"
                        v-if="
                          this.errors.includes(
                            'No type of organization selected for grantee observation.'
                          )
                        "
                      >
                        No type of organization selected for grantee
                        observation.
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="!isWinterSeason">
              <div class="card card-default">
                <div class="card-header separator">
                  <div class="card-title">Grantee technical assistance</div>
                </div>
                <div class="card-body row">
                  <div class="col-md-12 col-lg-6">
                    <div class="row grantee-select-row">
                      <div class="col-8 grantee-select">
                        <label for="gr_grantee_designee_tech_assist"
                          >Did grantee or its designee provide technical
                          assistance to support program implementation?</label
                        >
                      </div>
                      <div class="col-4">
                        <b-form-select
                          @change="dataUpdated()"
                          :class="{
                            'invalid-input': this.formErrors
                              .gr_grantee_designee_tech_assist.errors,
                          }"
                          v-model="
                            granteeSubmissionData.gr_grantee_designee_tech_assist
                          "
                          id="gr_grantee_designee_tech_assist"
                          :options="yn"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-6"
                    v-show="
                      granteeSubmissionData.gr_grantee_designee_tech_assist
                    "
                  >
                    <fieldset class="col-12">
                      <legend>
                        Type of organization that conducted technical
                        assistance:
                      </legend>
                      <div>
                        <b-form-checkbox
                          @change="dataUpdated()"
                          v-model="granteeSubmissionData.gr_grantee_tech_assist"
                          name="granteeSubmissionData_tech_assist"
                          id="gr_grantee_tech_assist"
                        >
                          Grantee
                        </b-form-checkbox>
                      </div>
                      <div>
                        <b-form-checkbox
                          @change="dataUpdated()"
                          v-model="
                            granteeSubmissionData.gr_developer_tech_assist
                          "
                          name="granteeSubmissionData_tech_assist"
                          id="gr_developer_tech_assist"
                        >
                          Developer
                        </b-form-checkbox>
                      </div>
                      <div>
                        <b-form-checkbox
                          @change="dataUpdated()"
                          v-model="
                            granteeSubmissionData.gr_ta_partner_tech_assist
                          "
                          name="granteeSubmissionData_tech_assist"
                          id="gr_ta_partner_tech_assist"
                        >
                          Training or technical assistance partner
                        </b-form-checkbox>
                      </div>
                      <div>
                        <b-form-checkbox
                          @change="dataUpdated()"
                          v-model="
                            granteeSubmissionData.gr_eval_partner_tech_assist
                          "
                          name="granteeSubmissionData_tech_assist"
                          id="gr_eval_partner_tech_assist"
                        >
                          Evaluation partner
                        </b-form-checkbox>
                      </div>
                      <div>
                        <b-form-checkbox
                          @change="dataUpdated()"
                          v-model="
                            granteeSubmissionData.gr_program_providers_tech_assist
                          "
                          name="granteeSubmissionData_tech_assist"
                          id="gr_program_providers_tech_assist"
                        >
                          Program provider
                        </b-form-checkbox>
                      </div>

                      <div
                        class="row form-group error-message"
                        v-if="
                          this.errors.includes(
                            'No type of organization selected for grantee technical assistance.'
                          )
                        "
                      >
                        No type of organization selected for grantee technical
                        assistance.
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="!isWinterSeason">
              <div class="card card-default">
                <div class="card-header separator">
                  <div class="card-title">Grantee training</div>
                </div>
                <div class="card-body row">
                  <div class="col-md-12 col-lg-6">
                    <div class="row grantee-select-row">
                      <div class="col-8 grantee-select">
                        <label for="gr_grantee_designee_training"
                          >Did grantee or its designee conduct training of
                          facilitators who deliver the program (or of other
                          staff who might train facilitators)?</label
                        >
                      </div>
                      <div class="col-4">
                        <b-form-select
                          @change="dataUpdated()"
                          :class="{
                            'invalid-input': this.formErrors
                              .gr_grantee_designee_training.errors,
                          }"
                          v-model="
                            granteeSubmissionData.gr_grantee_designee_training
                          "
                          id="gr_grantee_designee_training"
                          :options="yn"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-6"
                    v-show="granteeSubmissionData.gr_grantee_designee_training"
                  >
                    <fieldset class="col-12">
                      <legend>
                        Type of organization that conducted training:
                      </legend>
                      <div>
                        <b-form-checkbox
                          @change="dataUpdated()"
                          v-model="granteeSubmissionData.gr_grantee_training"
                          name="granteeSubmissionData_gr_training"
                          id="gr_grantee_training"
                        >
                          Grantee
                        </b-form-checkbox>
                      </div>
                      <div>
                        <b-form-checkbox
                          @change="dataUpdated()"
                          v-model="granteeSubmissionData.gr_developer_training"
                          name="granteeSubmissionData_gr_training"
                          id="gr_developer_training"
                        >
                          Developer
                        </b-form-checkbox>
                      </div>
                      <div>
                        <b-form-checkbox
                          @change="dataUpdated()"
                          v-model="granteeSubmissionData.gr_ta_partner_training"
                          name="granteeSubmissionData_gr_training"
                          id="gr_ta_partner_training"
                        >
                          Training or technical assistance partner
                        </b-form-checkbox>
                      </div>
                      <div>
                        <b-form-checkbox
                          @change="dataUpdated()"
                          v-model="
                            granteeSubmissionData.gr_eval_partner_training
                          "
                          name="granteeSubmissionData_gr_training"
                          id="gr_eval_partner_training"
                        >
                          Evaluation partner
                        </b-form-checkbox>
                      </div>
                      <div>
                        <b-form-checkbox
                          @change="dataUpdated()"
                          v-model="
                            granteeSubmissionData.gr_program_providers_training
                          "
                          name="granteeSubmissionData_gr_training"
                          id="gr_program_providers_training"
                        >
                          Program provider
                        </b-form-checkbox>
                      </div>

                      <div
                        class="row form-group error-message"
                        v-if="
                          this.errors.includes(
                            'No type of organization selected for grantee training.'
                          )
                        "
                      >
                        No type of organization selected for grantee training.
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="card card-default">
                  <div class="card-header separator" v-if="!isWinterSeason">
                    <div class="card-title">Number of providers</div>
                  </div>
                  <div class="card-body">
                    <div class="form-group row">
                      <div class="col-10">
                        <label for="gr_num_providers_funded">
                          Number of providers funded
                          <b-button
                            v-b-tooltip.hover
                            :title="`Number of program providers receiving SRAE funding from the grantee for the ${$store.state.reportingPeriodStr.gr} reporting period. These providers should be listed as active in the “Program Provider Table” on the next page. If the grantee provides SRAE programming directly to youth, it should be counted as a provider.`"
                            size="sm"
                            >?</b-button
                          >
                        </label>
                      </div>
                      <div class="col-2">
                        <input
                          v-model="
                            granteeSubmissionData.gr_num_providers_funded
                          "
                          id="gr_num_providers_funded"
                          @input="addThousandComma('gr_num_providers_funded')"
                          type="text"
                          class="form-control"
                          :class="{
                            'invalid-input': this.formErrors
                              .gr_num_providers_funded.errors,
                          }"
                          required=""
                        />
                      </div>
                    </div>
                    <div
                      class="form-group row error-message"
                      v-if="this.formErrors.gr_num_providers_funded.errors"
                    >
                      {{ this.formErrors.gr_num_providers_funded.message }}
                    </div>
                    <div class="form-group row" v-if="!isWinterSeason">
                      <div class="col-10">
                        <label for="gr_num_new_providers"
                          >Number of new providers</label
                        >
                      </div>
                      <div class="col-2">
                        <input
                          v-model="granteeSubmissionData.gr_num_new_providers"
                          id="gr_num_new_providers"
                          @input="addThousandComma('gr_num_new_providers')"
                          type="text"
                          class="form-control"
                          :class="{
                            'invalid-input': this.formErrors
                              .gr_num_new_providers.errors,
                          }"
                          required=""
                        />
                      </div>
                    </div>
                    <div
                      class="form-group row error-message"
                      v-if="this.formErrors.gr_num_new_providers.errors"
                    >
                      {{ this.formErrors.gr_num_new_providers.message }}
                    </div>
                    <div
                      class="form-group row error-message"
                      v-if="
                        this.showstoppers.includes(
                          'Number of new providers cannot exceed total number of providers.'
                        )
                      "
                    >
                      Number of new providers cannot exceed total number of
                      providers.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6" v-if="!isWinterSeason">
                <div class="card card-default">
                  <div class="card-header separator">
                    <div class="card-title">Number of youth</div>
                  </div>
                  <div class="card-body">
                    <div class="form-group row">
                      <div class="col-8">
                        <label for="gr_target_num_youth"
                          >Target number of youth to be served by the grantee
                          (in the original application or approved by your
                          project officer)</label
                        >
                      </div>
                      <div class="col-4">
                        <input
                          v-model="granteeSubmissionData.gr_target_num_youth"
                          id="gr_target_num_youth"
                          @input="addThousandComma('gr_target_num_youth')"
                          type="text"
                          class="form-control"
                          :class="{
                            'invalid-input': this.formErrors.gr_target_num_youth
                              .errors,
                          }"
                          required=""
                        />
                      </div>
                    </div>
                    <div
                      class="form-group row error-message"
                      v-if="this.formErrors.gr_target_num_youth.errors"
                    >
                      {{ this.formErrors.gr_target_num_youth.message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 text-right">
                <!-- Using modifiers -->
                <b-button
                  id="grantee-save-button"
                  v-b-modal.confirmSubmit
                  @click="checkForm('save', $event)"
                  class="btn btn-lg btn-primary m-r-10"
                >
                  Save
                </b-button>
                <router-link
                  id="grantee-continue-button"
                  to="/providers"
                  class="btn btn-lg btn-primary m-r-10"
                  title="Continue to Providers List"
                >
                  <span class="title">Continue</span>
                </router-link>
              </div>
            </div>
            <!-- The modal -->
            <b-modal id="confirmSubmit">
              <div v-if="errors.length || showstoppers.length">
                <p><b>Please correct the following error(s):</b></p>
                <ul>
                  <li
                    v-for="showstopper in showstoppers"
                    v-bind:key="showstopper"
                    class="error-message"
                  >
                    {{ showstopper }}
                  </li>
                  <li v-for="error in errors" v-bind:key="error">
                    {{ error }}
                  </li>
                </ul>
              </div>
              <p v-if="!showstoppers.length && !errors.length">
                <b>No issues detected.</b>
                <br />
                The data on this page has been saved.
              </p>
            </b-modal>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var rules = require("../rules");
import mixin from "../mixin";

export default {
  name: "grantee",
  mixins: [mixin],

  data() {
    return {
      numberFields: [
        "gr_srae_grant_funding",
        "gr_grantee_staff_num",
        "gr_grantee_ftes_num",
        "gr_num_providers_funded",
        "gr_num_new_providers",
        "gr_target_num_youth",
      ],
      newGranteeSubmissionData: {
        gr_interrupt_ops_covid: null,
        gr_interrupt_services_covid: null,
        gr_srae_grant_funding: null,
        gr_pct_direct_service: null,
        gr_pct_training: null,
        gr_pct_evaluation: null,
        gr_pct_admin_purposes: null,
        gr_grantee_staff_num: null,
        gr_grantee_ftes_num: null,
        gr_grantee_designee_observed: null,
        gr_grantee_obsvs: null,
        gr_developer_obsvs: null,
        gr_ta_partner_obsvs: null,
        gr_eval_partner_obsvs: null,
        gr_program_providers_obsvs: null,
        gr_grantee_designee_tech_assist: null,
        gr_grantee_tech_assist: null,
        gr_developer_tech_assist: null,
        gr_ta_partner_tech_assist: null,
        gr_eval_partner_tech_assist: null,
        gr_program_providers_tech_assist: null,
        gr_grantee_designee_training: null,
        gr_grantee_training: null,
        gr_developer_training: null,
        gr_ta_partner_training: null,
        gr_eval_partner_training: null,
        gr_program_providers_training: null,
        gr_num_providers_funded: null,
        gr_num_new_providers: null,
        gr_target_num_youth: null,
      },
      formName: "grantee",
      yn: [
        { value: null, text: "Select an option" },
        { value: true, text: "Yes" },
        { value: false, text: "No" },
      ],
      errors: [],
      showstoppers: [],
      errors_new: {
        total_funding_blank: {
          status: false,
          message: "Total funding blank",
        },
      },
      warnings: [],
      status_msg: "",
      saved: true,
    };
  },

  watch: {
    grantee() {
      // get grantee data
      this.getSubmissionData("watch");
    },

    "$store.state.reportingPeriodData": function () {
      this.getSubmissionData("watch");
    },
  },

  created() {
    // get grantee data if grantee.id exists
    this.grantee.id ? this.getSubmissionData("created") : "";
  },

  computed: {
    granteeSubmissionData() {
      const data = this.$store.state.granteeSubmissionData.filter(
        (grantee) => grantee.fk_grants === this.grantee.id
      );
      if (data.length) {
        let submissionData = data[0];
        this.numberFields.forEach((field) => {
          submissionData[field] = this.initializeCommas(submissionData[field]);
        });
        return submissionData;
      } else {
        return this.newGranteeSubmissionData;
      }
    },
    isWinterSeason() {
      return this.$store.state.isWinterSeason;
    },

    formErrors() {
      const errors = {
        // new covid-19 measures
        gr_interrupt_ops_covid: {
          errors: this.errors.includes(
            "Interruptions of operations due to COVID-19 is blank."
          ),
          message: this.errorMessages.blank,
        },
        gr_interrupt_services_covid: {
          errors: this.errors.includes(
            "Interruptions of services due to COVID-19 is blank."
          ),
          message: this.errorMessages.blank,
        },
        // end covid-19 measures
        gr_srae_grant_funding: {
          errors:
            this.errors.includes("Total funding amount is blank.") ||
            this.errors.includes("Total funding amount cannot be negative."),
          message: this.errors.includes("Total funding amount is blank.")
            ? this.errorMessages.blank
            : this.errors.includes("Total funding amount cannot be negative.")
            ? this.errorMessages.negative
            : "",
        },
        gr_grantee_staff_num: {
          errors:
            this.errors.includes("Total number of staff is blank.") ||
            this.errors.includes("Total number of staff cannot be negative.") ||
            this.showstoppers.includes(
              "Number of FTEs cannot exceed total number of staff."
            ),
          message: this.errors.includes("Total number of staff is blank.")
            ? this.errorMessages.blank
            : this.errors.includes("Total number of staff cannot be negative.")
            ? this.errorMessages.negative
            : "",
        },
        gr_grantee_ftes_num: {
          errors:
            this.errors.includes("Total number of FTE staff is blank.") ||
            this.errors.includes(
              "Total number of FTE staff cannot be negative."
            ) ||
            this.showstoppers.includes(
              "Number of FTEs cannot exceed total number of staff."
            ),
          message: this.errors.includes("Total number of FTE staff is blank.")
            ? this.errorMessages.blank
            : this.errors.includes(
                "Total number of FTE staff cannot be negative."
              )
            ? this.errorMessages.negative
            : "",
        },
        gr_pct_direct_service: {
          errors:
            this.errors.includes(
              "Percentage of funding designated for direct service cannot be negative."
            ) ||
            this.errors.includes(
              "Percentage of funding designated for direct service is blank."
            ) ||
            this.errors.includes("Percentages do not sum to 100%."),
          message: this.errors.includes(
            "Percentage of funding designated for direct service is blank."
          )
            ? this.errorMessages.blank
            : this.errors.includes(
                "Percentage of funding designated for direct service cannot be negative."
              )
            ? this.errorMessages.negative
            : "",
        },
        gr_pct_training: {
          errors:
            this.errors.includes(
              "Percentage of funding designated for training cannot be negative."
            ) ||
            this.errors.includes(
              "Percentage of funding designated for training is blank."
            ) ||
            this.errors.includes("Percentages do not sum to 100%."),
          message: this.errors.includes(
            "Percentage of funding designated for training is blank."
          )
            ? this.errorMessages.blank
            : this.errors.includes(
                "Percentage of funding designated for training cannot be negative."
              )
            ? this.errorMessages.negative
            : "",
        },
        gr_pct_evaluation: {
          errors:
            this.errors.includes(
              "Percentage of funding designated for evaluation/research cannot be negative."
            ) ||
            this.errors.includes(
              "Percentage of funding designated for evaluation/research is blank."
            ) ||
            this.errors.includes("Percentages do not sum to 100%."),
          message: this.errors.includes(
            "Percentage of funding designated for evaluation/research is blank."
          )
            ? this.errorMessages.blank
            : this.errors.includes(
                "Percentage of funding designated for evaluation/research cannot be negative."
              )
            ? this.errorMessages.negative
            : "",
        },
        gr_pct_admin_purposes: {
          errors:
            this.errors.includes(
              "Percentage of funding designated for administration cannot be negative."
            ) ||
            this.errors.includes(
              "Percentage of funding designated for administration is blank."
            ) ||
            this.errors.includes("Percentages do not sum to 100%."),
          message: this.errors.includes(
            "Percentage of funding designated for administration is blank."
          )
            ? this.errorMessages.blank
            : this.errors.includes(
                "Percentage of funding designated for administration cannot be negative."
              )
            ? this.errorMessages.negative
            : "",
        },
        gr_grantee_designee_observed: {
          errors: this.errors.includes("Observed program delivery is blank."),
          message: this.errorMessages.blank,
        },
        gr_grantee_obsvs_blank: {
          errors: this.errors.includes(
            "No type of organization selected for grantee observation."
          ),
          message: this.errorMessages.blank,
        },
        gr_grantee_designee_tech_assist: {
          errors: this.errors.includes(
            "Provided technical assistance is blank."
          ),
          message: this.errorMessages.blank,
        },
        gr_grantee_tech_assist_blank: {
          errors: this.errors.includes(
            "No type of organization selected for grantee technical assistance."
          ),
          message: this.errorMessages.blank,
        },
        gr_grantee_designee_training: {
          errors: this.errors.includes("Provided training is blank."),
          message: this.errorMessages.blank,
        },
        gr_grantee_training_blank: {
          errors: this.errors.includes(
            "No type of organization selected for grantee training."
          ),
          message: this.errorMessages.blank,
        },
        gr_num_providers_funded: {
          errors:
            this.errors.includes("Number of providers funded is blank.") ||
            this.errors.includes(
              "Number of providers funded cannot be negative."
            ) ||
            this.showstoppers.includes(
              "Number of new providers cannot exceed total number of providers."
            ),
          message: this.errors.includes("Number of providers funded is blank.")
            ? this.errorMessages.blank
            : this.errors.includes(
                "Number of providers funded cannot be negative."
              )
            ? this.errorMessages.negative
            : "",
        },
        gr_num_new_providers: {
          errors:
            this.errors.includes("Number of new providers is blank.") ||
            this.errors.includes(
              "Number of new providers cannot be negative."
            ) ||
            this.showstoppers.includes(
              "Number of new providers cannot exceed total number of providers."
            ),
          message: this.errors.includes("Number of new providers is blank.")
            ? this.errorMessages.blank
            : this.errors.includes(
                "Number of new providers cannot be negative."
              )
            ? this.errorMessages.negative
            : "",
        },
        gr_target_num_youth: {
          errors:
            this.errors.includes("Number of youth served is blank.") ||
            this.errors.includes("Number of youth served cannot be negative."),
          message: this.errors.includes("Number of youth served is blank.")
            ? this.errorMessages.blank
            : this.errors.includes("Number of youth served cannot be negative.")
            ? this.errorMessages.negative
            : "",
        },
      };
      return errors;
    },

    totalFundingAmountNegative: function () {
      return rules.default.inSet(this.errors, [
        "Total funding amount cannot be negative.",
      ]);
    },

    formDataSubmission: function () {
      return {
        formName: "grantee",
        formData: [
          {
            tableName: "grant_submissions",
            tableData: [
              [
                {
                  name: "grantee_name",
                  value: {
                    stringValue: this.grantee.name,
                  },
                },
                // new covid questions:
                {
                  name: "gr_interrupt_ops_covid",
                  value:
                    this.granteeSubmissionData.gr_interrupt_ops_covid === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.granteeSubmissionData
                            .gr_interrupt_ops_covid,
                        },
                },
                {
                  name: "gr_interrupt_services_covid",
                  value:
                    this.granteeSubmissionData.gr_interrupt_services_covid ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.granteeSubmissionData
                            .gr_interrupt_services_covid,
                        },
                },
                {
                  name: "gr_srae_grant_funding",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.granteeSubmissionData.gr_srae_grant_funding
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.granteeSubmissionData.gr_srae_grant_funding
                        ),
                      },
                },
                {
                  name: "gr_pct_direct_service",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.granteeSubmissionData.gr_pct_direct_service
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        doubleValue: this.removeThousandCommas(
                          this.granteeSubmissionData.gr_pct_direct_service
                        ),
                      },
                },
                {
                  name: "gr_pct_training",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.granteeSubmissionData.gr_pct_training
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        doubleValue: this.removeThousandCommas(
                          this.granteeSubmissionData.gr_pct_training
                        ),
                      },
                },
                {
                  name: "gr_pct_evaluation",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.granteeSubmissionData.gr_pct_evaluation
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        doubleValue: this.removeThousandCommas(
                          this.granteeSubmissionData.gr_pct_evaluation
                        ),
                      },
                },
                {
                  name: "gr_pct_admin_purposes",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.granteeSubmissionData.gr_pct_admin_purposes
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        doubleValue: this.removeThousandCommas(
                          this.granteeSubmissionData.gr_pct_admin_purposes
                        ),
                      },
                },
                {
                  name: "gr_grantee_staff_num",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.granteeSubmissionData.gr_grantee_staff_num
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.granteeSubmissionData.gr_grantee_staff_num
                        ),
                      },
                },
                {
                  name: "gr_grantee_ftes_num",
                  value: isNaN(
                    parseFloat(this.granteeSubmissionData.gr_grantee_ftes_num)
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        doubleValue: this.removeThousandCommas(
                          this.granteeSubmissionData.gr_grantee_ftes_num
                        ),
                      },
                },
                {
                  name: "gr_grantee_designee_observed",
                  value:
                    this.granteeSubmissionData.gr_grantee_designee_observed ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.granteeSubmissionData
                            .gr_grantee_designee_observed,
                        },
                },
                {
                  name: "gr_grantee_obsvs",
                  value:
                    this.granteeSubmissionData.gr_grantee_obsvs === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.granteeSubmissionData
                            .gr_grantee_obsvs,
                        },
                },
                {
                  name: "gr_developer_obsvs",
                  value:
                    this.granteeSubmissionData.gr_developer_obsvs === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.granteeSubmissionData
                            .gr_developer_obsvs,
                        },
                },
                {
                  name: "gr_ta_partner_obsvs",
                  value:
                    this.granteeSubmissionData.gr_ta_partner_obsvs === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.granteeSubmissionData
                            .gr_ta_partner_obsvs,
                        },
                },
                {
                  name: "gr_eval_partner_obsvs",
                  value:
                    this.granteeSubmissionData.gr_eval_partner_obsvs === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.granteeSubmissionData
                            .gr_eval_partner_obsvs,
                        },
                },
                {
                  name: "gr_program_providers_obsvs",
                  value:
                    this.granteeSubmissionData.gr_program_providers_obsvs ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.granteeSubmissionData
                            .gr_program_providers_obsvs,
                        },
                },
                {
                  name: "gr_grantee_designee_tech_assist",
                  value:
                    this.granteeSubmissionData
                      .gr_grantee_designee_tech_assist === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.granteeSubmissionData
                            .gr_grantee_designee_tech_assist,
                        },
                },
                {
                  name: "gr_grantee_tech_assist",
                  value:
                    this.granteeSubmissionData.gr_grantee_tech_assist === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.granteeSubmissionData
                            .gr_grantee_tech_assist,
                        },
                },
                {
                  name: "gr_developer_tech_assist",
                  value:
                    this.granteeSubmissionData.gr_developer_tech_assist === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.granteeSubmissionData
                            .gr_developer_tech_assist,
                        },
                },
                {
                  name: "gr_ta_partner_tech_assist",
                  value:
                    this.granteeSubmissionData.gr_ta_partner_tech_assist ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.granteeSubmissionData
                            .gr_ta_partner_tech_assist,
                        },
                },
                {
                  name: "gr_eval_partner_tech_assist",
                  value:
                    this.granteeSubmissionData.gr_eval_partner_tech_assist ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.granteeSubmissionData
                            .gr_eval_partner_tech_assist,
                        },
                },
                {
                  name: "gr_program_providers_tech_assist",
                  value:
                    this.granteeSubmissionData
                      .gr_program_providers_tech_assist === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.granteeSubmissionData
                            .gr_program_providers_tech_assist,
                        },
                },
                {
                  name: "gr_grantee_designee_training",
                  value:
                    this.granteeSubmissionData.gr_grantee_designee_training ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.granteeSubmissionData
                            .gr_grantee_designee_training,
                        },
                },
                {
                  name: "gr_grantee_training",
                  value:
                    this.granteeSubmissionData.gr_grantee_training === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.granteeSubmissionData
                            .gr_grantee_training,
                        },
                },
                {
                  name: "gr_developer_training",
                  value:
                    this.granteeSubmissionData.gr_developer_training === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.granteeSubmissionData
                            .gr_developer_training,
                        },
                },
                {
                  name: "gr_ta_partner_training",
                  value:
                    this.granteeSubmissionData.gr_ta_partner_training === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.granteeSubmissionData
                            .gr_ta_partner_training,
                        },
                },
                {
                  name: "gr_eval_partner_training",
                  value:
                    this.granteeSubmissionData.gr_eval_partner_training === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.granteeSubmissionData
                            .gr_eval_partner_training,
                        },
                },
                {
                  name: "gr_program_providers_training",
                  value:
                    this.granteeSubmissionData.gr_program_providers_training ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.granteeSubmissionData
                            .gr_program_providers_training,
                        },
                },
                {
                  name: "gr_num_providers_funded",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.granteeSubmissionData.gr_num_providers_funded
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.granteeSubmissionData.gr_num_providers_funded
                        ),
                      },
                },
                {
                  name: "gr_num_new_providers",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.granteeSubmissionData.gr_num_new_providers
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.granteeSubmissionData.gr_num_new_providers
                        ),
                      },
                },
                {
                  name: "gr_target_num_youth",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.granteeSubmissionData.gr_target_num_youth
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.granteeSubmissionData.gr_target_num_youth
                        ),
                      },
                },
                {
                  name: "gr_data_issues_minor",
                  value: {
                    booleanValue: this.errors.length > 0,
                  },
                },
                {
                  name: "gr_data_issues_major",
                  value: {
                    booleanValue: this.showstoppers.length > 0,
                  },
                },
              ],
            ],
          },
        ],
      };
    },
  },

  methods: {
    addThousandComma(value) {
      const target = this.granteeSubmissionData[value];
      const initialLength = target.length;

      const numericOnly = target.replace(/[^0-9,]/g, "");

      const commas = this.initializeCommas(numericOnly);
      this.granteeSubmissionData[value] = commas;

      if (numericOnly.length === initialLength) {
        this.dataUpdated();
      }
    },

    addThousandCommaDec(value) {
      const target = this.granteeSubmissionData[value];
      const initialLength = target.length;

      const numericOnly = target.replace(/[^0-9,\.]/g, "");

      const commas = this.initializeCommas(numericOnly);
      this.granteeSubmissionData[value] = commas;

      if (numericOnly.length === initialLength) {
        this.dataUpdated();
      }
    },

    removeThousandCommas(value) {
      if (typeof value === "undefined") {
        return NaN;
      }
      if (isNaN(value)) {
        let number = value.replace(/,/g, "");
        return parseFloat(number);
      } else {
        return parseFloat(value);
      }
    },

    initializeCommas(num) {
      if (num) {
        const string = num.toString();
        const number = string.replace(/,/g, "");
        const split = number.split(".");

        let commas;

        if (split.length > 1) {
          split[0] = split[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          commas = split.join(".");
        } else {
          commas = split[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        return commas;
      } else {
        return num;
      }
    },

    getSubmissionData() {
      this.$store
        .dispatch("getSubmissionData", {
          path: `/grantees/${this.grantee.id}/submissions/${this.$store.state.reportingPeriodData.pk}/reduce`,
          target: "grantee",
        })
        .then(() => {
          if (
            this.granteeSubmissionData.gr_data_issues_minor ||
            this.granteeSubmissionData.gr_data_issues_major
          ) {
            this.checkForm();
          }
        })
        .catch((err) =>
          console.error("[grantee] getSubmissionData error: ", err)
        );
    },

    dataUpdated() {
      this.saved = false;
    },

    checkForm(_type) {
      this.errors = [];
      this.showstoppers = [];
      // this.saved = true;

      var percentTotal = 0;

      var percents = [
        this.granteeSubmissionData.gr_pct_direct_service,
        this.granteeSubmissionData.gr_pct_training,
        this.granteeSubmissionData.gr_pct_evaluation,
        this.granteeSubmissionData.gr_pct_admin_purposes,
      ];

      for (var i = 0; i < percents.length; i++) {
        if (!isNaN(parseFloat(percents[i]))) {
          percentTotal += parseFloat(percents[i]);
        }
      }

      if (this.isWinterSeason) {
        if (
          isNaN(
            this.removeThousandCommas(
              this.granteeSubmissionData.gr_num_providers_funded
            )
          )
        ) {
          this.errors.push("Number of providers funded is blank.");
        }
      } else {
        // 1. percentages should add to 100

        if (Math.round(percentTotal * 100) / 100 != 100) {
          this.errors.push("Percentages do not sum to 100%.");
        }

        // 2. number of FTEs should not exceed total number of staff
        if (
          this.removeThousandCommas(
            this.granteeSubmissionData.gr_grantee_staff_num
          ) <
          this.removeThousandCommas(
            this.granteeSubmissionData.gr_grantee_ftes_num
          )
        ) {
          this.showstoppers.push(
            "Number of FTEs cannot exceed total number of staff."
          );
        }

        // 3. number of new providers cannot exceed total number of providers
        if (
          !isNaN(
            this.removeThousandCommas(
              this.granteeSubmissionData.gr_num_providers_funded
            )
          ) &&
          !isNaN(
            this.removeThousandCommas(
              this.granteeSubmissionData.gr_num_new_providers
            )
          ) &&
          this.removeThousandCommas(
            this.granteeSubmissionData.gr_num_providers_funded
          ) <
            this.removeThousandCommas(
              this.granteeSubmissionData.gr_num_new_providers
            )
        ) {
          this.showstoppers.push(
            "Number of new providers cannot exceed total number of providers."
          );
        }

        // 4. negative numbers
        if (
          this.removeThousandCommas(
            this.granteeSubmissionData.gr_srae_grant_funding
          ) < 0
        ) {
          this.errors.push("Total funding amount cannot be negative.");
        }

        if (this.granteeSubmissionData.gr_pct_direct_service < 0) {
          this.errors.push(
            "Percentage of funding designated for direct service cannot be negative."
          );
        }

        if (this.granteeSubmissionData.gr_pct_training < 0) {
          this.errors.push(
            "Percentage of funding designated for training cannot be negative."
          );
        }

        if (this.granteeSubmissionData.gr_pct_evaluation < 0) {
          this.errors.push(
            "Percentage of funding designated for evaluation/research cannot be negative."
          );
        }

        if (this.granteeSubmissionData.gr_pct_admin_purposes < 0) {
          this.errors.push(
            "Percentage of funding designated for administration cannot be negative."
          );
        }

        if (
          this.removeThousandCommas(
            this.granteeSubmissionData.gr_grantee_staff_num
          ) < 0
        ) {
          this.errors.push("Total number of staff cannot be negative.");
        }

        if (
          this.removeThousandCommas(
            this.granteeSubmissionData.gr_grantee_ftes_num
          ) < 0
        ) {
          this.errors.push("Total number of FTE staff cannot be negative.");
        }

        if (
          this.removeThousandCommas(
            this.granteeSubmissionData.gr_num_providers_funded
          ) < 0
        ) {
          this.errors.push("Number of providers funded cannot be negative.");
        }

        if (
          this.removeThousandCommas(
            this.granteeSubmissionData.gr_num_new_providers
          ) < 0
        ) {
          this.errors.push("Number of new providers cannot be negative.");
        }

        if (
          this.removeThousandCommas(
            this.granteeSubmissionData.gr_target_num_youth
          ) < 0
        ) {
          this.errors.push("Number of youth served cannot be negative.");
        }

        // 5. check for blank fields
        if (this.granteeSubmissionData.gr_interrupt_ops_covid == null) {
          this.errors.push(
            "Interruptions of operations due to COVID-19 is blank."
          );
        }
        if (this.granteeSubmissionData.gr_interrupt_services_covid == null) {
          this.errors.push(
            "Interruptions of services due to COVID-19 is blank."
          );
        }

        if (
          isNaN(
            this.removeThousandCommas(
              this.granteeSubmissionData.gr_srae_grant_funding
            )
          )
        ) {
          this.errors.push("Total funding amount is blank.");
        }

        if (
          isNaN(parseFloat(this.granteeSubmissionData.gr_pct_direct_service))
        ) {
          this.errors.push(
            "Percentage of funding designated for direct service is blank."
          );
        }

        if (isNaN(parseFloat(this.granteeSubmissionData.gr_pct_training))) {
          this.errors.push(
            "Percentage of funding designated for training is blank."
          );
        }

        if (isNaN(parseFloat(this.granteeSubmissionData.gr_pct_evaluation))) {
          this.errors.push(
            "Percentage of funding designated for evaluation/research is blank."
          );
        }

        if (
          isNaN(parseFloat(this.granteeSubmissionData.gr_pct_admin_purposes))
        ) {
          this.errors.push(
            "Percentage of funding designated for administration is blank."
          );
        }

        if (
          isNaN(
            this.removeThousandCommas(
              this.granteeSubmissionData.gr_grantee_staff_num
            )
          )
        ) {
          this.errors.push("Total number of staff is blank.");
        }

        if (
          isNaN(
            this.removeThousandCommas(
              this.granteeSubmissionData.gr_grantee_ftes_num
            )
          )
        ) {
          this.errors.push("Total number of FTE staff is blank.");
        }

        if (this.granteeSubmissionData.gr_grantee_designee_observed == null) {
          this.errors.push("Observed program delivery is blank.");
        }

        if (
          this.granteeSubmissionData.gr_grantee_designee_observed &&
          !this.granteeSubmissionData.gr_grantee_obsvs &&
          !this.granteeSubmissionData.gr_developer_obsvs &&
          !this.granteeSubmissionData.gr_ta_partner_obsvs &&
          !this.granteeSubmissionData.gr_eval_partner_obsvs &&
          !this.granteeSubmissionData.gr_program_providers_obsvs
        ) {
          this.errors.push(
            "No type of organization selected for grantee observation."
          );
        }

        if (
          this.granteeSubmissionData.gr_grantee_designee_tech_assist == null
        ) {
          this.errors.push("Provided technical assistance is blank.");
        }

        if (
          this.granteeSubmissionData.gr_grantee_designee_tech_assist &&
          !this.granteeSubmissionData.gr_grantee_tech_assist &&
          !this.granteeSubmissionData.gr_developer_tech_assist &&
          !this.granteeSubmissionData.gr_ta_partner_tech_assist &&
          !this.granteeSubmissionData.gr_eval_partner_tech_assist &&
          !this.granteeSubmissionData.gr_program_providers_tech_assist
        ) {
          this.errors.push(
            "No type of organization selected for grantee technical assistance."
          );
        }

        if (this.granteeSubmissionData.gr_grantee_designee_training == null) {
          this.errors.push("Provided training is blank.");
        }

        if (
          this.granteeSubmissionData.gr_grantee_designee_training &&
          !this.granteeSubmissionData.gr_grantee_training &&
          !this.granteeSubmissionData.gr_developer_training &&
          !this.granteeSubmissionData.gr_ta_partner_training &&
          !this.granteeSubmissionData.gr_eval_partner_training &&
          !this.granteeSubmissionData.gr_program_providers_training
        ) {
          this.errors.push(
            "No type of organization selected for grantee training."
          );
        }

        if (
          isNaN(
            this.removeThousandCommas(
              this.granteeSubmissionData.gr_num_providers_funded
            )
          )
        ) {
          this.errors.push("Number of providers funded is blank.");
        }

        if (
          isNaN(
            this.removeThousandCommas(
              this.granteeSubmissionData.gr_num_new_providers
            )
          )
        ) {
          this.errors.push("Number of new providers is blank.");
        }

        if (
          isNaN(
            this.removeThousandCommas(
              this.granteeSubmissionData.gr_target_num_youth
            )
          )
        ) {
          this.errors.push("Number of youth served is blank.");
        }
      }

      // Errors:

      if (_type === "save") {
        this.submitForm(_type);
      }

      if (!this.errors.length) {
        return true;
      }
      console.error("Validation errors:");
      console.error(this.errors);
    },
    //
    submitForm(_action) {
      const submitPath = `/grantees/${this.grantee.id}/submissions/${this.$store.state.reportingPeriodData.pk}`;
      const getSubmissionPath = `/grantees/${this.grantee.id}/submissions/${this.$store.state.reportingPeriodData.pk}/reduce`;

      this.$store
        .dispatch("submitForm", {
          action: _action,
          path: submitPath,
          getSubmissionPath,
          data: this.formDataSubmission,
          target: "grantee",
        })
        .then(() => {
          this.saved = true;
          this.statusMsg = "Data saved";
        })
        .catch((error) => console.error("[submitForm] error: ", error));
    },
  },

  beforeRouteLeave(to, from, next) {
    if (!this.saved) {
      const answer = window.confirm(
        "The page contains data that have not been saved. Are you sure you want to leave the page without saving?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>

<style scoped lang="scss">
@media (max-width: 767px) {
  .grantee-select-row {
    margin-bottom: 8px;
  }
}

.grantee-select {
  padding-right: 6px !important;
}

#winter-season-card {
  margin: 0 15px 20px;
  width: 100%;

  p {
    font-size: 16px;
    line-height: 26px;
  }
}
</style>
