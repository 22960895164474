<template>
  <div class="row">
    <div class="col-lg-12">
      <div v-if="errors.length || showstoppers.length">
        <p><b>Please correct the following error(s):</b></p>
        <ul>
          <div class="error-dark">
            <li v-for="showstopper in showstoppers" v-bind:key="showstopper">
              {{ showstopper }}
            </li>
          </div>
          <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
        </ul>
      </div>

      <b-form role="form" novalidate>
        <!--<b-form-select-->
        <!--  id="ProgramModel"-->
        <!--  v-model="addProgramModel.pk_program_model"-->
        <!--  :options="$store.state.allProgramModels"-->
        <!--  value-field="pk"-->
        <!--  text-field="program_model_name" >-->
        <!--</b-form-select>-->

        <div class="row">
          <div class="col-lg-6">
            <div class="card card-default">
              <div class="card-header separator">
                <div class="card-title">Core curriculum</div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-11 col-lg-4 form-group">
                    <label for="fk_program_curricula"
                      >Please select the correct curriculum:</label
                    >
                  </div>
                  <div class="col-md-1 btn-sm">
                    <b-button
                      v-b-tooltip.hover
                      id="fk_program_curricula_info"
                      title='Select from the drop-down menu to identify the core curriculum implemented for this program. If the program’s core curriculum is not listed, select "Other" and enter the curriculum name in the field that will appear. If the core curriculum does not have a name, select "Other" and enter [Program Provider Name] curriculum in the field that will appear.'
                      >?</b-button
                    >
                  </div>
                  <div class="col-lg-7 form-group">
                    <b-form-select
                      id="fk_program_curricula"
                      v-model="formData.input.fk_program_curricula.value"
                      @change="dataUpdated"
                      v-bind:class="
                        this.formData.input.fk_program_curricula.fmt
                      "
                      :key="keyChanger"
                    >
                      <b-form-select-option :value="null"
                        >Please select an option</b-form-select-option
                      >
                      <b-form-select-option-group
                        label="Pre-selected options:"
                        :options="program_models"
                        value-field="pk"
                        text-field="prog_curriculum_name"
                      ></b-form-select-option-group>
                      <b-form-select-option-group
                        label="Custom options:"
                        :options="custom_program_models"
                        value-field="pk"
                        text-field="prog_curriculum_name"
                      ></b-form-select-option-group>
                    </b-form-select>
                  </div>
                </div>
                <div
                  class="row"
                  v-if="formData.input.fk_program_curricula.value === 0"
                  align="right"
                >
                  <div class="col-lg-5 form-group" style="text-align: left">
                    <label
                      v-if="formData.input.fk_program_curricula.value === 0"
                      >Please specify:</label
                    >
                  </div>
                  <div class="col-lg-7 form-group">
                    <input
                      id="prog_curriculum_name_other_specify"
                      type="text"
                      class="form-control"
                      aria-invalid="true"
                      v-model="
                        formData.input.prog_curriculum_name_other_specify.value
                      "
                      @input="dataUpdated()"
                      v-bind:class="
                        this.formData.input.prog_curriculum_name_other_specify
                          .fmt
                      "
                      required=""
                    />
                    <!--<input v-model="formData.input.program_model_name_other_specify.value" @input = "dataUpdated()" type="text" v-bind:class='this.formData.input.program_model_name_other_specify.fmt' required="">-->
                  </div>
                </div>
                <div
                  class="form-group row"
                  v-if="this.formData.input.fk_program_curricula.flag"
                >
                  <div class="error-dark">
                    {{ this.formData.input.fk_program_curricula.msg }}
                  </div>
                </div>
                <!--v-if="this.custom_program_models.filter( (element) => element.pk == this.formData.input.fk_program_curricula.value) != [] && this.custom_program_models.filter( (element) => element.pk == this.formData.input.fk_program_curricula.value).0.prog_curriculum_name == '[blank]'"-->
                <!--<div class="form-group row"  v-if='this.formData.input.prog_curriculum_name_other_specify.flag'>-->
                <!--  <div class="error-dark">-->
                <!--    {{ this.formData.input.prog_curriculum_name_other_specify.msg }}-->
                <!--  </div>-->
                <!--</div>-->
              </div>
            </div>
          </div>
          <div v-if="!isWinterSeason" class="col-lg-6">
            <div class="card card-default">
              <div class="card-header separator">
                <div class="card-title">Intended program delivery hours</div>
              </div>
              <div class="card-body form-group">
                <div class="row" align="left">
                  <div class="col-lg-9 form-group">
                    <label for="prog_num_delivery_hours"
                      >Enter the number of intended program delivery
                      hours:</label
                    >
                  </div>
                  <div class="col-lg-3 form-group">
                    <input
                      v-model="formData.input.prog_num_delivery_hours.value"
                      id="prog_num_delivery_hours"
                      @input="addThousandComma('prog_num_delivery_hours')"
                      type="text"
                      v-bind:class="
                        this.formData.input.prog_num_delivery_hours.fmt
                      "
                      required=""
                    />
                  </div>
                </div>
                <div
                  class="form-group row"
                  v-if="this.formData.input.prog_num_delivery_hours.flag"
                  align="right"
                >
                  <div class="error-dark">
                    {{ this.formData.input.prog_num_delivery_hours.msg }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!isWinterSeason" class="row">
          <div class="col-lg-12">
            <div class="card card-default">
              <div class="card-header separator">
                <div class="card-title">
                  SRAE topics covered through core curriculum
                </div>
              </div>
              <div class="card-body form-group">
                <label
                  >Which SRAE topics are addressed by this core curriculum?
                  (Select all that apply)</label
                >

                <div class="row">
                  <fieldset class="col-lg-6">
                    <legend>Teaching the benefits of:</legend>

                    <label class="row">
                      <span class="col-7"
                        >Refraining from non-marital sexual activity</span
                      >
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="
                            formData.input.prog_addl_srae_tcm_sexual_activity
                              .value
                          "
                          id="prog_addl_srae_tcm_sexual_activity"
                          @change="
                            dataUpdated('prog_addl_srae_tcm_sexual_activity')
                          "
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>

                    <label class="row">
                      <span class="col-7">Self-regulation</span>
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="
                            formData.input.prog_addl_srae_tcm_self_regulation
                              .value
                          "
                          id="prog_addl_srae_tcm_self_regulation"
                          @change="
                            dataUpdated('prog_addl_srae_tcm_self_regulation')
                          "
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>

                    <label class="row">
                      <span class="col-7">Goal setting</span>
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="
                            formData.input.prog_addl_srae_tcm_goal_setting.value
                          "
                          id="prog_addl_srae_tcm_goal_setting"
                          @change="
                            dataUpdated('prog_addl_srae_tcm_goal_setting')
                          "
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>

                    <label class="row">
                      <span class="col-7"
                        >Success sequence for poverty prevention
                        <b-button
                          v-b-tooltip.hover
                          id="prog_addl_srae_tcm_success_prev_info"
                          title="Success sequence for poverty prevention – The three steps that young adults should take to improve the likelihood of successful economic outcomes when reaching adulthood. The steps include, but are not limited to, graduating from high school, working a full time job, and waiting until age 21 or later to get married and have children (SRAE 2019 Funding Opportunity Announcement)."
                          size="sm"
                          >?</b-button
                        ></span
                      >
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="
                            formData.input.prog_addl_srae_tcm_success_prev.value
                          "
                          id="prog_addl_srae_tcm_success_prev"
                          @change="
                            dataUpdated('prog_addl_srae_tcm_success_prev')
                          "
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>

                    <label class="row">
                      <span class="col-7">Healthy relationships</span>
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="
                            formData.input.prog_addl_srae_tcm_healthy_rltnshp
                              .value
                          "
                          id="prog_addl_srae_tcm_healthy_rltnshp"
                          @change="
                            dataUpdated('prog_addl_srae_tcm_healthy_rltnshp')
                          "
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>
                    <div class="error-dark">
                      {{ this.addl_srae_msg }}
                    </div>
                  </fieldset>
                  <fieldset class="col-lg-6">
                    <legend>Resisting:</legend>

                    <label class="row">
                      <span class="col-7">Sexual coercion</span>
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="
                            formData.input.prog_addl_srae_tcm_resist_coercion
                              .value
                          "
                          id="prog_addl_srae_tcm_resist_coercion"
                          @change="
                            dataUpdated('prog_addl_srae_tcm_resist_coercion')
                          "
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>

                    <label class="row">
                      <span class="col-7">Dating violence</span>
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="
                            formData.input.prog_addl_srae_tcm_dating_violence
                              .value
                          "
                          id="prog_addl_srae_tcm_dating_violence"
                          @change="
                            dataUpdated('prog_addl_srae_tcm_dating_violence')
                          "
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>

                    <label class="row">
                      <span class="col-7"
                        >Other risk behaviors, such as alcohol, tobacco, and
                        other drug use</span
                      >
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="
                            formData.input.prog_addl_srae_tcm_others.value
                          "
                          id="prog_addl_srae_tcm_others"
                          @change="dataUpdated('prog_addl_srae_tcm_others')"
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!isWinterSeason" class="row">
          <div class="col-lg-12">
            <div class="card card-default">
              <div class="card-header separator">
                <div class="card-title">
                  Other program elements that address SRAE topics
                </div>
              </div>
              <div class="card-body form-group">
                <div class="row">
                  <div class="col-md-8 col-lg-6">
                    <label for="prog_other_programs"
                      >Are there any other program elements – such as
                      supplemental curriculum, guest speakers, or other program
                      activities that are not part of the core curriculum – that
                      address SRAE topics?</label
                    >
                  </div>

                  <div class="col-md-3 offset-md-1 col-sm-4">
                    <b-form-select
                      v-model="formData.input.prog_other_programs.value"
                      id="prog_other_programs"
                      @change="dataUpdated('prog_other_programs')"
                      v-bind:class="this.formData.input.prog_other_programs.fmt"
                      :options="yn_blank"
                    ></b-form-select>
                  </div>
                  <div
                    class="form-group row"
                    v-if="this.formData.input.prog_other_programs.flag"
                  >
                    <div class="error-dark">
                      {{ this.formData.input.prog_other_programs.msg }}
                    </div>
                  </div>
                </div>

                <div
                  class="row"
                  v-show="this.formData.input.prog_other_programs.value"
                  style="margin-top: 12px"
                >
                  <label
                    >Indicate which SRAE topics are addressed through
                    supplemental program elements (select all that
                    apply):</label
                  >
                  <fieldset class="col-md-6">
                    <legend>Teaching the benefits of:</legend>
                    <b-form-checkbox
                      v-model="
                        formData.input.prog_supp_prog_nm_sexual_activity.value
                      "
                      id="prog_supp_prog_nm_sexual_activity"
                      @change="dataUpdated('prog_supp_prog_nm_sexual_activity')"
                    >
                      Refraining from non-marital sexual
                      activity</b-form-checkbox
                    >
                    <b-form-checkbox
                      v-model="
                        formData.input.prog_supp_prog_self_regulation.value
                      "
                      id="prog_supp_prog_self_regulation"
                      @change="dataUpdated('prog_supp_prog_self_regulation')"
                    >
                      Self-regulation</b-form-checkbox
                    >
                    <b-form-checkbox
                      v-model="formData.input.prog_supp_prog_goal_setting.value"
                      id="prog_supp_prog_goal_setting"
                      @change="dataUpdated('prog_supp_prog_goal_setting')"
                    >
                      Goal setting</b-form-checkbox
                    >
                    <b-form-checkbox
                      v-model="
                        formData.input.prog_supp_prog_success_prevention.value
                      "
                      id="prog_supp_prog_success_prevention"
                      @change="dataUpdated('prog_supp_prog_success_prevention')"
                    >
                      Success sequence for poverty prevention</b-form-checkbox
                    >
                    <b-form-checkbox
                      v-model="
                        formData.input.prog_supp_prog_healthy_rltnshp.value
                      "
                      id="prog_supp_prog_healthy_rltnshp"
                      @change="dataUpdated('prog_supp_prog_healthy_rltnshp')"
                    >
                      Healthy relationships</b-form-checkbox
                    >
                  </fieldset>
                  <fieldset class="col-md-6">
                    <legend>Resisting:</legend>
                    <b-form-checkbox
                      v-model="
                        formData.input.prog_supp_prog_resisting_coercion.value
                      "
                      id="prog_supp_prog_resisting_coercion"
                      @change="dataUpdated('prog_supp_prog_resisting_coercion')"
                    >
                      Sexual coercion</b-form-checkbox
                    >
                    <b-form-checkbox
                      v-model="
                        formData.input.prog_supp_prog_dating_violence.value
                      "
                      id="prog_supp_prog_dating_violence"
                      @change="dataUpdated('prog_supp_prog_dating_violence')"
                    >
                      Dating violence</b-form-checkbox
                    >
                    <b-form-checkbox
                      v-model="formData.input.prog_supp_prog_others.value"
                      id="prog_supp_prog_others"
                      @change="dataUpdated('prog_supp_prog_others')"
                    >
                      Other risk behaviors, such as alcohol, tobacco, and other
                      drug use</b-form-checkbox
                    >
                  </fieldset>
                  <div class="form group row" v-if="'this.supp_prog_msg' != ''">
                    <div class="error-dark">
                      {{ this.supp_prog_msg }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!isWinterSeason" class="row">
          <div class="col-lg-12">
            <div class="card card-default">
              <div class="card-header separator">
                <div class="card-title">Target populations</div>
              </div>
              <div class="card-body form-group">
                <label
                  >Indicate which, if any, of the following youth groups are
                  target populations for the provider’s program (select all that
                  apply):</label
                >
                <div class="row">
                  <div class="col-lg-6">
                    <label class="row">
                      <span class="col-7"
                        >Youth in high-need geographic areas</span
                      >
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="
                            formData.input.prog_trgt_pop_high_need_geo_area
                              .value
                          "
                          id="prog_trgt_pop_high_need_geo_area"
                          v-bind:class="
                            formData.input.prog_trgt_pop_high_need_geo_area.fmt
                          "
                          @change="
                            dataUpdated('prog_trgt_pop_high_need_geo_area')
                          "
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>
                    <label class="row">
                      <span class="col-7">Youth in foster care</span>
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="
                            formData.input.prog_trgt_pop_youth_foster_care.value
                          "
                          id="prog_trgt_pop_youth_foster_care"
                          v-bind:class="
                            formData.input.prog_trgt_pop_youth_foster_care.fmt
                          "
                          @change="
                            dataUpdated('prog_trgt_pop_youth_foster_care')
                          "
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>
                    <label class="row">
                      <span class="col-7">Homeless or runaway youth</span>
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="
                            formData.input.prog_trgt_pop_homeless_or_runaway
                              .value
                          "
                          id="prog_trgt_pop_homeless_or_runaway"
                          v-bind:class="
                            formData.input.prog_trgt_pop_homeless_or_runaway.fmt
                          "
                          @change="
                            dataUpdated('prog_trgt_pop_homeless_or_runaway')
                          "
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>
                    <label class="row">
                      <span class="col-7">Youth living with HIV/AIDS</span>
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="formData.input.prog_trgt_pop_hiv_aids.value"
                          id="prog_trgt_pop_hiv_aids"
                          v-bind:class="
                            formData.input.prog_trgt_pop_hiv_aids.fmt
                          "
                          @change="dataUpdated('prog_trgt_pop_hiv_aids')"
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>
                    <label class="row">
                      <span class="col-7">Pregnant or parenting youth</span>
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="
                            formData.input.prog_trgt_pop_prgnnt_or_parenting
                              .value
                          "
                          id="prog_trgt_pop_prgnnt_or_parenting"
                          v-bind:class="
                            formData.input.prog_trgt_pop_prgnnt_or_parenting.fmt
                          "
                          @change="
                            dataUpdated('prog_trgt_pop_prgnnt_or_parenting')
                          "
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>
                    <label class="row">
                      <span class="col-7">Hispanic/Latino youth</span>
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="
                            formData.input.prog_trgt_pop_hisp_latino.value
                          "
                          id="prog_trgt_pop_hisp_latino"
                          v-bind:class="
                            formData.input.prog_trgt_pop_hisp_latino.fmt
                          "
                          @change="dataUpdated('prog_trgt_pop_hisp_latino')"
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>
                    <label class="row">
                      <span class="col-7">African-American youth</span>
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="formData.input.prog_trgt_pop_afr_amer.value"
                          id="prog_trgt_pop_afr_amer"
                          v-bind:class="
                            formData.input.prog_trgt_pop_afr_amer.fmt
                          "
                          @change="dataUpdated('prog_trgt_pop_afr_amer')"
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>
                    <div class="form group row" v-if="'this.target_msg' != ''">
                      <div class="error-dark">
                        {{ this.target_msg }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <label class="row">
                      <span class="col-7">Native American youth</span>
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="formData.input.prog_trgt_pop_ntv_amer.value"
                          id="prog_trgt_pop_ntv_amer"
                          v-bind:class="
                            formData.input.prog_trgt_pop_ntv_amer.fmt
                          "
                          @change="dataUpdated('prog_trgt_pop_ntv_amer')"
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>
                    <label class="row">
                      <span class="col-7">LGBTQ Youth</span>
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="
                            formData.input.prog_trgt_pop_lgbtq_youth.value
                          "
                          id="prog_trgt_pop_lgbtq_youth"
                          v-bind:class="
                            formData.input.prog_trgt_pop_lgbtq_youth.fmt
                          "
                          @change="dataUpdated('prog_trgt_pop_lgbtq_youth')"
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>
                    <label class="row">
                      <span class="col-7">Youth in adjudication systems</span>
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="
                            formData.input.prog_trgt_pop_adjudication_systems
                              .value
                          "
                          id="prog_trgt_pop_adjudication_systems"
                          v-bind:class="
                            formData.input.prog_trgt_pop_adjudication_systems
                              .fmt
                          "
                          @change="
                            dataUpdated('prog_trgt_pop_adjudication_systems')
                          "
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>
                    <label class="row">
                      <span class="col-7">Male youth</span>
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="formData.input.prog_trgt_pop_male.value"
                          id="prog_trgt_pop_male"
                          v-bind:class="formData.input.prog_trgt_pop_male.fmt"
                          @change="dataUpdated('prog_trgt_pop_male')"
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>
                    <label class="row">
                      <span class="col-7">Out of school or dropout youth</span>
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="
                            formData.input.prog_trgt_pop_out_of_school.value
                          "
                          id="prog_trgt_pop_out_of_school"
                          v-bind:class="
                            formData.input.prog_trgt_pop_out_of_school.fmt
                          "
                          @change="dataUpdated('prog_trgt_pop_out_of_school')"
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>
                    <label class="row">
                      <span class="col-7"
                        >Youth in residential treatment for mental health
                        issues</span
                      >
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="
                            formData.input.prog_trgt_pop_residential_trtmnt
                              .value
                          "
                          id="prog_trgt_pop_residential_trtmnt"
                          v-bind:class="
                            formData.input.prog_trgt_pop_residential_trtmnt.fmt
                          "
                          @change="
                            dataUpdated('prog_trgt_pop_residential_trtmnt')
                          "
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>
                    <label class="row">
                      <span class="col-7">Trafficked youth</span>
                      <div class="col-5 p-r-20">
                        <b-form-select
                          v-model="
                            formData.input.prog_trgt_pop_trafficked.value
                          "
                          id="prog_trgt_pop_trafficked"
                          v-bind:class="
                            formData.input.prog_trgt_pop_trafficked.fmt
                          "
                          @change="dataUpdated('prog_trgt_pop_trafficked')"
                          :options="yn"
                        >
                        </b-form-select>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="row">
            <div class="col-lg-12 text-right">
              <!-- Using modifiers -->
              <b-button
                v-b-modal.confirmSubmit
                id="save_program_button"
                @click="checkForm('submit', $event)"
                class="btn btn-lg btn-primary m-r-10"
              >
                Save
              </b-button>

              <router-link
                id="continue_button"
                :to="{
                  name: 'program-reach',
                  params: { pid: $route.params.pid, pmid: $route.params.pmid },
                }"
                class="btn btn-lg btn-primary m-r-10"
                title="Continue to Attendance, Reach, and Dosage form"
              >
                <span>Continue</span>
              </router-link>
            </div>
          </div>

          <!-- The modal -->
          <b-modal id="confirmSubmit" ok-only>
            <div v-if="errors.length || showstoppers.length">
              <p><b>Please correct the following error(s):</b></p>
              <ul class="error-dark">
                <li
                  v-for="showstopper in showstoppers"
                  v-bind:key="showstopper"
                >
                  {{ showstopper }}
                </li>
              </ul>
              <ul>
                <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
              </ul>
            </div>

            <div v-if="warnings.length">
              <p><b>Warning(s):</b></p>
              <ul class="error-dark">
                <li v-for="warning in warnings" v-bind:key="warning">
                  {{ warning }}
                </li>
              </ul>
            </div>

            <p
              v-if="!warnings.length && !errors.length && !showstoppers.length"
            >
              <b>No issues detected</b>
              <br />
              The data on this page has been saved.
            </p>
          </b-modal>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { API } from "aws-amplify";
var rules = require("../../rules.js");
import mixin from "../../mixin";

export default {
  mixins: [mixin],
  data() {
    return {
      keyChanger: 0,
      formName: "program",
      formData: {
        grantee_name: "",
        provider_name: "",

        input: {
          fk_program_curricula: {
            type: "number",
            value: null,
            flag: false,
            desc: "Program curriculum name",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_curriculum_name_other_specify: {
            type: "string",
            value: null,
            flag: false,
            desc: "Specify program curriculum name",
            msg: "",
            fmt: "form-control",
            ignore: false,
          },
          prog_num_delivery_hours: {
            type: "number",
            value: null,
            flag: false,
            desc: "Number of intended program delivery hours",
            msg: "",
            fmt: "form-control",
            ignore: false,
          },

          prog_addl_srae_tcm_sexual_activity: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "Refraining from non-marital sexual activity is addressed by this core curriculum",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_addl_srae_tcm_self_regulation: {
            type: "boolean",
            value: false,
            flag: false,
            desc: "Self-regulation is addressed by this core curriculum",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_addl_srae_tcm_goal_setting: {
            type: "boolean",
            value: false,
            flag: false,
            desc: "Goal setting is addressed by this core curriculum",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_addl_srae_tcm_success_prev: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "Success sequence for poverty prevention is addressed by this core curriculum",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_addl_srae_tcm_healthy_rltnshp: {
            type: "boolean",
            value: false,
            flag: false,
            desc: "Healthy relationships is addressed by this core curriculum",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_addl_srae_tcm_resist_coercion: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "Resisting sexual coercion is addressed by this core curriculum",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_addl_srae_tcm_dating_violence: {
            type: "boolean",
            value: false,
            flag: false,
            desc: "Dating violence is addressed by this core curriculum",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_addl_srae_tcm_others: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "Other risk behaviors, such as alcohol, tobacco, and other drug use are addressed by this core curriculum",
            msg: "",
            fmt: "",
            ignore: false,
          },

          prog_other_programs: {
            type: "boolean",
            value: null,
            flag: false,
            desc: "Other program elements that address SRAE topics",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_supp_prog_nm_sexual_activity: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "Refraining from non-marital sexual activity is addressed through supplemental program elementsy",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_supp_prog_self_regulation: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "Self-regulation is addressed through supplemental program elements",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_supp_prog_goal_setting: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "Goal setting is addressed through supplemental program elements",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_supp_prog_success_prevention: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "Success sequence for poverty prevention is addressed through supplemental program elements",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_supp_prog_healthy_rltnshp: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "Healthy relationships is addressed through supplemental program elements",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_supp_prog_resisting_coercion: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "Resisting sexual coercion is addressed through supplemental program elements",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_supp_prog_dating_violence: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "Dating violence is addressed through supplemental program elements",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_supp_prog_others: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "Other risk behaviors, such as alcohol, tobacco, and other drug use, are addressed through supplemental program elements",
            msg: "",
            fmt: "",
            ignore: false,
          },

          prog_trgt_pop_high_need_geo_area: {
            type: "boolean",
            value: null,
            flag: false,
            desc:
              "Youth in high-need geographic areas are a target population for the provider's program",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_trgt_pop_youth_foster_care: {
            type: "boolean",
            value: null,
            flag: false,
            desc:
              "Youth in foster care are a target population for the provider's program",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_trgt_pop_homeless_or_runaway: {
            type: "boolean",
            value: null,
            flag: false,
            desc:
              "Homeless or runaway youth are a target population for the provider's program",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_trgt_pop_hiv_aids: {
            type: "boolean",
            value: null,
            flag: false,
            desc:
              "Youth living with HIV/AIDS are a target population for the provider's program",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_trgt_pop_prgnnt_or_parenting: {
            type: "boolean",
            value: null,
            flag: false,
            desc:
              "Pregnant or parenting youth are a target population for the provider's program",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_trgt_pop_hisp_latino: {
            type: "boolean",
            value: null,
            flag: false,
            desc:
              "Hispanic/Latino youth are a target population for the provider's program",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_trgt_pop_afr_amer: {
            type: "boolean",
            value: null,
            flag: false,
            desc:
              "African American youth are a target population for the provider's program",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_trgt_pop_ntv_amer: {
            type: "boolean",
            value: null,
            flag: false,
            desc:
              " Native American youth are a target population for the provider's program",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_trgt_pop_lgbtq_youth: {
            type: "boolean",
            value: null,
            flag: false,
            desc:
              "LGBTQ youth are a target population for the provider's program",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_trgt_pop_adjudication_systems: {
            type: "boolean",
            value: null,
            flag: false,
            desc:
              "Youth in adjudication systems are a target population for the provider's program",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_trgt_pop_male: {
            type: "boolean",
            value: null,
            flag: false,
            desc:
              "Male youth are a target population for the provider's program",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_trgt_pop_out_of_school: {
            type: "boolean",
            value: null,
            flag: false,
            desc:
              "Out of school or dropout youth are a target population for the provider's program",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_trgt_pop_residential_trtmnt: {
            type: "boolean",
            value: null,
            flag: false,
            desc:
              "Youth in residential treatment for mental health issues are a target population for the provider's program",
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_trgt_pop_trafficked: {
            type: "boolean",
            value: null,
            flag: false,
            desc:
              "Trafficked youth are a target population for the provider's program",
            msg: "",
            fmt: "",
            ignore: false,
          },
        },
      },
      program_models: [],
      custom_program_models: [],
      null_program_models: [
        { pk: null, prog_curriculum_name: "Please select an option" },
      ],

      yn: [
        { value: null, text: "Please select an option" },
        { value: true, text: "Yes" },
        { value: false, text: "No" },
      ],
      yn_blank: [
        { value: null, text: "" },
        { value: true, text: "Yes" },
        { value: false, text: "No" },
      ],

      errors: [],
      showstoppers: [],
      warnings: [],
      status_msg: "",
      target_msg: "",
      addl_srae_msg: "",
      supp_prog_msg: "",
      saved: true,
    };
  },
  watch: {
    grantee() {
      // get grantee data
      this.getSubmissionData();
    },

    "$store.state.reportingPeriodData": function () {
      this.getSubmissionData("watch");
    },
  },
  created() {
    this.getSubmissionData();
  },
  computed: {
    totalFundingAmountNegative: function () {
      return rules.default.inSet(this.errors, [
        "Total funding amount cannot be negative.",
      ]);
    },

    ignoreLowerHalf: function () {
      return true;
    },
    isWinterSeason: function () {
      return this.$store.state.isWinterSeason;
    },
    formDataSubmission: function () {
      return {
        formName: "program",
        formData: [
          {
            tableName: "program_submissions",
            tableData: [
              [
                {
                  name: "provider_name",
                  value: {
                    stringValue: "Test provider",
                  },
                },
                {
                  name: "grantee_name",
                  value: {
                    stringValue: "Test grantee",
                  },
                },
                {
                  name: "fk_program_curricula",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.fk_program_curricula.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.fk_program_curricula.value
                        ),
                      },
                },
                {
                  name: "prog_num_delivery_hours",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_num_delivery_hours.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        doubleValue: this.removeThousandCommas(
                          this.formData.input.prog_num_delivery_hours.value
                        ),
                      },
                },
                {
                  name: "prog_addl_srae_tcm_sexual_activity",
                  value:
                    this.formData.input.prog_addl_srae_tcm_sexual_activity
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_addl_srae_tcm_sexual_activity.value,
                        },
                },
                {
                  name: "prog_addl_srae_tcm_self_regulation",
                  value:
                    this.formData.input.prog_addl_srae_tcm_self_regulation
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_addl_srae_tcm_self_regulation.value,
                        },
                },
                {
                  name: "prog_addl_srae_tcm_goal_setting",
                  value:
                    this.formData.input.prog_addl_srae_tcm_goal_setting
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_addl_srae_tcm_goal_setting.value,
                        },
                },
                {
                  name: "prog_addl_srae_tcm_success_prev",
                  value:
                    this.formData.input.prog_addl_srae_tcm_success_prev
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_addl_srae_tcm_success_prev.value,
                        },
                },
                {
                  name: "prog_addl_srae_tcm_healthy_rltnshp",
                  value:
                    this.formData.input.prog_addl_srae_tcm_healthy_rltnshp
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_addl_srae_tcm_healthy_rltnshp.value,
                        },
                },
                {
                  name: "prog_addl_srae_tcm_resist_coercion",
                  value:
                    this.formData.input.prog_addl_srae_tcm_resist_coercion
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_addl_srae_tcm_resist_coercion.value,
                        },
                },
                {
                  name: "prog_addl_srae_tcm_dating_violence",
                  value:
                    this.formData.input.prog_addl_srae_tcm_dating_violence
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_addl_srae_tcm_dating_violence.value,
                        },
                },
                {
                  name: "prog_addl_srae_tcm_others",
                  value:
                    this.formData.input.prog_addl_srae_tcm_others.value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_addl_srae_tcm_others.value,
                        },
                },
                {
                  name: "prog_other_programs",
                  value:
                    this.formData.input.prog_other_programs.value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input.prog_other_programs
                            .value,
                        },
                },
                {
                  name: "prog_supp_prog_nm_sexual_activity",
                  value:
                    this.formData.input.prog_supp_prog_nm_sexual_activity
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_supp_prog_nm_sexual_activity.value,
                        },
                },
                {
                  name: "prog_supp_prog_self_regulation",
                  value:
                    this.formData.input.prog_supp_prog_self_regulation.value ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_supp_prog_self_regulation.value,
                        },
                },
                {
                  name: "prog_supp_prog_goal_setting",
                  value:
                    this.formData.input.prog_supp_prog_goal_setting.value ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_supp_prog_goal_setting.value,
                        },
                },
                {
                  name: "prog_supp_prog_success_prevention",
                  value:
                    this.formData.input.prog_supp_prog_success_prevention
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_supp_prog_success_prevention.value,
                        },
                },
                {
                  name: "prog_supp_prog_healthy_rltnshp",
                  value:
                    this.formData.input.prog_supp_prog_healthy_rltnshp.value ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_supp_prog_healthy_rltnshp.value,
                        },
                },
                {
                  name: "prog_supp_prog_resisting_coercion",
                  value:
                    this.formData.input.prog_supp_prog_resisting_coercion
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_supp_prog_resisting_coercion.value,
                        },
                },
                {
                  name: "prog_supp_prog_dating_violence",
                  value:
                    this.formData.input.prog_supp_prog_dating_violence.value ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_supp_prog_dating_violence.value,
                        },
                },
                {
                  name: "prog_supp_prog_others",
                  value:
                    this.formData.input.prog_supp_prog_others.value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_supp_prog_others.value,
                        },
                },
                {
                  name: "prog_trgt_pop_high_need_geo_area",
                  value:
                    this.formData.input.prog_trgt_pop_high_need_geo_area
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_trgt_pop_high_need_geo_area.value,
                        },
                },
                {
                  name: "prog_trgt_pop_youth_foster_care",
                  value:
                    this.formData.input.prog_trgt_pop_youth_foster_care
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_trgt_pop_youth_foster_care.value,
                        },
                },
                {
                  name: "prog_trgt_pop_homeless_or_runaway",
                  value:
                    this.formData.input.prog_trgt_pop_homeless_or_runaway
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_trgt_pop_homeless_or_runaway.value,
                        },
                },
                {
                  name: "prog_trgt_pop_hiv_aids",
                  value:
                    this.formData.input.prog_trgt_pop_hiv_aids.value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_trgt_pop_hiv_aids.value,
                        },
                },
                {
                  name: "prog_trgt_pop_prgnnt_or_parenting",
                  value:
                    this.formData.input.prog_trgt_pop_prgnnt_or_parenting
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_trgt_pop_prgnnt_or_parenting.value,
                        },
                },
                {
                  name: "prog_trgt_pop_hisp_latino",
                  value:
                    this.formData.input.prog_trgt_pop_hisp_latino.value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_trgt_pop_hisp_latino.value,
                        },
                },
                {
                  name: "prog_trgt_pop_afr_amer",
                  value:
                    this.formData.input.prog_trgt_pop_afr_amer.value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_trgt_pop_afr_amer.value,
                        },
                },
                {
                  name: "prog_trgt_pop_ntv_amer",
                  value:
                    this.formData.input.prog_trgt_pop_ntv_amer.value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_trgt_pop_ntv_amer.value,
                        },
                },
                {
                  name: "prog_trgt_pop_lgbtq_youth",
                  value:
                    this.formData.input.prog_trgt_pop_lgbtq_youth.value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_trgt_pop_lgbtq_youth.value,
                        },
                },

                {
                  name: "prog_trgt_pop_adjudication_systems",
                  value:
                    this.formData.input.prog_trgt_pop_adjudication_systems
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_trgt_pop_adjudication_systems.value,
                        },
                },
                {
                  name: "prog_trgt_pop_male",
                  value:
                    this.formData.input.prog_trgt_pop_male.value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input.prog_trgt_pop_male
                            .value,
                        },
                },
                {
                  name: "prog_trgt_pop_out_of_school",
                  value:
                    this.formData.input.prog_trgt_pop_out_of_school.value ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_trgt_pop_out_of_school.value,
                        },
                },
                {
                  name: "prog_trgt_pop_residential_trtmnt",
                  value:
                    this.formData.input.prog_trgt_pop_residential_trtmnt
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_trgt_pop_residential_trtmnt.value,
                        },
                },
                {
                  name: "prog_trgt_pop_trafficked",
                  value:
                    this.formData.input.prog_trgt_pop_trafficked.value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_trgt_pop_trafficked.value,
                        },
                },
                {
                  name: "prog_data_issues_minor",
                  value: {
                    booleanValue: this.errors.length > 0,
                  },
                },
                {
                  name: "prog_data_issues_major",
                  value: {
                    booleanValue: this.showstoppers.length > 0,
                  },
                },
              ],
            ],
          },
        ],
      };
    },
  },
  methods: {
    initializeCommas(num) {
      if (num) {
        const string = num.toString();
        const number = string.replace(/,/g, "");
        const split = number.split(".");

        let commas;

        if (split.length > 1) {
          split[0] = split[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          commas = split.join(".");
        } else {
          commas = split[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        return commas;
      } else {
        return num;
      }
    },

    addThousandComma(varName) {
      if (
        this.formData.input[varName].value === null ||
        typeof this.formData.input[varName].value === "undefined"
      ) {
        this.formData.input[varName].value = null;
      } else {
        const target = this.formData.input[varName].value;
        const initialLength = target.length;

        // For a long time we only added commas to fields that were integer only,
        // so we removed decimals in user input. For this one field (prog_num_deliver_hours),
        // we now want to allow two decimal places, but preserve the existing comma
        // insertion. So the regex for replacement varies for that one field.
        const regex =
          varName === "prog_num_delivery_hours" ? /[^0-9,.]/g : /[^0-9,]/g;

        const numericOnly = target.replace(regex, "");

        const commas = this.initializeCommas(numericOnly);
        this.formData.input[varName].value = commas;

        if (numericOnly.length === initialLength) {
          this.dataUpdated();
        }
      }
    },

    removeThousandCommas(value) {
      if (isNaN(value)) {
        let number = value.replace(/,/g, "");
        return parseFloat(number);
      } else {
        return parseFloat(value);
      }
    },
    getSubmissionData() {
      API.get(
        "webapi",
        `/program-models/${this.$route.params.pid}/submissions/${this.$store.state.reportingPeriodData.pk}`
      ).then((response) => {
        if (response.success) {
          this.custom_program_models = response.data.filter(
            (element) => element.fk_providers != null
          );
          this.program_models = response.data.filter(
            (element) => element.fk_providers === null
          );
          //this.program_models.unshift({pk:null, prog_curriculum_name:'Please select an option'});
          this.program_models.push({
            pk: 0,
            prog_curriculum_name: "Other",
            fk: null,
          });
          this.$store
            .dispatch("getSubmissionData", {
              path: `/grantees/${this.grantee.id}/providers/${this.$route.params.pid}/programs/${this.$route.params.pmid}/submissions/${this.$store.state.reportingPeriodData.pk}/reduce`,
            })
            .then((r) => {
              if (r.success && r.data.length) {
                const data = r.data[0];
                const keys = Object.keys(data);

                keys.forEach((key) => {
                  if (Object.keys(this.formData.input).includes(key)) {
                    if (this.formData.input[key].type === "number") {
                      data[key] = this.initializeCommas(data[key]);
                    }
                    this.formData.input[key].value = data[key];
                  }
                });

                if (
                  data.prog_data_issues_major ||
                  data.prog_data_issues_minor
                ) {
                  this.checkForm();
                }
              }

              //   if (r.data.length > 0) {
              //     var colnames = r.data.columnMetadata.map(obj => obj.name);
              //     var record   = r.data.records[0];
              //     var value;

              //   for (var i = 0; i < colnames.length; i++) {

              //       //r.data.records[0].map(obj => Object.values(obj)[0]);
              //       var colname = colnames[i]

              //       if (Object.keys(record[i]).includes('isNull')) {
              //           value = null;
              //       } else {
              //           value = Object.values(record[i])[0];
              //       }

              //       if (Object.keys(this.formData.input).includes(colname)) {
              //       //if (this.formData.input[colname]) {
              //         this.formData.input[colname].value = value;
              //         // this.formData.input[colname].value = value;
              //       }

              //       if ((colname === 'prog_data_issues_minor' || colname == 'prog_data_issues_major') && value) {
              //         checkForm = checkForm || value;
              //       }

              //   }

              //   }

              // if (checkForm) {
              //   this.checkForm();
              // }

              this.saved = true;
            });
        } else {
          // error getting programs names
          console.error("error getting programs model names");
        }
      });
    },
    dataUpdated() {
      this.saved = false;
    },

    submitForm(_action) {
      if (
        this.formData.input.fk_program_curricula.value === 0 ||
        (this.formData.input.prog_curriculum_name_other_specify.value &&
          Object.values(
            this.custom_program_models.map((obj) => obj.pk)
          ).includes(this.formData.input.fk_program_curricula.value))
      ) {
        // custom name

        API.post(
          "webapi",
          `/program-models/${this.$route.params.pid}/submissions/${this.$store.state.reportingPeriodData.pk}`,

          {
            body: {
              prog_curriculum_name: !this.formData.input
                .prog_curriculum_name_other_specify.value
                ? "[blank]"
                : this.formData.input.prog_curriculum_name_other_specify.value,
            },
          }
        ).then((response) => {
          if (response.success) {
            //prevents custom option from getting added to list twice
            if (
              !this.custom_program_models.reduce(
                (accum, val) =>
                  Object.values(val).includes(response.data[0].pk)
                    ? true
                    : accum,
                false
              )
            ) {
              this.custom_program_models.push({
                pk: response.data[0].pk,
                prog_curriculum_name: response.data[0].prog_curriculum_name,
                fk_providers: response.data[0].fk_providers,
              });
            }
            this.formData.input.fk_program_curricula.value =
              response.data[0].pk;
            //this updates validation checks for the "blank" case
            if (response.data[0].prog_curriculum_name == "[blank]") {
              this.formData.input.fk_program_curricula.flag = true;
              this.formData.input.fk_program_curricula.fmt += " invalid-input";
              this.formData.input.fk_program_curricula.msg =
                "Please enter the name of the program’s core curriculum.";
              this.showstoppers.unshift(
                this.formData.input.fk_program_curricula.desc +
                  ": " +
                  this.formData.input.fk_program_curricula.msg
              );
            }
            this.keyChanger += 1;
            this.$store
              .dispatch("submitForm", {
                action: _action,
                path: `/grantees/${this.grantee.id}/providers/${this.$route.params.pid}/programs/${this.$route.params.pmid}/submissions/${this.$store.state.reportingPeriodData.pk}`,
                data: this.formDataSubmission,
              })
              .then((r) => {
                this.status_msg = r;
                // trigger the dropdown to preselect new name
                this.formData.input.fk_program_curricula.value =
                  response.data[0].pk;
                this.saved = true;
              });
          } else {
            // state.modals.addProgram.errorMsg=('message' in response.error) ? response.error.message : 'An error has occurred, please contact support.'
            console.error("error adding new custom curriculum name");
          }
        });
      } else {
        // when 'Other' is selected for custom name but no text is entered, default to null
        //if(this.formData.input.fk_program_curricula.value === 0 && ( !this.formData.input.prog_curriculum_name_other_specify.value || this.formData.input.prog_curriculum_name_other_specify.value.replace(/\s/g, '').length <= 0 ))this.formData.input.fk_program_curricula.value = null
        this.$store
          .dispatch("submitForm", {
            action: _action,
            path: `/grantees/${this.grantee.id}/providers/${this.$route.params.pid}/programs/${this.$route.params.pmid}/submissions/${this.$store.state.reportingPeriodData.pk}`,
            data: this.formDataSubmission,
          })
          .then((r) => {
            this.status_msg = r;
          });
      }
    },

    checkForm(_type) {
      this.saved = true;

      this.errors = [];
      this.showstoppers = [];
      this.warnings = [];

      // 1. check for negative/blank values
      var f = Object.keys(this.formData.input);
      for (var i = 0; i < f.length; i++) {
        //can't do it like this... need separate blocks for other specify and other question
        if (this.formData.input[f[i]].desc == "Program curriculum name") {
          this.formData.input.prog_curriculum_name_other_specify.fmt = this.formData.input.prog_curriculum_name_other_specify.fmt.replace(
            "invalid-input",
            ""
          );
          this.formData.input.prog_curriculum_name_other_specify.msg = "";
          this.formData.input.prog_curriculum_name_other_specify.flag = false;
        }

        // reset format, message, and flag
        if (
          this.formData.input[f[i]].desc != "Specify program curriculum name"
        ) {
          this.formData.input[f[i]].fmt = this.formData.input[f[i]].fmt.replace(
            "invalid-input",
            ""
          );
          this.formData.input[f[i]].msg = "";
          this.formData.input[f[i]].flag = false;
        }

        var s = "";

        // deal w/ strings (using type != number since typeof null == object)
        if (this.formData.input[f[i]].desc == "Program curriculum name") {
          if (
            !!this.custom_program_models.filter(
              (element) => element.pk == this.formData.input[f[i]].value
            )[0] &&
            this.custom_program_models.filter(
              (element) => element.pk == this.formData.input[f[i]].value
            )[0].prog_curriculum_name == "[blank]"
          ) {
            this.formData.input[f[i]].flag = true;
            this.formData.input[f[i]].fmt += " invalid-input";
            this.formData.input[f[i]].msg = "Please enter a response.";
            s =
              this.formData.input[f[i]].desc +
              ": " +
              this.formData.input[f[i]].msg;
            this.showstoppers.push(s);
          } else if (this.formData.input[f[i]].value == null) {
            this.formData.input[f[i]].flag = true;
            this.formData.input[f[i]].fmt += " invalid-input";
            this.formData.input[f[i]].msg = "Please select a response.";
            s =
              this.formData.input[f[i]].desc +
              ": " +
              this.formData.input[f[i]].msg;
            this.showstoppers.push(s);
          }
        }

        if (!this.isWinterSeason) {
          // deal w/ numbers - account for negative values and NaNs
          if (this.formData.input[f[i]].type == "number") {
            if (
              this.removeThousandCommas(this.formData.input[f[i]].value) < 0
            ) {
              this.formData.input[f[i]].flag = true;
              this.formData.input[f[i]].fmt += " invalid-input";
              this.formData.input[f[i]].msg = "Please enter a positive value.";
              s =
                this.formData.input[f[i]].desc +
                ": " +
                this.formData.input[f[i]].msg;
              this.errors.push(s);
            } else if (
              this.formData.input[f[i]].value === null ||
              isNaN(this.removeThousandCommas(this.formData.input[f[i]].value))
            ) {
              this.formData.input[f[i]].flag = true;
              this.formData.input[f[i]].fmt += " invalid-input";
              this.formData.input[f[i]].msg = "Please enter a value.";
              s =
                this.formData.input[f[i]].desc +
                ": " +
                this.formData.input[f[i]].msg;
              this.errors.push(s);
            }
          } else if (
            this.formData.input[f[i]].type == "boolean" &&
            this.formData.input[f[i]].value === null &&
            this.formData.input[f[i]].desc ===
              "Other program elements that address SRAE topics"
          ) {
            this.formData.input[f[i]].flag = true;
            this.formData.input[f[i]].fmt += " invalid-input";
            this.formData.input[f[i]].msg = "Please select a response.";
            s =
              this.formData.input[f[i]].desc +
              ": " +
              this.formData.input[f[i]].msg;
            this.errors.push(s);
          }
        }
      }

      if (!this.isWinterSeason) {
        var prog_addl_srae = [
          this.formData.input.prog_addl_srae_tcm_dating_violence,
          this.formData.input.prog_addl_srae_tcm_goal_setting,
          this.formData.input.prog_addl_srae_tcm_healthy_rltnshp,
          this.formData.input.prog_addl_srae_tcm_others,
          this.formData.input.prog_addl_srae_tcm_resist_coercion,
          this.formData.input.prog_addl_srae_tcm_self_regulation,
          this.formData.input.prog_addl_srae_tcm_sexual_activity,
          this.formData.input.prog_addl_srae_tcm_success_prev,
        ];

        var prog_supp_prog = [
          this.formData.input.prog_supp_prog_dating_violence,
          this.formData.input.prog_supp_prog_others,
          this.formData.input.prog_supp_prog_resisting_coercion,
          this.formData.input.prog_supp_prog_healthy_rltnshp,
          this.formData.input.prog_supp_prog_success_prevention,
          this.formData.input.prog_supp_prog_goal_setting,
          this.formData.input.prog_supp_prog_self_regulation,
          this.formData.input.prog_supp_prog_nm_sexual_activity,
        ];

        var prog_target = [
          this.formData.input.prog_trgt_pop_high_need_geo_area,
          this.formData.input.prog_trgt_pop_youth_foster_care,
          this.formData.input.prog_trgt_pop_homeless_or_runaway,
          this.formData.input.prog_trgt_pop_hiv_aids,
          this.formData.input.prog_trgt_pop_prgnnt_or_parenting,
          this.formData.input.prog_trgt_pop_hisp_latino,
          this.formData.input.prog_trgt_pop_afr_amer,
          this.formData.input.prog_trgt_pop_ntv_amer,
          this.formData.input.prog_trgt_pop_lgbtq_youth,
          this.formData.input.prog_trgt_pop_adjudication_systems,
          this.formData.input.prog_trgt_pop_male,
          this.formData.input.prog_trgt_pop_out_of_school,
          this.formData.input.prog_trgt_pop_residential_trtmnt,
          this.formData.input.prog_trgt_pop_trafficked,
        ];

        this.addl_srae_msg = "";
        this.supp_prog_msg = "";
        this.target_msg = "";

        // prog delivery hours warning
        if (this.formData.input.prog_num_delivery_hours.value > 40) {
          this.warnings.push(
            `
              Please confirm that you have entered the number of hours of SRAE 
              programming intended to be delivered for one complete delivery 
              of the program. Do not multiply the number of hours of programming 
              by the number of youth expected to participate.
            `
          );
        }

        for (let element of prog_target) {
          if (element.value === null) {
            element.flag = true;
            element.fmt += " invalid-input";
            element.msg = "Please select a response.";
            s = element.desc + ": " + element.msg;
            this.errors.push(s);
            this.target_msg =
              "Please select an option for every technical assistance question listed";
          }
        }

        if (
          prog_addl_srae.reduce(
            (accum, val) => accum == true || val.value == true,
            false
          ) != true
        ) {
          this.errors.push(
            "No SRAE topics addressed by this core curriculum selected"
          );
          this.addl_srae_msg =
            "No SRAE topics addressed by this core curriculum selected";
        }

        if (
          this.formData.input.prog_other_programs.value === true &&
          prog_supp_prog.reduce(
            (accum, val) => accum == true || val.value == true,
            false
          ) != true
        ) {
          this.errors.push(
            "No SRAE topics that were addressed through supplemental program elements selected"
          );
          this.supp_prog_msg =
            "No SRAE topics that were addressed through supplemental program elements selected";
        }
      }

      this.submitForm(_type);

      if (!this.errors.length && !this.showstoppers.length) {
        return true;
      }

      if (this.errors || this.showstoppers) {
        console.error("Validation errors:");
        console.error(this.errors);
        console.error(this.showstoppers);
      }

      // e.preventDefault()
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.saved) {
      const answer = window.confirm(
        "The page contains data that have not been saved. Are you sure you want to leave the page without saving?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>
