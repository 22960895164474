<template>
  <div
    class="table table-hover table-condensed dataTable no-footer"
    id="detailedTable"
    role="grid"
    :class="{ 'table-detailed': detailed }"
  >
    <div class="row d-flex header-row">
      <h4
        v-for="header in headers"
        :key="header.key"
        :style="header.width ? `flex-basis: ${header.width};` : ''"
        class="sorting_disabled"
      >
        {{ header.label }}
      </h4>
    </div>
    <div class="row table-body">
      <MNestingRow
        v-for="(item, i) in items"
        :key="i"
        :i="i"
        :row="item"
        :headers="headers"
      >
        <slot :name="item.slotName" v-if="item.slotName" />
      </MNestingRow>
    </div>
  </div>
</template>
<script>
import MNestingRow from "./M-NestingRow.vue";

export default {
  name: "MPR-Table",
  components: { MNestingRow },
  props: [
    "headers",
    "items",
    "detailed",
    "canDeleteItem",
    "deleteLabel",
    "onClickDelete",
  ],
};
</script>
<style lang="scss" scoped>
.header-row {
  width: 100%;
  justify-content: center;
  align-items: stretch;
  border-bottom: 1px solid grey;

  h3,
  h4,
  h5 {
    flex: 0 1 25%;
  }
}

.table-body,
.header-row {
  padding: 0 8px;
  margin: 0 16px;
  width: auto;
}
</style>
