import helpers from "./helpers";

export default {
  data() {
    return {
      errorMessages: {
        negative: "Please enter a postive value.",
        blank: "Please enter a value.",
      },
    };
  },
  computed: {
    // siteAccess() {
    //   // let has_siteaccess_prop =
    //   //   this.grantee &&
    //   //   Object.prototype.hasOwnProperty.call(
    //   //     this.grantee,
    //   //     "has_dashboard_access"
    //   //   ) &&
    //   //   Object.prototype.hasOwnProperty.call(this.grantee, "has_portal_access");
    //   // if (has_siteaccess_prop) {
    //   //   return this.grantee;
    //   // } else {
    //   //   let association = this.$store.state.allUserAssociations.find(
    //   //     (assc) => assc.cognito_id == this.$store.state.user.username
    //   //   );
    //   //   return (
    //   //     association || {
    //   //       has_portal_access: null,
    //   //       has_dashboard_access: null,
    //   //     }
    //   //   );
    //   // }
    //   return this.grantee
    // },
    grantee() {
      return this.$store.state.grantees.find(
        (g) => Number(this.$store.state.granteeId) === Number(g.id)
      );
    },
    provider() {
      return "pid" in this.$route.params &&
        !!this.grantee &&
        "providers" in this.grantee
        ? this.grantee.providers.find(
            (p) => Number(this.$route.params.pid) === p.id
          )
        : {};
    },
    program() {
      return "pmid" in this.$route.params &&
        !!this.provider &&
        "programs" in this.provider
        ? this.provider.programs.find(
            (pm) => Number(this.$route.params.pmid) === pm.id
          )
        : {};
    },
  },
  methods: {
    isAdmin() {
      return helpers.isAdmin(this.$store.state.user);
    },
    isACF() {
      return helpers.isACF(this.$store.state.user);
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
