// START polyfill imports
import "core-js/stable";
import "regenerator-runtime/runtime";
import "whatwg-fetch";
// END polyfill imports

import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import Amplify, * as AmplifyModules from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";
import awsconfig from "./aws-config";
import { BootstrapVueIcons } from "bootstrap-vue";
import vueSmoothScroll from "vue2-smooth-scroll";

import router from "./router";
import store from "./store";
import App from "./App.vue";

import Login from "./layouts/Login.vue";
import Dashboard from "./layouts/Dashboard.vue";
import Portal from "./layouts/Portal.vue";

import VueCookies from "vue-cookies";

import "./theme/plugins/font-awesome/scss/font-awesome.scss";

Amplify.configure(awsconfig);

Vue.config.productionTip = false;

Vue.component("dashboard-layout", Dashboard);
Vue.component("portal-layout", Portal);
Vue.component("login-layout", Login);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(VueCookies);
Vue.use(vueSmoothScroll);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
