<template>
  <div class="m-row">
    <div class="body-row row">
      <p
        v-for="header in headers"
        :key="i + header.key"
        :style="header.width ? `flex-basis: ${header.width};` : ''"
        :class="{ empty: !row[header.key] }"
        class="body-cell"
      >
        {{ row[header.key] || "None Found" }}
      </p>
    </div>
    <slot />
  </div>
</template>
<script>
export default {
  name: "M-NestingRow",
  props: {
    headers: {},
    row: {},
    i: {},
    canDelete: {
      default: false,
    },
    onClickDelete: {
      default: () => {},
    },
  },
  data() {
    return {
      open: false,
    };
  },
};
</script>
<style lang="scss">
.m-row {
  padding: 16px 0;
  border-bottom: 1px solid lightgrey;
  width: 100%;
}
.body-row {
  width: 100%;
  margin: 0 !important;
  justify-content: center;
  align-items: stretch;

  .body-cell {
    flex: 0 1 25%;
    font-size: 18px;

    &.empty {
      opacity: 0.5 !important;
      font-style: italic;
    }
  }
}

.nested-row {
  margin: 0 8px 4px;
  padding: 4px;

  .nested-row-header {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
  }

  p {
    margin: 0;
    font-size: 16px;
  }
}
</style>
