<template>
  <div
    class="app-wrapper fixed-header dashboard menu-pin"
    v-bind:class="{ 'sidebar-open sidebar-visible': sidebarActive }"
  >
    <nav
      class="page-sidebar"
      data-pages="sidebar"
      v-bind:class="{ visible: sidebarActive }"
    >
      <div class="sidebar-header">
        <img
          src="@/assets/SRAEPAS_Logo.png"
          align="right"
          class="brand"
          alt="Sexual Risk Avoidance Education - Performance Analysis Study"
          width="225"
        />
      </div>
      <div class="sidebar-menu">
        <ul class="menu-items p-t-10" v-if="$store.state.ready">
          <li v-if="grantee.role == 'Grantee'">
            <router-link to="/grantee">
              <span class="title">Grantee Data</span>
            </router-link>
          </li>
          <li
            v-if="grantee.role == 'Grantee' || grantee.role == 'Provider'"
            v-bind:class="{ active: $route.name === 'providers' }"
          >
            <router-link to="/providers">
              <span class="title">Program Provider Data</span>
            </router-link>
          </li>
          <li v-if="grantee.role == 'Grantee'">
            <router-link to="/upload/entry">
              <span class="title">Upload Entry/Exit Data</span>
            </router-link>
          </li>
          <li
            v-if="grantee.role == 'Grantee' || grantee.role == 'Provider'"
            v-bind:class="{ active: $route.name === 'validationreport' }"
          >
            <router-link to="/validationreport">
              <span class="title">Data Submission Validation</span>
            </router-link>
          </li>
          <li
            v-if="grantee.role == 'Grantee'"
            v-bind:class="{ active: $route.name === 'requseraccounts' }"
          >
            <router-link to="/requseraccounts" class="">
              <span class="title" style="line-height: 1em"
                >Grantee User <br />
                Management</span
              >
            </router-link>
          </li>
          <li>
            <router-link
              to="/manual"
              v-bind:class="{ active: $route.name === 'manual' }"
            >
              <span class="title">Manual Of Operations</span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/contact"
              v-bind:class="{ active: $route.name === 'contact' }"
            >
              <span class="title">Contact</span>
            </router-link>
          </li>
          <li v-if="isAdmin()">
            <a
              href="javascript:;"
              @click="adminSubMenuFlag = !adminSubMenuFlag"
            >
              <span class="title">Admin</span>
              <span
                class="arrow"
                v-bind:class="{ open: adminSubMenuFlag }"
              ></span>
            </a>
            <ul class="sub-menu no-padding" v-if="adminSubMenuFlag">
              <li class="">
                <router-link
                  to="/admin/users"
                  v-bind:class="{ active: $route.name === 'admin-users' }"
                >
                  <span class="title">Users</span>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/admin/grantees"
                  v-bind:class="{ active: $route.name === 'admin-grantees' }"
                >
                  <span class="title">Grantees</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
        <div class="clearfix"></div>
      </div>
      <!-- END SIDEBAR MENU -->
    </nav>
    <div class="page-container">
      <div class="header">
        <a
          class="btn-link toggle-sidebar d-lg-none pg pg-menu"
          :class="{ open: sidebarActive }"
          data-toggle="sidebar"
          @click="sidebarActive = !sidebarActive"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            aria-labelledby="nav-title nav-desc"
          >
            <title id="nav-title">Toggle sidebar menu</title>
            <desc id="nav-desc">
              Click to activate or deactivate the sidebar navigation menu
            </desc>
            <g class="open">
              <rect y="2" width="32" height="4" rx="1" fill="#541D76" />
              <rect y="12" width="32" height="4" rx="1" fill="#541D76" />
              <rect y="22" width="32" height="4" rx="1" fill="#541D76" />
            </g>
            <g class="close">
              <line
                x1="2"
                y1="2"
                x2="30"
                y2="30"
                style="stroke: #541d76; stroke-width: 4"
              />
              <line
                x1="2"
                y1="30"
                x2="30"
                y2="2"
                style="stroke: #541d76; stroke-width: 4"
              />
            </g>
          </svg>
        </a>
        <div class="">
          <div class="brand inline">
            <img
              src="@/assets/SRAEPAS_Logo.png"
              align="right"
              class="brand"
              alt="Sexual Risk Avoidance Education - Performance Analysis Study"
              width="225"
            />
          </div>
          <router-link
            v-if="
              (grantee.role == 'Grantee' || isAdmin()) &&
              grantee.has_portal_access
            "
            to="/grantee"
            class="btn btn-link text-primary m-l-35"
            v-bind:class="{ active: $route.name != 'dashboard' }"
          >
            Portal
          </router-link>
          <router-link
            v-else-if="grantee.role == 'Provider' && grantee.has_portal_access"
            to="/providers"
            class="btn btn-link text-primary m-l-35"
            v-bind:class="{ active: $route.name != 'dashboard' }"
          >
            Portal
          </router-link>
          <router-link
            v-if="
              isAdmin($store.state.user) ||
              isACF($store.state.user) ||
              grantee.has_dashboard_access
            "
            to="/dashboard"
            class="btn btn-link text-primary"
            v-bind:class="{
              active: $route.name === 'dashboard',
              disabled: !canAccessDashboard,
              'm-l-10': grantee.has_portal_access,
              'm-l-35': !grantee.has_portal_access,
            }"
          >
            Dashboard {{ !canAccessDashboard ? "(available Feb 18th) " : "" }}
          </router-link>

          <span v-if="countDownTime">
            Your session will end after {{ countDownTime }} of inactivity
          </span>
        </div>
        <div class="d-flex align-items-center">
          <div
            class="pull-left p-r-10 fs-14 font-heading d-lg-inline-block d-none m-l-20"
            @click="viewProfileDropdown = !viewProfileDropdown"
          >
            <span class="semi-bold">{{ $store.state.user.name }} </span>
            <span class="text-master">
              {{ $store.state.user.family_name }}</span
            >
          </div>
          <div class="dropdown pull-right d-md-inline-block d-none">
            <button
              class="profile-dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              @click="viewProfileDropdown = !viewProfileDropdown"
            >
              <span
                class="thumbnail-wrapper d32 circular inline bg-master-light p-t-5"
                >{{ $store.state.user.name[0]
                }}{{ $store.state.user.family_name[0] }}
              </span>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right profile-dropdown show"
              role="menu"
              v-if="viewProfileDropdown"
              @click="viewProfileDropdown = false"
            >
              <a class="dropdown-item"
                ><span
                  >Signed in as <br /><b>{{
                    $store.state.user.full_name
                  }}</b></span
                ></a
              >
              <div class="dropdown-divider"></div>
              <router-link to="/profile" class="dropdown-item"
                >My Account<i class="pg-settings_small"></i
              ></router-link>
              <router-link
                v-if="$store.state.grantees.length > 1"
                to="/landing"
                class="dropdown-item"
                >Switch Grants</router-link
              >
              <div class="dropdown-divider"></div>
              <router-link to="/contact" class="dropdown-item"
                >Contact</router-link
              >
              <router-link
                to="/logout"
                class="clearfix bg-master-lighter dropdown-item"
              >
                Signout
                <i class="pg-power"></i
              ></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="page-content-wrapper">
        <b-alert
          :show="alertDismissCountDown"
          variant="danger"
          dismissible
          fade
          @dismissed="dismissAlert"
          class="content"
          @dismiss-count-down="alertCountDownChanged"
        >
          <span v-html="this.$store.state.alertMsg"></span>
        </b-alert>

        <router-view />
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
const awsConfig = require("../aws-config.js");
import mixin from "../mixin";
import Footer from "../components/Footer.vue";
import helpers from "../helpers";

export default {
  mixins: [mixin],
  components: {
    Footer,
  },
  data() {
    return {
      // We use the environment name to make the "Upload Entry/Exit" link appear
      // only in the local and dev environments for testing until we're ready
      // to release it
      env: awsConfig.default.env,

      sidebarActive: false,
      myEvaluationsSubMenu: false,
      viewProfileDropdown: false,
      alertDismissSecs: 5,
      alertDismissCountDown: 0,
      adminSubMenuFlag_holder: false,
    };
  },
  computed: {
    canAccessDashboard() {
      return (
        !this.$store.state.isDashboardClosed ||
        helpers.isACF(this.$store.state.user) ||
        helpers.isAdmin(this.$store.state.user)
      );
    },
    route() {
      return this.$route.path;
    },
    mprLogoBorder() {
      return this.route === "/about" ? "mpr-logo" : "";
    },
    alertMsg() {
      return this.$store.state.alertMsg;
    },
    adminSubMenuFlag: {
      get: function () {
        // console.log('adminSubMenuFlag',this.$route.name.includes('admin'))
        return this.adminSubMenuFlag_holder ||
          this.$route.name.includes("admin")
          ? true
          : this.adminSubMenuFlag_holder;
      },
      set: function (newValue) {
        this.adminSubMenuFlag_holder = newValue;
      },
    },

    countDownTime() {
      if (this.$store.state.countDownDistance <= 0) return "";
      let hours = Math.floor(
        this.$store.state.countDownDistance / (1000 * 60 * 60)
      );
      let minutes = Math.floor(
        (this.$store.state.countDownDistance % (1000 * 60 * 60)) / (1000 * 60)
      );
      let seconds = Math.floor(
        (this.$store.state.countDownDistance % (1000 * 60)) / 1000
      );

      if (hours == 0 && minutes == 0) {
        return `${seconds}s`;
      } else {
        return `${hours}h and ${minutes}m`;
      }
    },
  },
  watch: {
    alertMsg() {
      if (this.alertMsg === "") this.$store.commit("alertMsg", "");
      else this.alertDismissCountDown = this.alertDismissSecs;
    },
    route() {
      this.sidebarActive = false;
    },
  },
  methods: {
    dismissAlert() {
      this.$store.commit("alertMsg", "");
    },
    alertCountDownChanged(alertDismissCountDown) {
      this.alertDismissCountDown = alertDismissCountDown;
    },
  },
};
</script>

<style lang="scss">
.page-sidebar .sidebar-header {
  width: 225px;
  padding: 0;
}

.page-sidebar .sidebar-header .brand {
  height: auto;
  width: 100%;
  padding: 0px;
  margin-top: -7px;
  padding-left: 9px;
  padding-right: 11px;
}

.page-sidebar .sidebar-menu .menu-items {
  li > a {
    width: auto;
  }
  > li > a {
    > .title {
      width: auto;
      text-decoration: underline;
    }
    > .arrow {
      padding-right: 5px;
      padding-left: 15px;
    }
  }
  > li ul.sub-menu {
    display: block;
  }
}

.page-container .page-content-wrapper {
  min-height: 100vh;

  .alert {
    margin-top: 10px;
    padding-bottom: 10px !important;
    text-align: center;
    margin-bottom: -50px;
  }
  .content {
    padding-bottom: 154px;
  }
}

div.footer.fixed-footer {
  margin: 50px 0px;
}

.footer-logos {
  display: flex;
  align-items: center;
}

.footer.fixed-footer p {
  margin: 0;
  font-size: 14px;
}

.logo-footer {
  margin-right: 25px;
  padding-right: 25px;
}

.align-c {
  align-items: center;
}

.dropdown-item {
  display: flex;
  align-items: center;
}

.dropdown-item i {
  margin: 0 0 0 5px;
}

.mpr-logo {
  border-right: 1px solid #707070;
}

.toggle-sidebar {
  .open,
  .close {
    transition: opacity 0.2s ease;
  }

  .close {
    opacity: 0;
  }
  .open {
    opacity: 1;
  }

  &.open {
    .close {
      opacity: 1;
    }
    .open {
      opacity: 0;
    }
  }
}

@media (max-width: 991px) {
  .copyright {
    display: none;
  }
}

@media (max-width: 767px) {
  .footer-logos {
    flex-wrap: wrap;
  }
  .logo-footer-text {
    margin-top: 25px;
  }
}

@media (max-width: 449px) {
  .footer-logos {
    flex-direction: column;
    justify-content: center;
  }
  .mpr-logo {
    border-right: none;
    margin: 0;
    padding: 0;
  }
  .czi-logo-container {
    margin-top: 25px;
  }
  .logo-footer {
    margin: 0;
    padding: 0;
  }
}
</style>
