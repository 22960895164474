import { Auth } from 'aws-amplify'

export default {
  'aws_project_region': 'us-east-1',
  'aws_cognito_region': 'us-east-1',
  'aws_user_pools_id': 'us-east-1_xjGXqUOqi',
  'aws_user_pools_web_client_id': '4fgvbqt3c21hfqrl7s9ve3qmbe',
  "authenticationFlowType": "CUSTOM_AUTH",
  'aws_cloud_logic_custom': [
    {
      'name': 'webapi',
      'endpoint': 'https://hf3h4xppee.execute-api.us-east-1.amazonaws.com/prod',
      'region': 'us-east-1',
      'custom_header': async () => {
        return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
      }
    }
  ],
  'env': 'prod',
  'url': 'sraepas-portal.com'
}
