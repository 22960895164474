// Two arrays, one for entry survey columns, and one for exit survye columns.
// Each array contains one object per survey column we need to fill in the database.
// Each object contains:
//  - dbColumn: the name of the database column
//  - label: the label shown to the user when selecting the column
//  - type: the column type we'll pass with the db insertion request
//  - templateColumn: the Excel column header in the template file that maps to dbColumn
//  - customColumn: the Excel column header in the user-uploaded file that maps to dbColumn
//  - values: the set of allowable values for this field (determined by database)
//      these values should be added in UPPERCASE only - we will compare user-entered values
//      after upcasing them, to be case-insensitive

export default {
  entry: [
    {
      dbColumn: "provider_name",
      label: "Provider Name",
      type: "stringValue",
      templateColumn: "provider_name",
      customColumn: null,
      values: [],
    },
    {
      dbColumn: "program_name",
      label: "Program Name",
      type: "stringValue",
      templateColumn: "program_name",
      customColumn: null,
      values: [],
    },
    {
      dbColumn: "survey_version",
      label: "Version of Survey",
      type: "stringValue",
      templateColumn: "survey_version",
      customColumn: null,
      values: ["MS", "HS", "MS IMPACT", "HS IMPACT"],
    },
    {
      dbColumn: "en_age",
      label: "(Q1) Age at entry",
      type: "stringValue",
      templateColumn: "Q1",
      customColumn: null,
      values: [
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
    },
    {
      dbColumn: "en_grade",
      label: "(Q2) Grade at entry",
      type: "stringValue",
      templateColumn: "Q2",
      customColumn: null,
      values: [
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
    },
    {
      dbColumn: "en_lang_eng",
      label: "(Q3a) Language - English",
      type: "booleanValue",
      templateColumn: "Q3a",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "en_lang_span",
      label: "(Q3b) Language - Spanish",
      type: "booleanValue",
      templateColumn: "Q3b",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "en_lang_other",
      label: "(Q3c) Language - Other",
      type: "booleanValue",
      templateColumn: "Q3c",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "en_lang_other_spec",
      label: "(Q3c_other) Language - Other (specify)",
      type: "stringValue",
      templateColumn: "Q3c_other",
      customColumn: null,
    },
    {
      dbColumn: "en_hispanic",
      label: "(Q4) Hispanic / Latino",
      type: "booleanValue",
      templateColumn: "Q4",
      customColumn: null,
      values: ["Y", "N"],
    },
    {
      dbColumn: "en_race_amindian",
      label: "(Q5a) Race: American Indian / Alaska Native",
      type: "booleanValue",
      templateColumn: "Q5a",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "en_race_asian",
      label: "(Q5b) Race: Asian",
      type: "booleanValue",
      templateColumn: "Q5b",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "en_race_black",
      label: "(Q5c) Race: Black or African-American",
      type: "booleanValue",
      templateColumn: "Q5c",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "en_race_hpi",
      label: "(Q5d) Race: Native Hawaiian or Other Pacific Islander",
      type: "booleanValue",
      templateColumn: "Q5d",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "en_race_white",
      label: "(Q5e) Race: White or Caucasian",
      type: "booleanValue",
      templateColumn: "Q5e",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "en_race_other",
      label: "(Q5f) Race: Other",
      type: "booleanValue",
      templateColumn: "Q5f",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "en_race_other_spec",
      label: "(Q5f_other) Race: Other (specify)",
      type: "stringValue",
      templateColumn: "Q5f_other",
      customColumn: null,
    },
    {
      dbColumn: "en_sex",
      label: "(Q6) Sex",
      type: "stringValue",
      templateColumn: "Q6",
      customColumn: null,
      values: ["M", "F"],
    },
    {
      dbColumn: "en_living_family",
      label: "(Q7a) Living with family",
      type: "booleanValue",
      templateColumn: "Q7a",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "en_living_foster_family",
      label: "(Q7b) In foster care, living with a family",
      type: "booleanValue",
      templateColumn: "Q7b",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "en_living_foster_group",
      label: "(Q7c) In foster care, living in a group home",
      type: "booleanValue",
      templateColumn: "Q7c",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "en_living_couch_surfing",
      label: "(Q7d) Couch surfing or moving from home to home",
      type: "booleanValue",
      templateColumn: "Q7d",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "en_living_outside",
      label: "(Q7e) Living outside, in a tent city or homeless camp",
      type: "booleanValue",
      templateColumn: "Q7e",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "en_living_shelter",
      label:
        "(Q7f) Staying in an emergency shelter or transitional living program",
      type: "booleanValue",
      templateColumn: "Q7f",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "en_living_hotel",
      label: "(Q7g) Staying in a hotel or motel",
      type: "booleanValue",
      templateColumn: "Q7g",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "en_living_adjudication",
      label:
        "(Q7h) In juvenile detention, jail, prison, or other correctional facility",
      type: "booleanValue",
      templateColumn: "Q7h",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "en_living_none_above",
      label: "(Q7i) Living situation is none of the above",
      type: "booleanValue",
      templateColumn: "Q7i",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "en_subst_alcohol",
      label: "(Q8a) Drank alcohol in past 3 months",
      type: "stringValue",
      templateColumn: "Q8a",
      customColumn: null,
      enumName: "yesNoImpact",
    },
    {
      dbColumn: "en_subst_cigarettes_cigars",
      label: "(Q8b) Smoked cigarettes or cigar products in the past 3 months",
      type: "stringValue",
      templateColumn: "Q8b",
      customColumn: null,
      enumName: "yesNoImpact",
    },
    {
      dbColumn: "en_subst_other_tobacco",
      label: "(Q8c) Used other tobacco products in the past 3 months",
      type: "stringValue",
      templateColumn: "Q8c",
      customColumn: null,
      enumName: "yesNoImpact",
    },
    {
      dbColumn: "en_subst_vapor",
      label: "(Q8d) Used electronic vapor products in the past 3 months",
      type: "stringValue",
      templateColumn: "Q8d",
      customColumn: null,
      enumName: "yesNoImpact",
    },
    {
      dbColumn: "en_subst_marijuana",
      label: "(Q8e) Used marijuana in the past 3 months",
      type: "stringValue",
      templateColumn: "Q8e",
      customColumn: null,
      enumName: "yesNoImpact",
    },
    {
      dbColumn: "en_subst_presc_pain",
      label:
        "(Q8f) Taken prescription pain medicine without a doctor's prescription",
      type: "stringValue",
      templateColumn: "Q8f",
      customColumn: null,
      enumName: "yesNoImpact",
    },
    {
      dbColumn: "en_often_resisted_peer_pressure",
      label: "(Q9a) Resisted or said no to peer pressure in the past 3 months",
      type: "stringValue",
      templateColumn: "Q9a",
      customColumn: null,
      enumName: "timeImpact",
    },
    {
      dbColumn: "en_often_managed_emotions",
      label: "(Q9b) Managed emotions in healthy ways in the past 3 months",
      type: "stringValue",
      templateColumn: "Q9b",
      customColumn: null,
      enumName: "timeImpact",
    },
    {
      dbColumn: "en_often_thought_consequences",
      label: "(Q9c) Thought about consequences in the past 3 months",
      type: "stringValue",
      templateColumn: "Q9c",
      customColumn: null,
      enumName: "timeImpact",
    },
    {
      dbColumn: "en_often_talked_parents_sex",
      label:
        "(Q9d) Talked with my parent/guardian/caregiver about sex in the past 3 months",
      type: "stringValue",
      templateColumn: "Q9d",
      customColumn: null,
      enumName: "timeImpact",
    },
    {
      dbColumn: "en_make_plans",
      label: "(Q10a) Make plans to reach my goals",
      type: "stringValue",
      templateColumn: "Q10a",
      customColumn: null,
      enumName: "truthImpact",
    },
    {
      dbColumn: "en_care_school",
      label: "(Q10b) Care about doing well in school",
      type: "stringValue",
      templateColumn: "Q10b",
      customColumn: null,
      enumName: "truthImpact",
    },
    {
      dbColumn: "en_save_money",
      label: "(Q10c) Save money to get something I want",
      type: "stringValue",
      templateColumn: "Q10c",
      customColumn: null,
      enumName: "truthImpact",
    },
    {
      dbColumn: "en_ask_help_self_bullied",
      label: "(Q10d) Speak up or ask for help if I was being bullied",
      type: "stringValue",
      templateColumn: "Q10d",
      customColumn: null,
      enumName: "truthImpact",
    },
    {
      dbColumn: "en_ask_help_others_bullied",
      label: "(Q10e) Speak up or ask for help if others were being bullied",
      type: "stringValue",
      templateColumn: "Q10e",
      customColumn: null,
      enumName: "truthImpact",
    },
    {
      dbColumn: "en_understand_healthy_rel",
      label: "(Q11a) Understand what makes a relationship healthy",
      type: "stringValue",
      templateColumn: "Q11a",
      customColumn: null,
      enumName: "truthImpact",
    },
    {
      dbColumn: "en_able_resist_pressure_sex",
      label:
        "(Q11b): Be able to resist someone if they pressure me to participate in sexual acts",
      type: "stringValue",
      templateColumn: "Q11b",
      customColumn: null,
      enumName: "truthImpact",
    },
    {
      dbColumn: "en_talk_someone_uncomf",
      label: "(Q11c): Talk to trusted adult if someone makes me uncomfortable",
      type: "stringValue",
      templateColumn: "Q11c",
      customColumn: null,
      enumName: "truthImpact",
    },
    {
      dbColumn: "en_plan_delay_sex_after_hs",
      label:
        "(Q12a) Plan to delay having sexual intercourse until I graduate high school",
      type: "stringValue",
      templateColumn: "Q12a",
      customColumn: null,
      enumName: "truthMsImpact",
    },
    {
      dbColumn: "en_plan_delay_sex_after_college",
      label:
        "(Q12b) Plan to delay having sexual intercourse until I graduate college",
      type: "stringValue",
      templateColumn: "Q12b",
      customColumn: null,
      enumName: "truthMsImpact",
    },
    {
      dbColumn: "en_plan_delay_sex_after_married",
      label:
        "(Q12c) Plan to delay having sexual intercourse until I am married",
      type: "stringValue",
      templateColumn: "Q12c",
      customColumn: null,
      enumName: "truthMsImpact",
    },
    {
      dbColumn: "en_plan_married_before_child",
      label: "(Q12d) Plan to be married before I have a child",
      type: "stringValue",
      templateColumn: "Q12d",
      customColumn: null,
      enumName: "truthMsImpact",
    },
    {
      dbColumn: "en_plan_ft_job_before_married",
      label: "(Q12e) Plan to have a steady full-time job before I get married",
      type: "stringValue",
      templateColumn: "Q12e",
      customColumn: null,
      enumName: "truthMsImpact",
    },
    {
      dbColumn: "en_plan_ft_job_before_child",
      label: "(Q12f) Plan to have a steady full-time job before I have a child",
      type: "stringValue",
      templateColumn: "Q12f",
      customColumn: null,
      enumName: "truthMsImpact",
    },
    {
      dbColumn: "en_ever_had_sex",
      label: "(Q13) Have you ever had sexual intercourse?",
      type: "stringValue",
      templateColumn: "Q13",
      customColumn: null,
      enumName: "yesNoMsImpact",
    },
    {
      dbColumn: "en_ever_pregnant",
      label:
        "(Q14) Have you ever been pregnant or gotten someone else pregnant?",
      type: "stringValue",
      templateColumn: "Q14",
      customColumn: null,
      enumName: "yesNoNsMsImpact",
    },
    {
      dbColumn: "en_ever_had_sti",
      label:
        "(Q15) Have you ever been told by a doctor that you had a sexually transmitted infection (STI)?",
      type: "stringValue",
      templateColumn: "Q15",
      customColumn: null,
      enumName: "yesNoMsImpact",
    },
  ],

  exit: [
    {
      dbColumn: "provider_name",
      label: "Provider Name",
      type: "stringValue",
      templateColumn: "provider_name",
      customColumn: null,
      values: [],
    },
    {
      dbColumn: "program_name",
      label: "Program Name",
      type: "stringValue",
      templateColumn: "program_name",
      customColumn: null,
      values: [],
    },
    {
      dbColumn: "survey_version",
      label: "Version of Survey",
      type: "stringValue",
      templateColumn: "survey_version",
      customColumn: null,
      values: ["MS", "HS"],
    },
    {
      dbColumn: "ex_age",
      label: "(Q1) Age at exit",
      type: "stringValue",
      templateColumn: "Q1",
      customColumn: null,
      values: [
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
    },
    {
      dbColumn: "ex_grade",
      label: "(Q2) Grade at exit",
      type: "stringValue",
      templateColumn: "Q2",
      customColumn: null,
      values: [
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
    },
    {
      dbColumn: "ex_lang_eng",
      label: "(Q3a) Language - English",
      type: "booleanValue",
      templateColumn: "Q3a",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "ex_lang_span",
      label: "(Q3b) Language - Spanish",
      type: "booleanValue",
      templateColumn: "Q3b",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "ex_lang_other",
      label: "(Q3c) Language - Other",
      type: "booleanValue",
      templateColumn: "Q3c",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "ex_lang_other_spec",
      label: "(Q3c_other) Language - Other (specify)",
      type: "stringValue",
      templateColumn: "Q3c_other",
      customColumn: null,
    },
    {
      dbColumn: "ex_hispanic",
      label: "(Q4) Hispanic / Latino",
      type: "booleanValue",
      templateColumn: "Q4",
      customColumn: null,
      values: ["Y", "N"],
    },
    {
      dbColumn: "ex_race_amindian",
      label: "(Q5a) Race: American Indian / Alaska Native",
      type: "booleanValue",
      templateColumn: "Q5a",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "ex_race_asian",
      label: "(Q5b) Race: Asian",
      type: "booleanValue",
      templateColumn: "Q5b",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "ex_race_black",
      label: "(Q5c) Race: Black or African-American",
      type: "booleanValue",
      templateColumn: "Q5c",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "ex_race_hpi",
      label: "(Q5d) Race: Native Hawaiian or Other Pacific Islander",
      type: "booleanValue",
      templateColumn: "Q5d",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "ex_race_white",
      label: "(Q5e) Race: White or Caucasian",
      type: "booleanValue",
      templateColumn: "Q5e",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "ex_race_other",
      label: "(Q5f) Race: Other",
      type: "booleanValue",
      templateColumn: "Q5f",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "ex_race_other_spec",
      label: "(Q5f_other) Race: Other (specify)",
      type: "stringValue",
      templateColumn: "Q5f_other",
      customColumn: null,
    },
    {
      dbColumn: "ex_sex",
      label: "(Q6) Sex",
      type: "stringValue",
      templateColumn: "Q6",
      customColumn: null,
      values: ["M", "F"],
    },
    {
      dbColumn: "ex_living_family",
      label: "(Q7a) Living with family",
      type: "booleanValue",
      templateColumn: "Q7a",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "ex_living_foster_family",
      label: "(Q7b) In foster care, living with a family",
      type: "booleanValue",
      templateColumn: "Q7b",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "ex_living_foster_group",
      label: "(Q7c) In foster care, living in a group home",
      type: "booleanValue",
      templateColumn: "Q7c",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "ex_living_couch_surfing",
      label: "(Q7d) Couch surfing or moving from home to home",
      type: "booleanValue",
      templateColumn: "Q7d",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "ex_living_outside",
      label: "(Q7e) Living outside, in a tent city, or homeless camp",
      type: "booleanValue",
      templateColumn: "Q7e",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "ex_living_shelter",
      label:
        "(Q7f) Staying in an emergency shelter or transitional living program",
      type: "booleanValue",
      templateColumn: "Q7f",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "ex_living_hotel",
      label: "(Q7g) Staying in a hotel or motel",
      type: "booleanValue",
      templateColumn: "Q7g",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "ex_living_adjudication",
      label:
        "(Q7h) In juvenile detention, jail, prison, or other correctional facility",
      type: "booleanValue",
      templateColumn: "Q7h",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "ex_living_none_above",
      label: "(Q7i) Living situation is none of the above",
      type: "booleanValue",
      templateColumn: "Q7i",
      customColumn: null,
      values: ["0", "1"],
    },
    {
      dbColumn: "ex_subst_alcohol",
      label: "(Q8a) Make decisions not to drink alcohol?",
      type: "stringValue",
      templateColumn: "Q8a",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_subst_cigarettes_cigars",
      label: "(Q8b) Not smoke cigarettes or cigar products?",
      type: "stringValue",
      templateColumn: "Q8b",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_subst_other_tobacco",
      label: "(Q8c) Not use other tobacco products?",
      type: "stringValue",
      templateColumn: "Q8c",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_subst_vapor",
      label: "(Q8d) Not use electronic vapor products?",
      type: "stringValue",
      templateColumn: "Q8d",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_subst_marijuana",
      label: "(Q8e) Not use marijuana?",
      type: "stringValue",
      templateColumn: "Q8e",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_subst_presc_pain",
      label:
        "(Q8f) Not use prescription pain medicine without a doctor's prescription?",
      type: "stringValue",
      templateColumn: "Q8f",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_resist_peer_pressure",
      label: "(Q9a) Resist or say no to peer pressure?",
      type: "stringValue",
      templateColumn: "Q9a",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_manage_emotions",
      label: "(Q9b) Manage emotions in healthy ways?",
      type: "stringValue",
      templateColumn: "Q9b",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_think_consequences",
      label: "(Q9c) Think about consequences?",
      type: "stringValue",
      templateColumn: "Q9c",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_talk_parents_sex",
      label: "(Q9d) Talk with parent, guardian, or caregiver about sex?",
      type: "stringValue",
      templateColumn: "Q9d",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_make_plans",
      label: "(Q10a) Make plans to reach your goals?",
      type: "stringValue",
      templateColumn: "Q10a",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_care_school",
      label: "(Q10b) Care about doing well in school?",
      type: "stringValue",
      templateColumn: "Q10b",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_understand_healthy_rel",
      label: "(Q11a) Understand what makes a relationship healthy?",
      type: "stringValue",
      templateColumn: "Q11a",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_able_resist_pressure_sex",
      label:
        "(Q11b) Able to resist or say no to someone you are dating or going out with if they pressure you?",
      type: "stringValue",
      templateColumn: "Q11b",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_talk_someone_uncomf",
      label:
        "(Q11c) Talk to a friend if someone I am dating or going out with with makes me uncomfortable?",
      type: "stringValue",
      templateColumn: "Q11c",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_plan_delay_sex_after_hs",
      label:
        "(Q12a) Plan to delay having sexual intercourse until you graduate high school?",
      type: "stringValue",
      templateColumn: "Q12a",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_plan_delay_sex_after_college",
      label:
        "(Q12b) Plan to delay having sexual intercourse until you graduate college?",
      type: "stringValue",
      templateColumn: "Q12b",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_plan_delay_sex_after_married",
      label:
        "(Q12c) Plan to delay having sexual intercourse until you are married?",
      type: "stringValue",
      templateColumn: "Q12c",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_plan_married_before_child",
      label: "(Q12d) Plan to be married before you have a child?",
      type: "stringValue",
      templateColumn: "Q12d",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_plan_ft_job_before_married",
      label:
        "(Q12e) Plan to have a steady full-time job before you get married?",
      type: "stringValue",
      templateColumn: "Q12e",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_plan_ft_job_before_child",
      label:
        "(Q12f) Plan to have a steady full-time job before you have a child?",
      type: "stringValue",
      templateColumn: "Q12f",
      customColumn: null,
      enumName: "likelihood",
    },
    {
      dbColumn: "ex_plan_abstain_sex",
      label: "(Q13) Are you planning to abstain from sexual intercourse?",
      type: "stringValue",
      templateColumn: "Q13",
      customColumn: null,
      enumName: "yesNoNsMs",
    },
    {
      dbColumn: "ex_reason_affect_plans",
      label: "(Q14a) How it might affect your plans for the future",
      type: "stringValue",
      templateColumn: "Q14a",
      customColumn: null,
      enumName: "importantMs",
    },
    {
      dbColumn: "ex_reason_emotional_social_cons",
      label: "(Q14b) The possible emotional and social consequences",
      type: "stringValue",
      templateColumn: "Q14b",
      customColumn: null,
      enumName: "importantMs",
    },
    {
      dbColumn: "ex_reason_risk_sti",
      label:
        "(Q14c) The risk of getting a sexually transmitted infection (STI)",
      type: "stringValue",
      templateColumn: "Q14c",
      customColumn: null,
      enumName: "importantMs",
    },
    {
      dbColumn: "ex_reason_risk_pregnant",
      label: "(Q14d) The risk of getting pregnant or getting someone pregnant",
      type: "stringValue",
      templateColumn: "Q14d",
      customColumn: null,
      enumName: "importantMs",
    },
    {
      dbColumn: "ex_interested_program",
      label:
        "(Q15a - MS Q13a) Feel interested in program sessions and classes?",
      type: "stringValue",
      templateColumn: "Q15a (MS Q13a)",
      customColumn: null,
      enumName: "time",
    },
    {
      dbColumn: "ex_material_clear",
      label: "(Q15b - MS Q13b) Feel material presented was clear?",
      type: "stringValue",
      templateColumn: "Q15b (MS Q13b)",
      customColumn: null,
      enumName: "time",
    },
    {
      dbColumn: "ex_activities_help",
      label:
        "(Q15c - MS Q13c) Discussions or activities help you to learn program lessons?",
      type: "stringValue",
      templateColumn: "Q15c (MS Q13c)",
      customColumn: null,
      enumName: "time",
    },
    {
      dbColumn: "ex_chance_ask_questions",
      label:
        "(Q15d - MS Q13d) Have a chance to ask questions about topics or issues that came up?",
      type: "stringValue",
      templateColumn: "Q15d (MS Q13d)",
      customColumn: null,
      enumName: "time",
    },
    {
      dbColumn: "ex_feel_respected",
      label: "(Q15e - MS Q13e) Feel respected as a person?",
      type: "stringValue",
      templateColumn: "Q15e (MS Q13e)",
      customColumn: null,
      enumName: "time",
    },
  ],

  enumMaps: {
    importantMs: {
      1: "Not at all important",
      2: "Not too important",
      3: "Somewhat important",
      4: "Very important",
      MS: "No response because item is not included on middle school survey",
    },

    likelihood: {
      1: "Much more likely",
      2: "Somewhat more likely",
      3: "About the same",
      4: "Somewhat less likely",
      5: "Much less likely",
    },

    time: {
      1: "All of the time",
      2: "Most of the time",
      3: "Some of the time",
      4: "None of the time",
    },

    timeImpact: {
      1: "All of the time",
      2: "Most of the time",
      3: "Some of the time",
      4: "None of the time",
      "HS IMPACT":
        "No response because item is not included on high school impact survey",
      "MS IMPACT":
        "No response because item is not included on middle school impact survey",
    },

    truth: {
      1: "Not true at all",
      2: "Somewhat true",
      3: "Very true",
    },

    truthImpact: {
      1: "Not true at all",
      2: "Somewhat true",
      3: "Very true",
      "MS IMPACT":
        "No response because item is not included on middle school impact survey",
      "HS IMPACT":
        "No response because item is not included on high school impact survey",
    },

    truthMs: {
      1: "Not true at all",
      2: "Somewhat true",
      3: "Very true",
      MS: "No response because item is not included on middle school survey",
    },

    truthMsImpact: {
      1: "Not true at all",
      2: "Somewhat true",
      3: "Very true",
      MS: "No response because item is not included on middle school survey",
      "MS IMPACT":
        "No response because item is not included on middle school impact survey",
      "HS IMPACT":
        "No response because item is not included on high school impact survey",
    },

    yesNoImpact: {
      Y: "Yes",
      N: "No",
      "HS IMPACT":
        "No response because item is not included on high school impact survey",
      "MS IMPACT":
        "No response because item is not included on middle school impact survey",
    },

    yesNoMs: {
      Y: "Yes",
      N: "No",
      MS: "No response because item is not included on middle school survey",
    },

    yesNoMsImpact: {
      Y: "Yes",
      N: "No",
      MS: "No response because item is not included on middle school survey",
      "HS IMPACT":
        "No response because item is not included on high school impact survey",
      "MS IMPACT":
        "No response because item is not included on middle school impact survey",
    },

    yesNoNs: {
      0: "No",
      1: "Yes",
      2: "Not Sure",
    },

    yesNoNsMs: {
      Y: "Yes",
      N: "No",
      NS: "Not sure",
      MS: "No response because item is not included on middle school survey",
    },

    yesNoNsMsImpact: {
      Y: "Yes",
      N: "No",
      NS: "Not sure",
      MS: "No response because item is not included on middle school survey",
      "HS IMPACT":
        "No response because item is not included on high school impact survey",
      "MS IMPACT":
        "No response because item is not included on middle school impact survey",
    },
  },
};
