<template>
  <div class="container-fluid container-fixed-lg footer m-b-10">
    <div class="row copyright p-t-20">
      <div class="col-sm-3 text-center">
        <a href="https://www.acf.hhs.gov/opre" target="_blank">
          <img
            src="https://www.sraepas.com/wp-content/uploads/2019/12/OPRE_Logo.png"
            alt="Office of Planning, Research, and Evaluation Logo"
            srcset="
              https://www.sraepas.com/wp-content/uploads/2019/12/OPRE_Logo.png        375w,
              https://www.sraepas.com/wp-content/uploads/2019/12/OPRE_Logo-300x96.png 300w
            "
          />
        </a>
      </div>
      <div class="col-sm-3 text-center">
        <a href="https://www.acf.hhs.gov/fysb" target="_blank">
          <img
            src="https://www.sraepas.com/wp-content/uploads/2019/12/FYSB_Logo.png"
            alt="Family & Youth Services Bureau logo"
            srcset="
              https://www.sraepas.com/wp-content/uploads/2019/12/FYSB_Logo.png        570w,
              https://www.sraepas.com/wp-content/uploads/2019/12/FYSB_Logo-300x63.png 300w
            "
          />
        </a>
      </div>
      <div class="col-sm-3 text-center">
        <a href="http://publicstrategies.com/" target="_blank">
          <img
            src="https://www.sraepas.com/wp-content/uploads/2020/09/img-logo-ps.png"
            alt="Public Strategies logo"
            srcset="
              https://www.sraepas.com/wp-content/uploads/2020/09/img-logo-ps.png         315w,
              https://www.sraepas.com/wp-content/uploads/2020/09/img-logo-ps-300x114.png 300w
            "
          />
        </a>
      </div>
      <div class="col-sm-3 text-center">
        <a href="https://www.mathematica.org/" target="_blank" class="">
          <img
            src="https://www.sraepas.com/wp-content/uploads/2019/12/Mathematica_Logo.png"
            alt="Mathematica company logo"
            srcset="
              https://www.sraepas.com/wp-content/uploads/2019/12/Mathematica_Logo.png        456w,
              https://www.sraepas.com/wp-content/uploads/2019/12/Mathematica_Logo-300x79.png 300w
            "
          />
        </a>
      </div>
    </div>

    <div class="sm-text-center">
      <p class="small-text no-margin pull-left sm-pull-reset">
        Sexual Risk Avoidance Education (SRAE) Performance Analysis Study (PAS)
      </p>
      <p class="small no-margin pull-right sm-pull-reset">
        Copyright © 2020 ·
        <a href="http://www.mathematica.org" target="_blank">Mathematica</a>
      </p>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {},
};
</script>

<style scoped lang="scss">
img {
  max-width: 100%;
  max-height: 60px;
}
</style>
