import config from "./aws-config.js";

export function removeThousandCommas(value) {
  if (isNaN(value)) {
    let number = value.replace(/,/g, "");
    return parseFloat(number);
  } else {
    return parseFloat(value);
  }
}

export function formatProviderSubmissionData(
  grantee_name,
  provider_name,
  providerSubmissionData,
  reportingPeriodData,
  errors = [],
  showstoppers = []
) {
  return {
    formName: "provider",
    formData: [
      {
        tableName: "provider_submissions",
        tableData: [
          [
            {
              name: "provider_name",
              value: {
                stringValue: provider_name,
              },
            },
            {
              name: "grantee_name",
              value: {
                stringValue: grantee_name,
              },
            },
            {
              name: "prov_reporting_period_start",
              value: {
                stringValue: reportingPeriodData.prov_reporting_period_start,
              },
              typeHint: "DATE",
            },
            {
              name: "prov_reporting_period_stop",
              value: {
                stringValue: reportingPeriodData.prov_reporting_period_stop,
              },
              typeHint: "DATE",
            },
            {
              name: "prov_srae_provider_funding",
              value: isNaN(
                removeThousandCommas(
                  providerSubmissionData.prov_srae_provider_funding
                )
              )
                ? {
                    isNull: true,
                  }
                : {
                    longValue: removeThousandCommas(
                      providerSubmissionData.prov_srae_provider_funding
                    ),
                  },
            },
            {
              name: "prov_non_srae_provider_funding",
              value: isNaN(
                removeThousandCommas(
                  providerSubmissionData.prov_non_srae_provider_funding
                )
              )
                ? {
                    isNull: true,
                  }
                : {
                    longValue: removeThousandCommas(
                      providerSubmissionData.prov_non_srae_provider_funding
                    ),
                  },
            },
            {
              name: "prov_new_provider",
              value:
                providerSubmissionData.prov_new_provider === null
                  ? {
                      isNull: true,
                    }
                  : {
                      booleanValue: providerSubmissionData.prov_new_provider,
                    },
            },
            {
              name: "prov_active",
              value:
                providerSubmissionData.prov_active === null
                  ? {
                      isNull: true,
                    }
                  : {
                      booleanValue: providerSubmissionData.prov_active,
                    },
            },
            {
              name: "prov_provider_serve_youth",
              value:
                providerSubmissionData.prov_provider_serve_youth === null
                  ? {
                      isNull: true,
                    }
                  : {
                      booleanValue:
                        providerSubmissionData.prov_provider_serve_youth,
                    },
            },
            {
              name: "prov_num_staff_admin_progs",
              value: isNaN(
                removeThousandCommas(
                  providerSubmissionData.prov_num_staff_admin_progs
                )
              )
                ? {
                    isNull: true,
                  }
                : {
                    longValue: removeThousandCommas(
                      providerSubmissionData.prov_num_staff_admin_progs
                    ),
                  },
            },
            {
              name: "prov_num_fte_admin_progs",
              value: isNaN(
                removeThousandCommas(
                  providerSubmissionData.prov_num_fte_admin_progs
                )
              )
                ? {
                    isNull: true,
                  }
                : {
                    doubleValue: removeThousandCommas(
                      providerSubmissionData.prov_num_fte_admin_progs
                    ),
                  },
            },
            {
              name: "prov_num_facilitators_working",
              value: isNaN(
                removeThousandCommas(
                  providerSubmissionData.prov_num_facilitators_working
                )
              )
                ? {
                    isNull: true,
                  }
                : {
                    longValue: removeThousandCommas(
                      providerSubmissionData.prov_num_facilitators_working
                    ),
                  },
            },
            {
              name: "prov_num_facilitators_trained",
              value: isNaN(
                removeThousandCommas(
                  providerSubmissionData.prov_num_facilitators_trained
                )
              )
                ? {
                    isNull: true,
                  }
                : {
                    longValue: removeThousandCommas(
                      providerSubmissionData.prov_num_facilitators_trained
                    ),
                  },
            },
            {
              name: "prov_num_program_models_implemented",
              value: isNaN(
                removeThousandCommas(
                  providerSubmissionData.prov_num_program_models_implemented
                )
              )
                ? { isNull: true }
                : {
                    longValue: removeThousandCommas(
                      providerSubmissionData.prov_num_program_models_implemented
                    ),
                  },
            },
            {
              name: "prov_num_facilitators_obs_once",
              value: isNaN(
                removeThousandCommas(
                  providerSubmissionData.prov_num_facilitators_obs_once
                )
              )
                ? {
                    isNull: true,
                  }
                : {
                    longValue: removeThousandCommas(
                      providerSubmissionData.prov_num_facilitators_obs_once
                    ),
                  },
            },
            {
              name: "prov_num_facilitators_obs_2plus",
              value: isNaN(
                removeThousandCommas(
                  providerSubmissionData.prov_num_facilitators_obs_2plus
                )
              )
                ? {
                    isNull: true,
                  }
                : {
                    longValue: removeThousandCommas(
                      providerSubmissionData.prov_num_facilitators_obs_2plus
                    ),
                  },
            },
            {
              name: "prov_implmnt_chlng_recruit_youth",
              value:
                providerSubmissionData.prov_implmnt_chlng_recruit_youth === null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_implmnt_chlng_recruit_youth,
                    },
            },
            {
              name: "prov_implmnt_chlng_youth_engaged",
              value:
                providerSubmissionData.prov_implmnt_chlng_youth_engaged === null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_implmnt_chlng_youth_engaged,
                    },
            },
            {
              name: "prov_implmnt_chlng_youth_attend_reg",
              value:
                providerSubmissionData.prov_implmnt_chlng_youth_attend_reg ===
                null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_implmnt_chlng_youth_attend_reg,
                    },
            },
            {
              name: "prov_implmnt_chlng_recruit_qual_staff",
              value:
                providerSubmissionData.prov_implmnt_chlng_recruit_qual_staff ===
                null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_implmnt_chlng_recruit_qual_staff,
                    },
            },
            {
              name: "prov_implmnt_chlng_fcltatrs_undrstnd",
              value:
                providerSubmissionData.prov_implmnt_chlng_fcltatrs_undrstnd ===
                null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_implmnt_chlng_fcltatrs_undrstnd,
                    },
            },
            {
              name: "prov_implmnt_chlng_cover_prog_content",
              value:
                providerSubmissionData.prov_implmnt_chlng_cover_prog_content ===
                null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_implmnt_chlng_cover_prog_content,
                    },
            },
            {
              name: "prov_implmnt_chlng_staff_turnover",
              value:
                providerSubmissionData.prov_implmnt_chlng_staff_turnover ===
                null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_implmnt_chlng_staff_turnover,
                    },
            },
            {
              name: "prov_implmnt_chlng_neg_peer_reaction",
              value:
                providerSubmissionData.prov_implmnt_chlng_neg_peer_reaction ===
                null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_implmnt_chlng_neg_peer_reaction,
                    },
            },
            {
              name: "prov_implmnt_chlng_youth_bhvrl_probs",
              value:
                providerSubmissionData.prov_implmnt_chlng_youth_bhvrl_probs ===
                null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_implmnt_chlng_youth_bhvrl_probs,
                    },
            },
            {
              name: "prov_implmnt_chlng_natural_disasters",
              value:
                providerSubmissionData.prov_implmnt_chlng_natural_disasters ===
                null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_implmnt_chlng_natural_disasters,
                    },
            },
            {
              name: "prov_implmnt_chlng_prog_facilities",
              value:
                providerSubmissionData.prov_implmnt_chlng_prog_facilities ===
                null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_implmnt_chlng_prog_facilities,
                    },
            },
            {
              name: "prov_implmnt_chlng_obtaining_buy_in",
              value:
                providerSubmissionData.prov_implmnt_chlng_obtaining_buy_in ===
                null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_implmnt_chlng_obtaining_buy_in,
                    },
            },
            {
              name: "prov_implmnt_chlng_parent_support",
              value:
                providerSubmissionData.prov_implmnt_chlng_parent_support ===
                null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_implmnt_chlng_parent_support,
                    },
            },
            {
              name: "prov_implmnt_chlng_other",
              value:
                providerSubmissionData.prov_implmnt_chlng_other === null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_implmnt_chlng_other,
                    },
            },
            {
              name: "prov_implmnt_chlng_other_spec",
              value:
                providerSubmissionData.prov_implmnt_chlng_other_spec === null ||
                providerSubmissionData.prov_implmnt_chlng_other_spec === ""
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_implmnt_chlng_other_spec,
                    },
            },
            {
              name: "prov_tech_assist_recruit_youth",
              value:
                providerSubmissionData.prov_tech_assist_recruit_youth === null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_tech_assist_recruit_youth,
                    },
            },
            {
              name: "prov_tech_assist_youth_engaged",
              value:
                providerSubmissionData.prov_tech_assist_youth_engaged === null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_tech_assist_youth_engaged,
                    },
            },
            {
              name: "prov_tech_assist_youth_attend_reg",
              value:
                providerSubmissionData.prov_tech_assist_youth_attend_reg ===
                null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_tech_assist_youth_attend_reg,
                    },
            },
            {
              name: "prov_tech_assist_recruit_qual_staff",
              value:
                providerSubmissionData.prov_tech_assist_recruit_qual_staff ===
                null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_tech_assist_recruit_qual_staff,
                    },
            },
            {
              name: "prov_tech_assist_training_fcltatrs",
              value:
                providerSubmissionData.prov_tech_assist_training_fcltatrs ===
                null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_tech_assist_training_fcltatrs,
                    },
            },
            {
              name: "prov_tech_assist_retain_staff",
              value:
                providerSubmissionData.prov_tech_assist_retain_staff === null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_tech_assist_retain_staff,
                    },
            },
            {
              name: "prov_tech_assist_neg_peer_reaction",
              value:
                providerSubmissionData.prov_tech_assist_neg_peer_reaction ===
                null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_tech_assist_neg_peer_reaction,
                    },
            },
            {
              name: "prov_tech_assist_youth_bhvrl_probs",
              value:
                providerSubmissionData.prov_tech_assist_youth_bhvrl_probs ===
                null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_tech_assist_youth_bhvrl_probs,
                    },
            },
            {
              name: "prov_tech_assist_obtaining_buy_in",
              value:
                providerSubmissionData.prov_tech_assist_obtaining_buy_in ===
                null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_tech_assist_obtaining_buy_in,
                    },
            },
            {
              name: "prov_tech_assist_evaluation",
              value:
                providerSubmissionData.prov_tech_assist_evaluation === null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_tech_assist_evaluation,
                    },
            },
            {
              name: "prov_tech_assist_parent_support",
              value:
                providerSubmissionData.prov_tech_assist_parent_support === null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_tech_assist_parent_support,
                    },
            },
            {
              name: "prov_tech_assist_other",
              value:
                providerSubmissionData.prov_tech_assist_other === null
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_tech_assist_other,
                    },
            },
            {
              name: "prov_tech_assist_other_spec",
              value:
                providerSubmissionData.prov_tech_assist_other_spec === null ||
                providerSubmissionData.prov_tech_assist_other_spec === ""
                  ? {
                      isNull: true,
                    }
                  : {
                      stringValue:
                        providerSubmissionData.prov_tech_assist_other_spec,
                    },
            },
            {
              name: "prov_data_issues_minor",
              value: {
                booleanValue: errors.length > 0,
              },
            },
            {
              name: "prov_data_issues_major",
              value: {
                booleanValue: showstoppers.length > 0,
              },
            },
          ],
        ],
      },
    ],
  };
}

export function getUserAttributes(user) {
  if (!user) return { email: "not found" };
  if (!user.Attributes) return user;
  let attributes = user.Attributes.reduce((prev, curr) => {
    return {
      ...prev,
      [curr.Name]: curr.Value,
    };
  }, {});
  return {
    ...user,
    ...attributes,
  };
}

export default {
  isAdmin(_u) {
    return this.user_groups(_u).includes("Admin") ? true : false;
  },
  isACF(_u) {
    return this.user_groups(_u).includes("ACF") ? true : false;
  },
  user_groups(_u) {
    return "signInUserSession" in _u &&
      "cognito:groups" in _u.signInUserSession.idToken.payload
      ? _u.signInUserSession.idToken.payload["cognito:groups"]
      : [];
  },
  isPortalOpen(_u) {
    // isPortalOpen() {
    // currently portal is only avaiable to users in the Admin group
    // also only closing portal in prod

    // to close portal, uncomment the next line, and comment out the
    // last three lines in this function

    // return this.isAdmin(_u) || config.env != "prod";

    // to open portal portal again, comment the line above, and uncomment the next
    // three lines (first two are just variable references just to keep our linter
    // happy while the portal is open to everyone

    _u;
    config;
    return true;
  },
};
