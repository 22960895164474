var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content",class:{ 're-render-toogle': _vm.dataToogle }},[_c('div',{staticClass:"container-fluid container-fixed-lg"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card card-default"},[_c('div',{staticClass:"card-header separator no-print"},[_c('div',{staticClass:"card-title"},[_c('ol',{staticClass:"breadcrumb no-margin no-padding"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{staticClass:"no-margin no-padding",attrs:{"to":{ name: 'providers' }}},[_vm._v("Validation Report")])],1)])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-4 p-t-10 no-print"},[(_vm.grantee.role == 'Grantee')?_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.submitButton",modifiers:{"submitButton":true}}],staticClass:"btn btn-lg btn-primary btn-icon-left pull-right",attrs:{"to":{ name: 'programs' }},on:{"click":function($event){_vm.submitForm();
                    _vm.getProviderIssues();
                    _vm.getProgramIssues();}}},[_c('i',{staticClass:"pg-icon"},[_vm._v("send")]),_vm._v(" Submit Data ")]):_vm._e(),_c('button',{staticClass:"btn btn-lg btn-primary pull-right btn-print",on:{"click":function($event){return _vm.printScreen()}}},[_vm._v(" Print ")])])]),_c('h4',[_c('b',{staticClass:"srae-subtitle"},[_vm._v("Grantee Name:")]),_vm._v(" "+_vm._s(_vm.grantee.name)+" ")]),_c('h4',[_c('b',{staticClass:"srae-subtitle"},[_vm._v("Cohort:")]),_vm._v(" "+_vm._s(_vm.grantee.cohort))]),_c('h4',[_c('b',{staticClass:"srae-subtitle"},[_vm._v("Funding Stream:")]),_vm._v(" "+_vm._s(_vm.grantee.funding_stream)+" ")]),_c('h4',[_c('b',{staticClass:"srae-subtitle"},[_vm._v("Report Period:")]),_vm._v(" "+_vm._s(_vm.$store.state.reportingPeriodStr.gr)+" ")]),_vm._m(1),(_vm.grantee.role == 'Grantee')?_c('h6',[_c('router-link',{attrs:{"to":"/grantee"}},[_c('span',{class:_vm.granteeMessage.newClass},[_vm._v(" "+_vm._s(_vm.granteeMessage.message))])])],1):_vm._e()])])])]),(_vm.showSubmitButtonMessage)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card card-default validation-error-message"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_c('span',{staticClass:"semi-bold inline pull-left"},[_vm._v(_vm._s(_vm.submitButtonMessage()))])])])])])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},_vm._l((_vm.activeProviders),function(provider){return _c('div',{key:provider.id,staticClass:"card card-default provider-card",class:{
            'card-collapsed':
              _vm.collapseToogles['provider-collapse-' + provider.id],
          }},[_c('div',{staticClass:"card-header",attrs:{"role":"tab","id":"headingOne"},on:{"click":function($event){return _vm.collapseToogle(provider.id)}}},[_c('div',{staticClass:"card-title"},[_c('span',{staticClass:"semi-bold inline pull-left"},[_vm._v(_vm._s(provider.name))])]),_c('div',{staticClass:"card-controls"},[_c('ul',[_c('li',[_c('span',{staticClass:"card-collapse",attrs:{"data-toggle":"collapse","role":"button","tabindex":"0"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.collapseToogle(provider.id)}}},[_c('i',{staticClass:"card-icon card-icon-collapse"})])])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.collapseToogles['provider-collapse-' + provider.id] || _vm.printing
            ),expression:"\n              collapseToogles['provider-collapse-' + provider.id] || printing\n            "}],staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h6',[_c('router-link',{staticClass:"d-flex align-items-center inline m-r-20",attrs:{"to":{
                      name: 'provider-data',
                      params: { pid: provider.id },
                    }}},[_c('span',{},[_vm._v("Provider-Level Information ")]),_c('span',{class:_vm.providerMessage(provider.id).newClass},[_vm._v(_vm._s(_vm.providerMessage(provider.id).message))])])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('table',{staticClass:"table table-hover table-condensed no-footer",attrs:{"width":"100%"}},_vm._l((provider.programs),function(program){return _c('tr',{key:program.id,staticClass:"odd-even",attrs:{"role":"row"}},[_c('td',{staticClass:"v-align-middle semi-bold"},[_c('h6',[_vm._v(_vm._s(program.name))]),(!_vm.isWinterSeason)?_c('router-link',{staticClass:"d-flex align-items-center",attrs:{"to":{
                          name: 'program-data',
                          params: { pid: provider.id, pmid: program.id },
                        }}},[_c('span',{class:_vm.programMessage(program.id, 'SCS').newClass},[_vm._v(" "+_vm._s(_vm.programMessage(program.id, "SCS").message)+" ")])]):_vm._e(),_c('router-link',{staticClass:"align-items-center",class:{
                          active:
                            _vm.$router.currentRoute.name === 'program-reach',
                        },attrs:{"to":{
                          name: 'program-reach',
                          params: { pid: provider.id, pmid: program.id },
                        }}},[_c('span',{class:_vm.programMessage(program.id, 'ARD').newClass},[_vm._v(_vm._s(_vm.programMessage(program.id, "ARD").message))])]),(_vm.isMissingISA(program.id))?_c('router-link',{attrs:{"to":{
                          name: 'program-entry-exit',
                          params: { pid: provider.id, pmid: program.id },
                        }}},[_c('span',{class:[_vm.label.blue, 'upload-warning']},[_vm._v("Missing info about survey admin")])]):_vm._e(),(
                          _vm.grantee.role == 'Grantee' &&
                          _vm.isMissingUpload(program.id, 'entry')
                        )?_c('span',{class:[_vm.label.red, 'upload-warning']},[_vm._v("Missing entry survey data")]):_vm._e(),(
                          _vm.grantee.role == 'Grantee' &&
                          _vm.isMissingUpload(program.id, 'exit')
                        )?_c('span',{class:[_vm.label.red, 'upload-warning']},[_vm._v("Missing exit survey data")]):_vm._e()],1)])}),0)])])])])}),0)]),(_vm.grantee.role == 'Grantee')?_c('div',{staticClass:"card card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h6',[_c('div',{staticClass:"form-group row"},[_vm._m(2),_c('div',{staticClass:"col-12"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.gr_additional_info),expression:"formData.gr_additional_info"}],staticStyle:{"width":"100%","margin-top":"1px"},attrs:{"id":"formData.gr_additional_info","maxlength":"2000","rows":"5","required":""},domProps:{"value":(_vm.formData.gr_additional_info)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "gr_additional_info", $event.target.value)}}})]),_c('div',{staticClass:"col-12 p-t-10 no-print"},[(_vm.grantee.role == 'Grantee')?_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.optionalComments",modifiers:{"optionalComments":true}}],staticClass:"btn btn-lg btn-primary pull-right",on:{"click":function($event){_vm.submitForm();
                    _vm.getProviderIssues();
                    _vm.getProgramIssues();}}},[_vm._v(" Save comments ")]):_vm._e()])])])])])]):_vm._e(),_c('b-modal',{attrs:{"id":"submitButton","ok-only":""}},[_c('p',[_vm._v(" "+_vm._s(_vm.submitButtonMessage())+" ")])]),_c('b-modal',{attrs:{"id":"optionalComments","ok-only":""}},[_vm._v(" Thank you. Your optional comments have been successfully saved. ")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-8"},[_c('h3',{staticClass:"srae-title"},[_vm._v("Data Submission Validation")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('i',{staticClass:"srae-subtitle",staticStyle:{"color":"red"}},[_vm._v("Please print this page for your records. Your data validation report will not be available after the current submission period ends.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('label',{attrs:{"for":"formData.gr_additional_info"}},[_vm._v("Optional comments: In the box below, briefly explain any anomalies in the data submitted or provide any other information that would help interpret the data.")])])
}]

export { render, staticRenderFns }