import Vue from "vue";
import VueRouter from "vue-router";
// user
import Profile from "./views/user_profile.vue";
import RequestUserAccounts from "./views/request-user-accounts.vue";
// admin
import AdminMaster from "./views/admin/admin-master.vue";
import AdminUsers from "./views/admin/admin-users.vue";
import AdminGrantees from "./views/admin/admin-grantees.vue";
// general
import Login from "./views/login.vue";
import Landing from "./views/landing.vue";
import Logout from "./views/logout.vue";
import Dashboard from "./views/dashboard.vue";
import Contact from "./views/contact.vue";
import Manual from "./views/manual.vue";
//
import Grantee from "./views/grantee.vue";
import ValidationReport from "./views/validation-report.vue";
// Providers
import Providers from "./views/provider/providers.vue";
import ProviderMaster from "./views/provider/provider-master.vue";
import ProviderData from "./views/provider/provider-data.vue";
// Program
import ProviderPrograms from "./views/provider/provider-programs.vue";
import ProgramMaster from "./views/program/program-master.vue";
import ProgramData from "./views/program/program-data.vue";
import ProgramReach from "./views/program/program-reach.vue";
import ProgramEntryExit from "./views/program/program-entry-exit.vue";

import Upload from "./views/entry-exit/upload-master.vue";
import UploadEntry from "./views/entry-exit/upload-entry.vue";
import UploadExit from "./views/entry-exit/upload-exit.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    meta: { layout: "login" },
    component: Login,
  },
  {
    path: "/landing",
    name: "landing",
    meta: { layout: "login" },
    component: Landing,
  },
  {
    path: "/logout",
    name: "logout",
    meta: { layout: "login" },
    component: Logout,
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      layout: "dashboard",
      restricted: {
        access: "dashboard",
      },
    },
  },
  {
    path: "/grantee",
    name: "grantee",
    component: Grantee,
    meta: {
      restricted: {
        access: "portal",
        role: "grantee",
      },
    },
  },
  {
    path: "/validationreport",
    name: "validationreport",
    component: ValidationReport,
    meta: {
      restricted: {
        access: "portal",
      },
    },
  },
  {
    path: "/requseraccounts",
    name: "requseraccounts",
    component: RequestUserAccounts,
    meta: {
      restricted: {
        access: "portal",
        role: "grantee",
      },
    },
  },
  {
    path: "/manual",
    name: "manual",
    component: Manual,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  // providers
  {
    path: "/providers",
    name: "providers",
    component: Providers,
    meta: {
      restricted: {
        access: "portal",
      },
    },
  },
  {
    path: "/upload",
    name: "upload",
    component: Upload,
    meta: {
      restricted: {
        access: "portal",
        role: "grantee",
      },
    },
    children: [
      {
        path: "entry",
        name: "upload-entry",
        component: UploadEntry,
      },
      {
        path: "exit",
        name: "upload-exit",
        component: UploadExit,
      },
    ],
  },
  {
    // for /provider/:pid
    path: "/provider/:pid",
    component: ProviderMaster,
    meta: {
      restricted: {
        access: "portal",
      },
    },
    children: [
      {
        // for /provider/:pid
        path: "",
        name: "provider-data",
        component: ProviderData,
      },
      {
        // for /provider/:pid/programs
        path: "programs",
        name: "programs",
        component: ProviderPrograms,
        props: (route) => ({ pid: Number(route.params.pid) }),
      },
      {
        // for /program/:pmid
        path: "program/:pmid",
        component: ProgramMaster,
        children: [
          {
            // for /program/:pmid
            path: "",
            name: "program-data",
            component: ProgramData,
          },
          {
            // for /program/:pmid/reach
            path: "reach",
            name: "program-reach",
            component: ProgramReach,
          },
          {
            // for /program/:pmid/reach
            path: "entry-exit",
            name: "program-entry-exit",
            component: ProgramEntryExit,
          },
        ],
      },
    ],
  },
  // Admin
  {
    // for /admin
    path: "/admin",
    component: AdminMaster,
    meta: {
      restricted: {
        group: "admin",
      },
    },
    children: [
      {
        // for /admin/users
        path: "users",
        name: "admin-users",
        component: AdminUsers,
        props: (route) => ({ search: String(route.params.search) }),
      },
      {
        // for /admin/users
        path: "grantees",
        name: "admin-grantees",
        component: AdminGrantees,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
