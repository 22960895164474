export default {
  // Confirms every element of values array is a string.
  string: function (values) {
    return values.every(
      (value) => typeof value === "string" || value instanceof String
    );
  },

  // Confirms every element of values array is a number or can be parsed as an integer.
  number: function (values) {
    return values.every(
      (value) => typeof value === "number" || !isNaN(parseFloat(value))
    );
  },

  // Confirms every element of values array is an integer or can be parsed as an integer.
  integer: function (values) {
    return values.every(
      (value) =>
        !isNaN(value) &&
        parseInt(Number(value), 10) == value &&
        !isNaN(parseInt(value, 10))
    );
  },

  //checks that number is not negative (number can be blank)
  nonNeg: function (values) {
    return values.every((value) => isNaN(value) || value >= 0);
  },

  //similar to notBlank, but uses NaN
  nonBlank: function (values) {
    return values.every((value) => !isNaN(value));
  },

  // Confirms every element of values array is not blank.
  notBlank: function (values) {
    return values.every((value) => value !== "" && value !== null);
  },

  //same as sum function below, but counts blanks as 0
  sumBlank: function (values, target) {
    return (
      target ===
      values.reduce(
        (accum, val) => (isNaN(val) ? accum : accum + Number(val)),
        0
      )
    );
  },

  // Confirms sum of elements of values array is equal to target value.
  // Consider setting a tolerance instead of requiring strict equality
  // if we start using this with non-integers.
  sum: function (values, target) {
    return target === values.reduce((x, y) => x + y, 0);
  },

  // Confirms every element of values array is in a target array of values.
  inSet: function (values, target) {
    return values.every((value) => target.indexOf(value) >= 0);
  },
};
