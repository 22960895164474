var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.user.slotName,staticClass:"user-row-card"},[(
      _vm.selectedAssociation &&
      (_vm.grantee.role == 'Grantee' || _vm.grantee.role == 'Admin')
    )?_c('div',{staticClass:"ua-col d-flex flex-column"},[_c('h5',[_vm._v("Site Access")]),_c('div',{staticClass:"m-l-15 d-flex flex-column"},[_c('label',[_c('input',{attrs:{"type":"radio","name":`site-access-${_vm.selectedAssociation.pk}`},domProps:{"checked":_vm.selectedAssociation.has_portal_access &&
            !_vm.selectedAssociation.has_dashboard_access},on:{"click":function($event){return _vm.setSiteAccess(_vm.user, _vm.selectedAssociation, true, false)}}}),_vm._v(" Portal ")]),_c('label',[_c('input',{attrs:{"type":"radio","name":`site-access-${_vm.selectedAssociation.pk}`},domProps:{"checked":!_vm.selectedAssociation.has_portal_access &&
            _vm.selectedAssociation.has_dashboard_access},on:{"click":function($event){return _vm.setSiteAccess(_vm.user, _vm.selectedAssociation, false, true)}}}),_vm._v(" Dashboard ")]),_c('label',[_c('input',{attrs:{"type":"radio","name":`site-access-${_vm.selectedAssociation.pk}`},domProps:{"checked":_vm.selectedAssociation.has_portal_access &&
            _vm.selectedAssociation.has_dashboard_access},on:{"click":function($event){return _vm.setSiteAccess(_vm.user, _vm.selectedAssociation, true, true)}}}),_vm._v(" Dashboard and Portal ")])])]):_vm._e(),_c('div',{staticClass:"ua-col"},[_c('h5',[_vm._v("Associations")]),_vm._l((_vm.user.associations),function(association){return _c('div',{key:_vm.user.slotName + association.pk,staticClass:"association-label m-l-10 d-flex justify-content-between"},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.user.role))]),_vm._v(" at "),_c('strong',[_vm._v(_vm._s(association.name))])]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-danger m-l-10",attrs:{"title":`Delete association with ${association.name}`},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDelete(_vm.user, association)}}},[_c('i',{staticClass:"pg-icon"},[_vm._v("trash_alt")])])],1)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }