<template>
  <div class="text-center p-t-10">Logging out...</div>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  data() {
    return {};
  },
  methods: {},
  created: function () {
    // reset store on logout
    this.$store.commit("reset");

    Auth.signOut({ global: true })
      .then(() => {
        this.$router.push({ name: "login" });
      })
      .catch((err) => {
        console.error(err);
        this.$router.push({ name: "login" });
      });
  },
};
</script>
