<template>
  <div class="row">
    <div class="col-lg-12">
      <div v-if="errors.length || showstoppers.length">
        <p><b>Please correct the following error(s):</b></p>
        <ul>
          <div class="error-dark">
            <li v-for="showstopper in showstoppers" v-bind:key="showstopper">
              {{ showstopper }}
            </li>
          </div>
          <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
        </ul>
      </div>

      <b-form role="form" novalidate>
        <div class="row">
          <div class="col-lg-6">
            <div class="card card-default">
              <div class="card-header separator">
                <div class="card-title">
                  Information about Participant Entry Survey Administration
                </div>
              </div>
              <div
                class="card-body form-group"
                v-if="formData.readOnly.prog_any_youth_begin.value"
              >
                <div class="row">
                  <div class="col-lg-8 form-group">
                    <label for="prog_en_tot_surveys_ms"
                      >Total entry surveys completed (middle school)</label
                    >
                  </div>
                  <div class="col-lg-4 form-group">
                    <input
                      v-model="formData.input.prog_en_tot_surveys_ms.value"
                      id="prog_en_tot_surveys_ms"
                      @input="addThousandComma('prog_en_tot_surveys_ms')"
                      type="text"
                      min="0"
                      v-bind:class="
                        this.formData.input.prog_en_tot_surveys_ms.fmt
                      "
                      required=""
                    />
                  </div>
                  <div
                    class="form-group row"
                    v-if="this.formData.input.prog_en_tot_surveys_ms.flag"
                    align="right"
                  >
                    <div class="error-dark">
                      {{ this.formData.input.prog_en_tot_surveys_ms.msg }}
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-8 form-group">
                    <label for="prog_en_tot_surveys_ms_impact"
                      >How many of these middle school entry surveys were impact
                      versions? (Enter 0 if all middle school entry surveys were
                      the main version.)</label
                    >
                  </div>
                  <div class="col-lg-4 form-group">
                    <input
                      v-model="
                        formData.input.prog_en_tot_surveys_ms_impact.value
                      "
                      id="prog_en_tot_surveys_ms_impact"
                      @input="addThousandComma('prog_en_tot_surveys_ms_impact')"
                      type="text"
                      min="0"
                      v-bind:class="
                        this.formData.input.prog_en_tot_surveys_ms_impact.fmt
                      "
                      required=""
                    />
                  </div>
                  <div
                    class="form-group row"
                    v-if="
                      this.formData.input.prog_en_tot_surveys_ms_impact.flag
                    "
                    align="left"
                  >
                    <div class="error-dark">
                      {{
                        this.formData.input.prog_en_tot_surveys_ms_impact.msg
                      }}
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-8 form-group">
                    <label for="prog_en_tot_surveys_hs"
                      >Total entry surveys completed (high school or
                      older)</label
                    >
                  </div>
                  <div class="col-lg-4 form-group">
                    <input
                      v-model="formData.input.prog_en_tot_surveys_hs.value"
                      id="prog_en_tot_surveys_hs"
                      @input="addThousandComma('prog_en_tot_surveys_hs')"
                      type="text"
                      min="0"
                      v-bind:class="
                        this.formData.input.prog_en_tot_surveys_hs.fmt
                      "
                      required=""
                    />
                  </div>
                  <div
                    class="form-group row"
                    v-if="this.formData.input.prog_en_tot_surveys_hs.flag"
                    align="right"
                  >
                    <div class="error-dark">
                      {{ this.formData.input.prog_en_tot_surveys_hs.msg }}
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-8 form-group">
                    <label for="prog_en_tot_surveys_hs_impact"
                      >How many of these high school entry surveys were impact
                      versions? (Enter 0 if all high school entry surveys were
                      the main version.)</label
                    >
                  </div>
                  <div class="col-lg-4 form-group">
                    <input
                      v-model="
                        formData.input.prog_en_tot_surveys_hs_impact.value
                      "
                      id="prog_en_tot_surveys_hs_impact"
                      @input="addThousandComma('prog_en_tot_surveys_hs_impact')"
                      type="text"
                      min="0"
                      v-bind:class="
                        this.formData.input.prog_en_tot_surveys_hs_impact.fmt
                      "
                      required=""
                    />
                  </div>
                  <div
                    class="form-group row"
                    v-if="
                      this.formData.input.prog_en_tot_surveys_hs_impact.flag
                    "
                    align="left"
                  >
                    <div class="error-dark">
                      {{
                        this.formData.input.prog_en_tot_surveys_hs_impact.msg
                      }}
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-8">
                    <label for="prog_en_fed_officer_waiver"
                      >Did the program receive an approved waiver letter from
                      their Federal Project Officer for any entry survey
                      items?</label
                    >
                  </div>

                  <div class="col-4">
                    <div v-bind:style="{ 'padding-left': '7px' }">
                      <b-form-select
                        id="prog_en_fed_officer_waiver"
                        v-bind:class="
                          this.formData.input.prog_en_fed_officer_waiver.fmt
                        "
                        @change="dataUpdated()"
                        v-model="
                          formData.input.prog_en_fed_officer_waiver.value
                        "
                        :options="yn"
                      >
                      </b-form-select>
                    </div>
                  </div>
                  <div
                    class="form-group row"
                    v-if="this.formData.input.prog_en_fed_officer_waiver.flag"
                  >
                    <div class="error-dark">
                      {{ this.formData.input.prog_en_fed_officer_waiver.msg }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8">
                    <label for="prog_en_unable_collect_covid">{{
                      `At any point during the reporting period—${$store.state.reportingPeriodStr.prog_reach}—was the
                      program unable to collect entry survey data due to
                      COVID-19?`
                    }}</label>
                  </div>

                  <div class="col-4">
                    <div v-bind:style="{ 'padding-left': '7px' }">
                      <b-form-select
                        id="prog_en_unable_collect_covid"
                        v-bind:class="
                          this.formData.input.prog_en_unable_collect_covid.fmt
                        "
                        @change="dataUpdated()"
                        v-model="
                          formData.input.prog_en_unable_collect_covid.value
                        "
                        :options="yn"
                      >
                      </b-form-select>
                    </div>
                  </div>
                  <div
                    class="form-group row"
                    v-if="this.formData.input.prog_en_unable_collect_covid.flag"
                  >
                    <div class="error-dark">
                      {{ this.formData.input.prog_en_unable_collect_covid.msg }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    this.removeThousandCommas(
                      formData.input.prog_en_tot_surveys_ms.value
                    ) > 0 ||
                    this.removeThousandCommas(
                      formData.input.prog_en_tot_surveys_hs.value
                    ) > 0
                  "
                >
                  <legend v-bind:style="{ 'margin-top': '0px' }">
                    What mode(s) of data collection did the program use for
                    participant entry surveys during the reporting period?
                  </legend>
                  <div class="row">
                    <b-form-checkbox
                      id="prog_en_data_collection_in_person"
                      v-model="
                        formData.input.prog_en_data_collection_in_person.value
                      "
                      @change="dataUpdated()"
                      name="formData.input.prog_en_data_collection_in_person"
                    >
                      In-person paper-and-pencil survey</b-form-checkbox
                    >
                  </div>
                  <div class="row">
                    <b-form-checkbox
                      id="prog_en_data_collection_online"
                      v-model="
                        formData.input.prog_en_data_collection_online.value
                      "
                      @change="dataUpdated()"
                      name="formData.input.prog_en_data_collection_online"
                    >
                      Online, web-based survey</b-form-checkbox
                    >
                  </div>
                  <div class="row">
                    <b-form-checkbox
                      id="prog_en_data_collection_telephone"
                      v-model="
                        formData.input.prog_en_data_collection_telephone.value
                      "
                      @change="dataUpdated()"
                      name="formData.input.prog_en_data_collection_telephone"
                    >
                      Telephone survey</b-form-checkbox
                    >
                  </div>
                  <div class="row">
                    <b-form-checkbox
                      id="prog_en_data_collection_mail"
                      v-model="
                        formData.input.prog_en_data_collection_mail.value
                      "
                      @change="dataUpdated()"
                      name="formData.input.prog_en_data_collection_mail"
                    >
                      Mail survey</b-form-checkbox
                    >
                  </div>
                  <div class="row">
                    <b-form-checkbox
                      id="prog_en_data_collection_other"
                      v-model="
                        formData.input.prog_en_data_collection_other.value
                      "
                      @change="dataUpdated()"
                      name="formData.input.prog_en_data_collection_other"
                    >
                      Other</b-form-checkbox
                    >
                  </div>
                  <div
                    class="row"
                    v-if="formData.input.prog_en_data_collection_other.value"
                  >
                    <div class="col-lg-8 form-group">
                      <label for="prog_en_data_collection_other_spec"
                        >Please specify:
                      </label>
                    </div>
                    <div class="col-lg-4 form-group">
                      <input
                        v-model="
                          formData.input.prog_en_data_collection_other_spec
                            .value
                        "
                        id="prog_en_data_collection_other_spec"
                        type="text"
                        v-bind:class="
                          this.formData.input.prog_en_data_collection_other_spec
                            .fmt
                        "
                        required=""
                        maxlength="100"
                      />
                    </div>
                  </div>
                  <div class="row" v-if="this.entryCheckboxMsg" align="right">
                    <div class="error-dark">
                      {{ this.entryCheckboxMsg }}
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="
                      this.formData.input.prog_en_data_collection_other_spec
                        .flag
                    "
                    align="right"
                  >
                    <div class="error-dark">
                      {{
                        this.formData.input.prog_en_data_collection_other_spec
                          .msg
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body form-group bold" v-else>
                Information on entry survey administration is not required
                because no youth began the program during the reporting period.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card card-default">
              <div class="card-header separator">
                <div class="card-title">
                  Information about Participant Exit Survey Administration
                </div>
              </div>
              <div
                class="card-body form-group"
                v-if="formData.readOnly.prog_any_youth_complete.value"
              >
                <div class="row">
                  <div class="col-lg-8 form-group">
                    <label for="prog_ex_tot_surveys_ms"
                      >Total exit surveys completed (middle school)</label
                    >
                  </div>
                  <div class="col-lg-4 form-group">
                    <input
                      v-model="formData.input.prog_ex_tot_surveys_ms.value"
                      id="prog_ex_tot_surveys_ms"
                      @input="addThousandComma('prog_ex_tot_surveys_ms')"
                      type="text"
                      min="0"
                      v-bind:class="
                        this.formData.input.prog_ex_tot_surveys_ms.fmt
                      "
                      required=""
                    />
                  </div>
                  <div
                    class="form-group row"
                    v-if="this.formData.input.prog_ex_tot_surveys_ms.flag"
                    align="right"
                  >
                    <div class="error-dark">
                      {{ this.formData.input.prog_ex_tot_surveys_ms.msg }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-8 form-group">
                    <label for="prog_ex_tot_surveys_hs"
                      >Total exit surveys completed (high school or
                      older)</label
                    >
                  </div>
                  <div class="col-lg-4 form-group">
                    <input
                      v-model="formData.input.prog_ex_tot_surveys_hs.value"
                      id="prog_ex_tot_surveys_hs"
                      @input="addThousandComma('prog_ex_tot_surveys_hs')"
                      type="text"
                      min="0"
                      v-bind:class="
                        this.formData.input.prog_ex_tot_surveys_hs.fmt
                      "
                      required=""
                    />
                  </div>
                  <div
                    class="form-group row"
                    v-if="this.formData.input.prog_ex_tot_surveys_hs.flag"
                    align="right"
                  >
                    <div class="error-dark">
                      {{ this.formData.input.prog_ex_tot_surveys_hs.msg }}
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-8">
                    <label for="prog_ex_fed_officer_waiver"
                      >Did the program receive an approved waiver letter from
                      their Federal Project Officer for any exit survey
                      items?</label
                    >
                  </div>

                  <div class="col-4">
                    <div v-bind:style="{ 'padding-left': '7px' }">
                      <b-form-select
                        id="prog_ex_fed_officer_waiver"
                        v-bind:class="
                          this.formData.input.prog_ex_fed_officer_waiver.fmt
                        "
                        @change="dataUpdated()"
                        v-model="
                          formData.input.prog_ex_fed_officer_waiver.value
                        "
                        :options="yn"
                      >
                      </b-form-select>
                    </div>
                  </div>
                  <div
                    class="form-group row"
                    v-if="this.formData.input.prog_ex_fed_officer_waiver.flag"
                  >
                    <div class="error-dark">
                      {{ this.formData.input.prog_ex_fed_officer_waiver.msg }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8">
                    <label for="prog_ex_unable_collect_covid">{{
                      `At any point during the reporting period—${$store.state.reportingPeriodStr.prog_reach}—was the
                      program unable to collect exit survey data due to
                      COVID-19?`
                    }}</label>
                  </div>

                  <div class="col-4">
                    <div v-bind:style="{ 'padding-left': '7px' }">
                      <b-form-select
                        id="prog_ex_unable_collect_covid"
                        v-bind:class="
                          this.formData.input.prog_ex_unable_collect_covid.fmt
                        "
                        @change="dataUpdated()"
                        v-model="
                          formData.input.prog_ex_unable_collect_covid.value
                        "
                        :options="yn"
                      >
                      </b-form-select>
                    </div>
                  </div>
                  <div
                    class="form-group row"
                    v-if="this.formData.input.prog_ex_unable_collect_covid.flag"
                  >
                    <div class="error-dark">
                      {{ this.formData.input.prog_ex_unable_collect_covid.msg }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    this.removeThousandCommas(
                      formData.input.prog_ex_tot_surveys_ms.value
                    ) > 0 ||
                    this.removeThousandCommas(
                      formData.input.prog_ex_tot_surveys_hs.value
                    ) > 0
                  "
                >
                  <legend v-bind:style="{ 'margin-top': '0px' }">
                    What mode(s) of data collection did the program use for
                    participant exit surveys during the reporting period?
                  </legend>
                  <div class="row">
                    <b-form-checkbox
                      id="prog_ex_data_collection_in_person"
                      v-model="
                        formData.input.prog_ex_data_collection_in_person.value
                      "
                      @change="dataUpdated()"
                      name="formData.input.prog_ex_data_collection_in_person"
                    >
                      In-person paper-and-pencil survey</b-form-checkbox
                    >
                  </div>
                  <div class="row">
                    <b-form-checkbox
                      id="prog_ex_data_collection_online"
                      v-model="
                        formData.input.prog_ex_data_collection_online.value
                      "
                      @change="dataUpdated()"
                      name="formData.input.prog_ex_data_collection_online"
                    >
                      Online, web-based survey</b-form-checkbox
                    >
                  </div>
                  <div class="row">
                    <b-form-checkbox
                      id="prog_ex_data_collection_telephone"
                      v-model="
                        formData.input.prog_ex_data_collection_telephone.value
                      "
                      @change="dataUpdated()"
                      name="formData.input.prog_ex_data_collection_telephone"
                    >
                      Telephone survey</b-form-checkbox
                    >
                  </div>
                  <div class="row">
                    <b-form-checkbox
                      id="prog_ex_data_collection_mail"
                      v-model="
                        formData.input.prog_ex_data_collection_mail.value
                      "
                      @change="dataUpdated()"
                      name="formData.input.prog_ex_data_collection_mail"
                    >
                      Mail survey</b-form-checkbox
                    >
                  </div>
                  <div class="row">
                    <b-form-checkbox
                      id="prog_ex_data_collection_other"
                      v-model="
                        formData.input.prog_ex_data_collection_other.value
                      "
                      @change="dataUpdated()"
                      name="formData.input.prog_ex_data_collection_other"
                    >
                      Other</b-form-checkbox
                    >
                  </div>
                  <div
                    class="row"
                    v-if="formData.input.prog_ex_data_collection_other.value"
                  >
                    <div class="col-lg-8 form-group">
                      <label for="prog_ex_data_collection_other_spec"
                        >Please specify:
                      </label>
                    </div>
                    <div class="col-lg-4 form-group">
                      <input
                        v-model="
                          formData.input.prog_ex_data_collection_other_spec
                            .value
                        "
                        id="prog_ex_data_collection_other_spec"
                        type="text"
                        v-bind:class="
                          this.formData.input.prog_ex_data_collection_other_spec
                            .fmt
                        "
                        required=""
                        maxlength="100"
                      />
                    </div>
                  </div>
                  <div class="row" v-if="this.exitCheckboxMsg" align="right">
                    <div class="error-dark">
                      {{ this.exitCheckboxMsg }}
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="
                      this.formData.input.prog_ex_data_collection_other_spec
                        .flag
                    "
                    align="right"
                  >
                    <div class="error-dark">
                      {{
                        this.formData.input.prog_ex_data_collection_other_spec
                          .msg
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body form-group bold" v-else>
                Information on exit survey administration is not required
                because no youth completed the program during the reporting
                period.
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="row">
            <div class="col-lg-12 text-right">
              <!--Using modifiers -->
              <router-link
                id="previous_page_button"
                :to="{
                  name: 'program-reach',
                  params: { pid: $route.params.pid, pmid: $route.params.pmid },
                }"
                class="btn btn-lg btn-primary m-r-10"
                title="Return to Attendance, Reach, and Dosage Form"
              >
                <span>Previous</span>
              </router-link>
              <b-button
                v-if="
                  this.formData.readOnly.prog_any_youth_begin.value ||
                  this.formData.readOnly.prog_any_youth_complete.value
                "
                v-b-modal.confirmSubmit
                id="save_program_reach_button"
                @click="checkForm('submit', $event)"
                class="btn btn-lg btn-primary m-r-10"
              >
                Save
              </b-button>

              <router-link
                id="return_to_program_model_table_button"
                :to="{ name: 'programs', params: { pid: provider.id } }"
                class="btn btn-lg btn-primary m-r-10"
                title="Return to Program Model Table"
              >
                <span>Return to Program Model Table</span>
              </router-link>
            </div>
          </div>

          <!--The modal -->
          <b-modal id="confirmSubmit" ok-only>
            <div v-if="errors.length || showstoppers.length">
              <p><b>Please correct the following error(s):</b></p>
              <ul class="error-dark">
                <li
                  v-for="showstopper in showstoppers"
                  v-bind:key="showstopper"
                >
                  {{ showstopper }}
                </li>
              </ul>
              <ul>
                <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
              </ul>
            </div>

            <p
              v-if="!warnings.length && !errors.length && !showstoppers.length"
            >
              <b>No issues detected</b>
              <br />
              The data on this page has been saved.
            </p>
          </b-modal>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import mixin from "../../mixin";

export default {
  mixins: [mixin],
  data() {
    return {
      formName: "programentryexit",
      formData: {
        grantee_name: "",
        provider_name: "",

        input: {
          prog_en_tot_surveys_ms: {
            type: "number",
            value: null,
            flag: false,
            desc: "Total entry surveys completed (middle school)",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_en_tot_surveys_ms_impact: {
            type: "number",
            value: null,
            flag: false,
            desc: "Total impact entry surveys completed (middle school)",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_en_tot_surveys_hs: {
            type: "number",
            value: null,
            flag: false,
            desc: "Total entry surveys completed (high school or older)",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_en_tot_surveys_hs_impact: {
            type: "number",
            value: null,
            flag: false,
            desc: "Total impact entry surveys completed (high school or older)",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_en_fed_officer_waiver: {
            type: "boolean",
            value: null,
            flag: false,
            desc:
              "Did the program receive an approved waiver letter from their Federal Project Officer for any entry survey items?",
            msg: "",
            fmt: "",
            ignore: true,
          },
          prog_en_unable_collect_covid: {
            type: "boolean",
            value: null,
            flag: false,
            desc:
              "At any point during the reporting period, was the program unable to collect entry survey data due to COVID-19?",
            msg: "",
            fmt: "",
            ignore: true,
          },
          prog_en_data_collection_in_person: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "What mode(s) of data collection did the program use for participant entry surveys during the reporting period? In-person paper-and-pencil survey",
            msg: "",
            fmt: "",
            ignore: true,
          },
          prog_en_data_collection_online: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "What mode(s) of data collection did the program use for participant entry surveys during the reporting period? Online, web-based survey",
            msg: "",
            fmt: "",
            ignore: true,
          },
          prog_en_data_collection_telephone: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "What mode(s) of data collection did the program use for participant entry surveys during the reporting period? Telephone survey",
            msg: "",
            fmt: "",
            ignore: true,
          },
          prog_en_data_collection_mail: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "What mode(s) of data collection did the program use for participant entry surveys during the reporting period? Mail survey",
            msg: "",
            fmt: "",
            ignore: true,
          },
          prog_en_data_collection_other: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "What mode(s) of data collection did the program use for participant entry surveys during the reporting period? Other",
            msg: "",
            fmt: "",
            ignore: true,
          },
          prog_en_data_collection_other_spec: {
            type: "string",
            value: null,
            flag: false,
            desc:
              "What mode(s) of data collection did the program use for participant entry surveys during the reporting period? Other, specify",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_ex_tot_surveys_ms: {
            type: "number",
            value: null,
            flag: false,
            desc: "Total exit surveys completed (middle school)",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_ex_tot_surveys_hs: {
            type: "number",
            value: null,
            flag: false,
            desc: "Total exit surveys completed (high school or older)",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_ex_fed_officer_waiver: {
            type: "boolean",
            value: null,
            flag: false,
            desc:
              "Did the program receive an approved waiver letter from their Federal Project Officer for any exit survey items?",
            msg: "",
            fmt: "",
            ignore: true,
          },
          prog_ex_unable_collect_covid: {
            type: "boolean",
            value: null,
            flag: false,
            desc:
              "At any point during the reporting period, was the program unable to collect exit survey data due to COVID-19?",
            msg: "",
            fmt: "",
            ignore: true,
          },
          prog_ex_data_collection_in_person: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "What mode(s) of data collection did the program use for participant exit surveys during the reporting period? In-person paper-and-pencil survey",
            msg: "",
            fmt: "",
            ignore: true,
          },
          prog_ex_data_collection_online: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "What mode(s) of data collection did the program use for participant exit surveys during the reporting period? Online, web-based survey",
            msg: "",
            fmt: "",
            ignore: true,
          },
          prog_ex_data_collection_telephone: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "What mode(s) of data collection did the program use for participant exit surveys during the reporting period? Telephone survey",
            msg: "",
            fmt: "",
            ignore: true,
          },
          prog_ex_data_collection_mail: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "What mode(s) of data collection did the program use for participant exit surveys during the reporting period? Mail survey",
            msg: "",
            fmt: "",
            ignore: true,
          },
          prog_ex_data_collection_other: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "What mode(s) of data collection did the program use for participant exit surveys during the reporting period? Other",
            msg: "",
            fmt: "",
            ignore: true,
          },
          prog_ex_data_collection_other_spec: {
            type: "string",
            value: null,
            flag: false,
            desc:
              "What mode(s) of data collection did the program use for participant exit surveys during the reporting period? Other, specify",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
        },
        readOnly: {
          prog_any_youth_begin: {
            value: null,
          },
          prog_any_youth_complete: {
            value: null,
          },
        },
      },

      yn: [
        { value: null, text: "Select option" },
        { value: true, text: "Yes" },
        { value: false, text: "No" },
      ],

      entryCheckboxMsg: "",
      exitCheckboxMsg: "",

      current_index: 0,
      errors: [],
      showstoppers: [],

      warnings: [],
      status_msg: "",
      saved: true,
    };
  },
  watch: {
    grantee() {
      // get grantee data
      this.getSubmissionData();
    },

    "$store.state.reportingPeriodData": function () {
      this.getSubmissionData("watch");
    },
  },
  created() {
    this.getSubmissionData();
  },
  computed: {
    ignoreLowerHalf: function () {
      return true;
    },
    formDataSubmission: function () {
      return {
        formName: "programentryexit",
        formData: [
          {
            tableName: "program_submissions",
            tableData: [
              [
                {
                  name: "prog_en_tot_surveys_ms",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_en_tot_surveys_ms.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_en_tot_surveys_ms.value
                        ),
                      },
                },
                {
                  name: "prog_en_tot_surveys_ms_impact",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_en_tot_surveys_ms_impact.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_en_tot_surveys_ms_impact
                            .value
                        ),
                      },
                },
                {
                  name: "prog_en_tot_surveys_hs",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_en_tot_surveys_hs.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_en_tot_surveys_hs.value
                        ),
                      },
                },
                {
                  name: "prog_en_tot_surveys_hs_impact",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_en_tot_surveys_hs_impact.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_en_tot_surveys_hs_impact
                            .value
                        ),
                      },
                },
                {
                  name: "prog_en_fed_officer_waiver",
                  value:
                    this.formData.input.prog_en_fed_officer_waiver.value ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_en_fed_officer_waiver.value,
                        },
                },
                {
                  name: "prog_en_unable_collect_covid",
                  value:
                    this.formData.input.prog_en_unable_collect_covid.value ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_en_unable_collect_covid.value,
                        },
                },
                {
                  name: "prog_en_data_collection_in_person",
                  value:
                    this.formData.input.prog_en_data_collection_in_person
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_en_data_collection_in_person.value,
                        },
                },
                {
                  name: "prog_en_data_collection_online",
                  value:
                    this.formData.input.prog_en_data_collection_online.value ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_en_data_collection_online.value,
                        },
                },
                {
                  name: "prog_en_data_collection_telephone",
                  value:
                    this.formData.input.prog_en_data_collection_telephone
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_en_data_collection_telephone.value,
                        },
                },
                {
                  name: "prog_en_data_collection_mail",
                  value:
                    this.formData.input.prog_en_data_collection_mail.value ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_en_data_collection_mail.value,
                        },
                },
                {
                  name: "prog_en_data_collection_other",
                  value:
                    this.formData.input.prog_en_data_collection_other.value ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_en_data_collection_other.value,
                        },
                },
                {
                  name: "prog_en_data_collection_other_spec",
                  value:
                    this.formData.input.prog_en_data_collection_other_spec
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          stringValue: this.formData.input
                            .prog_en_data_collection_other_spec.value,
                        },
                },
                {
                  name: "prog_ex_tot_surveys_ms",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_ex_tot_surveys_ms.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_ex_tot_surveys_ms.value
                        ),
                      },
                },
                {
                  name: "prog_ex_tot_surveys_hs",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_ex_tot_surveys_hs.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_ex_tot_surveys_hs.value
                        ),
                      },
                },
                {
                  name: "prog_ex_fed_officer_waiver",
                  value:
                    this.formData.input.prog_ex_fed_officer_waiver.value ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_ex_fed_officer_waiver.value,
                        },
                },
                {
                  name: "prog_ex_unable_collect_covid",
                  value:
                    this.formData.input.prog_ex_unable_collect_covid.value ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_ex_unable_collect_covid.value,
                        },
                },
                {
                  name: "prog_ex_data_collection_in_person",
                  value:
                    this.formData.input.prog_ex_data_collection_in_person
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_ex_data_collection_in_person.value,
                        },
                },
                {
                  name: "prog_ex_data_collection_online",
                  value:
                    this.formData.input.prog_ex_data_collection_online.value ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_ex_data_collection_online.value,
                        },
                },
                {
                  name: "prog_ex_data_collection_telephone",
                  value:
                    this.formData.input.prog_ex_data_collection_telephone
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_ex_data_collection_telephone.value,
                        },
                },
                {
                  name: "prog_ex_data_collection_mail",
                  value:
                    this.formData.input.prog_ex_data_collection_mail.value ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_ex_data_collection_mail.value,
                        },
                },
                {
                  name: "prog_ex_data_collection_other",
                  value:
                    this.formData.input.prog_ex_data_collection_other.value ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_ex_data_collection_other.value,
                        },
                },
                {
                  name: "prog_ex_data_collection_other_spec",
                  value:
                    this.formData.input.prog_ex_data_collection_other_spec
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          stringValue: this.formData.input
                            .prog_ex_data_collection_other_spec.value,
                        },
                },
                {
                  name: "prog_entry_exit_data_issues_minor",
                  value: {
                    booleanValue: this.errors.length > 0,
                  },
                },
                {
                  name: "prog_entry_exit_data_issues_major",
                  value: {
                    booleanValue: this.showstoppers.length > 0,
                  },
                },
              ],
            ],
          },
        ],
      };
    },
  },
  methods: {
    initializeCommas(num) {
      if (num) {
        const string = num.toString();
        const number = string.replace(/,/g, "");
        const split = number.split(".");

        let commas;

        if (split.length > 1) {
          split[0] = split[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          commas = split.join(".");
        } else {
          commas = split[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        return commas;
      } else {
        return num;
      }
    },

    addThousandComma(varName) {
      if (
        this.formData.input[varName].value === null ||
        typeof this.formData.input[varName].value === "undefined"
      ) {
        this.formData.input[varName].value = null;
      } else {
        const target = this.formData.input[varName].value;
        const initialLength = target.length;

        const numericOnly = target.replace(/[^0-9,]/g, "");

        const commas = this.initializeCommas(numericOnly);
        this.formData.input[varName].value = commas;

        if (numericOnly.length === initialLength) {
          this.dataUpdated();
        }
      }
    },

    removeThousandCommas(value) {
      if (isNaN(value)) {
        let number = value.replace(/,/g, "");
        return parseFloat(number);
      } else {
        return parseFloat(value);
      }
    },

    getSubmissionData() {
      this.$store
        .dispatch("getSubmissionData", {
          path: `/grantees/${this.grantee.id}/providers/${this.$route.params.pid}/programs/${this.$route.params.pmid}/submissions/${this.$store.state.reportingPeriodData.pk}/reduce`,
        })
        .then((r) => {
          if (r.success && r.data.length) {
            const data = r.data[0];
            const keys = Object.keys(data);

            keys.forEach((key) => {
              if (Object.keys(this.formData.input).includes(key)) {
                if (this.formData.input[key].type === "number") {
                  data[key] = this.initializeCommas(data[key]);
                }
                this.formData.input[key].value = data[key];
              } else if (Object.keys(this.formData.readOnly).includes(key)) {
                //the two prog_any_youth_begin/complete booleans from program reach are needed for form logic
                this.formData.readOnly[key].value = data[key];
              }
            });
          }

          this.checkForm();
          this.saved = true;
        });
    },

    dataUpdated() {
      this.saved = false;
    },
    submitForm(_action) {
      this.$store
        .dispatch("submitForm", {
          action: _action,
          path: `/grantees/${this.grantee.id}/providers/${this.$route.params.pid}/programs/${this.$route.params.pmid}/submissions/${this.$store.state.reportingPeriodData.pk}`,
          data: this.formDataSubmission,
        })
        .then((r) => {
          this.status_msg = r;
        });
    },

    checkForm(_type) {
      this.saved = true;

      this.errors = [];
      this.showstoppers = [];

      // get "entry" form keys if prog_any_youth_begin; get "exit" form keys if prog_any_youth_complete
      var f = Object.keys(this.formData.input).filter(
        (key) =>
          (key.substring(0, 8) === "prog_en_" &&
            this.formData.readOnly.prog_any_youth_begin.value) ||
          (key.substring(0, 8) === "prog_ex_" &&
            this.formData.readOnly.prog_any_youth_complete.value)
      );

      for (var i = 0; i < f.length; i++) {
        // reset format, message, and flag
        this.formData.input[f[i]].fmt = this.formData.input[f[i]].fmt.replace(
          "invalid-input",
          ""
        );
        this.formData.input[f[i]].msg = "";
        this.formData.input[f[i]].flag = false;
        var s = "";

        if (this.formData.input[f[i]].type == "number") {
          if (
            this.formData.input[f[i]].value === null ||
            isNaN(this.removeThousandCommas(this.formData.input[f[i]].value))
          ) {
            this.formData.input[f[i]].flag = true;
            this.formData.input[f[i]].fmt += " invalid-input";
            this.formData.input[f[i]].msg = "Please enter a value.";
            s =
              this.formData.input[f[i]].desc +
              ": " +
              this.formData.input[f[i]].msg;
            this.errors.push(s);
          } else if (
            this.removeThousandCommas(this.formData.input[f[i]].value) < 0
          ) {
            this.formData.input[f[i]].flag = true;
            this.formData.input[f[i]].fmt += " invalid-input";
            this.formData.input[f[i]].msg = "Please enter a positive value.";
            s =
              this.formData.input[f[i]].desc +
              ": " +
              this.formData.input[f[i]].msg;
            this.errors.push(s);
          }
        }
        if (
          this.formData.input[f[i]].type == "boolean" &&
          !f[i].includes("data_collection")
        ) {
          if (this.formData.input[f[i]].value === null) {
            this.formData.input[f[i]].flag = true;
            this.formData.input[f[i]].fmt += " invalid-input";
            this.formData.input[f[i]].msg = "Please select a response.";
            s =
              this.formData.input[f[i]].desc +
              ": " +
              this.formData.input[f[i]].msg;
            this.errors.push(s);
          }
        }
      }

      const entryCheckboxValues = [
        "prog_en_data_collection_in_person",
        "prog_en_data_collection_online",
        "prog_en_data_collection_telephone",
        "prog_en_data_collection_mail",
        "prog_en_data_collection_other",
      ];

      const exitCheckboxValues = [
        "prog_ex_data_collection_in_person",
        "prog_ex_data_collection_online",
        "prog_ex_data_collection_telephone",
        "prog_ex_data_collection_mail",
        "prog_ex_data_collection_other",
      ];

      if (
        this.formData.readOnly.prog_any_youth_begin.value &&
        (this.removeThousandCommas(
          this.formData.input.prog_en_tot_surveys_ms.value
        ) > 0 ||
          this.removeThousandCommas(
            this.formData.input.prog_en_tot_surveys_hs.value
          ) > 0)
      ) {
        this.entryCheckboxMsg = "Please select a response";
        for (let key of entryCheckboxValues) {
          if (this.formData.input[key].value === true) {
            this.entryCheckboxMsg = "";
          }
        }
        if (this.entryCheckboxMsg) {
          this.errors.push(
            "Please select a mode of entry survey data collection"
          );
        }
        const otherVarEN = this.formData.input.prog_en_data_collection_other;
        const otherSpecVarEN = this.formData.input
          .prog_en_data_collection_other_spec;
        if (otherVarEN.value && !otherSpecVarEN.value) {
          otherSpecVarEN.flag = true;
          otherSpecVarEN.fmt += " invalid-input";
          otherSpecVarEN.msg = "Please enter a response";
          const s = otherSpecVarEN.desc + ": " + otherSpecVarEN.msg;
          this.errors.push(s);
        }
      }

      // number of MS entry surveys should be greater than or equal to the number
      // of impact MS entry surveys
      if (
        this.formData.readOnly.prog_any_youth_begin.value &&
        this.removeThousandCommas(
          this.formData.input.prog_en_tot_surveys_ms.value
        ) &&
        this.removeThousandCommas(
          this.formData.input.prog_en_tot_surveys_ms_impact.value
        ) &&
        this.removeThousandCommas(
          this.formData.input.prog_en_tot_surveys_ms.value
        ) <
          this.removeThousandCommas(
            this.formData.input.prog_en_tot_surveys_ms_impact.value
          )
      ) {
        this.formData.input.prog_en_tot_surveys_ms_impact.fmt +=
          " invalid-input";
        this.formData.input.prog_en_tot_surveys_ms_impact.flag = true;
        this.formData.input.prog_en_tot_surveys_ms_impact.msg =
          "The number of middle school impact versions completed should be smaller than the total number of middle school entry surveys completed";
        this.showstoppers.push(
          "The number of middle school impact versions completed should be smaller than the total number of middle school entry surveys completed"
        );
      }

      // number of HS entry surveys should be greater than or equal to the number
      // of impact HS entry surveys
      if (
        this.formData.readOnly.prog_any_youth_begin.value &&
        this.removeThousandCommas(
          this.formData.input.prog_en_tot_surveys_hs.value
        ) &&
        this.removeThousandCommas(
          this.formData.input.prog_en_tot_surveys_hs_impact.value
        ) &&
        this.removeThousandCommas(
          this.formData.input.prog_en_tot_surveys_hs.value
        ) <
          this.removeThousandCommas(
            this.formData.input.prog_en_tot_surveys_hs_impact.value
          )
      ) {
        this.formData.input.prog_en_tot_surveys_hs_impact.fmt +=
          " invalid-input";
        this.formData.input.prog_en_tot_surveys_hs_impact.flag = true;
        this.formData.input.prog_en_tot_surveys_hs_impact.msg =
          "The number of high school impact versions completed should be smaller than the total number of high school entry surveys completed";
        this.showstoppers.push(
          "The number of high school impact versions completed should be smaller than the total number of high school entry surveys completed"
        );
      }

      if (
        this.formData.readOnly.prog_any_youth_complete.value &&
        (this.removeThousandCommas(
          this.formData.input.prog_ex_tot_surveys_ms.value
        ) > 0 ||
          this.removeThousandCommas(
            this.formData.input.prog_ex_tot_surveys_hs.value
          ) > 0)
      ) {
        this.exitCheckboxMsg = "Please select a response";
        for (let key of exitCheckboxValues) {
          if (this.formData.input[key].value === true) {
            this.exitCheckboxMsg = "";
          }
        }
        if (this.exitCheckboxMsg) {
          this.errors.push(
            "Please select a mode of exit survey data collection"
          );
        }
        const otherVarEX = this.formData.input.prog_ex_data_collection_other;
        const otherSpecVarEX = this.formData.input
          .prog_ex_data_collection_other_spec;
        if (otherVarEX.value && !otherSpecVarEX.value) {
          otherSpecVarEX.flag = true;
          otherSpecVarEX.fmt += " invalid-input";
          otherSpecVarEX.msg = "Please enter a response";
          const s = otherSpecVarEX.desc + ": " + otherSpecVarEX.msg;
          this.errors.push(s);
        }
      }

      this.submitForm(_type);

      if (!this.errors.length && !this.showstoppers.length) {
        return true;
      }

      if (this.errors || this.showstoppers) {
        console.error("Validation errors:");
        console.error(this.errors);
        console.error(this.showstoppers);
      }

      // e.preventDefault()
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.saved) {
      const answer = window.confirm(
        "The page contains data that have not been saved. Are you sure you want to leave the page without saving?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>
