<template>
  <div class="row">
    <div class="col-12 no-padding">
      <div class="card card-transparent">
        <div class="card-header p-t-5">
          <div class="card-title">
            <div class="col-xs-12">
              <router-link
                :to="{ name: 'providers' }"
                class="btn btn-lg btn-block btn-primary btn-icon-left"
              >
                <i class="pg-icon md-18">arrow_left</i>
                <span>Return to Provider List</span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div v-if="errors.length || showstoppers.length">
            <div><b>Please correct the following error(s):</b></div>
            <ul>
              <li
                class="error-message"
                v-for="showstopper in showstoppers"
                v-bind:key="showstopper"
              >
                {{ showstopper }}
              </li>
              <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
            </ul>
          </div>

          <b-form role="form" novalidate>
            <div class="row">
              <div class="col-lg-6" v-if="!isWinterSeason">
                <div class="card card-default">
                  <div class="card-header separator">
                    <div class="card-title">Funding</div>
                  </div>
                  <div class="card-body">
                    <div class="form-group row">
                      <div class="col-8 p-r-20">
                        <label for="prov_srae_provider_funding"
                          >SRAE annual award amount for the
                          {{ $store.state.reportingPeriodYears }} grant
                          year</label
                        >
                      </div>
                      <div class="col-4">
                        <input
                          v-model="
                            providerSubmissionData.prov_srae_provider_funding
                          "
                          id="prov_srae_provider_funding"
                          @input="
                            addThousandComma('prov_srae_provider_funding')
                          "
                          type="text"
                          class="form-control"
                          :class="{
                            'invalid-input': this.formErrors
                              .prov_srae_provider_funding.flag,
                          }"
                          required=""
                        />
                      </div>

                      <div
                        class="form-group row"
                        v-if="this.formErrors.prov_srae_provider_funding.flag"
                      >
                        <div class="error-message">
                          {{ this.formErrors.prov_srae_provider_funding.msg }}
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-8 p-r-20">
                        <label for="prov_non_srae_provider_funding"
                          >Amount of non-SRAE funding received during current
                          reporting year to support SRAE programming</label
                        >
                      </div>
                      <div class="col-4">
                        <input
                          v-model="
                            providerSubmissionData.prov_non_srae_provider_funding
                          "
                          id="prov_non_srae_provider_funding"
                          @input="
                            addThousandComma('prov_non_srae_provider_funding')
                          "
                          type="text"
                          class="form-control"
                          :class="{
                            'invalid-input': this.formErrors
                              .prov_non_srae_provider_funding.flag,
                          }"
                          required=""
                        />
                      </div>
                    </div>

                    <div
                      class="form-group row"
                      v-if="this.formErrors.prov_non_srae_provider_funding.flag"
                    >
                      <div class="error-message">
                        {{ this.formErrors.prov_non_srae_provider_funding.msg }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                :class="{
                  'col-lg-6': !isWinterSeason,
                  'col-lg-12': isWinterSeason,
                }"
              >
                <div class="card card-default">
                  <div class="card-header separator" v-if="!isWinterSeason">
                    <div class="card-title">Provider status</div>
                  </div>
                  <div class="card-body">
                    <div class="form-group row" v-if="!isWinterSeason">
                      <div class="col-7">
                        <label for="prov_new_provider"
                          >Is provider new for the
                          {{ $store.state.reportingPeriodYears }} grant
                          year?</label
                        >
                      </div>

                      <div class="col-5 p-r-20">
                        <div>
                          <b-form-select
                            @change="dataUpdated()"
                            v-model="providerSubmissionData.prov_new_provider"
                            id="prov_new_provider"
                            :options="yn"
                            :class="{
                              'invalid-input': this.formErrors.prov_new_provider
                                .flag,
                            }"
                          >
                          </b-form-select>
                        </div>
                      </div>
                    </div>
                    <div
                      class="form-group row error-message"
                      v-if="
                        this.formErrors.prov_new_provider.flag &&
                        this.formErrors.prov_new_provider.msg !==
                          'Please enter a value.'
                      "
                    >
                      {{ this.formErrors.prov_new_provider.msg }}
                    </div>

                    <div class="form-group row">
                      <div class="col-7">
                        <label for="prov_provider_serve_youth"
                          >Did provider serve youth or their parents during the
                          {{ $store.state.reportingPeriodStr.prov }}
                          reporting period?</label
                        >
                      </div>

                      <div class="col-5 p-r-20">
                        <div>
                          <b-form-select
                            @change="dataUpdated()"
                            v-model="
                              providerSubmissionData.prov_provider_serve_youth
                            "
                            id="prov_provider_serve_youth"
                            :options="yn"
                            :class="{
                              'invalid-input': this.formErrors
                                .prov_provider_serve_youth.flag,
                            }"
                          >
                          </b-form-select>
                        </div>
                      </div>
                    </div>
                    <div
                      class="form-group row"
                      v-if="providerSubmissionData.prov_provider_serve_youth"
                    >
                      <div class="col-7">
                        <label for="prov_num_program_models_implemented"
                          >Number of program models implemented
                          <b-button
                            v-b-tooltip.hover
                            id="facil_num_programs"
                            :title="`Number of program models the provider implemented during the ${$store.state.reportingPeriodStr.prov} reporting period. These program models should be listed as active in the “Program Model Table” on the next page.`"
                            size="sm"
                            >?</b-button
                          ></label
                        >
                      </div>

                      <div class="col-5 p-r-20">
                        <div>
                          <input
                            v-model="
                              providerSubmissionData.prov_num_program_models_implemented
                            "
                            id="prov_num_program_models_implemented"
                            @input="
                              addThousandComma(
                                'prov_num_program_models_implemented'
                              )
                            "
                            type="text"
                            class="form-control"
                            :class="{
                              'invalid-input': this.formErrors
                                .prov_num_program_models_implemented.flag,
                            }"
                            required=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row"
              v-if="
                providerSubmissionData.prov_provider_serve_youth &&
                !isWinterSeason
              "
            >
              <div class="col-lg-12">
                <div class="card card-default">
                  <div class="card-header separator">
                    <div class="card-title">
                      Provider staff in SRAE administration
                    </div>
                  </div>
                  <div class="card-body row">
                    <div class="col-lg-6">
                      <div class="form-group row">
                        <div class="col-9">
                          <label for="prov_num_staff_admin_progs"
                            >Number of provider staff involved in administering
                            SRAE programs
                            <b-button
                              v-b-tooltip.hover
                              id="num_staff_admin"
                              title="This measure should include provider staff, such as SRAE program directors and program coordinators, who were directly responsible for administering, managing, and overseeing the SRAE program for the provider. Do not include staff who provide programming directly to youth in this measure. Those staff should be counted in the measure of facilitators later in this section. If staff play both roles, they should be included in both measures."
                              size="sm"
                              >?</b-button
                            ></label
                          >
                        </div>
                        <div class="col-3 p-r-20">
                          <input
                            v-model="
                              providerSubmissionData.prov_num_staff_admin_progs
                            "
                            id="prov_num_staff_admin_progs"
                            @input="
                              addThousandComma('prov_num_staff_admin_progs')
                            "
                            type="text"
                            class="form-control"
                            :class="{
                              'invalid-input': this.formErrors
                                .prov_num_staff_admin_progs.flag,
                            }"
                            required=""
                          />
                        </div>
                      </div>

                      <div
                        class="form-group row"
                        v-if="this.formErrors.prov_num_staff_admin_progs.flag"
                      >
                        <div class="error-message">
                          {{ this.formErrors.prov_num_staff_admin_progs.msg }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group row">
                        <div class="col-9">
                          <label for="prov_num_fte_admin_progs"
                            >Number of provider FTEs involved in administering
                            SRAE programs</label
                          >
                        </div>
                        <div class="col-3 p-r-20">
                          <input
                            v-model="
                              providerSubmissionData.prov_num_fte_admin_progs
                            "
                            id="prov_num_fte_admin_progs"
                            @input="
                              addThousandCommaDec('prov_num_fte_admin_progs')
                            "
                            type="text"
                            class="form-control"
                            :class="{
                              'invalid-input': this.formErrors
                                .prov_num_fte_admin_progs.flag,
                            }"
                            required=""
                          />
                        </div>
                      </div>

                      <div
                        class="form-group row"
                        v-if="this.formErrors.prov_num_fte_admin_progs.flag"
                      >
                        <div class="error-message">
                          {{ this.formErrors.prov_num_fte_admin_progs.msg }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row"
              v-if="
                providerSubmissionData.prov_provider_serve_youth &&
                !isWinterSeason
              "
            >
              <div class="col-lg-6">
                <div class="card card-default">
                  <div class="card-header separator">
                    <div class="card-title">Facilitators</div>
                  </div>
                  <div class="card-body row">
                    <div class="col-lg-12">
                      <div class="form-group row">
                        <div class="col-9">
                          <label for="prov_num_facilitators_working"
                            >Number of SRAE facilitators working for
                            provider</label
                          >
                        </div>
                        <div class="col-3 p-r-20">
                          <input
                            v-model="
                              providerSubmissionData.prov_num_facilitators_working
                            "
                            id="prov_num_facilitators_working"
                            @input="
                              addThousandComma('prov_num_facilitators_working')
                            "
                            type="text"
                            class="form-control"
                            :class="{
                              'invalid-input': this.formErrors
                                .prov_num_facilitators_working.flag,
                            }"
                            required=""
                          />
                        </div>
                      </div>

                      <div
                        class="form-group row"
                        v-if="
                          this.formErrors.prov_num_facilitators_working.flag
                        "
                      >
                        <div class="error-message">
                          {{
                            this.formErrors.prov_num_facilitators_working.msg
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="card card-default">
                  <div class="card-header separator">
                    <div class="card-title">
                      Facilitator training and observation
                    </div>
                  </div>
                  <div class="card-body row">
                    <div class="col-lg-12">
                      <div class="form-group row">
                        <div class="col-9">
                          <label for="prov_num_facilitators_trained"
                            >Number of SRAE facilitators trained in delivering
                            core curriculum</label
                          >
                        </div>
                        <div class="col-3 p-r-20">
                          <input
                            v-model="
                              providerSubmissionData.prov_num_facilitators_trained
                            "
                            id="prov_num_facilitators_trained"
                            @input="
                              addThousandComma('prov_num_facilitators_trained')
                            "
                            type="text"
                            class="form-control"
                            :class="{
                              'invalid-input': this.formErrors
                                .prov_num_facilitators_trained.flag,
                            }"
                            required=""
                          />
                        </div>
                      </div>

                      <div
                        class="form-group row"
                        v-if="
                          this.formErrors.prov_num_facilitators_trained.flag
                        "
                      >
                        <div class="error-message">
                          {{
                            this.formErrors.prov_num_facilitators_trained.msg
                          }}
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="col-9">
                          <label for="prov_num_facilitators_obs_once"
                            >Number of SRAE facilitators observed exactly once
                          </label>
                        </div>
                        <div class="col-3 p-r-20">
                          <input
                            v-model="
                              providerSubmissionData.prov_num_facilitators_obs_once
                            "
                            id="prov_num_facilitators_obs_once"
                            @input="
                              addThousandComma('prov_num_facilitators_obs_once')
                            "
                            type="text"
                            class="form-control"
                            :class="{
                              'invalid-input': this.formErrors
                                .prov_num_facilitators_obs_once.flag,
                            }"
                            required=""
                          />
                        </div>
                      </div>

                      <div
                        class="form-group row"
                        v-if="
                          this.formErrors.prov_num_facilitators_obs_once.flag
                        "
                      >
                        <div class="error-message">
                          {{
                            this.formErrors.prov_num_facilitators_obs_once.msg
                          }}
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="col-9">
                          <label for="prov_num_facilitators_obs_2plus"
                            >Number of SRAE facilitators observed at least
                            twice</label
                          >
                        </div>
                        <div class="col-3 p-r-20">
                          <input
                            v-model="
                              providerSubmissionData.prov_num_facilitators_obs_2plus
                            "
                            id="prov_num_facilitators_obs_2plus"
                            @input="
                              addThousandComma(
                                'prov_num_facilitators_obs_2plus'
                              )
                            "
                            type="text"
                            class="form-control"
                            :class="{
                              'invalid-input': this.formErrors
                                .prov_num_facilitators_obs_2plus.flag,
                            }"
                            required=""
                          />
                        </div>
                      </div>

                      <div
                        class="form-group row"
                        v-if="
                          this.formErrors.prov_num_facilitators_obs_2plus.flag
                        "
                      >
                        <div class="error-message">
                          {{
                            this.formErrors.prov_num_facilitators_obs_2plus.msg
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row"
              v-if="
                providerSubmissionData.prov_provider_serve_youth &&
                !isWinterSeason
              "
            >
              <div class="col-lg-12">
                <div class="card card-default">
                  <div class="card-header separator">
                    <div class="card-title">
                      Provider implementation challenges
                    </div>
                  </div>
                  <fieldset class="card-body">
                    <legend>
                      Indicate how the provider assessed the implementation
                      challenges below:
                    </legend>

                    <div class="row">
                      <br />
                      <div class="col-md-12 col-lg-6">
                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_implmnt_chlng_recruit_youth"
                              >Recruiting youth</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_implmnt_chlng_recruit_youth
                              "
                              id="prov_implmnt_chlng_recruit_youth"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_implmnt_chlng_recruit_youth.flag,
                              }"
                              :options="problem"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_implmnt_chlng_youth_engaged"
                              >Youth engagement</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_implmnt_chlng_youth_engaged
                              "
                              id="prov_implmnt_chlng_youth_engaged"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_implmnt_chlng_youth_engaged.flag,
                              }"
                              :options="problem"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_implmnt_chlng_youth_attend_reg"
                              >Getting youth to attend regularly</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_implmnt_chlng_youth_attend_reg
                              "
                              id="prov_implmnt_chlng_youth_attend_reg"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_implmnt_chlng_youth_attend_reg.flag,
                              }"
                              :options="problem"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_implmnt_chlng_recruit_qual_staff"
                              >Recruiting quality staff</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_implmnt_chlng_recruit_qual_staff
                              "
                              id="prov_implmnt_chlng_recruit_qual_staff"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_implmnt_chlng_recruit_qual_staff.flag,
                              }"
                              :options="problem"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_implmnt_chlng_fcltatrs_undrstnd"
                              >Ensuring facilitators understand content</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_implmnt_chlng_fcltatrs_undrstnd
                              "
                              id="prov_implmnt_chlng_fcltatrs_undrstnd"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_implmnt_chlng_fcltatrs_undrstnd.flag,
                              }"
                              :options="problem"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_implmnt_chlng_cover_prog_content"
                              >Covering program content</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_implmnt_chlng_cover_prog_content
                              "
                              id="prov_implmnt_chlng_cover_prog_content"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_implmnt_chlng_cover_prog_content.flag,
                              }"
                              :options="problem"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_implmnt_chlng_staff_turnover"
                              >Staff turnover</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_implmnt_chlng_staff_turnover
                              "
                              id="prov_implmnt_chlng_staff_turnover"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_implmnt_chlng_staff_turnover.flag,
                              }"
                              :options="problem"
                            ></b-form-select>
                          </div>
                        </div>
                      </div>

                      <div class="colmd-12 col-lg-6">
                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_implmnt_chlng_neg_peer_reaction"
                              >Negative peer reactions</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_implmnt_chlng_neg_peer_reaction
                              "
                              id="prov_implmnt_chlng_neg_peer_reaction"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_implmnt_chlng_neg_peer_reaction.flag,
                              }"
                              :options="problem"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_implmnt_chlng_youth_bhvrl_probs"
                              >Youth behavioral problems</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_implmnt_chlng_youth_bhvrl_probs
                              "
                              id="prov_implmnt_chlng_youth_bhvrl_probs"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_implmnt_chlng_youth_bhvrl_probs.flag,
                              }"
                              :options="problem"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_implmnt_chlng_natural_disasters"
                              >Natural disasters</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_implmnt_chlng_natural_disasters
                              "
                              id="prov_implmnt_chlng_natural_disasters"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_implmnt_chlng_natural_disasters.flag,
                              }"
                              :options="problem"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_implmnt_chlng_prog_facilities"
                              >Program facilities</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_implmnt_chlng_prog_facilities
                              "
                              id="prov_implmnt_chlng_prog_facilities"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_implmnt_chlng_prog_facilities.flag,
                              }"
                              :options="problem"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_implmnt_chlng_obtaining_buy_in"
                              >Obtaining buy-in/support from key
                              stakeholders</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_implmnt_chlng_obtaining_buy_in
                              "
                              id="prov_implmnt_chlng_obtaining_buy_in"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_implmnt_chlng_obtaining_buy_in.flag,
                              }"
                              :options="problem"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_implmnt_chlng_parent_support"
                              >Parent support or engagement</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_implmnt_chlng_parent_support
                              "
                              id="prov_implmnt_chlng_parent_support"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_implmnt_chlng_parent_support.flag,
                              }"
                              :options="problem"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_implmnt_chlng_other">Other</label>
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_implmnt_chlng_other
                              "
                              id="prov_implmnt_chlng_other"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_implmnt_chlng_other.flag,
                              }"
                              :options="problem"
                            ></b-form-select>
                          </div>
                        </div>

                        <div
                          class="form-group row"
                          v-if="
                            [
                              'Somewhat a problem',
                              'A serious problem',
                            ].includes(
                              this.providerSubmissionData
                                .prov_implmnt_chlng_other
                            )
                          "
                        >
                          <div class="col-6">
                            <label for="prov_implmnt_chlng_other_spec"
                              >Please specify</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <textarea
                              v-model="
                                providerSubmissionData.prov_implmnt_chlng_other_spec
                              "
                              id="prov_implmnt_chlng_other_spec"
                              @input="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_implmnt_chlng_other_spec.flag,
                              }"
                              maxlength="200"
                              required=""
                            ></textarea>

                            <div
                              class="form-group row"
                              v-if="
                                this.formErrors.prov_implmnt_chlng_other_spec
                                  .flag
                              "
                            >
                              <div class="error-message">
                                {{
                                  this.formErrors.prov_implmnt_chlng_other_spec
                                    .msg
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form group row" v-if="this.challenge_msg">
                      <div class="error-message">
                        {{ this.challenge_msg }}
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <div
              class="row"
              v-if="
                providerSubmissionData.prov_provider_serve_youth &&
                !isWinterSeason
              "
            >
              <div class="col-lg-12">
                <div class="card card-default">
                  <div class="card-header separator">
                    <div class="card-title">Needs for technical assistance</div>
                  </div>
                  <fieldset class="card-body">
                    <legend>
                      Indicate provider interest in each type of technical
                      assistance below:
                    </legend>

                    <div class="row">
                      <div class="col-md-12 col-lg-6">
                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_tech_assist_recruit_youth"
                              >Recruiting youth</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_tech_assist_recruit_youth
                              "
                              id="prov_tech_assist_recruit_youth"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_tech_assist_recruit_youth.flag,
                              }"
                              :options="interest"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_tech_assist_youth_engaged"
                              >Youth engagement</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_tech_assist_youth_engaged
                              "
                              id="prov_tech_assist_youth_engaged"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_tech_assist_youth_engaged.flag,
                              }"
                              :options="interest"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_tech_assist_youth_attend_reg"
                              >Getting youth to attend regularly</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_tech_assist_youth_attend_reg
                              "
                              id="prov_tech_assist_youth_attend_reg"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_tech_assist_youth_attend_reg.flag,
                              }"
                              :options="interest"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_tech_assist_recruit_qual_staff"
                              >Recruiting quality staff</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_tech_assist_recruit_qual_staff
                              "
                              id="prov_tech_assist_recruit_qual_staff"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_tech_assist_recruit_qual_staff.flag,
                              }"
                              :options="interest"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_tech_assist_training_fcltatrs"
                              >Training facilitators</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_tech_assist_training_fcltatrs
                              "
                              id="prov_tech_assist_training_fcltatrs"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_tech_assist_training_fcltatrs.flag,
                              }"
                              :options="interest"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_tech_assist_retain_staff"
                              >Retaining staff</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_tech_assist_retain_staff
                              "
                              id="prov_tech_assist_retain_staff"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_tech_assist_retain_staff.flag,
                              }"
                              :options="interest"
                            ></b-form-select>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 col-lg-6">
                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_tech_assist_neg_peer_reaction"
                              >Negative peer reactions</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_tech_assist_neg_peer_reaction
                              "
                              id="prov_tech_assist_neg_peer_reaction"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_tech_assist_neg_peer_reaction.flag,
                              }"
                              :options="interest"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_tech_assist_youth_bhvrl_probs"
                              >Youth behavioral problems</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_tech_assist_youth_bhvrl_probs
                              "
                              id="prov_tech_assist_youth_bhvrl_probs"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_tech_assist_youth_bhvrl_probs.flag,
                              }"
                              :options="interest"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_tech_assist_obtaining_buy_in"
                              >Obtaining buy-in/support from key
                              stakeholders</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_tech_assist_obtaining_buy_in
                              "
                              id="prov_tech_assist_obtaining_buy_in"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_tech_assist_obtaining_buy_in.flag,
                              }"
                              :options="interest"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_tech_assist_evaluation"
                              >Evaluation</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_tech_assist_evaluation
                              "
                              id="prov_tech_assist_evaluation"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_tech_assist_evaluation.flag,
                              }"
                              :options="interest"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_tech_assist_parent_support"
                              >Parent support or engagement</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_tech_assist_parent_support
                              "
                              id="prov_tech_assist_parent_support"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_tech_assist_parent_support.flag,
                              }"
                              :options="interest"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="form group row form-dropdown">
                          <div class="col-6">
                            <label for="prov_tech_assist_other">Other</label>
                          </div>
                          <div class="col-6 p-r-20">
                            <b-form-select
                              v-model="
                                providerSubmissionData.prov_tech_assist_other
                              "
                              id="prov_tech_assist_other"
                              @change="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_tech_assist_other.flag,
                              }"
                              :options="interest"
                            ></b-form-select>
                          </div>
                        </div>

                        <div
                          class="form-group row"
                          v-if="
                            ['Somewhat interested', 'Very interested'].includes(
                              this.providerSubmissionData.prov_tech_assist_other
                            )
                          "
                        >
                          <div class="col-6">
                            <label for="prov_tech_assist_other_spec"
                              >Please specify</label
                            >
                          </div>
                          <div class="col-6 p-r-20">
                            <textarea
                              v-model="
                                providerSubmissionData.prov_tech_assist_other_spec
                              "
                              id="prov_tech_assist_other_spec"
                              @input="dataUpdated()"
                              class="form-control"
                              :class="{
                                'invalid-input': this.formErrors
                                  .prov_tech_assist_other_spec.flag,
                              }"
                              maxlength="200"
                              required=""
                            ></textarea>

                            <div
                              class="form-group row"
                              v-if="
                                this.formErrors.prov_tech_assist_other_spec.flag
                              "
                            >
                              <div class="error-message">
                                {{
                                  this.formErrors.prov_tech_assist_other_spec
                                    .msg
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form group row" v-if="this.tech_assist_msg">
                      <div class="error-message">
                        {{ this.tech_assist_msg }}
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12 text-right">
                <b-button
                  v-b-modal.confirmSubmit
                  @click="submitForm('save')"
                  class="btn btn-lg btn-primary"
                  id="prov-save-button"
                >
                  Save
                </b-button>

                <router-link
                  v-bind:to="
                    this.providerSubmissionData.prov_provider_serve_youth
                      ? { name: 'programs', params: { pid: provider.id } }
                      : { name: 'providers' }
                  "
                  class="btn btn-lg btn-primary m-r-10 m-l-15"
                  v-bind:title="this.continueMsg"
                  id="prov-continue-button"
                >
                  Continue
                </router-link>
              </div>
            </div>

            <b-modal id="confirmSubmit">
              <div v-if="errors.length || showstoppers.length">
                <p><b>Please correct the following error(s):</b></p>
                <ul>
                  <li
                    v-for="showstopper in showstoppers"
                    v-bind:key="showstopper"
                    class="error-message"
                  >
                    {{ showstopper }}
                  </li>
                  <li v-for="error in errors" v-bind:key="error">
                    {{ error }}
                  </li>
                </ul>
              </div>

              <p v-if="!showstoppers.length && !errors.length">
                <b>No issues detected</b>
                <br />
                The data on this page has been saved.
              </p>
            </b-modal>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import rules from "../../rules.js";
import mixin from "../../mixin";
import { formatProviderSubmissionData } from "../../helpers.js";

export default {
  mixins: [mixin],
  data() {
    return {
      numberFields: [
        "prov_srae_provider_funding",
        "prov_non_srae_provider_funding",
        "prov_num_staff_admin_progs",
        "prov_num_fte_admin_progs",
        "prov_num_facilitators_working",
        "prov_num_facilitators_trained",
        "prov_num_facilitators_obs_once",
        "prov_num_facilitators_obs_2plus",
        "prov_num_program_models_implemented",
      ],
      newProviderSubmissionData: {
        prov_active: null,
        prov_implmnt_chlng_recruit_youth: null,
        prov_implmnt_chlng_youth_engaged: null,
        prov_implmnt_chlng_youth_attend_reg: null,
        prov_implmnt_chlng_recruit_qual_staff: null,
        prov_implmnt_chlng_fcltatrs_undrstnd: null,
        prov_implmnt_chlng_cover_prog_content: null,
        prov_implmnt_chlng_staff_turnover: null,
        prov_implmnt_chlng_neg_peer_reaction: null,
        prov_implmnt_chlng_youth_bhvrl_probs: null,
        prov_implmnt_chlng_natural_disasters: null,
        prov_implmnt_chlng_prog_facilities: null,
        prov_implmnt_chlng_obtaining_buy_in: null,
        prov_implmnt_chlng_parent_support: null,
        prov_implmnt_chlng_other: null,
        prov_implmnt_chlng_other_spec: null,
        prov_new_provider: null,
        prov_non_srae_provider_funding: null,
        prov_num_staff_admin_progs: null,
        prov_num_fte_admin_progs: null,
        prov_num_facilitators_working: null,
        prov_num_facilitators_trained: null,
        prov_num_facilitators_obs_once: null,
        prov_num_facilitators_obs_2plus: null,
        prov_provider_serve_youth: null,
        prov_num_program_models_implemented: null,
        prov_srae_provider_funding: null,
        prov_tech_assist_recruit_youth: null,
        prov_tech_assist_youth_engaged: null,
        prov_tech_assist_youth_attend_reg: null,
        prov_tech_assist_recruit_qual_staff: null,
        prov_tech_assist_training_fcltatrs: null,
        prov_tech_assist_retain_staff: null,
        prov_tech_assist_neg_peer_reaction: null,
        prov_tech_assist_youth_bhvrl_probs: null,
        prov_tech_assist_obtaining_buy_in: null,
        prov_tech_assist_evaluation: null,
        prov_tech_assist_parent_support: null,
        prov_tech_assist_other: null,
        prov_tech_assist_other_spec: null,
      },
      formName: "provider",
      formErrors: {
        prov_srae_provider_funding: {
          type: "number",
          flag: false,
          desc: "SRAE annual award amount",
          msg: "",
          ignore: false,
        },
        prov_non_srae_provider_funding: {
          type: "number",
          flag: false,
          desc: "Amount of non-SRAE funding",
          msg: "",
          ignore: false,
        },
        prov_new_provider: {
          type: "boolean",
          flag: false,
          desc: "Provider is new",
          msg: "",
          ignore: false,
        },
        prov_provider_serve_youth: {
          type: "boolean",
          flag: false,
          desc: "Provider served youth",
          msg: "",
          ignore: false,
        },

        prov_num_program_models_implemented: {
          type: "number",
          flag: false,
          desc: "Number of program models implemented",
          msg: "",
          ignore: true,
        },
        prov_active: {
          type: "skip",
          flag: false,
          desc: "Provider is active",
          msg: "",
          ignore: false,
        },
        prov_num_staff_admin_progs: {
          type: "number",
          flag: false,
          desc:
            "Number of provider staff involved in administering SRAE programs",
          msg: "",
          ignore: true,
        },
        prov_num_fte_admin_progs: {
          type: "number",
          flag: false,
          desc:
            "Number of provider FTEs involved in administering SRAE programs",
          msg: "",
          ignore: true,
        },
        prov_num_facilitators_working: {
          type: "number",
          flag: false,
          desc: "Number of SRAE facilitators",
          msg: "",
          ignore: true,
        },
        prov_num_facilitators_trained: {
          type: "number",
          flag: false,
          desc:
            "Number of SRAE facilitators trained in delivering core curriculum",
          msg: "",
          ignore: true,
        },
        prov_num_facilitators_obs_once: {
          type: "number",
          flag: false,
          desc: "Number of SRAE facilitators observed exactly once",
          msg: "",
          ignore: true,
        },
        prov_num_facilitators_obs_2plus: {
          type: "number",
          flag: false,
          desc: "Number of SRAE facilitators observed two or more times",
          msg: "",
          ignore: true,
        },
        prov_implmnt_chlng_recruit_youth: {
          type: "categorical",
          flag: false,
          desc:
            "The provider assessed implementation challenges for recruiting youth",
          msg: "",
          ignore: true,
        },
        prov_implmnt_chlng_youth_engaged: {
          type: "categorical",
          flag: false,
          desc:
            "The provider assessed implementation challenges for keeping youth engaged",
          msg: "",
          ignore: true,
        },
        prov_implmnt_chlng_youth_attend_reg: {
          type: "categorical",
          flag: false,
          desc:
            "The provider assessed implementation challenges for getting youth to attend regularly",
          msg: "",
          ignore: true,
        },
        prov_implmnt_chlng_recruit_qual_staff: {
          type: "categorical",
          flag: false,
          desc:
            "The provider assessed implementation challenges for recruiting qualified staff",
          msg: "",
          ignore: true,
        },
        prov_implmnt_chlng_fcltatrs_undrstnd: {
          type: "categorical",
          flag: false,
          desc:
            "The provider assessed implementation challenges for ensuring facilitators understand content",
          msg: "",
          ignore: true,
        },
        prov_implmnt_chlng_cover_prog_content: {
          type: "categorical",
          flag: false,
          desc:
            "The provider assessed implementation challenges for covering program content",
          msg: "",
          ignore: true,
        },
        prov_implmnt_chlng_staff_turnover: {
          type: "categorical",
          flag: false,
          desc:
            "The provider assessed implementation challenges for staff turnover",
          msg: "",
          ignore: true,
        },
        prov_implmnt_chlng_neg_peer_reaction: {
          type: "categorical",
          flag: false,
          desc:
            "The provider assessed implementation challenges for negative peer reactions",
          msg: "",
          ignore: true,
        },
        prov_implmnt_chlng_youth_bhvrl_probs: {
          type: "categorical",
          flag: false,
          desc:
            "The provider assessed implementation challenges for behavioral problems",
          msg: "",
          ignore: true,
        },
        prov_implmnt_chlng_natural_disasters: {
          type: "categorical",
          flag: false,
          desc:
            "The provider assessed implementation challenges for natural disasters",
          msg: "",
          ignore: true,
        },
        prov_implmnt_chlng_prog_facilities: {
          type: "categorical",
          flag: false,
          desc:
            "The provider assessed implementation challenges for program facilities",
          msg: "",
          ignore: true,
        },
        prov_implmnt_chlng_obtaining_buy_in: {
          type: "categorical",
          flag: false,
          desc:
            "The provider assessed implementation challenges for obtaining buy-in or support",
          msg: "",
          ignore: true,
        },
        prov_implmnt_chlng_parent_support: {
          type: "categorical",
          flag: false,
          desc:
            "The provider assessed implementation challenges for parent support or engagement",
          msg: "",
          ignore: true,
        },
        prov_implmnt_chlng_other: {
          type: "categorical",
          flag: false,
          desc: "The provider assessed implementation challenges for other",
          msg: "",
          ignore: true,
        },
        prov_implmnt_chlng_other_spec: {
          type: "otherspec",
          flag: false,
          desc:
            "The provider assessed implementation challenges for other (specify)",
          msg: "",
          ignore: true,
        },
        prov_tech_assist_recruit_youth: {
          type: "categorical",
          flag: false,
          desc: "The provider needs technical assistance for recruiting youth",
          msg: "",
          ignore: true,
        },
        prov_tech_assist_youth_engaged: {
          type: "categorical",
          flag: false,
          desc:
            "The provider needs technical assistance for keeping youth engaged",
          msg: "",
          ignore: true,
        },
        prov_tech_assist_youth_attend_reg: {
          type: "categorical",
          flag: false,
          desc:
            "The provider needs technical assistance for getting youth to attend regularly",
          msg: "",
          ignore: true,
        },
        prov_tech_assist_recruit_qual_staff: {
          type: "categorical",
          flag: false,
          desc:
            "The provider needs technical assistance for recruiting qualified staff",
          msg: "",
          ignore: true,
        },
        prov_tech_assist_training_fcltatrs: {
          type: "categorical",
          flag: false,
          desc:
            "The provider needs technical assistance for training facilitators",
          msg: "",
          ignore: true,
        },
        prov_tech_assist_retain_staff: {
          type: "categorical",
          flag: false,
          desc: "The provider needs technical assistance for retaining staff",
          msg: "",
          ignore: true,
        },
        prov_tech_assist_neg_peer_reaction: {
          type: "categorical",
          flag: false,
          desc:
            "The provider needs technical assistance for minimizing negative peer reactions",
          msg: "",
          ignore: true,
        },
        prov_tech_assist_youth_bhvrl_probs: {
          type: "categorical",
          flag: false,
          desc:
            "The provider needs technical assistance for addressing youth behavioral issues",
          msg: "",
          ignore: true,
        },
        prov_tech_assist_obtaining_buy_in: {
          type: "categorical",
          flag: false,
          desc:
            "The provider needs technical assistance for obtaining buy-in or support",
          msg: "",
          ignore: true,
        },
        prov_tech_assist_evaluation: {
          type: "categorical",
          flag: false,
          desc: "The provider needs technical assistance for evaluation",
          msg: "",
          ignore: true,
        },
        prov_tech_assist_parent_support: {
          type: "categorical",
          flag: false,
          desc:
            "The provider needs technical assistance for parent support and engagement",
          msg: "",
          ignore: true,
        },
        prov_tech_assist_other: {
          type: "categorical",
          flag: false,
          desc: "The provider needs technical assistance for other",
          msg: "",
          ignore: true,
        },
        prov_tech_assist_other_spec: {
          type: "otherspec",
          flag: false,
          desc: "The provider needs technical assistance for other (spec)",
          msg: "",
          ignore: true,
        },
      },

      problem: [
        { value: null, text: "Please select an option" },
        { value: "Not a problem", text: "Not a problem" },
        { value: "Somewhat a problem", text: "Somewhat a problem" },
        { value: "A serious problem", text: "A serious problem" },
      ],

      interest: [
        { value: null, text: "Please select an option" },
        { value: "Not interested", text: "Not interested" },
        {
          value: "Not interested, because already received",
          text: "Not interested, because already received",
        },
        { value: "Somewhat interested", text: "Somewhat interested" },
        { value: "Very interested", text: "Very interested" },
      ],

      yn: [
        { value: null, text: "Please select an option" },
        { value: true, text: "Yes" },
        { value: false, text: "No" },
      ],

      errors: [],
      showstoppers: [],
      challenge_msg: null,
      tech_assist_msg: null,
      warnings: [],
      status_msg: "",
      saved: true,
    };
  },
  watch: {
    grantee() {
      // get grantee data
      this.getSubmissionData();
    },

    "$store.state.reportingPeriodData": function () {
      this.getSubmissionData("watch");
    },
  },
  created() {
    this.getSubmissionData();
  },
  computed: {
    isWinterSeason() {
      return this.$store.state.isWinterSeason;
    },
    gr_num_new_providers: function () {
      // gr_num_new_providers
      const data = this.$store.state.granteeSubmissionData.filter(
        (grantee) => grantee.fk_grants === this.grantee.id
      );
      if (data.length) {
        return data[0].gr_num_new_providers;
      } else {
        return -1;
      }
    },
    providerSubmissionData() {
      const id = parseInt(this.$route.params.pid);
      const data = this.$store.state.providerSubmissionData;
      const provider = data.find((provider) => provider.fk_providers === id);
      if (provider) {
        this.numberFields.forEach((field) => {
          if (
            provider[field] &&
            !["prov_num_fte_admin_progs"].includes(field)
          ) {
            provider[field] = this.initializeCommas(provider[field]);
          }
        });
        return provider;
      } else {
        return this.newProviderSubmissionData;
      }
    },

    totalFundingAmountNegative: function () {
      return rules.default.inSet(this.errors, [
        "Total funding amount cannot be negative",
      ]);
    },

    continueMsg() {
      var message = "";
      if (this.providerSubmissionData.prov_provider_serve_youth) {
        message = "Continue to program data";
      } else {
        message = "Return to provider list";
      }
      return message;
    },

    continueURL() {
      var url = "";
      if (this.providerSubmissionData.prov_provider_serve_youth) {
        url = "{ name: 'programs', params: { pid: provider.id }}";
      } else {
        url = "{ name: 'providers'}";
      }
      return url;
    },
  },
  methods: {
    getSubmissionData() {
      const self = this;

      this.$store
        .dispatch("getSubmissionData", {
          path: `/grantees/${this.grantee.id}/submissions/${this.$store.state.reportingPeriodData.pk}/reduce`,
          target: "grantee",
        })
        .then(() => {
          self.$store
            .dispatch("getSubmissionData", {
              path: `/providers/${self.$route.params.pid}/submissions/${self.$store.state.reportingPeriodData.pk}/reduce`,
              target: "provider",
            })
            .then(() => {
              self.errorCheck();
              self.saved = true;
            })
            .catch((err) =>
              console.error("getSubmissionData] provider-data error: ", err)
            );
        })
        .catch((err) =>
          console.error("[grantee] getSubmissionData error: ", err)
        );
    },

    initializeCommas(num) {
      if (num) {
        const string = num.toString();
        const number = string.replace(/,/g, "");
        const split = number.split(".");

        let commas;

        if (split.length > 1) {
          split[0] = split[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          commas = split.join(".");
        } else {
          commas = split[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        return commas;
      } else {
        return num;
      }
    },

    addThousandComma(value) {
      const target = this.providerSubmissionData[value];
      const initialLength = target.length;

      const numericOnly = target.replace(/[^0-9,]/g, "");

      const commas = this.initializeCommas(numericOnly);
      this.providerSubmissionData[value] = commas;

      if (numericOnly.length === initialLength) {
        this.dataUpdated();
      }
    },
    addThousandCommaDec(value) {
      const target = this.providerSubmissionData[value];
      const initialLength = target.length;

      const numericOnly = target.replace(/[^0-9,\.]/g, "");

      const commas = this.initializeCommas(numericOnly);
      this.providerSubmissionData[value] = commas;

      if (numericOnly.length === initialLength) {
        this.dataUpdated();
      }
    },
    removeThousandCommas(value) {
      if (isNaN(value)) {
        let number = value.replace(/,/g, "");
        return parseFloat(number);
      } else {
        return parseFloat(value);
      }
    },
    dataUpdated() {
      this.saved = false;
    },

    errorCheck() {
      // reset errors and showstoppers
      this.errors = [];
      this.showstoppers = [];

      // clear flags and messages
      const fields = Object.keys(this.formErrors);
      fields.forEach((field) => {
        this.formErrors[field].flag = false;
        this.formErrors[field].msg = "";
      });
      this.challenge_msg = null;
      this.tech_assist_msg = null;

      const serveYouth = this.providerSubmissionData.prov_provider_serve_youth;

      // separate fields by type and filter out ignored fields
      const numberFields = fields.filter((field) =>
        serveYouth
          ? this.formErrors[field].type === "number"
          : this.formErrors[field].type === "number" &&
            !this.formErrors[field].ignore
      );

      const categoricalFields = fields.filter(
        (field) => this.formErrors[field].type === "categorical" && serveYouth
      );

      const booleanFields = fields.filter(
        (field) => this.formErrors[field].type === "boolean"
      );

      // const otherFields = fields.filter(field => this.formErrors[field].type !== "number" && this.formErrors[field].type !== "categorical" && this.formErrors[field].type !== "boolean")

      const numberFields_blankCheck = numberFields;

      const blankCheck = [
        numberFields_blankCheck,
        categoricalFields,
        booleanFields,
      ];

      if (this.isWinterSeason) {
        if (
          this.providerSubmissionData["prov_provider_serve_youth"] === null ||
          this.providerSubmissionData["prov_provider_serve_youth"] === ""
        ) {
          this.formErrors["prov_provider_serve_youth"].flag = true;
          this.formErrors[
            "prov_provider_serve_youth"
          ].msg = this.errorMessages.blank;
          this.errors.push(
            this.formErrors["prov_provider_serve_youth"].desc +
              ": " +
              this.errorMessages.blank
          );
        }

        if (
          this.providerSubmissionData["prov_provider_serve_youth"] &&
          (this.providerSubmissionData[
            "prov_num_program_models_implemented"
          ] === null ||
            this.providerSubmissionData[
              "prov_num_program_models_implemented"
            ] === "")
        ) {
          (this.formErrors["prov_num_program_models_implemented"].flag = true),
            (this.formErrors[
              "prov_num_program_models_implemented"
            ].msg = this.errorMessages.blank);
          this.errors.push(
            this.formErrors["prov_num_program_models_implemented"].desc +
              ": " +
              this.errorMessages.blank
          );
        }
      } else {
        // 1. check for blanks
        blankCheck.forEach((arr) => {
          arr.forEach((field) => {
            let isDisabled = document
              .getElementById(field)
              ?.hasAttribute("disabled");

            if (
              !isDisabled &&
              (this.providerSubmissionData[field] === null ||
                this.providerSubmissionData[field] === "")
            ) {
              this.formErrors[field].flag = true;
              this.formErrors[field].msg = this.errorMessages.blank;
              this.errors.push(
                this.formErrors[field].desc + ": " + this.errorMessages.blank
              );
            }
          });
        });

        // 2. generate card-level messages for challenge/TA questions
        const challenges = fields.filter(
          (field) =>
            field.startsWith("prov_implmnt_chlng") &&
            field !== "prov_implmnt_chlng_other_spec"
        );
        const tech_assist = fields.filter(
          (field) =>
            field.startsWith("prov_tech_assist") &&
            field !== "prov_tech_assist_other"
        );
        const training = fields.filter((field) =>
          field.startsWith("prov_num_facilitators")
        );

        const cardLevelCheck = [challenges, tech_assist, training];

        if (!serveYouth) {
          cardLevelCheck.forEach((arr) => {
            arr.forEach((field) => (this.providerSubmissionData[field] = null));
          });
        } else {
          if (challenges.some((field) => this.formErrors[field].flag)) {
            this.challenge_msg =
              "Please select an option for every challenge question listed";
          }
          if (tech_assist.some((field) => this.formErrors[field].flag)) {
            this.tech_assist_msg =
              "Please select an option for every technical assistance question listed";
          }
        }

        // 3. high priority stuff...
        if (
          this.gr_num_new_providers === 0 &&
          this.providerSubmissionData.prov_new_provider === true
        ) {
          this.showstoppers.push(
            "Entry on grantee page indicates that no providers were new this period"
          );
          this.formErrors.prov_new_provider.msg =
            "Entry on grantee page indicates that no providers were new this period";
          this.formErrors.prov_new_provider.flag = true;
        }

        if (serveYouth) {
          const facilitators = this.removeThousandCommas(
            this.providerSubmissionData.prov_num_facilitators_working
          );
          const trained = this.removeThousandCommas(
            this.providerSubmissionData.prov_num_facilitators_trained
          );
          const observedOnce = this.removeThousandCommas(
            this.providerSubmissionData.prov_num_facilitators_obs_once
          );
          const observedMore = this.removeThousandCommas(
            this.providerSubmissionData.prov_num_facilitators_obs_2plus
          );

          // a. number of srae facilitators observed once and srae facilitators observed two or more times should not exceed the total number of srae facilitators.
          if (
            !isNaN(facilitators) &&
            !isNaN(observedOnce) &&
            !isNaN(observedMore)
          ) {
            if (observedOnce + observedMore > facilitators) {
              this.showstoppers.push(
                "Number of facilitators observed cannot exceed total number of facilitators"
              );
              this.formErrors.prov_num_facilitators_obs_once.flag = true;
              this.formErrors.prov_num_facilitators_obs_2plus.flag = true;
              this.formErrors.prov_num_facilitators_obs_2plus.msg =
                "Number of facilitators observed cannot exceed total number of facilitators";
            }
          }

          // b. number of srae facilitators trained should not exceed total number of facilitators
          if (!isNaN(facilitators) && !isNaN(trained)) {
            if (trained > facilitators) {
              this.showstoppers.push(
                "Number of facilitators trained cannot exceed total number of facilitators"
              );
              this.formErrors.prov_num_facilitators_trained.flag = true;
              this.formErrors.prov_num_facilitators_trained.msg =
                "Number of facilitators trained cannot exceed total number of facilitators";
            }
          }

          // c. other, specify variables should not be empty if other challenges/ta needs = somewhat or serious
          if (
            ["Somewhat a problem", "A serious problem"].includes(
              this.providerSubmissionData.prov_implmnt_chlng_other
            ) &&
            !this.providerSubmissionData.prov_implmnt_chlng_other_spec
          ) {
            this.formErrors.prov_implmnt_chlng_other_spec.msg = this.errorMessages.blank;
            this.formErrors.prov_implmnt_chlng_other_spec.flag = true;
            this.errors.push(
              this.formErrors.prov_implmnt_chlng_other_spec.desc +
                ": " +
                this.errorMessages.blank
            );
          }

          if (
            ["Somewhat interested", "Very interested"].includes(
              this.providerSubmissionData.prov_tech_assist_other
            ) &&
            !this.providerSubmissionData.prov_tech_assist_other_spec
          ) {
            this.formErrors.prov_tech_assist_other_spec.msg = this.errorMessages.blank;
            this.formErrors.prov_tech_assist_other_spec.flag = true;
            this.errors.push(
              this.formErrors.prov_tech_assist_other_spec.desc +
                ": " +
                this.errorMessages.blank
            );
          }

          if (
            this.removeThousandCommas(
              this.providerSubmissionData.prov_num_staff_admin_progs
            ) <
            this.removeThousandCommas(
              this.providerSubmissionData.prov_num_fte_admin_progs
            )
          ) {
            this.formErrors.prov_num_fte_admin_progs.flag = true;
            this.formErrors.prov_num_fte_admin_progs.msg =
              "FTEs cannot exceed number of staff";
            this.showstoppers.push("FTEs cannot exceed number of staff");
          }
        }
      }

      if (this.errors || this.showstoppers) {
        console.error("Validation errors:");
        console.error(this.errors);
        console.error(this.showstoppers);
      }
    },

    submitForm(_action) {
      const submitPath = `/grantees/${this.$store.state.granteeId}/providers/${this.$route.params.pid}/submissions/${this.$store.state.reportingPeriodData.pk}`;
      const getSubmissionPath = `/providers/${this.$route.params.pid}/submissions/${this.$store.state.reportingPeriodData.pk}/reduce`;

      this.errorCheck();
      let data = formatProviderSubmissionData(
        this.grantee.name,
        this.provider.name,
        this.providerSubmissionData,
        this.$store.state.reportingPeriodData,
        this.errors,
        this.showstoppers
      );

      this.$store
        .dispatch("submitForm", {
          action: _action,
          path: submitPath,
          getSubmissionPath,
          data,
          target: "provider",
        })
        .then((response) => {
          this.saved = true;
          this.statusMsg = "Data saved" + response;
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.saved) {
      const answer = window.confirm(
        "The page contains data that have not been saved. Are you sure you want to leave the page without saving?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>
