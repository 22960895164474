<template>
  <upload-base surveyType="entry" />
</template>

<script>
import uploadBase from "./upload-base.vue";

export default {
  name: "upload-entry",
  components: {
    uploadBase,
  },
};
</script>
