<template>
  <div :key="user.slotName" class="user-row-card">
    <div
      class="ua-col d-flex flex-column"
      v-if="
        selectedAssociation &&
        (grantee.role == 'Grantee' || grantee.role == 'Admin')
      "
    >
      <h5>Site Access</h5>
      <div class="m-l-15 d-flex flex-column">
        <label>
          <input
            type="radio"
            :name="`site-access-${selectedAssociation.pk}`"
            :checked="
              selectedAssociation.has_portal_access &&
              !selectedAssociation.has_dashboard_access
            "
            @click="setSiteAccess(user, selectedAssociation, true, false)"
          />
          Portal
        </label>
        <label>
          <input
            type="radio"
            :name="`site-access-${selectedAssociation.pk}`"
            :checked="
              !selectedAssociation.has_portal_access &&
              selectedAssociation.has_dashboard_access
            "
            @click="setSiteAccess(user, selectedAssociation, false, true)"
          />
          Dashboard
        </label>
        <label>
          <input
            type="radio"
            :name="`site-access-${selectedAssociation.pk}`"
            :checked="
              selectedAssociation.has_portal_access &&
              selectedAssociation.has_dashboard_access
            "
            @click="setSiteAccess(user, selectedAssociation, true, true)"
          />
          Dashboard and Portal
        </label>
      </div>
    </div>
    <div class="ua-col">
      <h5>Associations</h5>
      <div
        v-for="association in user.associations"
        class="association-label m-l-10 d-flex justify-content-between"
        :key="user.slotName + association.pk"
      >
        <p>
          <strong>{{ user.role }}</strong> at
          <strong>{{ association.name }}</strong>
        </p>
        <b-button
          @click.stop="confirmDelete(user, association)"
          v-b-tooltip.hover
          :title="`Delete association with ${association.name}`"
          class="btn btn-danger m-l-10"
        >
          <i class="pg-icon">trash_alt</i>
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "UserAssociations",
  data() {
    return {
      // selectedAssociation: {},
    };
  },
  computed: {
    selectedAssociation: function () {
      return this.user.associations[0];
    },
    siteAccess: function () {
      if (
        this.selectedAssociation.has_dashboard_access &&
        this.selectedAssociation.has_portal_access
      ) {
        return "both";
      } else if (
        !this.selectedAssociation.has_dashboard_access &&
        this.selectedAssociation.has_portal_access
      ) {
        return "portal";
      } else if (
        this.selectedAssociation.has_dashboard_access &&
        !this.selectedAssociation.has_portal_access
      ) {
        return "dashboard";
      }
      return "";
    },
  },
  props: ["user", "confirmDelete", "setSiteAccess", "grantee"],
};
</script>
<style lang="scss">
.association-label {
  padding: 4px;
  cursor: pointer;
  align-items: center;

  p {
    margin: 0;
    font-size: 16px;
  }

  &.selected {
    background: #f3f3fa;
  }
}
.user-row-card {
  /*background: #f3f3f4;*/
  display: flex;
  /*border-radius: 8px;*/
  margin: 0 8px;

  border-left: 8px solid #c29ed8;

  .ua-col {
    min-width: 20%;
    margin-right: 16px;

    label {
      font-size: 16px;
    }
  }

  .card-body {
    padding: 12px;
  }

  h5 {
    color: #6a338a;
    font-size: 18px;
    margin: 0;

    padding: 12px;
  }
}
</style>
