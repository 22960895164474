<template>
  <div class="row">
    <div class="col-lg-12">
      <div v-if="errors.length || showstoppers.length">
        <p><b>Please correct the following error(s):</b></p>
        <ul>
          <div class="error-dark">
            <li v-for="showstopper in showstoppers" v-bind:key="showstopper">
              {{ showstopper }}
            </li>
          </div>
          <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
        </ul>
      </div>

      <b-form role="form" novalidate>
        <div class="col-lg-12">
          <div class="card card-default">
            <!--<div class="card-header separator">-->
            <!--  <div class="card-title">Provider status</div>-->
            <!--</div>-->
            <div class="card-body">
              <div class="form-group row">
                <div class="col-8">
                  <label for="prog_any_youth_begin"
                    >Did any youth begin this program between January 1, 2024
                    and June 30, 2024?</label
                  >
                </div>

                <div class="col-4">
                  <div>
                    <b-form-select
                      id="prog_any_youth_begin"
                      v-bind:class="
                        this.formData.input.prog_any_youth_begin.fmt
                      "
                      @change="dataUpdated()"
                      v-model="formData.input.prog_any_youth_begin.value"
                      :options="yn"
                    >
                    </b-form-select>
                  </div>
                </div>
                <div
                  class="form-group row"
                  v-if="this.formData.input.prog_any_youth_begin.flag"
                >
                  <div class="error-dark">
                    {{ this.formData.input.prog_any_youth_begin.msg }}
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-8">
                  <label for="prog_any_youth_complete"
                    >Did any youth complete this program between January 1, 2024
                    and June 30, 2024?</label
                  >
                </div>

                <div class="col-4">
                  <div>
                    <b-form-select
                      id="prog_any_youth_complete"
                      v-bind:class="
                        this.formData.input.prog_any_youth_complete.fmt
                      "
                      @change="dataUpdated()"
                      v-model="formData.input.prog_any_youth_complete.value"
                      :options="yn"
                    >
                    </b-form-select>
                  </div>
                </div>
                <div
                  class="form-group row"
                  v-if="this.formData.input.prog_any_youth_complete.flag"
                >
                  <div class="error-dark">
                    {{ this.formData.input.prog_any_youth_complete.msg }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="formData.input.prog_any_youth_complete.value">
          <div class="col-lg-6">
            <div class="card card-default">
              <div class="card-header separator">
                <div class="card-title">Program reach</div>
              </div>
              <div class="card-body form-group">
                <label align="left"
                  >Enter the number of youth during the reporting period who
                  attended at least one program session:
                  <b-button
                    v-b-tooltip.hover
                    id="cohort_complete_edit"
                    title="For these items, report the numbers of youth attending at least one session in any setting. Separate items below will capture information on program settings."
                    size="sm"
                    >?</b-button
                  ></label
                >
                <div class="table-responsive">
                  <div
                    id="condensedTable_wrapper"
                    class="dataTables_wrapper no-footer"
                  >
                    <table
                      class="table table-hover table-condensed dataTable no-footer"
                      id="condensedTable"
                      role="grid"
                    >
                      <thead>
                        <tr role="row">
                          <th rowspan="1" colspan="1">Reach</th>
                          <th rowspan="1" colspan="1" class="text-center">
                            Enter count
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr role="row" class="odd-even">
                          <td class="v-align-middle text-left program_reach_td">
                            Attended at least one program session
                          </td>
                          <td class="v-align-middle semi-bold form-group">
                            <label for="prog_reach_attend" class="label-hidden"
                              >Total attending at least one program
                              session</label
                            >
                            <input
                              v-model="formData.input.prog_reach_attend.value"
                              id="prog_reach_attend"
                              @input="addThousandComma('prog_reach_attend')"
                              type="text"
                              min="0"
                              v-bind:class="
                                this.formData.input.prog_reach_attend.fmt
                              "
                              required=""
                            />
                          </td>
                        </tr>

                        <tr role="row" class="odd-even">
                          <td
                            class="v-align-middle text-left program_reach_td"
                            style="padding-left: 10%"
                          >
                            Number of middle school age participants
                          </td>
                          <td class="v-align-middle semi-bold form-group">
                            <label
                              for="prog_reach_attend_ms"
                              class="label-hidden"
                              >Total number of middle school participants
                              attending</label
                            >
                            <input
                              v-model="
                                formData.input.prog_reach_attend_ms.value
                              "
                              id="prog_reach_attend_ms"
                              @input="addThousandComma('prog_reach_attend_ms')"
                              type="text"
                              min="0"
                              v-bind:class="
                                this.formData.input.prog_reach_attend_ms.fmt
                              "
                              required=""
                            />
                          </td>
                        </tr>

                        <tr role="row" class="odd-even">
                          <td
                            class="v-align-middle text-left program_reach_td"
                            style="padding-left: 10%"
                          >
                            Number of high school age and older participants
                          </td>
                          <td class="v-align-middle semi-bold form-group">
                            <label
                              for="prog_reach_attend_hs"
                              class="label-hidden"
                              >Total number of high school participants who
                              attended</label
                            >
                            <input
                              v-model="
                                formData.input.prog_reach_attend_hs.value
                              "
                              id="prog_reach_attend_hs"
                              @input="addThousandComma('prog_reach_attend_hs')"
                              type="text"
                              min="0"
                              v-bind:class="
                                this.formData.input.prog_reach_attend_hs.fmt
                              "
                              required=""
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row error-dark" v-if="reachIndMsg != ''">
                    {{ reachIndMsg }}
                  </div>
                  <div class="row error-dark" v-if="reachTotalIndMsg != ''">
                    {{ reachTotalIndMsg }}
                  </div>
                  <div class="row error-dark" v-if="reachBeforeIndMsg != ''">
                    {{ reachBeforeIndMsg }}
                  </div>
                  <div class="row error-dark" v-if="reachAfterIndMsg != ''">
                    {{ reachAfterIndMsg }}
                  </div>
                  <div class="row error-dark" v-if="reachTotalColMsg != ''">
                    {{ reachTotalColMsg }}
                  </div>
                  <div class="row error-dark" v-if="reachBeforeColMsg != ''">
                    {{ reachBeforeColMsg }}
                  </div>
                  <div class="row error-dark" v-if="reachAfterColMsg != ''">
                    {{ reachAfterColMsg }}
                  </div>
                  <div class="row error-dark" v-if="reachRowMsg != ''">
                    {{ reachRowMsg }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card card-default">
              <div class="card-header separator">
                <div class="card-title">Program attendance/dosage</div>
              </div>
              <div class="card-body form-group">
                <div class="row" align="right">
                  <div class="col-lg-8 form-group">
                    <label for="prog_dosage_75_pct_complete"
                      >Enter the number of youth during the reporting period who
                      completed at least 75 percent of the scheduled program
                      hours</label
                    >
                  </div>
                  <div class="col-lg-4 form-group">
                    <input
                      v-model="formData.input.prog_dosage_75_pct_complete.value"
                      id="prog_dosage_75_pct_complete"
                      @input="addThousandComma('prog_dosage_75_pct_complete')"
                      type="text"
                      min="0"
                      v-bind:class="
                        this.formData.input.prog_dosage_75_pct_complete.fmt
                      "
                      required=""
                    />
                  </div>
                  <div
                    class="form-group row"
                    v-if="this.formData.input.prog_dosage_75_pct_complete.flag"
                    align="right"
                  >
                    <div class="error-dark">
                      {{ this.formData.input.prog_dosage_75_pct_complete.msg }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="formData.input.prog_any_youth_complete.value">
          <div class="col-lg-12">
            <div class="card card-default">
              <div class="card-header separator">
                <div class="card-title">Program setting</div>
              </div>
              <div class="card-body form-group">
                <div class="row">
                  <label align="left"
                    >Enter the number of youth during the reporting period who
                    attended a session:</label
                  >
                </div>
                <div class="row">
                  <div class="col-lg-6 table-responsive">
                    <div
                      id="condensedTable_wrapper"
                      class="dataTables_wrapper no-footer"
                    >
                      <table
                        class="table table-hover table-condensed dataTable no-footer"
                        style="word-wrap: break-word; color: red"
                        id="condensedTable"
                        role="grid"
                      >
                        <thead>
                          <tr role="row">
                            <th rowspan="1" colspan="1">Program Setting</th>
                            <th rowspan="1" colspan="1" class="text-center">
                              Enter count
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr role="row" class="odd-even">
                            <td
                              class="v-align-middle text-left program_reach_td"
                            >
                              In school during school
                            </td>
                            <td class="v-align-middle semi-bold form-group">
                              <label
                                for="prog_set_attend_dur_school"
                                class="label-hidden"
                                >Total number of students who attended during
                                school hours</label
                              >
                              <input
                                v-model="
                                  formData.input.prog_set_attend_dur_school
                                    .value
                                "
                                id="prog_set_attend_dur_school"
                                @input="
                                  addThousandComma('prog_set_attend_dur_school')
                                "
                                type="text"
                                min="0"
                                v-bind:class="
                                  this.formData.input.prog_set_attend_dur_school
                                    .fmt
                                "
                                required=""
                              />
                            </td>
                          </tr>

                          <tr role="row" class="odd-even">
                            <td
                              class="v-align-middle text-left program_reach_td"
                            >
                              In school after school
                            </td>
                            <td class="v-align-middle semi-bold form-group">
                              <label
                                for="prog_set_attend_after_school"
                                class="label-hidden"
                                >Total number of students who attended after
                                school hours</label
                              >
                              <input
                                v-model="
                                  formData.input.prog_set_attend_after_school
                                    .value
                                "
                                id="prog_set_attend_after_school"
                                @input="
                                  addThousandComma(
                                    'prog_set_attend_after_school'
                                  )
                                "
                                type="text"
                                min="0"
                                v-bind:class="
                                  this.formData.input
                                    .prog_set_attend_after_school.fmt
                                "
                                required=""
                              />
                            </td>
                          </tr>

                          <tr role="row" class="odd-even">
                            <td
                              class="v-align-middle text-left program_reach_td"
                            >
                              In a community-based organization
                            </td>
                            <td class="v-align-middle semi-bold form-group">
                              <label
                                for="prog_set_attend_cbo"
                                class="label-hidden"
                                >Total number of students who attended in a
                                community-based organization</label
                              >
                              <input
                                v-model="
                                  formData.input.prog_set_attend_cbo.value
                                "
                                id="prog_set_attend_cbo"
                                @input="addThousandComma('prog_set_attend_cbo')"
                                type="text"
                                min="0"
                                v-bind:class="
                                  this.formData.input.prog_set_attend_cbo.fmt
                                "
                                required=""
                              />
                            </td>
                          </tr>

                          <tr role="row" class="odd-even">
                            <td
                              class="v-align-middle text-left program_reach_td"
                            >
                              In a clinic
                            </td>
                            <td class="v-align-middle semi-bold form-group">
                              <label
                                for="prog_set_attend_clinic"
                                class="label-hidden"
                                >Total number of students who attended in a
                                clinic</label
                              >
                              <input
                                v-model="
                                  formData.input.prog_set_attend_clinic.value
                                "
                                id="prog_set_attend_clinic"
                                @input="
                                  addThousandComma('prog_set_attend_clinic')
                                "
                                type="text"
                                min="0"
                                v-bind:class="
                                  this.formData.input.prog_set_attend_clinic.fmt
                                "
                                required=""
                              />
                            </td>
                          </tr>

                          <tr role="row" class="odd-even">
                            <td
                              class="v-align-middle text-left program_reach_td"
                            >
                              In a foster care setting
                            </td>
                            <td class="v-align-middle semi-bold form-group">
                              <label
                                for="prog_set_attend_foster_care"
                                class="label-hidden"
                                >Total number of students who attended in a
                                foster care setting</label
                              >
                              <input
                                v-model="
                                  formData.input.prog_set_attend_foster_care
                                    .value
                                "
                                id="prog_set_attend_foster_care"
                                @input="
                                  addThousandComma(
                                    'prog_set_attend_foster_care'
                                  )
                                "
                                type="text"
                                min="0"
                                v-bind:class="
                                  this.formData.input
                                    .prog_set_attend_foster_care.fmt
                                "
                                required=""
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-lg-6 table-responsive">
                    <div
                      id="condensedTable_wrapper"
                      class="dataTables_wrapper no-footer"
                    >
                      <table
                        class="table table-hover table-condensed dataTable no-footer"
                        style="word-wrap: break-word; color: red"
                        id="condensedTable"
                        role="grid"
                      >
                        <thead>
                          <tr role="row">
                            <th rowspan="1" colspan="1">Program Setting</th>
                            <th rowspan="1" colspan="1" class="text-center">
                              Enter count
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr role="row" class="odd-even">
                            <td
                              class="v-align-middle text-left program_reach_td"
                            >
                              In a juvenile detention center
                            </td>
                            <td class="v-align-middle semi-bold form-group">
                              <label
                                for="prog_set_attend_jdc"
                                class="label-hidden"
                                >Total number of students who attended in a
                                juvenile detention center</label
                              >
                              <input
                                v-model="
                                  formData.input.prog_set_attend_jdc.value
                                "
                                id="prog_set_attend_jdc"
                                @input="addThousandComma('prog_set_attend_jdc')"
                                type="text"
                                min="0"
                                v-bind:class="
                                  this.formData.input.prog_set_attend_jdc.fmt
                                "
                                required=""
                              />
                            </td>
                          </tr>

                          <tr role="row" class="odd-even">
                            <td
                              class="v-align-middle text-left program_reach_td"
                            >
                              In a residential mental health treatment facility
                            </td>
                            <td class="v-align-middle semi-bold form-group">
                              <label
                                for="prog_set_attend_mh_facility"
                                class="label-hidden"
                                >Total number of students who attended in a
                                residential mental health treatment
                                facility</label
                              >
                              <input
                                v-model="
                                  formData.input.prog_set_attend_mh_facility
                                    .value
                                "
                                id="prog_set_attend_mh_facility"
                                @input="
                                  addThousandComma(
                                    'prog_set_attend_mh_facility'
                                  )
                                "
                                type="text"
                                min="0"
                                v-bind:class="
                                  this.formData.input
                                    .prog_set_attend_mh_facility.fmt
                                "
                                required=""
                              />
                            </td>
                          </tr>

                          <tr role="row" class="odd-even">
                            <td
                              class="v-align-middle text-left program_reach_td"
                            >
                              Virtually
                              <b-button
                                v-b-tooltip.hover
                                id="online_info"
                                title="A virtual session includes any programming that is facilitated virtually rather than by an in-person facilitator, regardless of the physical setting where participants are located."
                                size="sm"
                                >?</b-button
                              >
                            </td>
                            <td class="v-align-middle semi-bold form-group">
                              <label
                                for="prog_set_attend_online"
                                class="label-hidden"
                                >Total number of students who attended
                                virtually</label
                              >
                              <input
                                v-model="
                                  formData.input.prog_set_attend_online.value
                                "
                                id="prog_set_attend_online"
                                @input="
                                  addThousandComma('prog_set_attend_online')
                                "
                                type="text"
                                min="0"
                                v-bind:class="
                                  this.formData.input.prog_set_attend_online.fmt
                                "
                                required=""
                              />
                            </td>
                          </tr>

                          <tr role="row" class="odd-even">
                            <td
                              class="v-align-middle text-left program_reach_td"
                            >
                              In another setting
                            </td>
                            <td class="v-align-middle semi-bold form-group">
                              <label
                                for="prog_set_attend_other"
                                class="label-hidden"
                                >Total number of students who attended in
                                another setting</label
                              >
                              <input
                                v-model="
                                  formData.input.prog_set_attend_other.value
                                "
                                id="prog_set_attend_other"
                                @input="
                                  addThousandComma('prog_set_attend_other')
                                "
                                type="text"
                                min="0"
                                v-bind:class="
                                  this.formData.input.prog_set_attend_other.fmt
                                "
                                required=""
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row error-dark" v-if="settingIndMsg != ''">
                    {{ settingIndMsg }}
                  </div>
                  <div class="row error-dark" v-if="settingTotalIndMsg != ''">
                    {{ settingTotalIndMsg }}
                  </div>
                  <div class="row error-dark" v-if="settingBeforeIndMsg != ''">
                    {{ settingBeforeIndMsg }}
                  </div>
                  <div class="row error-dark" v-if="settingAfterIndMsg != ''">
                    {{ settingAfterIndMsg }}
                  </div>
                  <div class="row error-dark" v-if="settingTotalColMsg != ''">
                    {{ settingTotalColMsg }}
                  </div>
                  <div class="row error-dark" v-if="settingBeforeColMsg != ''">
                    {{ settingBeforeColMsg }}
                  </div>
                  <div class="row error-dark" v-if="settingAfterColMsg != ''">
                    {{ settingAfterColMsg }}
                  </div>
                  <div class="row error-dark" v-if="settingRowMsg != ''">
                    {{ settingRowMsg }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="formData.input.prog_any_youth_complete.value">
          <div class="col-lg-12">
            <div class="card card-default">
              <div class="card-header separator">
                <div class="card-title">Majority population</div>
              </div>
              <fieldset class="card-body form-group">
                <legend>
                  Indicate whether more than 50 percent of youth attending the
                  program were:
                </legend>

                <div class="row">
                  <div class="col-lg-6">
                    <div>
                      <b-form-checkbox
                        id="prog_attend_50_pct_foster"
                        v-model="formData.input.prog_attend_50_pct_foster.value"
                        @change="dataUpdated()"
                        name="formData.input.prog_attend_50_pct"
                      >
                        In foster care</b-form-checkbox
                      >
                    </div>
                    <div>
                      <b-form-checkbox
                        id="prog_attend_50_pct_pregnant"
                        v-model="
                          formData.input.prog_attend_50_pct_pregnant.value
                        "
                        @change="dataUpdated()"
                        name="formData.input.prog_attend_50_pct"
                      >
                        Pregnant or parenting</b-form-checkbox
                      >
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div>
                      <b-form-checkbox
                        id="prog_attend_50_pct_homeless"
                        v-model="
                          formData.input.prog_attend_50_pct_homeless.value
                        "
                        @change="dataUpdated()"
                        name="formData.input.prog_attend_50_pct"
                      >
                        Homeless or runaway</b-form-checkbox
                      >
                    </div>
                    <div>
                      <b-form-checkbox
                        id="prog_attend_50_pct_adjuc_systems"
                        v-model="
                          formData.input.prog_attend_50_pct_adjuc_systems.value
                        "
                        @change="dataUpdated()"
                        name="formData.input.prog_attend_50_pct"
                      >
                        In adjudication systems</b-form-checkbox
                      >
                    </div>
                    <div>
                      <b-form-checkbox
                        id="prog_attend_50_pct_lgbtq_youth"
                        v-model="
                          formData.input.prog_attend_50_pct_lgbtq_youth.value
                        "
                        @change="dataUpdated()"
                        name="formData.input.prog_attend_50_pct"
                      >
                        LGBTQ youth</b-form-checkbox
                      >
                    </div>
                    <div>
                      <b-form-checkbox
                        id="prog_attend_50_pct_none_above"
                        v-model="
                          formData.input.prog_attend_50_pct_none_above.value
                        "
                        @change="dataUpdated()"
                        name="formData.input.prog_attend_50_pct"
                      >
                        None of the above</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
                <div class="row form-group">
                  <div
                    class="error-dark"
                    v-if="
                      this.formData.input.prog_attend_50_pct_none_above.flag
                    "
                  >
                    {{ this.formData.input.prog_attend_50_pct_none_above.msg }}
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>

        <div class="row" v-if="formData.input.prog_any_youth_complete.value">
          <div class="col-lg-12">
            <div class="card card-default">
              <div class="card-header separator">
                <div class="card-title">Parent involvement</div>
              </div>
              <div class="card-body form-group">
                <div class="form-group">
                  <div class="row">
                    <div class="col-lg-9" align="right">
                      <label for="prog_par_involve_attend_one_sess"
                        >Enter the number of youths’ parents and other caring
                        adults who attended at least one program session during
                        the reporting period
                        <b-button
                          v-b-tooltip.hover
                          id="cohort_complete_edit"
                          title="Enter 0 if no youths’ parents or other caring adults participated in any SRAE sessions."
                          size="sm"
                          >?</b-button
                        ></label
                      >
                    </div>
                    <div class="col-lg-3">
                      <input
                        v-model="
                          formData.input.prog_par_involve_attend_one_sess.value
                        "
                        id="prog_par_involve_attend_one_sess"
                        @input="
                          addThousandComma('prog_par_involve_attend_one_sess')
                        "
                        type="text"
                        min="0"
                        v-bind:class="
                          this.formData.input.prog_par_involve_attend_one_sess
                            .fmt
                        "
                        required=""
                      />
                    </div>
                    <div
                      class="form-group row"
                      v-if="
                        this.formData.input.prog_par_involve_attend_one_sess
                          .flag
                      "
                    >
                      <div class="error-dark">
                        {{
                          this.formData.input.prog_par_involve_attend_one_sess
                            .msg
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="formData.input.prog_any_youth_complete.value">
          <div class="col-12 no-padding">
            <div class="card card-transparent">
              <div class="card-header separator">
                <div class="card-title">
                  Cohorts
                  <b-button
                    style="margin-bottom: 3%"
                    v-b-tooltip.hover
                    title="A cohort, in this context, represents a group of youth that all jointly receive a defined SRAE program, which could include a specific curriculum and any additional hours of programming added to meet all requirements for the SRAE program. If a provider is delivering an SRAE program to multiple groups of youth simultaneously, but these groups meet separately, each group of youth should be considered a separate cohort. A cohort could consist of an individual, if programming is delivered one-on-one."
                    size="sm"
                    >?
                  </b-button>
                </div>
              </div>
              <div class="card-body">
                <label align="left"
                  >Provide the number of hours of programming delivered for each
                  cohort of youth that ended programming during the
                  {{ $store.state.reportingPeriodStr.prog_reach }} reporting
                  period:
                </label>
                <div class="row p-t-5">
                  <div class="pull-left">
                    <b-button
                      v-b-modal.addCohort
                      id="add_cohort_button"
                      class="btn btn-lg btn-block btn-primary btn-icon-left pull-left"
                    >
                      <i class="pg-icon">add</i> Add Cohort
                    </b-button>
                  </div>
                </div>
                <div class="table-responsive">
                  <div
                    id="condensedTable_wrapper"
                    class="dataTables_wrapper no-footer"
                  >
                    <table
                      class="table table-hover table-condensed dataTable no-footer"
                      id="condensedTable"
                      role="grid"
                    >
                      <thead>
                        <tr role="row">
                          <th
                            rowspan="1"
                            colspan="1"
                            class="text-left program_reach_td"
                          >
                            Cohort Name
                          </th>
                          <th
                            rowspan="1"
                            colspan="1"
                            class="text-center program_reach_td"
                          >
                            Number of hours delivered
                          </th>
                          <th
                            rowspan="1"
                            colspan="1"
                            class="text-center program_reach_td"
                          >
                            Edit
                          </th>
                          <th
                            rowspan="1"
                            colspan="1"
                            class="text-center program_reach_td"
                          >
                            Delete
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="cohort in this.formData.input.cohorts.value"
                          :key="cohort.name"
                          role="row"
                          class="odd-even"
                        >
                          <td class="v-align-middle semi-bold program_reach_td">
                            {{ cohort.cohort_name }}
                          </td>
                          <td
                            class="v-align-middle text-center program_reach_td"
                          >
                            {{
                              (cohort.cohort_hours &&
                                parseFloat(cohort.cohort_hours).toFixed(2)) ||
                              `Please enter number.`
                            }}
                          </td>
                          <td class="v-align-middle text-center">
                            <i
                              v-b-modal.editCohort
                              id="edit_cohort_button"
                              @click="current_index = cohort.cohort_index"
                              class="material-icons"
                              >create</i
                            >
                          </td>
                          <td class="v-align-middle text-center">
                            <i
                              v-b-modal.dropCohort
                              id="drop_cohort_button"
                              @click="current_index = cohort.cohort_index"
                              class="material-icons"
                              >clear</i
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row error-dark" v-if="cohortBlankMsg != ''">
                    {{ cohortBlankMsg }}
                  </div>
                  <div class="row error-dark" v-if="cohortNegMsg != ''">
                    {{ cohortNegMsg }}
                  </div>
                  <div class="row error-dark" v-if="cohortYYmsg != ''">
                    {{ cohortYYmsg }}
                  </div>
                  <div class="row error-dark" v-if="cohortNNmsg != ''">
                    {{ cohortNNmsg }}
                  </div>
                  <div class="row error-dark" v-if="cohortNumLargeMsg != ''">
                    {{ cohortNumLargeMsg }}
                  </div>
                  <div class="row error-dark" v-if="cohortOnlineMsg != ''">
                    {{ cohortOnlineMsg }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <b-modal
          id="dropCohort"
          @ok="
            dropCohort();
            dataUpdated();
          "
          title="Remove Cohort"
        >
          <p>Press OK to delete this entry</p>
        </b-modal>

        <!--The modal -->
        <b-modal
          id="addCohort"
          @ok="
            cohortPush();
            dataUpdated();
          "
          size="lg"
          title="Add Cohort"
        >
          <div class="row form-group">
            <div class="col-lg-6" style="padding-left: 5%">
              <label for="newCohortEntryHours_add"
                >How many hours of programming, overall, were delivered to the
                cohort?
                <b-button
                  v-b-tooltip.hover
                  id="cohort_hours_add"
                  title="The number of program hours delivered should include the hours for all defined SRAE programming, which could include a specific curriculum plus any additional hours of programming added to meet SRAE goals."
                  size="sm"
                  >?</b-button
                ></label
              >
            </div>
            <div class="col-lg-5">
              <div class="row">
                <input
                  v-model="newCohortEntryHours"
                  id="newCohortEntryHours_add"
                  type="number"
                  min="0"
                  v-bind:class="this.formData.input.cohorts.fmt"
                  required=""
                />
              </div>
              <div
                class="row"
                style="color: red"
                v-if="
                  newCohortEntryHours > 40 &&
                  (newCohortEntryHours <= parseFloat(num_delivery_hours) || num_delivery_hours == null)
                "
              >
                Please confirm that you have entered the number of hours of SRAE
                programming delivered to the cohort. Do not multiply the number
                of hours delivered by the number of youth participating.
              </div>

              <div
                class="row"
                style="color: red"
                v-if="newCohortEntryHours > parseFloat(num_delivery_hours)"
              >
                The number of hours you have entered for this cohort is larger
                than the intended number of hours you reported on the
                program-level data page. Please confirm.
              </div>
            </div>
          </div>
        </b-modal>

        <!--The modal -->
        <b-modal
          size="xl"
          id="editCohort"
          @ok="
            cohortEdit();
            dataUpdated();
          "
          title="Edit Cohort"
        >
          <div class="row form-group">
            <div class="col-lg-6" style="padding-left: 5%">
              <label for="newCohortEntryHours_add"
                >How many hours of programming, overall, were delivered to the
                cohort?
                <b-button
                  v-b-tooltip.hover
                  id="cohort_hours_edit"
                  title="The number of program hours delivered should include the hours for all defined SRAE programming, which could include a specific curriculum plus any additional hours of programming added to meet SRAE goals."
                  size="sm"
                  >?</b-button
                ></label
              >
            </div>
            <div class="col-lg-5">
              <div class="row">
                <input
                  v-model="newCohortEntryHours"
                  id="newCohortEntryHours_add"
                  type="number"
                  min="0"
                  v-bind:class="this.formData.input.cohorts.fmt"
                  required=""
                />
              </div>
              <div
                class="row"
                style="color: red"
                v-if="
                  newCohortEntryHours > 40 &&
                  (newCohortEntryHours <= parseFloat(num_delivery_hours) || num_delivery_hours == null)
                "
              >
                Please confirm that you have entered the number of hours of SRAE
                programming delivered to the cohort. Do not multiply the number
                of hours delivered by the number of youth participating.
              </div>

              <div
                class="row"
                style="color: red"
                v-if="
                  newCohortEntryHours > parseFloat(num_delivery_hours)
                "
              >
                The number of hours you have entered for this cohort is larger
                than the intended number of hours you reported on the
                program-level data page. Please confirm.
              </div>
            </div>
          </div>
        </b-modal>

        <div>
          <div class="row">
            <div class="col-lg-12 text-right">
              <!--Using modifiers -->
              <router-link
                id="previous_page_button"
                :to="{
                  name: 'program-data',
                  params: { pmid: $route.params.pmid },
                }"
                class="btn btn-lg btn-primary m-r-10"
                title="Previous"
              >
                <span>Previous</span>
              </router-link>
              <b-button
                v-b-modal.confirmSubmit
                id="save_program_reach_button"
                @click="checkForm('submit', $event)"
                class="btn btn-lg btn-primary m-r-10"
              >
                Save
              </b-button>

              <router-link
                v-if="
                  this.formData.input.prog_any_youth_begin.value ||
                  this.formData.input.prog_any_youth_complete.value
                "
                id="next_page_button"
                :to="{
                  name: 'program-entry-exit',
                  params: { pid: $route.params.pid, pmid: $route.params.pmid },
                }"
                class="btn btn-lg btn-primary m-r-10"
                title="Continue to Info About Survey Admin"
              >
                <span>Continue to Info About Survey Admin</span>
              </router-link>
              <router-link
                v-else
                id="return_to_program_model_table_button"
                :to="{ name: 'programs', params: { pid: provider.id } }"
                class="btn btn-lg btn-primary m-r-10"
                title="Return to Program Model Table"
              >
                <span>Return to Program Model Table</span>
              </router-link>
            </div>
          </div>

          <!--The modal -->
          <b-modal id="confirmSubmit" ok-only>
            <div v-if="errors.length || showstoppers.length">
              <p><b>Please correct the following error(s):</b></p>
              <ul class="error-dark">
                <li
                  v-for="showstopper in showstoppers"
                  v-bind:key="showstopper"
                >
                  {{ showstopper }}
                </li>
              </ul>
              <ul>
                <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
              </ul>
            </div>

            <p
              v-if="!warnings.length && !errors.length && !showstoppers.length"
            >
              <b>No issues detected</b>
              <br />
              The data on this page has been saved.
            </p>
          </b-modal>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import mixin from "../../mixin";

export default {
  mixins: [mixin],
  data: function () {
    return {
      formName: "programreach",
      formData: {
        grantee_name: "",
        provider_name: "",

        input: {
          prog_any_youth_begin_complete: {
            type: "boolean",
            value: null,
            flag: false,
            desc: `Did any youth begin and complete this program between January 1, 2024 and June 30, 2024?`,
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_any_youth_begin: {
            type: "boolean",
            value: null,
            flag: false,
            desc: `Did any youth begin this program between January 1, 2024 and June 30, 2024?`,
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_any_youth_complete: {
            type: "boolean",
            value: null,
            flag: false,
            desc: `Did any youth complete this program between July 1, 2023 and December 31, 2023?`,
            msg: "",
            fmt: "",
            ignore: false,
          },
          prog_reach_attend: {
            type: "number",
            value: null,
            flag: false,
            desc: "Number of youth who attended at least one program session",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_reach_attend_before_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended at least one program session before COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_reach_attend_after_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended at least one program session after COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_reach_attend_ms: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth in middle school who attended at least one program session",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_reach_attend_ms_before_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth in middle school who attended at least one program session before COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_reach_attend_ms_after_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth in middle school who attended at least one program session after COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_reach_attend_hs: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth in high school who attended at least one program session",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_reach_attend_hs_before_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth in high school who attended at least one program session before COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_reach_attend_hs_after_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth in high school who attended at least one program session after COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_dur_school: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session in a school during school",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_dur_school_before_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session in a school during school before COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_dur_school_after_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session in a school during school after COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_after_school: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session in a school after school",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_after_school_before_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session in a school after school before COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_after_school_after_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session in a school after school after COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_cbo: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session in a community-based organization",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_cbo_before_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session in a community-based organization before COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_cbo_after_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session in a community-based organization after COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_clinic: {
            type: "number",
            value: null,
            flag: false,
            desc: "Number of youth who attended a session in a clinic",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_clinic_before_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session in a clinic before COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_clinic_after_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session in a clinic after COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_foster_care: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session in a foster care setting",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_foster_care_before_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session in a foster care setting before COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_foster_care_after_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session in a foster care setting after COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_jdc: {
            type: "number",
            value: null,
            flag: false,
            desc: "Number of youth who attended a juvenile detention center",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_jdc_before_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a juvenile detention center before COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_jdc_after_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a juvenile detention center after COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_mh_facility: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session in a residential mental health treatment facility",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_mh_facility_before_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session in a residential mental health treatment facility before COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_mh_facility_after_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session in a residential mental health treatment facility after COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_online: {
            type: "number",
            value: null,
            flag: false,
            desc: "Number of youth who attended a session virtually",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_online_before_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session online before COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_online_after_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session online after COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_other: {
            type: "number",
            value: null,
            flag: false,
            desc: "Number of youth who attended a session in another setting",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_other_before_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session in another setting before COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_set_attend_other_after_covid: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who attended a session in another setting after COVID-19",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_dosage_75_pct_complete: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "Number of youth who completed at least 75 percent of the scheduled program hours",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          prog_attend_50_pct_adjuc_systems: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "More than 50 percent of the youth attending the program were in adjudication system",
            msg: "",
            fmt: "",
            ignore: true,
          },
          prog_attend_50_pct_lgbtq_youth: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "More than 50 percent of the youth attending the program were LGBTQ youth",
            msg: "",
            fmt: "",
            ignore: true,
          },
          prog_attend_50_pct_foster: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "More than 50 percent of the youth attending the program were in foster care",
            msg: "",
            fmt: "",
            ignore: true,
          },
          prog_attend_50_pct_homeless: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "More than 50 percent of the youth attending the program were homeless or runaway",
            msg: "",
            fmt: "",
            ignore: true,
          },
          prog_attend_50_pct_pregnant: {
            type: "boolean",
            value: false,
            flag: false,
            desc:
              "More than 50 percent of the youth attending the program were pregnant",
            msg: "",
            fmt: "",
            ignore: true,
          },
          prog_attend_50_pct_none_above: {
            type: "boolean",
            value: false,
            flag: false,
            desc: "None of the majority population responses apply",
            msg: "",
            fmt: "",
            ignore: true,
          },
          prog_par_involve_attend_one_sess: {
            type: "number",
            value: null,
            flag: false,
            desc:
              "The number of youths' parents and other caring adults who attended at least one program session during the reporting period",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
          cohorts: {
            type: "object",
            value: [],
            flag: false,
            desc: "Cohorts",
            msg: "",
            fmt: "form-control",
            ignore: true,
          },
        },
      },

      yn: [
        { value: null, text: "Please select an option" },
        { value: true, text: "Yes" },
        { value: false, text: "No" },
      ],

      newCohortEntryName: null,
      newCohortEntryComplete: null,
      newCohortEntryHours: null,
      newCohortEntryEndPrematurely: null,
      newCohortEntryHoursEnd: null,
      newCohortEntryShiftOnline: null,
      newCohortEntryPctOnline: null,

      current_index: 0,
      errors: [],
      showstoppers: [],

      settingIndMsg: "",
      reachIndMsg: "",

      settingTotalIndMsg: "",
      reachTotalIndMsg: "",
      settingBeforeIndMsg: "",
      reachBeforeIndMsg: "",
      settingAfterIndMsg: "",
      reachAfterIndMsg: "",

      settingTotalColMsg: "",
      reachTotalColMsg: "",
      settingBeforeColMsg: "",
      reachBeforeColMsg: "",
      settingAfterColMsg: "",
      reachAfterColMsg: "",

      settingRowMsg: "",
      reachRowMsg: "",

      cohortBlankMsg: "",
      cohortNegMsg: "",
      cohortYYmsg: "",
      cohortNNmsg: "",
      cohortNumLargeMsg: "",
      cohortOnlineMsg: "",

      warnings: [],
      status_msg: "",
      saved: true,
    };
  },
  watch: {
    grantee() {
      // get grantee data
      this.getSubmissionData();
    },

    "$store.state.reportingPeriodData": function () {
      this.getSubmissionData("watch");
    },
  },
  created() {
    this.getSubmissionData();
  },
  computed: {
    ignoreLowerHalf: function () {
      return true;
    },
    formDataSubmission: function () {
      return {
        formName: "programreach",
        formData: [
          {
            tableName: "program_submissions",
            tableData: [
              [
                {
                  name: "provider_name",
                  value: {
                    stringValue: "Test provider",
                  },
                },
                {
                  name: "grantee_name",
                  value: {
                    stringValue: "Test grantee",
                  },
                },
                {
                  name: "prog_any_youth_begin_complete",
                  value:
                    this.formData.input.prog_any_youth_begin_complete.value ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_any_youth_begin_complete.value,
                        },
                },
                {
                  name: "prog_any_youth_begin",
                  value:
                    this.formData.input.prog_any_youth_begin.value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input.prog_any_youth_begin
                            .value,
                        },
                },
                {
                  name: "prog_any_youth_complete",
                  value:
                    this.formData.input.prog_any_youth_complete.value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_any_youth_complete.value,
                        },
                },
                {
                  name: "prog_reach_attend",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_reach_attend.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_reach_attend.value
                        ),
                      },
                },
                {
                  name: "prog_reach_attend_before_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_reach_attend_before_covid.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_reach_attend_before_covid
                            .value
                        ),
                      },
                },
                {
                  name: "prog_reach_attend_after_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_reach_attend_after_covid.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_reach_attend_after_covid
                            .value
                        ),
                      },
                },
                {
                  name: "prog_reach_attend_ms",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_reach_attend_ms.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_reach_attend_ms.value
                        ),
                      },
                },
                {
                  name: "prog_reach_attend_ms_before_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_reach_attend_ms_before_covid
                        .value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_reach_attend_ms_before_covid
                            .value
                        ),
                      },
                },
                {
                  name: "prog_reach_attend_ms_after_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_reach_attend_ms_after_covid.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_reach_attend_ms_after_covid
                            .value
                        ),
                      },
                },
                {
                  name: "prog_reach_attend_hs",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_reach_attend_hs.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_reach_attend_hs.value
                        ),
                      },
                },
                {
                  name: "prog_reach_attend_hs_before_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_reach_attend_hs_before_covid
                        .value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_reach_attend_hs_before_covid
                            .value
                        ),
                      },
                },
                {
                  name: "prog_reach_attend_hs_after_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_reach_attend_hs_after_covid.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_reach_attend_hs_after_covid
                            .value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_dur_school",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_set_attend_dur_school.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_set_attend_dur_school.value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_dur_school_before_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input
                        .prog_set_attend_dur_school_before_covid.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input
                            .prog_set_attend_dur_school_before_covid.value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_dur_school_after_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_set_attend_dur_school_after_covid
                        .value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input
                            .prog_set_attend_dur_school_after_covid.value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_after_school",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_set_attend_after_school.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_set_attend_after_school.value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_after_school_before_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input
                        .prog_set_attend_after_school_before_covid.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input
                            .prog_set_attend_after_school_before_covid.value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_after_school_after_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input
                        .prog_set_attend_after_school_after_covid.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input
                            .prog_set_attend_after_school_after_covid.value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_cbo",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_set_attend_cbo.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_set_attend_cbo.value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_cbo_before_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_set_attend_cbo_before_covid.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_set_attend_cbo_before_covid
                            .value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_cbo_after_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_set_attend_cbo_after_covid.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_set_attend_cbo_after_covid
                            .value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_clinic",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_set_attend_clinic.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_set_attend_clinic.value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_clinic_before_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_set_attend_clinic_before_covid
                        .value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input
                            .prog_set_attend_clinic_before_covid.value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_clinic_after_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_set_attend_clinic_after_covid
                        .value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_set_attend_clinic_after_covid
                            .value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_foster_care",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_set_attend_foster_care.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_set_attend_foster_care.value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_foster_care_before_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input
                        .prog_set_attend_foster_care_before_covid.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input
                            .prog_set_attend_foster_care_before_covid.value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_foster_care_after_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input
                        .prog_set_attend_foster_care_after_covid.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input
                            .prog_set_attend_foster_care_after_covid.value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_jdc",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_set_attend_jdc.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_set_attend_jdc.value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_jdc_before_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_set_attend_jdc_before_covid.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_set_attend_jdc_before_covid
                            .value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_jdc_after_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_set_attend_jdc_after_covid.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_set_attend_jdc_after_covid
                            .value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_mh_facility",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_set_attend_mh_facility.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_set_attend_mh_facility.value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_mh_facility_before_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input
                        .prog_set_attend_mh_facility_before_covid.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input
                            .prog_set_attend_mh_facility_before_covid.value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_mh_facility_after_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input
                        .prog_set_attend_mh_facility_after_covid.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input
                            .prog_set_attend_mh_facility_after_covid.value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_online",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_set_attend_online.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_set_attend_online.value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_online_before_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_set_attend_online_before_covid
                        .value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input
                            .prog_set_attend_online_before_covid.value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_online_after_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_set_attend_online_after_covid
                        .value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_set_attend_online_after_covid
                            .value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_other",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_set_attend_other.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_set_attend_other.value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_other_before_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_set_attend_other_before_covid
                        .value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_set_attend_other_before_covid
                            .value
                        ),
                      },
                },
                {
                  name: "prog_set_attend_other_after_covid",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_set_attend_other_after_covid
                        .value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_set_attend_other_after_covid
                            .value
                        ),
                      },
                },
                {
                  name: "prog_dosage_75_pct_complete",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_dosage_75_pct_complete.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_dosage_75_pct_complete.value
                        ),
                      },
                },
                {
                  name: "prog_attend_50_pct_adjuc_systems",
                  value:
                    this.formData.input.prog_attend_50_pct_adjuc_systems
                      .value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_attend_50_pct_adjuc_systems.value,
                        },
                },
                {
                  name: "prog_attend_50_pct_lgbtq_youth",
                  value:
                    this.formData.input.prog_attend_50_pct_lgbtq_youth.value ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_attend_50_pct_lgbtq_youth.value,
                        },
                },
                {
                  name: "prog_attend_50_pct_foster",
                  value:
                    this.formData.input.prog_attend_50_pct_foster.value === null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_attend_50_pct_foster.value,
                        },
                },
                {
                  name: "prog_attend_50_pct_homeless",
                  value:
                    this.formData.input.prog_attend_50_pct_homeless.value ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_attend_50_pct_homeless.value,
                        },
                },
                {
                  name: "prog_attend_50_pct_pregnant",
                  value:
                    this.formData.input.prog_attend_50_pct_pregnant.value ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_attend_50_pct_pregnant.value,
                        },
                },
                {
                  name: "prog_attend_50_pct_none_above",
                  value:
                    this.formData.input.prog_attend_50_pct_none_above.value ===
                    null
                      ? {
                          isNull: true,
                        }
                      : {
                          booleanValue: this.formData.input
                            .prog_attend_50_pct_none_above.value,
                        },
                },
                {
                  name: "prog_par_involve_attend_one_sess",
                  value: isNaN(
                    this.removeThousandCommas(
                      this.formData.input.prog_par_involve_attend_one_sess.value
                    )
                  )
                    ? {
                        isNull: true,
                      }
                    : {
                        longValue: this.removeThousandCommas(
                          this.formData.input.prog_par_involve_attend_one_sess
                            .value
                        ),
                      },
                },
                {
                  name: "prog_reach_data_issues_minor",
                  value: {
                    booleanValue: this.errors.length > 0,
                  },
                },
                {
                  name: "prog_reach_data_issues_major",
                  value: {
                    booleanValue: this.showstoppers.length > 0,
                  },
                },
              ],
            ],
          },
          {
            tableName: "program_cohorts",
            tableData: this.cohortSubmissionArray(),
          },
        ],
      };
    },
  },
  methods: {
    initializeCommas(num) {
      if (num) {
        const string = num.toString();
        const number = string.replace(/,/g, "");
        const split = number.split(".");

        let commas;

        if (split.length > 1) {
          split[0] = split[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          commas = split.join(".");
        } else {
          commas = split[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        return commas;
      } else {
        return num;
      }
    },

    addThousandComma(varName) {
      if (
        this.formData.input[varName].value === null ||
        typeof this.formData.input[varName].value === "undefined"
      ) {
        this.formData.input[varName].value = null;
      } else {
        const target = this.formData.input[varName].value;
        const initialLength = target.length;

        const numericOnly = target.replace(/[^0-9,]/g, "");

        const commas = this.initializeCommas(numericOnly);
        this.formData.input[varName].value = commas;

        if (numericOnly.length === initialLength) {
          this.dataUpdated();
        }
      }
    },

    addThousandCommaDec(value) {
      const target = this.granteeSubmissionData[value];
      const initialLength = target.length;

      const numericOnly = target.replace(/[^0-9,\.]/g, "");

      const commas = this.initializeCommas(numericOnly);
      this.granteeSubmissionData[value] = commas;

      if (numericOnly.length === initialLength) {
        this.dataUpdated();
      }
    },

    removeThousandCommas(value) {
      if (isNaN(value)) {
        let number = value.replace(/,/g, "");
        return parseFloat(number);
      } else {
        return parseFloat(value);
      }
    },

    getSubmissionData() {
      this.$store
        .dispatch("getSubmissionData", {
          path: `/grantees/${this.grantee.id}/providers/${this.$route.params.pid}/programs/${this.$route.params.pmid}/reach/submissions/${this.$store.state.reportingPeriodData.pk}/reduce`,
        })
        .then((r) => {
          var checkForm = false;

          // handle program_reach data first

          var program_reach = r.data.program_reach;

          if (r.success && program_reach.length) {
            const data = r.data.program_reach[0];
            const keys = Object.keys(data);

            keys.forEach((key) => {
              if (Object.keys(this.formData.input).includes(key)) {
                if (this.formData.input[key].type === "number") {
                  data[key] = this.initializeCommas(data[key]);
                }
                this.formData.input[key].value = data[key];
              }
            });

            // get number of delivery hours to check against cohort hours
            if (
              Object.keys(r.data.program_reach[0]).includes(
                "prog_num_delivery_hours"
              )
            ) {
              this.num_delivery_hours =
                r.data.program_reach[0]["prog_num_delivery_hours"];
            }

            if (
              data.prog_reach_data_issues_major ||
              data.prog_reach_data_issues_minor
            ) {
              checkForm = true;
            }
          }

          // now handle cohorts

          this.formData.input.cohorts.value = [];

          var program_cohorts = r.data.program_cohorts;

          if (r.success && program_cohorts.length) {
            const data = r.data.program_cohorts;

            data.forEach((cohort, index) => {
              const cohort_i = {
                cohort_index: index,
                cohort_hours: cohort.prog_cohort_hours,
                cohort_name: cohort.prog_cohort_name,
                cohort_complete: cohort.prog_cohort_complete,
                cohort_end_prematurely: cohort.prog_cohort_end_prematurely,
                cohort_hours_end_prematurely:
                  cohort.prog_cohort_hours_end_prematurely,
                cohort_shift_online: cohort.prog_cohort_shift_online,
                cohort_pct_shift_online: cohort.prog_cohort_pct_shift_online,
              };
              this.formData.input.cohorts.value.push(cohort_i);
            });
          }

          if (checkForm) {
            this.checkForm();
          }
          this.saved = true;
        });
    },

    cohortSubmissionArray: function () {
      let cohortArray = [];
      this.formData.input.cohorts.value.forEach((element) =>
        cohortArray.push([
          {
            name: "prog_cohort_name",
            value: {
              stringValue: element.cohort_name,
            },
          },
          {
            name: "prog_cohort_complete",
            value:
              element.cohort_complete === null
                ? {
                    isNull: true,
                  }
                : {
                    booleanValue: element.cohort_complete,
                  },
          },
          {
            name: "prog_cohort_hours",
            value: isNaN(this.removeThousandCommas(element.cohort_hours))
              ? {
                  isNull: true,
                }
              : {
                  doubleValue: this.removeThousandCommas(element.cohort_hours),
                },
          },
          {
            name: "prog_cohort_end_prematurely",
            value:
              element.cohort_end_prematurely === null
                ? {
                    isNull: true,
                  }
                : {
                    booleanValue: element.cohort_end_prematurely,
                  },
          },
          {
            name: "prog_cohort_hours_end_prematurely",
            value: isNaN(
              this.removeThousandCommas(element.cohort_hours_end_prematurely)
            )
              ? {
                  isNull: true,
                }
              : {
                  doubleValue: this.removeThousandCommas(
                    element.cohort_hours_end_prematurely
                  ),
                },
          },
          {
            name: "prog_cohort_shift_online",
            value:
              element.cohort_shift_online === null
                ? {
                    isNull: true,
                  }
                : {
                    booleanValue: element.cohort_shift_online,
                  },
          },
          {
            name: "prog_cohort_pct_shift_online",
            value: isNaN(
              this.removeThousandCommas(element.cohort_pct_shift_online)
            )
              ? {
                  isNull: true,
                }
              : {
                  doubleValue: this.removeThousandCommas(
                    element.cohort_pct_shift_online
                  ),
                },
          },
        ])
      );

      return cohortArray;
    },

    cohortPush: function () {
      var cohort_n = this.formData.input.cohorts.value.length;

      const newCohort = {
        cohort_name: "Cohort " + (cohort_n + 1),
        cohort_complete: this.newCohortEntryComplete,
        cohort_hours: this.newCohortEntryHours,
        cohort_end_prematurely: this.newCohortEntryEndPrematurely,
        cohort_hours_end_prematurely:
          this.newCohortEntryEndPrematurely === true
            ? this.newCohortEntryHoursEnd
            : null,
        cohort_shift_online: this.newCohortEntryShiftOnline,
        cohort_pct_shift_online:
          this.newCohortEntryShiftOnline === true
            ? this.newCohortEntryPctOnline
            : null,
        cohort_index: cohort_n,
      };

      this.formData.input.cohorts.value.push(newCohort);
      this.newCohortEntryName = null;
      this.newCohortEntryComplete = null;
      this.newCohortEntryHours = null;
      this.newCohortEntryEndPrematurely = null;
      this.newCohortEntryHoursEnd = null;
      this.newCohortEntryShiftOnline = null;
      this.newCohortEntryPctOnline = null;
    },

    cohortEdit: function () {
      this.formData.input.cohorts.value[
        this.current_index
      ].cohort_complete = this.newCohortEntryComplete;
      this.formData.input.cohorts.value[
        this.current_index
      ].cohort_hours = this.newCohortEntryHours;
      this.formData.input.cohorts.value[
        this.current_index
      ].cohort_end_prematurely = this.newCohortEntryEndPrematurely;
      this.formData.input.cohorts.value[
        this.current_index
      ].cohort_hours_end_prematurely =
        this.newCohortEntryEndPrematurely === true
          ? this.newCohortEntryHoursEnd
          : null;
      this.formData.input.cohorts.value[
        this.current_index
      ].cohort_shift_online = this.newCohortEntryShiftOnline;
      this.formData.input.cohorts.value[
        this.current_index
      ].cohort_pct_shift_online =
        this.newCohortEntryShiftOnline === true
          ? this.newCohortEntryPctOnline
          : null;
      this.newCohortEntryName = null;
      this.newCohortEntryComplete = null;
      this.newCohortEntryHours = null;
      this.newCohortEntryEndPrematurely = null;
      this.newCohortEntryHoursEnd = null;
      this.newCohortEntryShiftOnline = null;
      this.newCohortEntryPctOnline = null;
    },

    dropCohort: function () {
      this.formData.input.cohorts.value.splice(this.current_index, 1);
      for (let x = 0; x < this.formData.input.cohorts.value.length; x++) {
        this.formData.input.cohorts.value[x].cohort_index = x;
        this.formData.input.cohorts.value[x].cohort_name = `Cohort ${x + 1}`;
      }
    },

    dataUpdated() {
      this.saved = false;
    },
    submitForm(_action) {
      this.$store
        .dispatch("submitForm", {
          action: _action,
          path: `/grantees/${this.grantee.id}/providers/${this.$route.params.pid}/programs/${this.$route.params.pmid}/reach/submissions/${this.$store.state.reportingPeriodData.pk}`,
          data: this.formDataSubmission,
        })
        .then((r) => {
          this.status_msg = r;
        });
    },

    checkForm(_type) {
      this.saved = true;

      this.errors = [];
      this.showstoppers = [];

      this.settingIndMsg = "";
      this.reachIndMsg = "";

      this.settingTotalIndMsg = "";
      this.reachTotalIndMsg = "";
      this.settingBeforeIndMsg = "";
      this.reachBeforeIndMsg = "";
      this.settingAfterIndMsg = "";
      this.reachAfterIndMsg = "";

      this.settingTotalColMsg = "";
      this.reachTotalColMsg = "";
      this.settingBeforeColMsg = "";
      this.reachBeforeColMsg = "";
      this.settingAfterColMsg = "";
      this.reachAfterColMsg = "";

      this.settingRowMsg = "";
      this.reachRowMsg = "";

      this.cohortBlankMsg = "";
      this.cohortNegMsg = "";
      this.cohortYYmsg = "";
      this.cohortNNmsg = "";
      this.cohortNumLargeMsg = "";
      this.cohortOnlineMsg = "";

      // 1. check for negative/blank values
      var f = Object.keys(this.formData.input);
      for (var i = 0; i < f.length; i++) {
        if (!f[i].includes("covid")) {
          // reset format, message, and flag
          this.formData.input[f[i]].fmt = this.formData.input[f[i]].fmt.replace(
            "invalid-input",
            ""
          );
          this.formData.input[f[i]].msg = "";
          this.formData.input[f[i]].flag = false;
          var s = "";

          if (
            this.formData.input[f[i]].ignore &&
            this.formData.input.prog_any_youth_complete.value != true
          ) {
            continue;
          }

          if (this.formData.input[f[i]].type == "number") {
            if (
              this.formData.input[f[i]].value === null ||
              isNaN(this.removeThousandCommas(this.formData.input[f[i]].value))
            ) {
              this.formData.input[f[i]].flag = true;
              this.formData.input[f[i]].fmt += " invalid-input";
              this.formData.input[f[i]].msg = "Please enter a value.";
              s =
                this.formData.input[f[i]].desc +
                ": " +
                this.formData.input[f[i]].msg;
              this.errors.push(s);
            } else if (
              this.removeThousandCommas(this.formData.input[f[i]].value) < 0
            ) {
              this.formData.input[f[i]].flag = true;
              this.formData.input[f[i]].fmt += " invalid-input";
              this.formData.input[f[i]].msg = "Please enter a positive value.";
              s =
                this.formData.input[f[i]].desc +
                ": " +
                this.formData.input[f[i]].msg;
              this.errors.push(s);
            }
          }

          if (
            this.formData.input[f[i]].type == "boolean" &&
            this.formData.input[f[i]].value === null &&
            (f[i] === "prog_any_youth_begin" ||
              f[i] == "prog_any_youth_complete")
          ) {
            this.formData.input[f[i]].flag = true;
            this.formData.input[f[i]].fmt += " invalid-input";
            this.formData.input[f[i]].msg = "Please select a response";
            s =
              this.formData.input[f[i]].desc +
              ": " +
              this.formData.input[f[i]].msg;
            this.errors.push(s);
          }
        }
      }

      var reach = [
        this.formData.input.prog_reach_attend,
        this.formData.input.prog_reach_attend_ms,
        this.formData.input.prog_reach_attend_hs,
      ];

      // var reachTotal = [this.formData.input.prog_reach_attend]

      // var reachMS = [this.formData.input.prog_reach_attend_ms]

      // var reachHS = [this.formData.input.prog_reach_attend_hs];

      var setting = [
        this.formData.input.prog_set_attend_dur_school,
        this.formData.input.prog_set_attend_after_school,
        this.formData.input.prog_set_attend_cbo,
        this.formData.input.prog_set_attend_clinic,
        this.formData.input.prog_set_attend_foster_care,
        this.formData.input.prog_set_attend_jdc,
        this.formData.input.prog_set_attend_mh_facility,
        this.formData.input.prog_set_attend_online,
        this.formData.input.prog_set_attend_other,
      ];

      // var settingDurSchool = [this.formData.input.prog_set_attend_dur_school];

      // var settingAfterSchool = [this.formData.input.prog_set_attend_after_school];

      // var settingCBO = [this.formData.input.prog_set_attend_cbo];

      // var settingClinic = [this.formData.input.prog_set_attend_clinic];

      // var settingFosterCare = [this.formData.input.prog_set_attend_foster_care];

      // var settingJDC = [this.formData.input.prog_set_attend_jdc];

      // var settingMHFacility = [this.formData.input.prog_set_attend_mh_facility];

      // var settingOnline = [this.formData.input.prog_set_attend_online];

      // var settingOther = [this.formData.input.prog_set_attend_other];

      var dosage = [this.formData.input.prog_dosage_75_pct_complete];

      var majority_options = [
        this.formData.input.prog_attend_50_pct_adjuc_systems,
        this.formData.input.prog_attend_50_pct_lgbtq_youth,
        this.formData.input.prog_attend_50_pct_foster,
        this.formData.input.prog_attend_50_pct_homeless,
        this.formData.input.prog_attend_50_pct_pregnant,
      ];

      for (const setting_var of setting) {
        if (
          this.removeThousandCommas(setting_var.value) === null ||
          isNaN(this.removeThousandCommas(setting_var.value)) ||
          (this.removeThousandCommas(setting_var.value) < 0 &&
            this.formData.input.prog_any_youth_complete.value == true)
        ) {
          this.settingIndMsg =
            "At least one setting field is blank or negative";
        }
      }

      for (const reach_var of reach) {
        if (
          this.removeThousandCommas(reach_var.value) === null ||
          isNaN(this.removeThousandCommas(reach_var.value)) ||
          (this.removeThousandCommas(reach_var.value) < 0 &&
            this.formData.input.prog_any_youth_complete.value == true)
        ) {
          this.reachIndMsg = "At least one reach field is blank or negative";
        }
      }

      //check if prog_reach_attend is greater than all other count variables individually
      if (this.formData.input.prog_any_youth_complete.value == true) {
        for (var arrayVar of reach.concat(setting.concat(dosage))) {
          if (
            !isNaN(
              this.removeThousandCommas(
                this.formData.input.prog_reach_attend.value
              )
            ) &&
            !isNaN(this.removeThousandCommas(arrayVar.value)) &&
            this.removeThousandCommas(
              this.formData.input.prog_reach_attend.value
            ) < this.removeThousandCommas(arrayVar.value)
          ) {
            arrayVar.fmt += " invalid-input";
            arrayVar.msg = `${arrayVar.desc} cannot exceed the number of youth who attended at least one session`;
            arrayVar.flag = true;
            this.showstoppers.push(
              `${arrayVar.desc} cannot exceed the number of youth who attended at least one session`
            );

            if (setting.includes(arrayVar)) {
              this.settingTotalIndMsg =
                "At least one entry exceeds the number of youth who attended at least one session";
            }
            if (reach.includes(arrayVar)) {
              this.reachTotalIndMsg =
                "At least one entry exceeds the number of youth who attended at least one session";
            }
          }
        }
      }

      // SETTING TOTAL
      if (
        setting
          .filter((elem) => !elem.desc.includes("COVID"))
          .reduce(
            (accum, val) => accum + this.removeThousandCommas(val.value),
            0
          ) >
          this.removeThousandCommas(
            this.formData.input.prog_reach_attend.value
          ) &&
        this.removeThousandCommas(
          this.formData.input.prog_reach_attend.value
        ) != null &&
        !isNaN(
          this.removeThousandCommas(this.formData.input.prog_reach_attend.value)
        ) &&
        this.formData.input.prog_any_youth_complete.value == true
      ) {
        let m =
          "Total number of youth exceeds number of youth who attended at least one session";
        this.showstoppers.push(m);
        this.settingTotalColMsg = m;
      }

      // reach TOTAL
      if (
        reach
          .filter((elem) => !elem.desc.includes("COVID"))
          .reduce(
            (accum, val) =>
              val.desc.includes("youth who")
                ? accum
                : accum + this.removeThousandCommas(val.value),
            0
          ) >
          this.removeThousandCommas(
            this.formData.input.prog_reach_attend.value
          ) &&
        this.removeThousandCommas(
          this.formData.input.prog_reach_attend.value
        ) != null &&
        !isNaN(
          this.removeThousandCommas(this.formData.input.prog_reach_attend.value)
        ) &&
        this.formData.input.prog_any_youth_complete.value == true
      ) {
        let m =
          "Total number of middle school and high school participants exceeds number of youth who attended at least one session";
        this.showstoppers.push(m);
        this.reachTotalColMsg = m;
      }

      //check that

      //50 pct v checks
      if (
        majority_options.reduce(
          (accum, val) => accum == true || val.value == true,
          false
        ) != true &&
        this.formData.input.prog_attend_50_pct_none_above.value != true &&
        this.formData.input.prog_any_youth_complete.value == true
      ) {
        this.errors.push("No majority population responses selected");
        this.formData.input.prog_attend_50_pct_none_above.flag = true;
        this.formData.input.prog_attend_50_pct_none_above.msg =
          "No majority population responses selected";
      }

      if (
        majority_options.reduce(
          (accum, val) => accum == true || val.value == true,
          false
        ) &&
        this.formData.input.prog_attend_50_pct_none_above.value === true &&
        this.formData.input.prog_any_youth_complete.value == true
      ) {
        this.errors.push(
          "'None of the above' and at least one other majority population response was selected"
        );
        this.formData.input.prog_attend_50_pct_none_above.flag = true;
        this.formData.input.prog_attend_50_pct_none_above.msg =
          "'None of the above' and at least one other majority population response was selected";
      }

      let m;
      this.cohortSubmissionArray().forEach((arr) => {
        if (
          arr[2].value.isNull &&
          this.formData.input.prog_any_youth_complete.value == true
        ) {
          m =
            "At least one cohort was not assigned a number for hours of programming delivered";
          this.cohortBlankMsg = m;
          if (!this.errors.includes(m)) {
            this.errors.push(m);
          }
        }
        if (
          arr[2].value.doubleValue &&
          arr[2].value.doubleValue < 0 &&
          this.formData.input.prog_any_youth_complete.value == true
        ) {
          m =
            "At least one cohort was assigned a negative number for hours of programming delivered";
          this.cohortNegMsg = m;
          if (!this.errors.includes(m)) {
            this.errors.push(m);
          }
        }
      });

      this.submitForm(_type);

      if (!this.errors.length && !this.showstoppers.length) {
        return true;
      }

      if (this.errors || this.showstoppers) {
        console.error("Validation errors:");
        console.error(this.errors);
        console.error(this.showstoppers);
      }

      // e.preventDefault()
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.saved) {
      const answer = window.confirm(
        "The page contains data that have not been saved. Are you sure you want to leave the page without saving?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>
<style>
.label-hidden {
  visibility: hidden;
  position: absolute;
  pointer-events: none;
}
</style>
