var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 no-padding"},[_c('div',{staticClass:"card card-transparent"},[_c('div',{staticClass:"card-header p-t-5"},[_c('div',{staticClass:"card-title"},[_c('div',{staticClass:"col-xs-12"},[_c('router-link',{staticClass:"btn btn-lg btn-block btn-primary btn-icon-left",attrs:{"to":{ name: 'providers' }}},[_c('i',{staticClass:"pg-icon md-18"},[_vm._v("arrow_left")]),_c('span',[_vm._v("Return to Provider List")])])],1)])]),_c('div',{staticClass:"card-body"},[(_vm.errors.length || _vm.showstoppers.length)?_c('div',[_vm._m(0),_c('ul',[_vm._l((_vm.showstoppers),function(showstopper){return _c('li',{key:showstopper,staticClass:"error-message"},[_vm._v(" "+_vm._s(showstopper)+" ")])}),_vm._l((_vm.errors),function(error){return _c('li',{key:error},[_vm._v(_vm._s(error))])})],2)]):_vm._e(),_c('b-form',{attrs:{"role":"form","novalidate":""}},[_c('div',{staticClass:"row"},[(!_vm.isWinterSeason)?_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"card card-default"},[_c('div',{staticClass:"card-header separator"},[_c('div',{staticClass:"card-title"},[_vm._v("Funding")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-8 p-r-20"},[_c('label',{attrs:{"for":"prov_srae_provider_funding"}},[_vm._v("SRAE annual award amount for the "+_vm._s(_vm.$store.state.reportingPeriodYears)+" grant year")])]),_c('div',{staticClass:"col-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                          _vm.providerSubmissionData.prov_srae_provider_funding
                        ),expression:"\n                          providerSubmissionData.prov_srae_provider_funding\n                        "}],staticClass:"form-control",class:{
                          'invalid-input': this.formErrors
                            .prov_srae_provider_funding.flag,
                        },attrs:{"id":"prov_srae_provider_funding","type":"text","required":""},domProps:{"value":(
                          _vm.providerSubmissionData.prov_srae_provider_funding
                        )},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.providerSubmissionData, "prov_srae_provider_funding", $event.target.value)},function($event){return _vm.addThousandComma('prov_srae_provider_funding')}]}})]),(this.formErrors.prov_srae_provider_funding.flag)?_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(this.formErrors.prov_srae_provider_funding.msg)+" ")])]):_vm._e()]),_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-8 p-r-20"},[_c('label',{attrs:{"for":"prov_non_srae_provider_funding"}},[_vm._v("Amount of non-SRAE funding received during current reporting year to support SRAE programming")])]),_c('div',{staticClass:"col-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                          _vm.providerSubmissionData.prov_non_srae_provider_funding
                        ),expression:"\n                          providerSubmissionData.prov_non_srae_provider_funding\n                        "}],staticClass:"form-control",class:{
                          'invalid-input': this.formErrors
                            .prov_non_srae_provider_funding.flag,
                        },attrs:{"id":"prov_non_srae_provider_funding","type":"text","required":""},domProps:{"value":(
                          _vm.providerSubmissionData.prov_non_srae_provider_funding
                        )},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.providerSubmissionData, "prov_non_srae_provider_funding", $event.target.value)},function($event){return _vm.addThousandComma('prov_non_srae_provider_funding')}]}})])]),(this.formErrors.prov_non_srae_provider_funding.flag)?_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(this.formErrors.prov_non_srae_provider_funding.msg)+" ")])]):_vm._e()])])]):_vm._e(),_c('div',{class:{
                'col-lg-6': !_vm.isWinterSeason,
                'col-lg-12': _vm.isWinterSeason,
              }},[_c('div',{staticClass:"card card-default"},[(!_vm.isWinterSeason)?_c('div',{staticClass:"card-header separator"},[_c('div',{staticClass:"card-title"},[_vm._v("Provider status")])]):_vm._e(),_c('div',{staticClass:"card-body"},[(!_vm.isWinterSeason)?_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-7"},[_c('label',{attrs:{"for":"prov_new_provider"}},[_vm._v("Is provider new for the "+_vm._s(_vm.$store.state.reportingPeriodYears)+" grant year?")])]),_c('div',{staticClass:"col-5 p-r-20"},[_c('div',[_c('b-form-select',{class:{
                            'invalid-input': this.formErrors.prov_new_provider
                              .flag,
                          },attrs:{"id":"prov_new_provider","options":_vm.yn},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(_vm.providerSubmissionData.prov_new_provider),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_new_provider", $$v)},expression:"providerSubmissionData.prov_new_provider"}})],1)])]):_vm._e(),(
                      this.formErrors.prov_new_provider.flag &&
                      this.formErrors.prov_new_provider.msg !==
                        'Please enter a value.'
                    )?_c('div',{staticClass:"form-group row error-message"},[_vm._v(" "+_vm._s(this.formErrors.prov_new_provider.msg)+" ")]):_vm._e(),_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-7"},[_c('label',{attrs:{"for":"prov_provider_serve_youth"}},[_vm._v("Did provider serve youth or their parents during the "+_vm._s(_vm.$store.state.reportingPeriodStr.prov)+" reporting period?")])]),_c('div',{staticClass:"col-5 p-r-20"},[_c('div',[_c('b-form-select',{class:{
                            'invalid-input': this.formErrors
                              .prov_provider_serve_youth.flag,
                          },attrs:{"id":"prov_provider_serve_youth","options":_vm.yn},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                            _vm.providerSubmissionData.prov_provider_serve_youth
                          ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_provider_serve_youth", $$v)},expression:"\n                            providerSubmissionData.prov_provider_serve_youth\n                          "}})],1)])]),(_vm.providerSubmissionData.prov_provider_serve_youth)?_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-7"},[_c('label',{attrs:{"for":"prov_num_program_models_implemented"}},[_vm._v("Number of program models implemented "),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"id":"facil_num_programs","title":`Number of program models the provider implemented during the ${_vm.$store.state.reportingPeriodStr.prov} reporting period. These program models should be listed as active in the “Program Model Table” on the next page.`,"size":"sm"}},[_vm._v("?")])],1)]),_c('div',{staticClass:"col-5 p-r-20"},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.providerSubmissionData.prov_num_program_models_implemented
                          ),expression:"\n                            providerSubmissionData.prov_num_program_models_implemented\n                          "}],staticClass:"form-control",class:{
                            'invalid-input': this.formErrors
                              .prov_num_program_models_implemented.flag,
                          },attrs:{"id":"prov_num_program_models_implemented","type":"text","required":""},domProps:{"value":(
                            _vm.providerSubmissionData.prov_num_program_models_implemented
                          )},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.providerSubmissionData, "prov_num_program_models_implemented", $event.target.value)},function($event){return _vm.addThousandComma(
                              'prov_num_program_models_implemented'
                            )}]}})])])]):_vm._e()])])])]),(
              _vm.providerSubmissionData.prov_provider_serve_youth &&
              !_vm.isWinterSeason
            )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card card-default"},[_c('div',{staticClass:"card-header separator"},[_c('div',{staticClass:"card-title"},[_vm._v(" Provider staff in SRAE administration ")])]),_c('div',{staticClass:"card-body row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-9"},[_c('label',{attrs:{"for":"prov_num_staff_admin_progs"}},[_vm._v("Number of provider staff involved in administering SRAE programs "),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"id":"num_staff_admin","title":"This measure should include provider staff, such as SRAE program directors and program coordinators, who were directly responsible for administering, managing, and overseeing the SRAE program for the provider. Do not include staff who provide programming directly to youth in this measure. Those staff should be counted in the measure of facilitators later in this section. If staff play both roles, they should be included in both measures.","size":"sm"}},[_vm._v("?")])],1)]),_c('div',{staticClass:"col-3 p-r-20"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.providerSubmissionData.prov_num_staff_admin_progs
                          ),expression:"\n                            providerSubmissionData.prov_num_staff_admin_progs\n                          "}],staticClass:"form-control",class:{
                            'invalid-input': this.formErrors
                              .prov_num_staff_admin_progs.flag,
                          },attrs:{"id":"prov_num_staff_admin_progs","type":"text","required":""},domProps:{"value":(
                            _vm.providerSubmissionData.prov_num_staff_admin_progs
                          )},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.providerSubmissionData, "prov_num_staff_admin_progs", $event.target.value)},function($event){return _vm.addThousandComma('prov_num_staff_admin_progs')}]}})])]),(this.formErrors.prov_num_staff_admin_progs.flag)?_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(this.formErrors.prov_num_staff_admin_progs.msg)+" ")])]):_vm._e()]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-9"},[_c('label',{attrs:{"for":"prov_num_fte_admin_progs"}},[_vm._v("Number of provider FTEs involved in administering SRAE programs")])]),_c('div',{staticClass:"col-3 p-r-20"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.providerSubmissionData.prov_num_fte_admin_progs
                          ),expression:"\n                            providerSubmissionData.prov_num_fte_admin_progs\n                          "}],staticClass:"form-control",class:{
                            'invalid-input': this.formErrors
                              .prov_num_fte_admin_progs.flag,
                          },attrs:{"id":"prov_num_fte_admin_progs","type":"text","required":""},domProps:{"value":(
                            _vm.providerSubmissionData.prov_num_fte_admin_progs
                          )},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.providerSubmissionData, "prov_num_fte_admin_progs", $event.target.value)},function($event){return _vm.addThousandCommaDec('prov_num_fte_admin_progs')}]}})])]),(this.formErrors.prov_num_fte_admin_progs.flag)?_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(this.formErrors.prov_num_fte_admin_progs.msg)+" ")])]):_vm._e()])])])])]):_vm._e(),(
              _vm.providerSubmissionData.prov_provider_serve_youth &&
              !_vm.isWinterSeason
            )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"card card-default"},[_c('div',{staticClass:"card-header separator"},[_c('div',{staticClass:"card-title"},[_vm._v("Facilitators")])]),_c('div',{staticClass:"card-body row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-9"},[_c('label',{attrs:{"for":"prov_num_facilitators_working"}},[_vm._v("Number of SRAE facilitators working for provider")])]),_c('div',{staticClass:"col-3 p-r-20"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.providerSubmissionData.prov_num_facilitators_working
                          ),expression:"\n                            providerSubmissionData.prov_num_facilitators_working\n                          "}],staticClass:"form-control",class:{
                            'invalid-input': this.formErrors
                              .prov_num_facilitators_working.flag,
                          },attrs:{"id":"prov_num_facilitators_working","type":"text","required":""},domProps:{"value":(
                            _vm.providerSubmissionData.prov_num_facilitators_working
                          )},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.providerSubmissionData, "prov_num_facilitators_working", $event.target.value)},function($event){return _vm.addThousandComma('prov_num_facilitators_working')}]}})])]),(
                        this.formErrors.prov_num_facilitators_working.flag
                      )?_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(this.formErrors.prov_num_facilitators_working.msg)+" ")])]):_vm._e()])])])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"card card-default"},[_c('div',{staticClass:"card-header separator"},[_c('div',{staticClass:"card-title"},[_vm._v(" Facilitator training and observation ")])]),_c('div',{staticClass:"card-body row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-9"},[_c('label',{attrs:{"for":"prov_num_facilitators_trained"}},[_vm._v("Number of SRAE facilitators trained in delivering core curriculum")])]),_c('div',{staticClass:"col-3 p-r-20"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.providerSubmissionData.prov_num_facilitators_trained
                          ),expression:"\n                            providerSubmissionData.prov_num_facilitators_trained\n                          "}],staticClass:"form-control",class:{
                            'invalid-input': this.formErrors
                              .prov_num_facilitators_trained.flag,
                          },attrs:{"id":"prov_num_facilitators_trained","type":"text","required":""},domProps:{"value":(
                            _vm.providerSubmissionData.prov_num_facilitators_trained
                          )},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.providerSubmissionData, "prov_num_facilitators_trained", $event.target.value)},function($event){return _vm.addThousandComma('prov_num_facilitators_trained')}]}})])]),(
                        this.formErrors.prov_num_facilitators_trained.flag
                      )?_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(this.formErrors.prov_num_facilitators_trained.msg)+" ")])]):_vm._e(),_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-9"},[_c('label',{attrs:{"for":"prov_num_facilitators_obs_once"}},[_vm._v("Number of SRAE facilitators observed exactly once ")])]),_c('div',{staticClass:"col-3 p-r-20"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.providerSubmissionData.prov_num_facilitators_obs_once
                          ),expression:"\n                            providerSubmissionData.prov_num_facilitators_obs_once\n                          "}],staticClass:"form-control",class:{
                            'invalid-input': this.formErrors
                              .prov_num_facilitators_obs_once.flag,
                          },attrs:{"id":"prov_num_facilitators_obs_once","type":"text","required":""},domProps:{"value":(
                            _vm.providerSubmissionData.prov_num_facilitators_obs_once
                          )},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.providerSubmissionData, "prov_num_facilitators_obs_once", $event.target.value)},function($event){return _vm.addThousandComma('prov_num_facilitators_obs_once')}]}})])]),(
                        this.formErrors.prov_num_facilitators_obs_once.flag
                      )?_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(this.formErrors.prov_num_facilitators_obs_once.msg)+" ")])]):_vm._e(),_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-9"},[_c('label',{attrs:{"for":"prov_num_facilitators_obs_2plus"}},[_vm._v("Number of SRAE facilitators observed at least twice")])]),_c('div',{staticClass:"col-3 p-r-20"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.providerSubmissionData.prov_num_facilitators_obs_2plus
                          ),expression:"\n                            providerSubmissionData.prov_num_facilitators_obs_2plus\n                          "}],staticClass:"form-control",class:{
                            'invalid-input': this.formErrors
                              .prov_num_facilitators_obs_2plus.flag,
                          },attrs:{"id":"prov_num_facilitators_obs_2plus","type":"text","required":""},domProps:{"value":(
                            _vm.providerSubmissionData.prov_num_facilitators_obs_2plus
                          )},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.providerSubmissionData, "prov_num_facilitators_obs_2plus", $event.target.value)},function($event){return _vm.addThousandComma(
                              'prov_num_facilitators_obs_2plus'
                            )}]}})])]),(
                        this.formErrors.prov_num_facilitators_obs_2plus.flag
                      )?_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(this.formErrors.prov_num_facilitators_obs_2plus.msg)+" ")])]):_vm._e()])])])])]):_vm._e(),(
              _vm.providerSubmissionData.prov_provider_serve_youth &&
              !_vm.isWinterSeason
            )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card card-default"},[_c('div',{staticClass:"card-header separator"},[_c('div',{staticClass:"card-title"},[_vm._v(" Provider implementation challenges ")])]),_c('fieldset',{staticClass:"card-body"},[_c('legend',[_vm._v(" Indicate how the provider assessed the implementation challenges below: ")]),_c('div',{staticClass:"row"},[_c('br'),_c('div',{staticClass:"col-md-12 col-lg-6"},[_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_implmnt_chlng_recruit_youth"}},[_vm._v("Recruiting youth")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_implmnt_chlng_recruit_youth.flag,
                            },attrs:{"id":"prov_implmnt_chlng_recruit_youth","options":_vm.problem},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_implmnt_chlng_recruit_youth
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_implmnt_chlng_recruit_youth", $$v)},expression:"\n                              providerSubmissionData.prov_implmnt_chlng_recruit_youth\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_implmnt_chlng_youth_engaged"}},[_vm._v("Youth engagement")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_implmnt_chlng_youth_engaged.flag,
                            },attrs:{"id":"prov_implmnt_chlng_youth_engaged","options":_vm.problem},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_implmnt_chlng_youth_engaged
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_implmnt_chlng_youth_engaged", $$v)},expression:"\n                              providerSubmissionData.prov_implmnt_chlng_youth_engaged\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_implmnt_chlng_youth_attend_reg"}},[_vm._v("Getting youth to attend regularly")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_implmnt_chlng_youth_attend_reg.flag,
                            },attrs:{"id":"prov_implmnt_chlng_youth_attend_reg","options":_vm.problem},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_implmnt_chlng_youth_attend_reg
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_implmnt_chlng_youth_attend_reg", $$v)},expression:"\n                              providerSubmissionData.prov_implmnt_chlng_youth_attend_reg\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_implmnt_chlng_recruit_qual_staff"}},[_vm._v("Recruiting quality staff")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_implmnt_chlng_recruit_qual_staff.flag,
                            },attrs:{"id":"prov_implmnt_chlng_recruit_qual_staff","options":_vm.problem},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_implmnt_chlng_recruit_qual_staff
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_implmnt_chlng_recruit_qual_staff", $$v)},expression:"\n                              providerSubmissionData.prov_implmnt_chlng_recruit_qual_staff\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_implmnt_chlng_fcltatrs_undrstnd"}},[_vm._v("Ensuring facilitators understand content")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_implmnt_chlng_fcltatrs_undrstnd.flag,
                            },attrs:{"id":"prov_implmnt_chlng_fcltatrs_undrstnd","options":_vm.problem},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_implmnt_chlng_fcltatrs_undrstnd
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_implmnt_chlng_fcltatrs_undrstnd", $$v)},expression:"\n                              providerSubmissionData.prov_implmnt_chlng_fcltatrs_undrstnd\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_implmnt_chlng_cover_prog_content"}},[_vm._v("Covering program content")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_implmnt_chlng_cover_prog_content.flag,
                            },attrs:{"id":"prov_implmnt_chlng_cover_prog_content","options":_vm.problem},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_implmnt_chlng_cover_prog_content
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_implmnt_chlng_cover_prog_content", $$v)},expression:"\n                              providerSubmissionData.prov_implmnt_chlng_cover_prog_content\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_implmnt_chlng_staff_turnover"}},[_vm._v("Staff turnover")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_implmnt_chlng_staff_turnover.flag,
                            },attrs:{"id":"prov_implmnt_chlng_staff_turnover","options":_vm.problem},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_implmnt_chlng_staff_turnover
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_implmnt_chlng_staff_turnover", $$v)},expression:"\n                              providerSubmissionData.prov_implmnt_chlng_staff_turnover\n                            "}})],1)])]),_c('div',{staticClass:"colmd-12 col-lg-6"},[_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_implmnt_chlng_neg_peer_reaction"}},[_vm._v("Negative peer reactions")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_implmnt_chlng_neg_peer_reaction.flag,
                            },attrs:{"id":"prov_implmnt_chlng_neg_peer_reaction","options":_vm.problem},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_implmnt_chlng_neg_peer_reaction
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_implmnt_chlng_neg_peer_reaction", $$v)},expression:"\n                              providerSubmissionData.prov_implmnt_chlng_neg_peer_reaction\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_implmnt_chlng_youth_bhvrl_probs"}},[_vm._v("Youth behavioral problems")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_implmnt_chlng_youth_bhvrl_probs.flag,
                            },attrs:{"id":"prov_implmnt_chlng_youth_bhvrl_probs","options":_vm.problem},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_implmnt_chlng_youth_bhvrl_probs
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_implmnt_chlng_youth_bhvrl_probs", $$v)},expression:"\n                              providerSubmissionData.prov_implmnt_chlng_youth_bhvrl_probs\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_implmnt_chlng_natural_disasters"}},[_vm._v("Natural disasters")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_implmnt_chlng_natural_disasters.flag,
                            },attrs:{"id":"prov_implmnt_chlng_natural_disasters","options":_vm.problem},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_implmnt_chlng_natural_disasters
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_implmnt_chlng_natural_disasters", $$v)},expression:"\n                              providerSubmissionData.prov_implmnt_chlng_natural_disasters\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_implmnt_chlng_prog_facilities"}},[_vm._v("Program facilities")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_implmnt_chlng_prog_facilities.flag,
                            },attrs:{"id":"prov_implmnt_chlng_prog_facilities","options":_vm.problem},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_implmnt_chlng_prog_facilities
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_implmnt_chlng_prog_facilities", $$v)},expression:"\n                              providerSubmissionData.prov_implmnt_chlng_prog_facilities\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_implmnt_chlng_obtaining_buy_in"}},[_vm._v("Obtaining buy-in/support from key stakeholders")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_implmnt_chlng_obtaining_buy_in.flag,
                            },attrs:{"id":"prov_implmnt_chlng_obtaining_buy_in","options":_vm.problem},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_implmnt_chlng_obtaining_buy_in
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_implmnt_chlng_obtaining_buy_in", $$v)},expression:"\n                              providerSubmissionData.prov_implmnt_chlng_obtaining_buy_in\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_implmnt_chlng_parent_support"}},[_vm._v("Parent support or engagement")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_implmnt_chlng_parent_support.flag,
                            },attrs:{"id":"prov_implmnt_chlng_parent_support","options":_vm.problem},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_implmnt_chlng_parent_support
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_implmnt_chlng_parent_support", $$v)},expression:"\n                              providerSubmissionData.prov_implmnt_chlng_parent_support\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_implmnt_chlng_other"}},[_vm._v("Other")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_implmnt_chlng_other.flag,
                            },attrs:{"id":"prov_implmnt_chlng_other","options":_vm.problem},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_implmnt_chlng_other
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_implmnt_chlng_other", $$v)},expression:"\n                              providerSubmissionData.prov_implmnt_chlng_other\n                            "}})],1)]),(
                          [
                            'Somewhat a problem',
                            'A serious problem',
                          ].includes(
                            this.providerSubmissionData
                              .prov_implmnt_chlng_other
                          )
                        )?_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_implmnt_chlng_other_spec"}},[_vm._v("Please specify")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.providerSubmissionData.prov_implmnt_chlng_other_spec
                            ),expression:"\n                              providerSubmissionData.prov_implmnt_chlng_other_spec\n                            "}],staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_implmnt_chlng_other_spec.flag,
                            },attrs:{"id":"prov_implmnt_chlng_other_spec","maxlength":"200","required":""},domProps:{"value":(
                              _vm.providerSubmissionData.prov_implmnt_chlng_other_spec
                            )},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.providerSubmissionData, "prov_implmnt_chlng_other_spec", $event.target.value)},function($event){return _vm.dataUpdated()}]}}),(
                              this.formErrors.prov_implmnt_chlng_other_spec
                                .flag
                            )?_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(this.formErrors.prov_implmnt_chlng_other_spec .msg)+" ")])]):_vm._e()])]):_vm._e()])]),(this.challenge_msg)?_c('div',{staticClass:"form group row"},[_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(this.challenge_msg)+" ")])]):_vm._e()])])])]):_vm._e(),(
              _vm.providerSubmissionData.prov_provider_serve_youth &&
              !_vm.isWinterSeason
            )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card card-default"},[_c('div',{staticClass:"card-header separator"},[_c('div',{staticClass:"card-title"},[_vm._v("Needs for technical assistance")])]),_c('fieldset',{staticClass:"card-body"},[_c('legend',[_vm._v(" Indicate provider interest in each type of technical assistance below: ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-lg-6"},[_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_tech_assist_recruit_youth"}},[_vm._v("Recruiting youth")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_tech_assist_recruit_youth.flag,
                            },attrs:{"id":"prov_tech_assist_recruit_youth","options":_vm.interest},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_tech_assist_recruit_youth
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_tech_assist_recruit_youth", $$v)},expression:"\n                              providerSubmissionData.prov_tech_assist_recruit_youth\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_tech_assist_youth_engaged"}},[_vm._v("Youth engagement")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_tech_assist_youth_engaged.flag,
                            },attrs:{"id":"prov_tech_assist_youth_engaged","options":_vm.interest},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_tech_assist_youth_engaged
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_tech_assist_youth_engaged", $$v)},expression:"\n                              providerSubmissionData.prov_tech_assist_youth_engaged\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_tech_assist_youth_attend_reg"}},[_vm._v("Getting youth to attend regularly")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_tech_assist_youth_attend_reg.flag,
                            },attrs:{"id":"prov_tech_assist_youth_attend_reg","options":_vm.interest},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_tech_assist_youth_attend_reg
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_tech_assist_youth_attend_reg", $$v)},expression:"\n                              providerSubmissionData.prov_tech_assist_youth_attend_reg\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_tech_assist_recruit_qual_staff"}},[_vm._v("Recruiting quality staff")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_tech_assist_recruit_qual_staff.flag,
                            },attrs:{"id":"prov_tech_assist_recruit_qual_staff","options":_vm.interest},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_tech_assist_recruit_qual_staff
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_tech_assist_recruit_qual_staff", $$v)},expression:"\n                              providerSubmissionData.prov_tech_assist_recruit_qual_staff\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_tech_assist_training_fcltatrs"}},[_vm._v("Training facilitators")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_tech_assist_training_fcltatrs.flag,
                            },attrs:{"id":"prov_tech_assist_training_fcltatrs","options":_vm.interest},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_tech_assist_training_fcltatrs
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_tech_assist_training_fcltatrs", $$v)},expression:"\n                              providerSubmissionData.prov_tech_assist_training_fcltatrs\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_tech_assist_retain_staff"}},[_vm._v("Retaining staff")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_tech_assist_retain_staff.flag,
                            },attrs:{"id":"prov_tech_assist_retain_staff","options":_vm.interest},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_tech_assist_retain_staff
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_tech_assist_retain_staff", $$v)},expression:"\n                              providerSubmissionData.prov_tech_assist_retain_staff\n                            "}})],1)])]),_c('div',{staticClass:"col-md-12 col-lg-6"},[_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_tech_assist_neg_peer_reaction"}},[_vm._v("Negative peer reactions")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_tech_assist_neg_peer_reaction.flag,
                            },attrs:{"id":"prov_tech_assist_neg_peer_reaction","options":_vm.interest},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_tech_assist_neg_peer_reaction
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_tech_assist_neg_peer_reaction", $$v)},expression:"\n                              providerSubmissionData.prov_tech_assist_neg_peer_reaction\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_tech_assist_youth_bhvrl_probs"}},[_vm._v("Youth behavioral problems")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_tech_assist_youth_bhvrl_probs.flag,
                            },attrs:{"id":"prov_tech_assist_youth_bhvrl_probs","options":_vm.interest},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_tech_assist_youth_bhvrl_probs
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_tech_assist_youth_bhvrl_probs", $$v)},expression:"\n                              providerSubmissionData.prov_tech_assist_youth_bhvrl_probs\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_tech_assist_obtaining_buy_in"}},[_vm._v("Obtaining buy-in/support from key stakeholders")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_tech_assist_obtaining_buy_in.flag,
                            },attrs:{"id":"prov_tech_assist_obtaining_buy_in","options":_vm.interest},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_tech_assist_obtaining_buy_in
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_tech_assist_obtaining_buy_in", $$v)},expression:"\n                              providerSubmissionData.prov_tech_assist_obtaining_buy_in\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_tech_assist_evaluation"}},[_vm._v("Evaluation")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_tech_assist_evaluation.flag,
                            },attrs:{"id":"prov_tech_assist_evaluation","options":_vm.interest},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_tech_assist_evaluation
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_tech_assist_evaluation", $$v)},expression:"\n                              providerSubmissionData.prov_tech_assist_evaluation\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_tech_assist_parent_support"}},[_vm._v("Parent support or engagement")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_tech_assist_parent_support.flag,
                            },attrs:{"id":"prov_tech_assist_parent_support","options":_vm.interest},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_tech_assist_parent_support
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_tech_assist_parent_support", $$v)},expression:"\n                              providerSubmissionData.prov_tech_assist_parent_support\n                            "}})],1)]),_c('div',{staticClass:"form group row form-dropdown"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_tech_assist_other"}},[_vm._v("Other")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('b-form-select',{staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_tech_assist_other.flag,
                            },attrs:{"id":"prov_tech_assist_other","options":_vm.interest},on:{"change":function($event){return _vm.dataUpdated()}},model:{value:(
                              _vm.providerSubmissionData.prov_tech_assist_other
                            ),callback:function ($$v) {_vm.$set(_vm.providerSubmissionData, "prov_tech_assist_other", $$v)},expression:"\n                              providerSubmissionData.prov_tech_assist_other\n                            "}})],1)]),(
                          ['Somewhat interested', 'Very interested'].includes(
                            this.providerSubmissionData.prov_tech_assist_other
                          )
                        )?_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"prov_tech_assist_other_spec"}},[_vm._v("Please specify")])]),_c('div',{staticClass:"col-6 p-r-20"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.providerSubmissionData.prov_tech_assist_other_spec
                            ),expression:"\n                              providerSubmissionData.prov_tech_assist_other_spec\n                            "}],staticClass:"form-control",class:{
                              'invalid-input': this.formErrors
                                .prov_tech_assist_other_spec.flag,
                            },attrs:{"id":"prov_tech_assist_other_spec","maxlength":"200","required":""},domProps:{"value":(
                              _vm.providerSubmissionData.prov_tech_assist_other_spec
                            )},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.providerSubmissionData, "prov_tech_assist_other_spec", $event.target.value)},function($event){return _vm.dataUpdated()}]}}),(
                              this.formErrors.prov_tech_assist_other_spec.flag
                            )?_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(this.formErrors.prov_tech_assist_other_spec .msg)+" ")])]):_vm._e()])]):_vm._e()])]),(this.tech_assist_msg)?_c('div',{staticClass:"form group row"},[_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(this.tech_assist_msg)+" ")])]):_vm._e()])])])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 text-right"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.confirmSubmit",modifiers:{"confirmSubmit":true}}],staticClass:"btn btn-lg btn-primary",attrs:{"id":"prov-save-button"},on:{"click":function($event){return _vm.submitForm('save')}}},[_vm._v(" Save ")]),_c('router-link',{staticClass:"btn btn-lg btn-primary m-r-10 m-l-15",attrs:{"to":this.providerSubmissionData.prov_provider_serve_youth
                    ? { name: 'programs', params: { pid: _vm.provider.id } }
                    : { name: 'providers' },"title":this.continueMsg,"id":"prov-continue-button"}},[_vm._v(" Continue ")])],1)]),_c('b-modal',{attrs:{"id":"confirmSubmit"}},[(_vm.errors.length || _vm.showstoppers.length)?_c('div',[_c('p',[_c('b',[_vm._v("Please correct the following error(s):")])]),_c('ul',[_vm._l((_vm.showstoppers),function(showstopper){return _c('li',{key:showstopper,staticClass:"error-message"},[_vm._v(" "+_vm._s(showstopper)+" ")])}),_vm._l((_vm.errors),function(error){return _c('li',{key:error},[_vm._v(" "+_vm._s(error)+" ")])})],2)]):_vm._e(),(!_vm.showstoppers.length && !_vm.errors.length)?_c('p',[_c('b',[_vm._v("No issues detected")]),_c('br'),_vm._v(" The data on this page has been saved. ")]):_vm._e()])],1)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b',[_vm._v("Please correct the following error(s):")])])
}]

export { render, staticRenderFns }