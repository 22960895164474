<template>
  <div class="content dashboardContent" style="padding-left: 0px">
    <div class="container-fluid container-fixed-lg dashboardContent no-padding">
      <div
        v-if="$store.state.granteeId == 0 || !canAccessDashboard"
        class="dashboard-closed-message"
      >
        <h2>The dashboard will be available Feb, 18th</h2>
      </div>
      <div
        v-if="$store.state.granteeId > 0 && canAccessDashboard"
        class="row dashboardContent no-margin no-padding"
      >
        <iframe
          ref="dashContent"
          id="dashboardContent"
          class="dashboardContent"
          style="border: none"
          :src="iframe.src"
          @load="load"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import awsconfig from "../aws-config";
import helpers from "../helpers";

export default {
  data() {
    return {
      iframe: {
        src: `https://dashboard.${awsconfig.url}/`,
        loaded: false,
        window: null,
      },
    };
  },
  computed: {
    canAccessDashboard() {
      return (
        !this.$store.state.isDashboardClosed ||
        helpers.isACF(this.$store.state.user) ||
        helpers.isAdmin(this.$store.state.user)
      );
    },
  },
  methods: {
    load() {
      let _this = this;

      // delay cross window message to allow child window to load
      setTimeout(() => {
        window.document
          .getElementById("dashboardContent")
          .contentWindow.postMessage(
            JSON.stringify({
              event: "signIn",
              session: _this.$store.state.user,
              grant_access: this.$store.state.grantees.find(
                (grant) => grant.id === Number(this.$store.state.granteeId)
              ),
            }),
            _this.iframe.src
          );
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.dashboardContent {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100% !important;
}

.dashboard-closed-message {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
