var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-height"},[_c('div',{staticClass:"lock-container",staticStyle:{"width":"800px","margin-top":"15%"}},[_c('div',{staticClass:"full-height sm-p-t-50 align-items-center d-md-flex"},[_c('div',{staticClass:"row full-width text-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[(_vm.$store.state.grantees[0].id != 0)?_c('div',{staticClass:"card-body"},[_c('h3',{},[_vm._v("Select the Grant you would like to access")]),_c('div',{staticClass:"table-responsive"},[_c('div',{staticClass:"dataTables_wrapper no-footer",attrs:{"id":"basicTable_wrapper"}},[_c('table',{staticClass:"table table-hover dataTable no-footer",attrs:{"id":"basicTable","role":"grid"}},[_vm._m(0),_c('tbody',[_vm._l((_vm.$store.state.grantees),function(grant,index){return _c('tr',{key:grant.id,class:{ odd: index % 2, even: !(index % 2) },attrs:{"role":"row"}},[_c('td',{staticClass:"v-align-middle"},[_c('p',[_vm._v(_vm._s(grant.name))])]),_c('td',{staticClass:"v-align-middle"},[_c('p',[_vm._v(_vm._s(grant.role))])]),_c('td',{staticClass:"v-align-middle"},[_c('p',[_vm._v(_vm._s(grant.cohort))])]),_c('td',{staticClass:"v-align-middle"},[_c('p',[_vm._v(_vm._s(grant.funding_stream))])]),_c('td',{staticClass:"v-align-middle"},[_c('p',[_vm._v(_vm._s(grant.gr_active ? "Active" : "Inactive"))])]),(grant.has_portal_access)?_c('td',[_c('button',{staticClass:"btn btn-primary link-button",class:{
                              disabled: !_vm.canAccessPortal || !grant.gr_active,
                            },attrs:{"id":"portal-button"},on:{"click":function($event){return _vm.selectGrant(grant.id, 'portal')}}},[_vm._v(" Portal "),_c('i',{staticClass:"pg-icon"},[_vm._v("arrow_right")])])]):_vm._e(),(grant.has_dashboard_access)?_c('td',[_c('b-button',{staticClass:"btn btn-success link-button",class:{ disabled: !_vm.canAccessDashboard },attrs:{"id":"dashboard-button"},on:{"click":function($event){return _vm.selectGrant(grant.id, 'dashboard')}}},[_vm._v(" Dashboard "),_c('i',{staticClass:"pg-icon"},[_vm._v("arrow_right")])])],1):_vm._e()])}),(
                          _vm.$store.state.grantees.find(
                            (grantee) => grantee.has_dashboard_access
                          ) && !_vm.canAccessDashboard
                        )?_c('tr',[_c('td',{attrs:{"colspan":"4"}}),_c('td',{staticStyle:{"font-size":"12px","font-style":"italic"},attrs:{"colspan":"2"}},[_vm._v(" The dashboard will be available Feb 18th ")])]):_vm._e(),(
                          _vm.$store.state.grantees.find(
                            (grantee) => grantee.has_portal_access
                          ) && !_vm.canAccessPortal
                        )?_c('tr',[_vm._m(1)]):_vm._e()],2)])])])]):_vm._e(),(_vm.$store.state.grantees[0].id == 0 || !_vm.grantee)?_c('div',[_c('div',{staticClass:"col-md-12 text-center"},[_c('br'),_c('p',{staticClass:"m-b-10"},[_vm._v(" Welcome back, "+_vm._s(_vm.$store.state.user.full_name)+"! ")]),_c('div',{staticClass:"progress-circle-indeterminate"}),_c('p',{staticClass:"m-t-10 m-b-0"},[_vm._v("Loading Your Grants Associations")]),_c('br')])]):_vm._e()])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{attrs:{"role":"row"}},[_c('th',{attrs:{"aria-controls":"basicTable","rowspan":"1","colspan":"1","aria-label":"Grantee Name"}},[_vm._v(" Grantee Name ")]),_c('th',{attrs:{"aria-controls":"basicTable","rowspan":"1","colspan":"1","aria-label":"Role"}},[_vm._v(" My Role ")]),_c('th',{attrs:{"aria-controls":"basicTable","rowspan":"1","colspan":"1","aria-label":"Cohort"}},[_vm._v(" Cohort ")]),_c('th',{attrs:{"aria-controls":"basicTable","rowspan":"1","colspan":"1","aria-label":"Funding Stream"}},[_vm._v(" Funding Stream ")]),_c('th',{attrs:{"aria-controls":"basicTable","rowspan":"1","colspan":"1","aria-label":"Status"}},[_vm._v(" Status ")]),_c('th',{attrs:{"colspan":"1","rowspan":"1"}}),_c('th',{attrs:{"colspan":"1","rowspan":"1"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticStyle:{"font-size":"12px","font-style":"italic","pointer-events":"none"},attrs:{"colspan":"6"}},[_vm._v(" The SRAE Performance Measures Portal is currently closed. "),_c('br'),_vm._v("Additional information on the SRAE Performance Measures is available at "),_c('a',{attrs:{"href":"https://sraepas.com"}},[_vm._v("https://sraepas.com")])])
}]

export { render, staticRenderFns }