<template>
  <div class="full-height">
    <div class="lock-container full-height">
      <div class="full-height sm-p-t-50 align-items-center d-md-flex">
        <div class="row full-width">
          <div class="col-md-6">
            <div class="d-flex justify-content-start align-items-center">
              <div class="">
                <h5 class="logged hint-text no-margin">User log in</h5>
                <!--<h2 class="name no-margin">SRAE-PAS</h2>-->
                <img
                  src="@/assets/SRAEPAS_Logo.png"
                  align="right"
                  class="brand"
                  alt="Sexual Risk Avoidance Education - Performance Analysis Study"
                  width="225"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!-- LOGIN FORM -->
            <b-form @submit="onSignInSubmit" class="form p-t-15">
              <b-form-group
                label-for="login-email"
                description
                class="form-group"
                v-if="step == 'login' || step == 'resetPassword'"
              >
                <label>Username</label>
                <b-form-input
                  id="login-email"
                  v-model="signInform.email"
                  type="email"
                  required
                  placeholder="Email"
                  autocomplete="username"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label-for="login-password"
                description
                class="form-group"
                v-if="step == 'login'"
              >
                <label>Password</label>
                <b-form-input
                  v-model="signInform.password"
                  type="password"
                  required
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>

              <!-- START new passwords -->
              <b-form-group
                label-for="login-newPassword"
                description
                class="form-group"
                v-if="
                  step == 'resetCodeRequested' || step == 'newPasswordRequired'
                "
              >
                <label>New Password</label>
                <b-form-input
                  id="login-newPassword"
                  v-model="signInform.newPassword"
                  type="password"
                  required
                  autocomplete="new-password"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="login-repPassword-group"
                label-for="login-repPassword"
                description
                class="form-group"
                v-if="
                  step == 'resetCodeRequested' || step == 'newPasswordRequired'
                "
              >
                <label>Confirm New Password</label>
                <b-form-input
                  id="login-repPassword"
                  v-model="signInform.repPassword"
                  type="password"
                  required
                  autocomplete="off"
                ></b-form-input>
                <span
                  class="help"
                  v-if="
                    signInform.newPassword != signInform.repPassword &&
                    signInform.repPassword.length >=
                      signInform.newPassword.length
                  "
                  >Password does not match.</span
                >
              </b-form-group>

              <div
                v-if="
                  step == 'resetCodeRequested' || step == 'newPasswordRequired'
                "
                class="row password-policy"
              >
                <p>Your password must contain:</p>
                <ul>
                  <li>
                    <i v-if="passwordLength" class="fa fa-check"></i
                    ><i v-else class="fa fa-times"></i>At least 8 characters
                  </li>
                  <li>
                    <i v-if="passwordUppercases" class="fa fa-check"></i
                    ><i v-else class="fa fa-times"></i>At least one uppercase
                    letter
                  </li>
                  <li>
                    <i v-if="passwordLowerCases" class="fa fa-check"></i
                    ><i v-else class="fa fa-times"></i>At least one lowercase
                    letter
                  </li>
                  <li>
                    <i v-if="passwordNumbers" class="fa fa-check"></i
                    ><i v-else class="fa fa-times"></i>At least one number
                  </li>
                  <li>
                    <i v-if="passwordSpecialCases" class="fa fa-check"></i
                    ><i v-else class="fa fa-times"></i>At least one special
                    character
                  </li>
                </ul>
              </div>
              <!-- END new passwords -->

              <b-form-group
                id="login-confirmCode-group"
                label-for="login-confirmCode"
                description
                class="form-group"
                v-if="step == 'resetCodeRequested'"
              >
                <label>Verification Code</label>
                <b-form-input
                  id="login-confirmCode"
                  v-model="signInform.confirmCode"
                  type="text"
                  required
                  autocomplete="one-time-code"
                ></b-form-input>
              </b-form-group>
              <!--MFA-->
              <b-form-group
                label-for="login-phoneNumber"
                description
                class="form-group"
                v-if="step == 'mfaSetup'"
              >
                <label
                  >Please enter your mobile phone number to enable Multi-Factor
                  Authentication (MFA).</label
                >
                <b-form-select
                  v-model="signInform.phoneNumberCountryCode"
                  :options="mfaCountryCodes"
                  size="sm"
                  class="mt-3 m-b-10"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled
                      >Please select the location</b-form-select-option
                    >
                  </template>
                </b-form-select>
                <masked-input
                  v-if="signInform.phoneNumberCountryCode == '+1'"
                  class="form-control m-b-10"
                  id="login-phoneNumber"
                  mask="\+\1 (111) 111-1111"
                  placeholder="Phone number"
                  @input="signInform.phoneNumber = '+1' + arguments[1]"
                  required
                />
                <masked-input
                  v-if="signInform.phoneNumberCountryCode == '+1787'"
                  class="form-control m-b-10"
                  id="login-phoneNumber"
                  mask="\+\1 \(\7\8\7\) 111-1111"
                  placeholder="Phone number"
                  @input="signInform.phoneNumber = '+1787' + arguments[1]"
                  required
                />
                <masked-input
                  v-if="signInform.phoneNumberCountryCode == '+1671'"
                  class="form-control m-b-10"
                  id="login-phoneNumber"
                  mask="\+\1 \(\6\7\1\) 111-1111"
                  placeholder="Phone number"
                  @input="signInform.phoneNumber = '+1671' + arguments[1]"
                  required
                />
                <masked-input
                  v-if="signInform.phoneNumberCountryCode == '+1340'"
                  class="form-control m-b-10"
                  id="login-phoneNumber"
                  mask="\+\1 \(\3\4\0\) 111-1111"
                  placeholder="Phone number"
                  @input="signInform.phoneNumber = '+1340' + arguments[1]"
                  required
                />
                <masked-input
                  v-if="signInform.phoneNumberCountryCode == '+1684'"
                  class="form-control m-b-10"
                  id="login-phoneNumber"
                  mask="\+\1 \(\6\8\4\) 111-1111"
                  placeholder="Phone number"
                  @input="signInform.phoneNumber = '+16s84' + arguments[1]"
                  required
                />
                <masked-input
                  v-if="signInform.phoneNumberCountryCode == '+691'"
                  class="form-control m-b-10"
                  id="login-phoneNumber"
                  mask="\+\6\9\1 111-1111"
                  placeholder="Phone number"
                  @input="signInform.phoneNumber = '+691' + arguments[1]"
                  required
                />

                <b-alert show variant="warning"
                  >Please ensure the phone number you are entering belongs to a
                  device capable of receiving <b>SMS Text Messages.</b></b-alert
                >
              </b-form-group>

              <b-form-group
                label-for="login-mfaCode"
                description
                class="form-group"
                v-if="step == 'mfaVerify'"
              >
                <label>Verification SMS Code</label>
                <b-form-input
                  id="login-mfaCode"
                  v-model="signInform.mfaCode"
                  type="text"
                  required
                  autocomplete="one-time-code"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label-for="login-mfaCode"
                description
                class="form-group"
                v-if="step == 'customChallenge'"
              >
                <label>Verification Code</label>
                <b-form-input
                  id="login-mfaCode"
                  v-model="signInform.mfaCode"
                  type="text"
                  required
                  autocomplete="one-time-code"
                ></b-form-input>
              </b-form-group>

              <div class="row p-t-5 p-b-5" v-if="signInform.errorMessage">
                <div class="col-md-12">
                  {{ signInform.errorMessage }}
                  <span v-if="signInform.mfaResent">
                    <br />
                    If you are having problems with MFA, please contact the help
                    desk for assistance at
                    <a
                      href="mailto:SRAEperformancemeasures@publicstrategies.com"
                      >SRAEperformancemeasures@publicstrategies.com</a
                    >
                    or call toll-free 1-833-797-0166.
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 no-padding">
                  <a
                    href="#"
                    class="text-info"
                    @click="step = 'resetPassword'"
                    v-if="step == 'login'"
                    ><u>Forgot Password?</u></a
                  >
                  <a
                    href="#"
                    class="text-info"
                    @click="step = 'login'"
                    v-if="step == 'resetPassword'"
                    ><u>Return to Login</u></a
                  >
                  <a
                    href="#"
                    class="text-info"
                    @click="authSignIn(true)"
                    v-if="step == 'mfaVerify'"
                    ><u>Resend MFA code</u></a
                  >
                </div>
              </div>
              <div class="row">
                <div
                  class="col-md-12 d-flex align-items-center justify-content-end"
                >
                  <button
                    class="btn btn-lg btn-primary btn-cons btn-pill gold m-t-10"
                    type="submit"
                    :disabled="
                      (!step == 'resetPassword' && !signInReady) ||
                      (step == 'resetPassword' && !this.signInform.email) ||
                      (step == 'resetCodeRequested' && !resetPassReady)
                    "
                  >
                    {{ signInSubmitBtn }}
                  </button>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import MaskedInput from "vue-masked-input";

export default {
  components: {
    MaskedInput,
  },
  data() {
    return {
      step: "login",
      signInform: {
        userObj: null,
        email: "",
        password: "",
        newPassword: "",
        repPassword: "",
        phoneNumberCountryCode: null,
        phoneNumber: "",
        confirmCode: undefined,
        errorMessage: null,
        submitted: false,
        // resetPassword: false,
        // resetCodeRequested: false,
        // newPasswordRequired: false,
        // mfaSetup:false,
        // mfaVerify:false,
        mfaCode: "",
        mfaResent: false,
      },
      mfaCountryCodes: [
        { value: "+1", text: "United States" },
        { value: "+1787", text: "Puerto Rico" },
        { value: "+1671", text: "Guam" },
        { value: "+1340", text: "U.S. Virgin Islands" },
        { value: "+1684", text: "American Samoa" },
        { value: "+691", text: "Federated States of Micronesia" },
      ],
    };
  },
  computed: {
    signInReady() {
      return this.signInform.email && this.signInform.password ? true : false;
    },
    resetPassReady() {
      return this.signInform.email &&
        this.signInform.newPassword &&
        this.signInform.newPassword === this.signInform.repPassword &&
        this.signInform.confirmCode &&
        this.signInform.confirmCode.replace(/\s/g, "").length === 6
        ? true
        : false;
    },
    signInSubmitBtn() {
      if (this.step == "resetPassword") return "Request Verification Code";
      if (this.step == "resetCodeRequested") return "Reset Password";
      if (this.step == "newPasswordRequired") return "Save New Password";
      if (this.step == "mfaSetup") return "Verify Number";
      // "mfaVerify"
      return "Login";
    },
    passwordLength() {
      const password = this.signInform.newPassword;
      const regex = /(?=.{8,})/;
      return regex.test(password);
    },
    passwordUppercases() {
      const password = this.signInform.newPassword;
      const regex = /(?=.*[A-Z])/;
      return regex.test(password);
    },
    passwordLowerCases() {
      const password = this.signInform.newPassword;
      const regex = /(?=.*[a-z])/;
      return regex.test(password);
    },
    passwordNumbers() {
      const password = this.signInform.newPassword;
      const regex = /(?=.*\d)/;
      return regex.test(password);
    },
    passwordSpecialCases() {
      const password = this.signInform.newPassword;
      const regex = /(?=.*[\W])/;
      return regex.test(password);
    },
  },
  methods: {
    onSignInSubmit(evt) {
      evt.preventDefault();
      //
      // For Submited for New Required Password
      if (this.step == "newPasswordRequired") {
        // user.completeNewPasswordChallenge
        let user = this.signInform.userObj;
        let newPassword = this.signInform.newPassword;
        Auth.completeNewPassword(
          user, // the Cognito User Object
          newPassword // the new password
          // commented out the following argument on 2022-05-05 - seems to be
          // causing password reset issues, but only in staging (?). More details
          // available here: https://stackoverflow.com/questions/71667989/aws-cognito-respond-to-new-password-required-challenge-returns-cannot-modify-an#:~:text=Even%20though%20the%20value%20of,Deleting%20it%20solves%20the%20issue.&text=This%20works%20and%20it's%20very,publish%20any%20updates%20and%20logs.
          // OPTIONAL, the required attributes
          //{
          //  email: this.signInform.email,
          //}
        )
          .then((loggedUser) => {
            // this.signInform.newPasswordRequired = false
            // this.proceedLoginInit()
            if (
              "challengeName" in loggedUser &&
              loggedUser.challengeName === "SMS_MFA"
            ) {
              this.step = "mfaVerify";
            } else {
              this.step = "mfaSetup";
            }
            this.signInform.errorMessage = "";
          })
          .catch((e) => {
            console.error(e);
          });
        //
        // For Submited to Reset Password
      } else if (this.step == "resetCodeRequested") {
        // Collect confirmation code and new password, then
        Auth.forgotPasswordSubmit(
          this.signInform.email,
          this.signInform.confirmCode.replace(/\s/g, ""),
          this.signInform.newPassword
        )
          .then(() => {
            this.signInform.errorMessage = "Password successfully changed.";
            this.step = "login";
          })
          .catch((err) => {
            console.error(err);
            if ("message" in err) this.signInform.errorMessage = err.message;
            else this.signInform.errorMessage = err;
          });
      } else if (this.step == "resetPassword") {
        Auth.forgotPassword(this.signInform.email)
          .then(() => {
            this.step = "resetCodeRequested";
            this.signInform.errorMessage =
              "Verification code sent to " + this.signInform.email;
          })
          .catch((err) => {
            console.error(err);
            if ("message" in err) this.signInform.errorMessage = err.message;
            else this.signInform.errorMessage = err;
          });
        //
        // For submited with phone number to set MFA
      } else if (this.step == "mfaSetup") {
        Auth.currentAuthenticatedUser({ bypassCache: true }).then(async () => {
          Auth.updateUserAttributes(this.signInform.userObj, {
            phone_number: this.signInform.phoneNumber,
            phone_number_verified: true,
          }).then(() => {
            // Auth.currentSession().then(session)
            Auth.currentAuthenticatedUser({ bypassCache: true }).then(
              async (_user) => {
                // set MFA only for US #s
                if (
                  this.signInform.phoneNumberCountryCode.slice(0, 2) == "+1"
                ) {
                  Auth.setPreferredMFA(_user, "SMS").then(() => {
                    // this.step='mfaVerify'
                    Auth.signOut({ global: true }).then(() => {
                      this.authSignIn();
                    });
                  });
                } else {
                  Auth.signOut({ global: true }).then(() => {
                    this.authSignIn();
                  });
                }
              }
            );
          });
          // Auth.currentAuthenticatedUser({ bypassCache: true }).then({})
        });
      } else if (this.step == "mfaVerify") {
        Auth.confirmSignIn(
          this.signInform.userObj,
          this.signInform.mfaCode,
          "SMS_MFA" // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
        ).catch((err) => {
          console.error(err);
          if ("code" in err && err.code == "CodeMismatchException")
            this.signInform.errorMessage = "Invalid MFA code.";
          else if ("message" in err) this.signInform.errorMessage = err.message;
          this.step = "login";
          this.signInform.mfaCode = "";
        });
      } else if (this.step == "customChallenge") {
        Auth.sendCustomChallengeAnswer(
          this.signInform.userObj,
          this.signInform.mfaCode
        ).catch((err) => {
          console.error(err);
          if ("code" in err && err.code == "CodeMismatchException")
            this.signInform.errorMessage = "Invalid code.";
          else if ("message" in err) this.signInform.errorMessage = err.message;
          this.step = "login";
          this.signInform.mfaCode = "";
        });
      } else {
        Auth.signIn({
          username: this.signInform.email,
          password: this.signInform.password,
        })
          .then((user) => {
            this.signInform.userObj = user;
            // Auth.setPreferredMFA(this.signInform.userObj, 'SMS')
            try {
              if (
                user.challengeName === "SMS_MFA" ||
                user.challengeName === "SOFTWARE_TOKEN_MFA"
              ) {
                if (!user.challengeParam.CODE_DELIVERY_DESTINATION) {
                  this.step = "mfaSetup";
                  this.signInform.errorMessage;
                } else {
                  this.step = "mfaVerify";
                  this.signInform.errorMessage =
                    "SMS sent to " +
                    user.challengeParam.CODE_DELIVERY_DESTINATION +
                    ". ";
                }
              } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                this.step = "newPasswordRequired";
                this.signInform.errorMessage = "";
              } else if (user.challengeName === "CUSTOM_CHALLENGE") {
                this.step = "customChallenge";
                this.signInform.errorMessage =
                  "Enter your unique verification code.";
              } else if (
                user.preferredMFA == "NOMFA" ||
                ("attributes" in user && !("phone_number" in user.attributes))
              ) {
                this.step = "mfaSetup";
                this.signInform.errorMessage = "";
              }
            } catch (err) {
              this.signInform.errorMessage = err.message;
              if (err.code === "UserNotConfirmedException") {
                // The error happens if the user didn't finish the confirmation step when signing up
                // In this case you need to resend the code and confirm the user
                // About how to resend the code and confirm the user, please check the signUp part
              } else if (err.code === "PasswordResetRequiredException") {
                // The error happens when the password is reset in the Cognito console
                // In this case you need to call forgotPassword to reset the password
                // Please check the Forgot Password part.
              } else if (err.code === "NotAuthorizedException") {
                // The error happens when the incorrect password is provided
              } else if (err.code === "UserNotFoundException") {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
              } else {
                console.error(err);
              }
            }
          })
          .catch((err) => {
            console.error(err);
            // NotAuthorizedException
            if (err.code === "UserNotConfirmedException") {
              this.signInform.submitted = true;
            } else if ("message" in err)
              this.signInform.errorMessage = err.message;
            else this.signInform.errorMessage = err;
          });
      }
    },
    authSignIn(_mfaResent) {
      if (_mfaResent) this.signInform.mfaResent = true;
      Auth.signIn({
        username: this.signInform.email,
        password:
          this.signInform.newPassword.replace(/\s/g, "").length > 0
            ? this.signInform.newPassword
            : this.signInform.password,
      })
        .then((user) => {
          this.signInform.userObj = user;
          if (user.challengeName === "CUSTOM_CHALLENGE") {
            this.step = "customChallenge";
            this.signInform.errorMessage =
              "Enter your unique verification code.";
          } else {
            this.step = "mfaVerify";
            this.signInform.errorMessage =
              "SMS sent to " +
              user.challengeParam.CODE_DELIVERY_DESTINATION +
              ". ";
          }
        })
        .catch((err) => {
          console.error(err);
          if ("message" in err) this.signInform.errorMessage = err.message;
        });
    },
  },
};
</script>

<style scoped>
.password-policy {
  font-family: Arial, sans-serif;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
}

.password-policy p {
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.password-policy ul {
  padding: 0;
}

.password-policy li {
  list-style-type: none;
}

.password-policy i {
  padding-right: 5px;
}

.password-policy i.fa-times {
  color: #ad0000;
}

.password-policy i.fa-check {
  color: #006100;
}
</style>
