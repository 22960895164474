<template>
  <div class="content">
    <div class="container-fluid container-fixed-lg">
      <div class="row">
        <div class="col-12">
          <div class="card card-default">
            <div class="card-header separator">
              <div class="card-title">
                <ol class="breadcrumb no-margin no-padding">
                  <li class="breadcrumb-item">
                    <router-link
                      :to="{ name: 'providers' }"
                      class="no-margin no-padding"
                      >Providers</router-link
                    >
                  </li>
                  <li
                    class="breadcrumb-item active"
                    v-if="'pid' in $route.params"
                  >
                    <router-link
                      :to="{
                        name: 'provider-data',
                        params: { pid: $route.params.pid },
                      }"
                      class="no-margin no-padding"
                    >
                      {{ provider.name || "Current Provider" }}
                    </router-link>
                  </li>
                  <li
                    class="breadcrumb-item active"
                    v-if="'pmid' in $route.params"
                  >
                    <router-link
                      :to="{
                        name: 'program-reach',
                        params: { pmid: $route.params.pmid },
                      }"
                      class="no-margin no-padding"
                    >
                      {{ program.name }}
                    </router-link>
                  </li>
                </ol>
              </div>
            </div>
            <div class="card-body">
              <h3 v-if="'pmid' in this.$route.params" class="srae-title">
                Program-Level Data
              </h3>
              <h3
                v-if="
                  !('pmid' in this.$route.params) &&
                  !($router.currentRoute.name === 'programs')
                "
                class="srae-title"
              >
                Provider-Level Information
              </h3>
              <h3
                v-if="
                  !('pmid' in this.$route.params) &&
                  $router.currentRoute.name === 'programs'
                "
                class="srae-title"
              >
                Program Model Table
              </h3>
              <h4>
                <b class="srae-subtitle">Grantee Name:</b> {{ grantee.name }}
              </h4>

              <h4><b class="srae-subtitle">Cohort:</b> {{ grantee.cohort }}</h4>

              <h4>
                <b class="srae-subtitle">Funding Stream:</b>
                {{ grantee.funding_stream }}
              </h4>

              <h4>
                <b class="srae-subtitle">Provider Name:</b> {{ provider.name }}
              </h4>
              <h4 v-if="'pmid' in this.$route.params">
                <b class="srae-subtitle">Program Model:</b> {{ program.name }}
              </h4>
              <h4 v-if="'pmid' in this.$route.params">
                <b class="srae-subtitle">Report Period:</b>
                {{
                  this.$route.path.includes("reach") ||
                  this.$route.path.includes("entry-exit")
                    ? $store.state.reportingPeriodStr.prog_reach
                    : $store.state.reportingPeriodStr.prog
                }}
                <b-button
                  v-if="
                    !isWinterSeason &&
                    !this.$route.path.includes('reach') &&
                    !this.$route.path.includes('entry-exit')
                  "
                  v-b-tooltip.hover
                  :title="`All grantees should submit data for the same reporting period—the federal grant year, ${$store.state.reportingPeriodStr.prog}—even if it does not align with the grantee organization’s fiscal year.`"
                  size="sm"
                  >?</b-button
                >
              </h4>
              <h4 v-if="!('pmid' in this.$route.params)">
                <b class="srae-subtitle">Report Period:</b>
                {{ $store.state.reportingPeriodStr.prov }}
                <b-button
                  v-if="!isWinterSeason"
                  v-b-tooltip.hover
                  :title="`All grantees should submit data for the same reporting period—the federal grant year, ${$store.state.reportingPeriodStr.prov}—even if it does not align with the grantee organization’s fiscal year.`"
                  size="sm"
                  >?</b-button
                >
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row"
        v-if="
          isWinterSeason &&
          !('pmid' in this.$route.params) &&
          !($router.currentRoute.name === 'programs')
        "
      >
        <div class="card card-default" id="winter-season-card">
          <div class="card-body">
            <p>
              Welcome to the Sexual Risk Avoidance Education (SRAE) Performance
              Measures Portal (SPMP). Some SRAE performance measures are
              submitted annually each summer, and others are submitted twice a
              year, in winter and summer. Because measures of structure, cost,
              and support for program implementation are submitted annually, the
              only information to record on this page is whether the provider
              served youth during the
              {{ $store.state.reportingPeriodStr.prov }} reporting period, and,
              if so, how many programs were implemented.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card card-transparent">
            <div class="card-body no-padding">
              <ul
                class="nav nav-tabs nav-tabs-fillup d-none d-md-flex d-lg-flex d-xl-flex"
              >
                <li class="nav-item">
                  <b-button
                    v-bind:class="{
                      active: $router.currentRoute.name === 'provider-data',
                    }"
                  >
                    <router-link
                      :to="{
                        name: 'provider-data',
                        params: { pid: provider.id },
                      }"
                      class="d-flex align-items-center"
                    >
                      <span>Enter/Edit Provider-Level Information</span>
                    </router-link>
                  </b-button>
                </li>
                <li class="nav-item">
                  <b-button
                    v-bind:class="{
                      active:
                        $router.currentRoute.name === 'programs' ||
                        'pmid' in $route.params,
                    }"
                  >
                    <router-link
                      :to="{ name: 'programs', params: { pid: provider.id } }"
                      class="d-flex align-items-center"
                    >
                      <span>Enter/Edit Program Model Table</span>
                    </router-link>
                  </b-button>
                </li>
              </ul>
              <div class="tab-content p-l-0 p-r-0">
                <div class="tab-pane sm-no-padding slide-left active">
                  <router-view></router-view>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Add Program Modal-->
    <b-modal
      v-model="$store.state.modals.addProgram.show"
      title="Add Program"
      hide-footer
    >
      <div class="form-horizontal">
        <div class="form-group row no-border">
          <label for="ProgramModelName" class="col-md-3 control-label">
            <b-button
              v-b-tooltip.hover
              title="Enter the name the provider uses to refer to the program, which could be the name of a curriculum or a local name. The program model name entered here will be used on other pages of the portal and in the SRAE Performance Measures Dashboard to identify the program."
              >?</b-button
            >
            Name
          </label>
          <div class="col-md-9">
            <input
              id="ProgramModelName"
              type="text"
              class="form-control"
              aria-invalid="true"
              v-model="addProgramModel.name"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 text-left">
            <button
              @click="$store.state.modals.addProgram.show = false"
              aria-label="Cancel Add Provider Modal"
              type="button"
              class="btn btn-lg btn-primary m-t-5"
            >
              Cancel
            </button>
          </div>
          <div class="col-md-8 text-right">
            <button
              @click="addProgram()"
              aria-label="Add Provider"
              type="button"
              class="btn btn-lg btn-primary m-t-5"
              :class="{ disabled: !canSubmit }"
            >
              Add Program
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <confirmModal
      :show="confirmModel.show"
      :headerTitle="confirmModel.headerTitle"
      :bodyText="confirmModel.bodyText"
      :close="closeConfirmModal"
    />
  </div>
</template>
<style lang="scss" scoped>
#winter-season-card {
  margin: 0 15px 20px;
  width: 100%;

  p {
    font-size: 16px;
    line-height: 26px;
  }
}
</style>

<script>
import mixin from "../../mixin";
import confirmModal from "../../components/confirm-modal.vue";

export default {
  mixins: [mixin],
  components: {
    confirmModal,
  },
  data() {
    return {
      confirmModel: {
        show: false,
        headerTitle: "Program Model Added",
        bodyText: "",
      },
      addProgramModel: {
        name: "",
      },
    };
  },
  computed: {
    canSubmit() {
      return this.addProgramModel.name.replace(/\s/g, "").length > 0;
    },
    isWinterSeason() {
      return this.$store.state.isWinterSeason;
    },
  },
  methods: {
    addProgram() {
      this.$store.dispatch("addProgram", this.addProgramModel).then(() => {
        // this.statusMsg = 'Data saved';
        this.$store.state.modals.addProgram.show = false;
        this.confirmModel.bodyText = `Program Model <i>${this.addProgramModel.name}</i> was added successfully.`;
        this.confirmModel.show = true;
      });
    },
    closeConfirmModal() {
      this.confirmModel.show = false;
      this.confirmModel.bodyText = "";
    },
  },
};
</script>
