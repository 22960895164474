<template>
  <div>
    <div class="card card-default">
      <div class="card-header separator">
        <div class="card-title">Current Status</div>
      </div>
      <div class="card-body">
        <div class="row" v-if="currentStatus">
          <div class="col-9">
            {{ currentStatus }}
          </div>
        </div>
      </div>
    </div>

    <div class="card card-default" v-if="providersPrograms">
      <div class="card-header separator">
        <div class="card-title">
          Upload Excel (.xlsx) or .csv
          <b-button
            v-b-tooltip.hover
            title="If your file includes a large number of survey records (e.g. over 10,000), please use .csv rather than .xlsx format for a successful upload."
            size="sm"
            >?</b-button
          >
          file using the button below
        </div>
      </div>
      <div class="card-body">
        <b-form role="form">
          <div class="form-group row">
            <div class="col-6">
              <b-form-file
                id="file-upload"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @change="handleFile($event)"
                ref="fileInput"
              >
              </b-form-file>
            </div>
          </div>
          <div
            class="row d-flex align-items-center"
            v-if="showFileLoadingStatus && fileUploadedSuccessfully === null"
          >
            <b-spinner
              label="Loading"
              variant="success"
              style="width: 1rem; height: 1rem; margin-right: 8px"
            ></b-spinner>
            <h5>Loading...</h5>
          </div>
          <div
            class="row d-flex align-items-center"
            v-if="showFileLoadingStatus && fileUploadedSuccessfully === true"
          >
            <i
              class="material-icons"
              style="
                color: #28a745;
                width: 1rem;
                height: 1rem;
                margin-right: 8px;
              "
              >check_circle</i
            >
            <h5>
              File loaded successfully. If you intended to choose a different
              file, select or drop that file to replace the one currently
              selected.
            </h5>
          </div>
          <div
            class="row d-flex align-items-center"
            v-if="showFileLoadingStatus && fileUploadedSuccessfully === false"
          >
            <i
              class="material-icons"
              style="
                color: #ea2c54;
                width: 1rem;
                height: 1rem;
                margin-right: 8px;
              "
              >error</i
            >
            <h5>Error loading file.</h5>
          </div>
          <div class="row" v-if="messageData.fileExtension.length > 0">
            <div
              v-for="item in messageData.fileExtension"
              v-bind:key="item.fileName"
            >
              The uploaded file ({{ item.fileName }}) has extension
              {{ item.fileExtension }}, but only extensions xlsx and csv can be
              accepted. If your file is not xlsx or csv format, please save your
              data in xlsx or csv format and try again. If your file is in xlsx
              or csv format, but has an incorrect file extension, please rename
              it with the correct extension.
            </div>
          </div>
        </b-form>
      </div>
    </div>

    <div
      class="card card-default"
      v-if="!providersPrograms && messageData.providersPrograms.length > 0"
    >
      <div class="card-header separator">
        <div class="card-title">
          There was an issue preparing to receive survey data.
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-9">
            <b-form role="form">
              <div
                class="row"
                v-for="item in messageData.providersPrograms"
                v-bind:key="item.text"
              >
                {{ item.text }}
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>

    <div v-if="workbook">
      <div v-if="customFile">
        <div class="card card-default">
          <div class="card-header separator">
            <div class="card-title">Custom File</div>
          </div>
          <div class="card-body">
            <span
              >It looks like you're using a custom file. We need to know a few
              more details to make sure the file is read correctly.</span
            >
            <p></p>
            <b-form role="form">
              <div class="form-group row">
                <div class="col-6">
                  <label for="sheet"
                    >Select the sheet that contains {{ surveyType }} survey
                    data:</label
                  >
                </div>
                <div class="col-4">
                  <b-form-select
                    v-model="sheet"
                    id="sheet"
                    :options="sheetOptions"
                    v-on:input="resetFormDataSubmission()"
                  />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-6">
                  <label for="headersRow"
                    >Enter the row number in which column names/headers
                    appear:</label
                  >
                </div>
                <div class="col-4">
                  <input
                    v-model="headersRow"
                    id="headersRow"
                    type="number"
                    class="form-control"
                    required=""
                    value="1"
                    min="1"
                    step="1"
                  />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-6">
                  <label for="headersRow"
                    >Enter the row number in which data entries begin:</label
                  >
                </div>
                <div class="col-4">
                  <input
                    v-model="dataRow"
                    id="dataRow"
                    type="number"
                    class="form-control"
                    required=""
                    value="1"
                    min="1"
                    step="1"
                  />
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>

      <div v-if="customFile">
        <div class="card card-default">
          <div class="card-header separator">
            <div class="card-title">Column Mapping</div>
          </div>
          <div class="card-body">
            <div class="form-group row">
              <div class="col-10">
                <ul>
                  <li>
                    Please select the column from your file that corresponds to
                    each survey item
                  </li>
                  <li>You must make a selection for each survey item</li>
                  <li>
                    If your file does not contain a particular survey item,
                    please select "{{ notUploadedText }}" for that item
                  </li>
                  <li>No column can be mapped to more than one survey item</li>
                </ul>
              </div>
            </div>
            <b-form role="form">
              <div
                class="form-group row"
                v-for="column in columns"
                v-bind:key="column.label"
              >
                <div class="col-4">
                  <span>{{ column.label }}</span>
                </div>
                <div class="col-3">
                  <b-form-select
                    v-model="column.customColumn"
                    :id="column.dbColumn"
                    :options="columnOptions"
                    v-on:input="updateMappedColumns"
                  />
                </div>
              </div>
              <p></p>
              <p></p>
              <p></p>
            </b-form>
          </div>
        </div>
      </div>

      <div
        id="data-check-results"
        v-if="
          messageData.unselectedRequiredColumns.length > 0 ||
          messageData.invalidProvidersPrograms.length > 0 ||
          messageData.invalidRequiredColumns.length > 0 ||
          messageData.invalidDataColumns.length > 0
        "
      >
        <div class="card card-default">
          <div class="card-header separator">
            <div class="card-title">
              Data Check Results -
              <span style="color: #990000">
                (issues listed must be resolved before uploading data)</span
              >
            </div>
          </div>
          <div class="card-body">
            <div class="form-group row">
              <div class="col-9">
                <div v-if="messageData.unselectedRequiredColumns.length > 0">
                  <div>
                    Required columns not selected:
                    <ul>
                      <li
                        v-for="item in messageData.unselectedRequiredColumns"
                        v-bind:key="item.label"
                      >
                        No column was selected for
                        <strong>{{ item.label }}</strong
                        >.
                      </li>
                    </ul>
                  </div>
                </div>

                <div v-if="messageData.invalidProvidersPrograms.length > 0">
                  <div>
                    Invalid provider names and program names:
                    <ul>
                      <li
                        v-for="item in messageData.invalidProvidersPrograms"
                        v-bind:key="item.fileColumn"
                      >
                        {{ item.count }} rows have an invalid combination of
                        provider name and program name. For example,
                        <strong>row {{ item.exampleRowNum }}</strong> has
                        provider name
                        <strong>{{ item.exampleValue.provider }}</strong> and
                        program name
                        <strong>{{ item.exampleValue.program }}</strong
                        >.
                      </li>
                    </ul>
                  </div>

                  <b-button
                    @click="toggleProviderProgramTable()"
                    class="btn btn-lg btn-primary"
                  >
                    {{
                      (showProviderProgramTable ? "Hide" : "Show") +
                      " acceptable provider and program name combinations"
                    }}
                  </b-button>
                  <p></p>

                  <div
                    id="provider-program-table"
                    class="table-responsive"
                    v-if="showProviderProgramTable"
                  >
                    <div
                      id="condensedTable_wrapper"
                      class="dataTables_wrapper no-footer"
                    >
                      <table
                        class="table table-hover table-condensed dataTable no-footer"
                        id="condensedTable"
                        role="grid"
                      >
                        <thead>
                          <tr role="row">
                            <th rowspan="1" colspan="2">Provider</th>
                            <th rowspan="1" colspan="2">Program</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in providersProgramsArray"
                            :key="index"
                            role="row"
                            class="odd-even"
                          >
                            <td class="v-align-middle semi-bold" colspan="2">
                              {{ item.provider_name }}
                            </td>
                            <td class="v-align-middle semi-bold" colspan="2">
                              {{ item.program_name }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div v-if="messageData.invalidRequiredColumns.length > 0">
                  <div>
                    Missing values for required columns:
                    <ul>
                      <li
                        v-for="item in messageData.invalidRequiredColumns"
                        v-bind:key="item"
                      >
                        <strong>{{ item }}</strong> row(s) have missing values
                        (blanks) for one or more required columns ({{
                          requiredColumnLabels.join(", ")
                        }}).
                        <p></p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div v-if="messageData.invalidDataColumns.length > 0">
                  <div>
                    Invalid values:
                    <ul>
                      <li
                        v-for="item in messageData.invalidDataColumns"
                        v-bind:key="item.fileColumn"
                      >
                        Column <strong>{{ item.fileColumn }}</strong> has
                        <strong>{{ item.count }}</strong> out-of-range value(s).
                        <br />

                        For example,
                        <strong>row {{ item.exampleRowNum }}</strong> has value
                        <strong>{{ item.exampleValue }}</strong
                        >.

                        <div v-if="item.dbColumn.search('_other_spec') >= 0">
                          Values must be 100 characters or less.
                        </div>

                        <div v-if="item.dbColumn.search('_other_spec') === -1">
                          Acceptable values for
                          <strong>{{ item.label }}</strong> are:
                          <strong>{{ item.values }}</strong
                          >.
                        </div>

                        <p></p>
                      </li>
                    </ul>
                    Please confirm that those columns are mapped to the correct
                    items. If the mapping is correct, review the list of
                    acceptable values for each column, correct data file as
                    needed, and upload the revised file.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row"
        v-if="
          messageData.rowCount.length > 0 ||
          messageData.programCheck.length > 0 ||
          messageData.missingValues.length > 0
        "
      >
        <div class="col-12">
          <div class="card card-default">
            <div class="card-header separator">
              <div class="card-title">Data Summary</div>
            </div>
            <div class="card-body">
              <div
                class="form-group row"
                v-if="messageData.rowCount.length > 0"
              >
                <div class="col-9">
                  Row count
                  <ul>
                    <li
                      v-for="(item, index) in messageData.rowCount"
                      v-bind:key="index"
                    >
                      <span v-if="item.rowCount === expectedSurveyCount.total">
                        Your file contains {{ item.rowCount }} survey records
                        with valid provider and program names, which aligns with
                        the number of {{ surveyType }} surveys you reported on
                        earlier pages.
                      </span>
                      <span
                        v-if="item.rowCount != expectedSurveyCount.total"
                        class="error-dark"
                      >
                        Your file contains {{ item.rowCount }} survey records
                        with valid provider and program names, but on earlier
                        pages, you reported a total of
                        {{ expectedSurveyCount.total }} {{ surveyType }} surveys
                        administered by your programs during the period. Please
                        confirm.
                      </span>
                    </li>
                  </ul>
                </div>

                <div class="col-9" v-if="surveyType === 'entry'">
                  MS Impact survey count
                  <ul>
                    <li
                      v-for="(item, index) in messageData.impactMsCount"
                      v-bind:key="index"
                    >
                      <span
                        v-if="
                          item.impactMsCount === expectedSurveyCount.impactMs
                        "
                      >
                        Your file contains {{ item.impactMsCount }} MS Impact
                        survey records with valid provider and program names,
                        which aligns with the number of MS Impact surveys you
                        reported on earlier pages.
                      </span>
                      <span
                        v-if="
                          item.impactMsCount != expectedSurveyCount.impactMs
                        "
                        class="error-dark"
                      >
                        Your file contains {{ item.impactMsCount }} MS Impact
                        survey records with valid provider and program names,
                        but on earlier pages, you reported a total of
                        {{ expectedSurveyCount.impactMs }} MS Impact surveys
                        administered by your programs during the period. Please
                        confirm.
                      </span>
                    </li>
                  </ul>
                </div>

                <div class="col-9" v-if="surveyType === 'entry'">
                  HS Impact survey count
                  <ul>
                    <li
                      v-for="(item, index) in messageData.impactHsCount"
                      v-bind:key="index"
                    >
                      <span
                        v-if="
                          item.impactHsCount === expectedSurveyCount.impactHs
                        "
                      >
                        Your file contains {{ item.impactHsCount }} HS Impact
                        survey records with valid provider and program names,
                        which aligns with the number of HS Impact surveys you
                        reported on earlier pages.
                      </span>
                      <span
                        v-if="
                          item.impactHsCount != expectedSurveyCount.impactHs
                        "
                        class="error-dark"
                      >
                        Your file contains {{ item.impactHsCount }} HS Impact
                        survey records with valid provider and program names,
                        but on earlier pages, you reported a total of
                        {{ expectedSurveyCount.impactHs }} HS Impact surveys
                        administered by your programs during the period. Please
                        confirm.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                class="form-group row"
                v-if="messageData.programCheck.length > 0"
              >
                <div class="col-9">
                  Program check

                  <ul>
                    <li
                      v-for="(item, index) in messageData.programCheck"
                      v-bind:key="index"
                    >
                      No data found in this file for provider "{{
                        item.providerName
                      }}" and program "{{ item.programName }}"
                    </li>
                  </ul>
                </div>
              </div>

              <div
                class="form-group row"
                v-if="messageData.missingValues.length > 0"
              >
                <div class="col-9">
                  Missing/blank values

                  <ul>
                    <li
                      v-for="item in messageData.missingValues"
                      v-bind:key="item.fileColumn"
                    >
                      Column {{ item.fileColumn }} is missing/blank for
                      {{ item.percent >= 100 ? "100%" : "more than 25%" }} of
                      rows{{
                        item.dbColumn.search("_lang_other_spec") >= 0
                          ? " that indicated 'other language selected.'"
                          : item.dbColumn.search("_race_other_spec") >= 0
                          ? " that indicated 'other race selected.'"
                          : "."
                      }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card card-default" v-if="debug">
        <div class="card-header separator">
          <div class="card-title">Data</div>
        </div>
        <div class="card-body" v-if="workbook">
          <div class="form-group row">
            <div class="col-12">
              <h5>Column map selection data:</h5>
              <span>{{ JSON.stringify(columns) }}</span>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12">
              <h5>All data from selected sheet:</h5>
              <span>{{ JSON.stringify(sheetData) }}</span>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12">
              <h5>Form submission data:</h5>
              <span>{{ JSON.stringify(formDataSubmission) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <b-button
          @click="checkData()"
          class="btn btn-lg btn-primary"
          :disabled="!sheetData || (customFile && !columnMappingComplete)"
        >
          Check Data
        </b-button>

        <b-button
          @click="submitForm('save')"
          class="btn btn-lg btn-primary m-r-15 m-l-15"
          :disabled="!formDataSubmission"
        >
          Save
        </b-button>

        <b-button
          @click="deleteData()"
          class="btn btn-lg btn-danger m-l-15"
          v-if="env === 'local' || env === 'dev'"
        >
          Delete Existing Data
        </b-button>
      </div>
    </div>

    <div
      class="card card-default"
      v-if="sheetData && customFile && !columnMappingComplete"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <h5>
              You must select an option for each item before clicking the "Check
              Data" button.
            </h5>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-default" v-if="messageData.batchStatus.length > 0">
      <div class="card-header separator">
        <div class="card-title">Upload Progress</div>
      </div>
      <div class="card-body" v-if="workbook">
        <div class="form-group row">
          <div class="col-9">
            <div
              v-for="item in messageData.batchStatus"
              v-bind:key="item.batchNumber"
            >
              <div v-if="item.complete">
                <span>Upload complete. You may now leave this page.</span>
              </div>

              <div v-if="item.inProgress">
                <span>
                  This process may take several minutes to complete, depending
                  on the number of rows in the uploaded file and your internet
                  connection.
                </span>
                <span class="error-dark"
                  >Please DO NOT leave this page while upload is in progress.
                </span>
                <br /><br />
                <b-spinner
                  label="Loading"
                  variant="success"
                  style="width: 1rem; height: 1rem; margin-right: 8px"
                ></b-spinner>
                <span>{{
                  `Uploading rows ${item.rowStart} through ${item.rowStop} (batch ${item.batchNumber} of ${item.batchTotal}).`
                }}</span>
              </div>
              <div v-if="item.failed">
                <span
                  >Upload failed. Please try again in a few minutes. If upload
                  fails three times in a row, contact the SRAE Performance
                  Measures Help Desk at
                  <a href="mailto:SRAEperformancemeasures@publicstrategies.com"
                    >SRAEperformancemeasures@publicstrategies.com</a
                  >.</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="submissionConfirmation">
      <p>
        <b>{{
          submissionConfirmation
            ? "Data saved successfully."
            : "There was a problem saving the uploaded data. Please try again."
        }}</b>
      </p>
    </b-modal>

    <b-modal id="fileReadError">
      <p>
        <b
          >There was a problem reading the uploaded data file. Please confirm
          the file is in XLSX or CSV format and try again.</b
        >
      </p>
    </b-modal>
  </div>
</template>

<script>
//import XLSX from 'xlsx';
const awsConfig = require("../../aws-config.js");
const gzip = require("node-gzip");
const papaparse = require("../../../node_modules/papaparse/papaparse.min.js");
const surveyColumns = require("./survey-columns.js");
const XLSX = require("../../../node_modules/xlsx/dist/xlsx.mini.min.js");

export default {
  name: "upload",

  data() {
    return {
      debug: false,

      // We use the environment name to make the "Delete Data" button appear
      // only in the local and dev environments for testing
      env: awsConfig.default.env,

      currentStatus: "",

      customFile: false,

      // the row number to use for the first data entry
      // keep this 1-based to make easier for users to understand,
      // and we'll subtract 1 when using it to index arrays
      dataRow: 2,

      // sets of columns that are defined as ENUMs in the database,
      // and the acceptable values (input will be numeric, and we need to
      // translate to text to submit to database)
      enumMaps: surveyColumns.default.enumMaps,

      file: null,
      fileExtension: null,

      // this will be replaced with the JSON structure to be sent to RDS, after the data passes all checks
      // in the checkData method
      formDataSubmission: null,

      // the row number to use for column headers
      // keep this 1-based to make easier for users to understand,
      // and we'll subtract 1 when using it to index arrays
      headersRow: 1,

      // A boolean attribute to determine whether to show the user a loading beacon to indicate a file is being loaded
      showFileLoadingStatus: false,
      fileUploadedSuccessfully: null, //null if is still loading, true if successful, false otherwise

      mappedColumns: [],

      // messages to show the user if there are any issues
      messageData: {
        batchStatus: [],
        fileExtension: [],
        invalidDataColumns: [],
        invalidProvidersPrograms: [],
        invalidRequiredColumns: [],
        missingValues: [],
        programCheck: [],
        providersPrograms: [],
        rowCount: [],
        impactMsCount: [],
        impactHsCount: [],
        unselectedRequiredColumns: [],
      },

      // This is the same data
      providersProgramsArray: [],

      requiredColumns: ["provider_name", "program_name", "survey_version"],

      // sheet is the sheet name selected by the user, after uploading a file
      sheet: null,
      // sheetOptions contains the choices for the select input where the user
      // can choose which sheet to use. Updated when the workbook file input changes.
      sheetOptions: [{ text: "Please select a sheet", value: null }],

      showProviderProgramTable: false,

      submissionConfirmation: null,

      // surveyType: "entry",

      notUploadedText: "< not in uploaded file >",

      // number of records to send to database in each batch
      uploadBatchSize: 500,

      // workbook is the file uploaded by the user
      workbook: null,
    };
  },

  props: {
    surveyType: {
      type: String,
    },
  },

  watch: {
    "$store.state.granteeId": function () {
      this.getProvidersPrograms();
      this.getLastUpload();
    },

    "$store.state.reportingPeriodData": function () {
      this.getProvidersPrograms();
      this.getLastUpload();
    },
  },

  created() {
    // get set of providers and programs associated with this grant
    if (this.$store.state.granteeId) {
      this.getProvidersPrograms();
      this.getLastUpload();
      this.updateMappedColumns();
    }
  },

  methods: {
    setFileLoadingStatus(status = "loading", success = null) {
      this.fileUploadedSuccessfully = success;
      if (status == "complete") {
        // keep success/complete message visible indefinitely for now
        // window.setTimeout(() => (this.showFileLoadingStatus = false), 5000);
      } else {
        this.showFileLoadingStatus = true;
      }
    },

    checkData() {
      var self = this;

      this.resetMessageData();

      var columns = this.columns;

      var dataSummary = {
        invalidValues: {
          dataColumns: {},
          providersPrograms: {},
          requiredColumns: {},
        },
        missingValues: {},
        observedProvidersPrograms: {},
        rowCount: 0,
        impactMsCount: 0,
        impactHsCount: 0,
      };

      var providersPrograms = this.providersPrograms;

      // Catch required columns that were not selected (null) or that were marked as not present in uploaded file
      var unselectedRequiredColumns = columns
        .map(function (column) {
          var unselected =
            self.customFile &&
            self.requiredColumns.includes(column.dbColumn) &&
            [null, self.notUploadedText].includes(column.customColumn);

          if (unselected) {
            self.messageData.unselectedRequiredColumns.push({
              label: column.label,
            });
          }

          return unselected;
        })
        .some(function (x) {
          return x;
        });

      var csvHeaders;

      var surveyData = this.sheetData.map(function (row, rowNum) {
        var providerName = null;
        var programName = null;
        var surveyVersion = null;
        var providerProgramExpected;
        var languageOther, raceOther;

        var rowValues;

        if (rowNum === 0) {
          csvHeaders = row;
          rowValues = [];
        } else {
          var rowObj = {};

          for (let i = 0; i < row.length; i++) {
            rowObj[csvHeaders[i]] = row[i];
          }

          row = rowObj;

          rowValues = columns.map(function (column) {
            var value;
            var fileColumn;
            var invalidValue;
            var invalidValueType;
            var exampleValue;

            if (self.customFile) {
              fileColumn = column.customColumn;
            } else {
              fileColumn = column.templateColumn;
            }

            value = row[fileColumn];

            //var valueObj;

            var valueBlank =
              typeof value === "undefined" || value.toString() === "";
            var columnNotUploaded =
              self.customFile && fileColumn === self.notUploadedText;

            if (valueBlank) {
              if (column.dbColumn === "program_name") value = "";
              else value = null;

              if (!columnNotUploaded) {
                // keep a count of missing values, which will be compared to total number of rows of data
                // so we can display a message if more than 25% of rows are missing for a column.
                if (
                  providerName &&
                  programName &&
                  surveyVersion &&
                  providerProgramExpected &&
                  fileColumn &&
                  (column.dbColumn.search("_lang_other") === -1 ||
                    languageOther) &&
                  (column.dbColumn.search("_race_other") === -1 || raceOther)
                ) {
                  if (dataSummary.missingValues[column.dbColumn]) {
                    dataSummary.missingValues[column.dbColumn].count++;
                  } else {
                    dataSummary.missingValues[column.dbColumn] = {
                      count: 1,
                      dbColumn: column.dbColumn,
                      fileColumn: fileColumn,
                      sortOrder: self.dbColumnsOrdered.indexOf(column.dbColumn),
                    };
                  }
                }
              }
            }

            // For most columns, we only want to run this block if the value is not blank.
            // But for program_name, we need to run it regardless, because that's when
            // we determine if the combination of provider and program name is valid or not.
            // If we only ran with a non-blank program name, we would not flag a combination
            // of a valid provider name with a blank program name.
            if (!valueBlank || column.dbColumn === "program_name") {
              if (column.dbColumn === "provider_name") {
                providerName = self.removeDuplicateSpaces(
                  value.toString().trim()
                );
                column.values = Object.keys(self.providersPrograms);
              } else if (column.dbColumn === "program_name") {
                // As described in the comment above, we can be in this block if
                // program name is blank. But if that's the case, we don't want to set
                // the variable programName to blank - we should keep it null as defined
                // at the top of the function each data row runs through, because
                // below there's a count of how many required columns remained null.
                if (value.toString() != "") {
                  programName = self.removeDuplicateSpaces(
                    value.toString().trim()
                  );
                }

                if (self.providersPrograms[providerName]) {
                  column.values = self.providersPrograms[providerName];
                }

                // If this row's combination of provider and program row exists in providersPrograms,
                // increment the total number of rows with expected provider-program combinations.
                // Also keep track of which ones are observed cumulatively, so we can display it in the
                // file dataSummary.

                providerProgramExpected =
                  Object.keys(providersPrograms).includes(providerName) &&
                  providersPrograms[providerName].includes(
                    value.toString().trim()
                  );

                if (providerProgramExpected) {
                  dataSummary.rowCount++;

                  var observedProvider = Object.keys(
                    dataSummary.observedProvidersPrograms
                  ).includes(providerName);

                  if (!observedProvider) {
                    dataSummary.observedProvidersPrograms[providerName] = [
                      programName,
                    ];
                  } else {
                    var observedProgram = dataSummary.observedProvidersPrograms[
                      providerName
                    ].includes(programName);

                    if (!observedProgram) {
                      dataSummary.observedProvidersPrograms[providerName].push(
                        programName
                      );
                    }
                  }
                }
              } else if (
                ["en_lang_other", "ex_lang_other"].includes(column.dbColumn)
              ) {
                languageOther = parseInt(value) === 1;
              } else if (
                ["en_race_other", "ex_race_other"].includes(column.dbColumn)
              ) {
                raceOther = parseInt(value) === 1;
              } else {
                if (!column.values && column.enumName) {
                  column.values = Object.keys(self.enumMaps[column.enumName]);
                }

                if (column.dbColumn === "survey_version") {
                  surveyVersion = value.toString();

                  if (providerProgramExpected) {
                    if (surveyVersion.trim().toUpperCase() === "MS IMPACT") {
                      dataSummary.impactMsCount++;
                    } else if (
                      surveyVersion.trim().toUpperCase() === "HS IMPACT"
                    ) {
                      dataSummary.impactHsCount++;
                    }
                  }
                }
              }

              if (column.dbColumn === "provider_name") {
                // Do nothing - since we're checking provider and program as a combination,
                // wait until we have program name.
                invalidValue = false;
              } else if (column.dbColumn === "program_name") {
                invalidValue =
                  providerName != null &&
                  programName != null &&
                  !(
                    Object.keys(providersPrograms).includes(providerName) &&
                    providersPrograms[providerName].includes(programName)
                  );
              } else if (
                column.dbColumn.search("_lang_other_spec") >= 0 ||
                column.dbColumn.search("_race_other_spec") >= 0
              ) {
                // For our free response columns, verify entry is short enough to fit in database,
                // which allows up to 100 characters.
                invalidValue = value.toString().length > 100;
                column.values = [""];
              } else {
                // flag as invalid value if either:
                // column is a required column, but we do not have any required columns that are not selected (null) or not uploaded.
                //      - otherwise, this can lead to confusing output, where values are marked invalid solely because the column
                //      - was not selected. better to show the first problem (not selected/uploaded), then after that's solved,
                //      - show the invalid values, if any
                // OR column is not a required column,
                // AND and the value isn't in the list of acceptable values.
                column.values;

                invalidValue =
                  ((self.requiredColumns.includes(column.dbColumn) &&
                    !unselectedRequiredColumns) ||
                    !self.requiredColumns.includes(column.dbColumn)) &&
                  column.values &&
                  column.values.indexOf(value.toString().toUpperCase()) === -1;
              }

              if (invalidValue) {
                if (
                  column.dbColumn === "provider_name" ||
                  column.dbColumn === "program_name"
                ) {
                  invalidValueType = "providersPrograms";
                  exampleValue = {
                    provider: providerName,
                    program: programName,
                  };
                } else {
                  invalidValueType = "dataColumns";
                  exampleValue = value.toString();
                }

                if (
                  dataSummary.invalidValues[invalidValueType][column.dbColumn]
                ) {
                  dataSummary.invalidValues[invalidValueType][column.dbColumn]
                    .count++;
                } else {
                  dataSummary.invalidValues[invalidValueType][
                    column.dbColumn
                  ] = {
                    count: 1,
                    dbColumn: column.dbColumn,
                    exampleRowNum:
                      parseInt(rowNum) - 1 + parseInt(self.dataRow),
                    exampleValue: exampleValue,
                    label: column.label,
                    fileColumn: fileColumn,
                    sortOrder: self.dbColumnsOrdered.indexOf(column.dbColumn),
                    values: column.values.join(", "),
                  };
                }
              }
            }

            return value;
            // return { name: column.dbColumn, value: valueObj };
          });

          rowValues.push(rowNum + 1);

          // We have three required columns: provider_name, program_name, and survey_version.
          // If all three are null (blank), we can skip this row.
          // If at least one is not null, but at least one is null, we should mark it as an
          // error.
          var requiredValues = [providerName, programName, surveyVersion];

          var requiredValuesNulls = requiredValues.filter(function (x) {
            return x === null;
          }).length;

          if (requiredValuesNulls === self.requiredColumns.length) {
            rowValues = [];
          } else if (requiredValuesNulls > 0) {
            if (dataSummary.invalidValues.requiredColumns.count) {
              dataSummary.invalidValues.requiredColumns.count++;
            } else {
              dataSummary.invalidValues.requiredColumns.count = 1;
            }
          }
        }

        return rowValues;
      });

      // filter out rows that were left empty because of missing providerName or programName
      surveyData = surveyData.filter(function (row) {
        return row.length > 0;
      });

      self.messageData.invalidDataColumns = Object.values(
        dataSummary.invalidValues.dataColumns
      ).sort(function (a, b) {
        return a.sortOrder - b.sortOrder;
      });

      self.messageData.invalidProvidersPrograms = Object.values(
        dataSummary.invalidValues.providersPrograms
      ).sort(function (a, b) {
        return a.sortOrder - b.sortOrder;
      });

      self.messageData.invalidRequiredColumns = Object.values(
        dataSummary.invalidValues.requiredColumns
      );

      // Only want to keep missingValue cases if >= 25% of rows (based on dataSummary.rowCount) are missing.
      self.messageData.missingValues = Object.values(dataSummary.missingValues)
        .map(function (item) {
          item.percent = 100 * (item.count / dataSummary.rowCount);

          return item;
        })
        .filter(function (item) {
          return item.percent > 25;
        })
        .sort(function (a, b) {
          return a.sortOrder - b.sortOrder;
        });

      this.messageData.rowCount.push({
        rowCount: dataSummary.rowCount,
      });

      this.messageData.impactMsCount.push({
        impactMsCount: dataSummary.impactMsCount,
      });

      this.messageData.impactHsCount.push({
        impactHsCount: dataSummary.impactHsCount,
      });

      var providers = Object.keys(providersPrograms);

      for (var i = 0; i < providers.length; i++) {
        var programs = providersPrograms[providers[i]];

        for (var j = 0; j < programs.length; j++) {
          if (
            !dataSummary.observedProvidersPrograms[providers[i]] ||
            !dataSummary.observedProvidersPrograms[providers[i]].includes(
              programs[j]
            )
          ) {
            this.messageData.programCheck.push({
              providerName: providers[i],
              programName: programs[j],
            });
          }
        }
      }

      var passed =
        Object.values(dataSummary.invalidValues.providersPrograms).length ===
          0 &&
        Object.values(dataSummary.invalidValues.dataColumns).length === 0 &&
        Object.values(dataSummary.invalidValues.requiredColumns).length === 0 &&
        !unselectedRequiredColumns;

      if (passed) {
        this.formDataSubmission = surveyData;
      }

      setTimeout(() => this.scrollToId("data-check-results"), 250);
    },

    scrollToId(id) {
      const element = document.getElementById(id);
      if (!element) {
        return;
      }
      this.$smoothScroll({
        scrollTo: element,
        offset: -61,
        updateHistory: true,
      });
    },

    deleteData() {
      this.$store
        .dispatch("deleteSubmissionData", {
          path: `/${this.surveyType}/${this.$store.state.granteeId}/submissions/${this.$store.state.reportingPeriodData.pk}`,
          target: "",
        })
        .then((response) => {
          if (response.success) {
            this.currentStatus = `We do not have a record of any previous uploaded ${this.surveyType} survey data files for this grant.`;
          }
        });
    },

    getLastUpload() {
      if (
        this.$store.state.granteeId != 0 &&
        this.$store.state.reportingPeriodData.pk
      ) {
        this.$store
          .dispatch("getSubmissionData", {
            path: `/${this.surveyType}/${this.$store.state.granteeId}/submissions/${this.$store.state.reportingPeriodData.pk}/uploads/reduce`,
            target: "",
          })
          .then((response) => {
            if (response.data.length > 0) {
              var data = response.data[0];

              var date = new Date(data.upload_time + " UTC");

              this.currentStatus = `You have already uploaded ${
                this.surveyType
              } survey data for this grant. The most recently uploaded file was named 
                      "${data.upload_file_name}", contained ${
                data.upload_row_count
              } rows of data, and was uploaded on ${date.toLocaleDateString()} 
                      at ${date.toLocaleTimeString()}. You may upload another file, but it will replace any existing ${
                this.surveyType
              } data, so the 
                      new file should include ALL available ${
                        this.surveyType
                      } survey data.`;
            } else {
              this.currentStatus = `We do not have a record of any previous uploaded ${this.surveyType} survey data files for this grant.`;
            }
          })
          .catch((err) => {
            console.error(
              `[${this.surveyType}-uploads] getLastUpload error: `,
              err
            );
          });
      }
    },

    getProvidersPrograms() {
      if (
        this.$store.state.granteeId != 0 &&
        this.$store.state.reportingPeriodData.pk
      ) {
        this.$store
          .dispatch("getSubmissionData", {
            path: `/${this.surveyType}/${this.$store.state.granteeId}/submissions/${this.$store.state.reportingPeriodData.pk}/providers-programs/reduce`,
            target: "",

            // use the line below to test getting 0 providers and programs back, by setting grant id to 9999
            // path: `/${this.surveyType}/9999/providers-programs/reduce`, target: ''
          })
          .then((response) => {
            if (response.data.length > 0) {
              this.providersProgramsArray = response.data;
            } else {
              this.messageData.providersPrograms.push({
                text: `Based on the information entered on other pages of this Portal, no programs under this grant are expected to provide participant ${this.surveyType} survey data for the ${this.$store.state.reportingPeriodStr.en} reporting period. Please ensure that the Program Provider Table, Program Model Table, and the program-level questions for all programs are complete and accurate before uploading ${this.surveyType} survey data.`,
              });
            }
          })
          .catch((err) => {
            console.error(err);
            this.messageData.providersPrograms.push({
              text: `We were unable to retrieve the list of providers and programs for which survey data is expected. 
                    Please try again in a few minutes. If the problem continues, please contact the help desk for assistance.`,
            });
          });
      }
    },

    handleFile(e) {
      this.resetMessageData();

      this.resetFormDataSubmission();

      // Determine whether this is replacing a previous file that was a template.
      // If so, and we determine the new file is a custom file, we'll need to reset headersRow and dataRow below
      const previousTemplate = this.sheet && !this.customFile;

      this.workbook = null;
      this.sheet = null;
      this.setFileLoadingStatus("loading");

      let files = [];
      let f;

      // when the workbook file input changes, read the file, construct a new file reader
      // dataTransfer is the property where file data is stored in a drag/drop event
      // otherwise, check the target.files property
      if (e.dataTransfer && e.dataTransfer.items) {
        for (var i = 0; i < e.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (e.dataTransfer.items[i].kind === "file") {
            files.push(e.dataTransfer.items[i].getAsFile());
          }
        }
        f = e.dataTransfer.items[0].getAsFile();
      } else {
        files = e.target.files;
        f = files[0];
      }

      this.file = f;
      this.fileName = f.name;

      const fileExtension = f.name.split(".").pop().toLowerCase();

      this.fileExtension = fileExtension;

      if (fileExtension != "xlsx" && fileExtension != "csv") {
        this.messageData.fileExtension.push({
          fileName: f.name,
          fileExtension: fileExtension,
        });
        this.setFileLoadingStatus("complete", false);
      } else {
        var reader = new FileReader();

        // create a new instance of this, to be used inside the reader.onload function
        var self = this;

        if (fileExtension === "xlsx") {
          reader.onload = function (e) {
            var data = new Uint8Array(e.target.result);

            try {
              var workbook = XLSX.read(data, { type: "array" });

              // need to check for whether this is real data or jumbled data - for example, file name and extension look right,
              // but file contents were saved incorrectly.

              // assign the current workbook object
              self.workbook = workbook;

              var sheetNames = workbook.SheetNames;

              var customFile = false;

              if (sheetNames.length != 4) {
                customFile = true;
              } else {
                var sheetCheck = [
                  "Instructions",
                  "Participant " + self.surveyTypeCapitalized + " Survey",
                  "Data Entry Codes",
                  "Summary Data",
                ];

                for (let sheetName of sheetNames) {
                  if (!sheetCheck.includes(sheetName)) {
                    customFile = true;
                  }
                }
              }

              // If there was a template file uploaded previously,
              // and this file is a custom file, set headersRow and
              // dataRow back to defaults.
              if (previousTemplate && customFile) {
                self.headersRow = 1;
                self.dataRow = 2;
              }

              self.customFile = customFile;

              if (customFile) {
                // create sheetOptions
                self.sheetOptions = workbook.SheetNames.map(function (
                  sheetName,
                  i
                ) {
                  if (i === 0) {
                    self.sheet = sheetName;
                    self.setFileLoadingStatus("complete", true);
                  }

                  return { value: sheetName, text: sheetName };
                });
              } else {
                self.sheet =
                  "Participant " + self.surveyTypeCapitalized + " Survey";
                self.setFileLoadingStatus("complete", true);

                if (self.surveyType === "entry") {
                  self.headersRow = 4;
                  self.dataRow = 5;
                } else if (self.surveyType === "exit") {
                  self.headersRow = 5;
                  self.dataRow = 6;
                }
              }
            } catch {
              self.$bvModal.show("fileReadError");
            }

            self.$refs.fileInput.$refs.input.value = "";
          };

          reader.onerror = function () {
            self.setFileLoadingStatus("complete", false);
          };

          reader.readAsArrayBuffer(f);
        } else if (fileExtension === "csv") {
          papaparse.parse(f, {
            header: false,
            complete: function (results) {
              self.setFileLoadingStatus("complete", true);

              self.headersRow = 1;
              self.dataRow = 2;
              self.customFile = true;

              const sheetName = "Sheet1";

              self.sheetOptions = [{ value: sheetName, text: sheetName }];
              self.sheet = sheetName;

              self.workbook = results.data;
            },
            error: function () {
              self.setFileLoadingStatus("complete", false);
              self.$bvModal.show("fileReadError");
            },
            worker: true,
          });
        }
      }
    },

    removeDuplicateSpaces(x) {
      return x.replace(/  +/, " ");
    },

    resetFormDataSubmission() {
      this.formDataSubmission = null;
    },

    resetMessageData() {
      var keys = Object.keys(this.messageData);

      for (var i = 0; i < keys.length; i++) {
        this.messageData[keys[i]] = [];
      }
    },

    submitForm() {
      const submitPath = `/${this.surveyType}/${this.$store.state.granteeId}/submissions/${this.$store.state.reportingPeriodData.pk}/reduce`;

      var self = this;
      var batchNumber = 1;
      var batchTotal = Math.ceil(
        self.formDataSubmission.length / self.uploadBatchSize
      );
      self.submissionConfirmation = null;

      self.messageData.batchStatus = [
        {
          complete: false,
          inProgress: true,
          failed: false,
        },
      ];

      var sendBatch = function (data) {
        // Show a message indicating the current row range being uploaded,
        // along with batch # (out of total)
        var rowStart = self.uploadBatchSize * (batchNumber - 1);
        var rowStop = Math.min(
          rowStart + self.uploadBatchSize - 1,
          self.formDataSubmission.length - 1
        );

        self.messageData.batchStatus = [
          {
            complete: false,
            inProgress: true,
            failed: false,
            batchNumber: batchNumber,
            batchTotal: batchTotal,
            rowStart: rowStart + 1, // display rowStart and rowStop as 1-based instead of 0-based for user-friendliness
            rowStop: rowStop + 1,
          },
        ];

        self.$store
          .dispatch("submitForm", {
            path: submitPath,
            data: data,
            target: self.surveyType,
          })
          .then((response) => {
            var payload = JSON.parse(response.data.Payload);

            // if the payload indicates a successful db insertion,
            // so increment batchNumber
            if (payload.success) {
              batchNumber++;

              // if batchNumber is still less than the
              // total number of batches to be sent, send another batch
              // with the previous submission's transaction_id included
              if (batchNumber <= batchTotal) {
                var batchData = [
                  {
                    tableName: self.surveyType + "_submissions",
                    tableData: {
                      columnInfo: self.uploadColumnInfo,
                      enumMaps: self.enumMaps,
                      batchValues: self.formDataSubmission.slice(
                        rowStart + self.uploadBatchSize,
                        rowStop + self.uploadBatchSize + 1 // adding 1 because Array.slice returns start to stop - 1
                      ),
                    },
                  },
                ];

                var commit = batchNumber === batchTotal;

                batchData = {
                  commit: commit,
                  formName: self.surveyType,
                  formData: batchData,
                };

                batchData.transaction_id = payload.transaction_id;

                gzip
                  .gzip(JSON.stringify(batchData))
                  .then((compressed) => sendBatch(compressed));
              } else {
                // if here, it means all batches were inserted successfully,
                // so set submissionConfirmation to true, formDataSubmission to null,
                // and update last upload info
                self.messageData.batchStatus = [
                  {
                    complete: true,
                    inProgress: false,
                    failed: false,
                    batchNumber: batchNumber,
                    batchTotal: batchTotal,
                    rowStart: rowStart + 1, // start at 1 instead of 0 for user-friendliness
                    rowStop: rowStop + 1,
                  },
                ];

                self.submissionConfirmation = true;
                self.formDataSubmission = null;
                self.getLastUpload();
              }
            } else {
              // if here, it means a batch db insertion failed,
              // so don't send any more batches, and set
              // submissionConfirmation to false
              self.submissionConfirmation = false;
            }
          })
          .catch((error) => {
            console.error("[submitForm] error: ", error);

            self.submissionConfirmation = false;

            self.messageData.batchStatus = [
              {
                complete: false,
                inProgress: false,
                failed: true,
              },
            ];
          })
          .finally(function () {
            // if submissionConfirmation has been set
            if (self.submissionConfirmation != null) {
              self.$bvModal.show("submissionConfirmation");
            }
          });
      };

      var batchData = [
        {
          tableName: this.surveyType + "_submissions",
          tableData: {
            columnInfo: this.uploadColumnInfo,
            enumMaps: this.enumMaps,
            batchValues: this.formDataSubmission.slice(0, this.uploadBatchSize),
          },
        },
        this.uploadsData,
      ];

      var commit = batchTotal === 1;

      batchData = JSON.stringify({
        commit: commit,
        formName: this.surveyType,
        formData: batchData,
      });

      // the initial call to sendBatch
      gzip.gzip(batchData).then((compressed) => sendBatch(compressed));
    },

    toggleProviderProgramTable() {
      this.showProviderProgramTable = !this.showProviderProgramTable;
    },

    updateMappedColumns() {
      this.resetFormDataSubmission();

      this.mappedColumns = this.columns.map(function (x) {
        return x.customColumn;
      });
    },
  },

  computed: {
    columns() {
      return surveyColumns.default[this.surveyType];
    },

    columnMappingComplete() {
      var self = this;

      var columns = null;

      if (this.sheetData != null && this.sheetData.length > 0) {
        if (this.fileExtension === "xlsx") {
          columns = this.sheetData[0];
        } else if (this.fileExtension === "csv") {
          columns = this.sheetData[this.headersRow - 1];
        }
      }

      // Confirm that every mapped column is not null, data columns exist, and
      // that the mapped column is in the set of data columns, or explicitly set
      // to "not in uploaded file" by user
      return this.mappedColumns.every(function (x) {
        return (
          x != null &&
          columns != null &&
          (columns.indexOf(x) > -1 || x === self.notUploadedText)
        );
      });
    },

    columnOptions() {
      var options = [
        {
          value: this.notUploadedText,
          text: this.notUploadedText,
          disabled: false,
        },
      ];

      var columns;

      if (this.sheetData != null && this.sheetData.length > 0) {
        if (this.fileExtension === "xlsx") {
          columns = this.sheetData[0];
        } else if (this.fileExtension === "csv") {
          columns = this.sheetData[this.headersRow - 1];
        }

        var mappedColumns = this.mappedColumns;

        columns = columns
          .map(function (column) {
            return {
              value: column,
              text: column,
              disabled: mappedColumns.indexOf(column) > -1,
            };
          })
          .filter(function (column) {
            // Columns without a header will be named __EMPTY, __EMPTY_1, __EMPTY_2, ... by sheetJS.
            // This might confuse the user, so we filter them out. This way they can't
            // select a blank column intentionally or accidentally - better for them to not see it
            // and have to add a meaningful header to the file, then re-upload.
            return column.value.indexOf("__EMPTY") === -1;
          });

        options = options.concat(columns);
      }

      return options;
    },

    dbColumnsOrdered() {
      return this.columns.map(function (col) {
        return col.dbColumn;
      });
    },

    expectedSurveyCount() {
      const output = {
        total: this.providersProgramsArray.reduce(function (a, b) {
          return a + b.survey_count;
        }, 0),
      };

      // only need to count reported impact version surveys if the
      // survey type is entry. exit survey did not have an impact version
      if (this.surveyType === "entry") {
        output.impactMs = this.providersProgramsArray.reduce(function (a, b) {
          return a + b.prog_en_tot_surveys_ms_impact;
        }, 0);

        output.impactHs = this.providersProgramsArray.reduce(function (a, b) {
          return a + b.prog_en_tot_surveys_hs_impact;
        }, 0);
      }

      return output;
    },

    providersPrograms() {
      var providersPrograms = {};

      if (this.providersProgramsArray.length > 0) {
        for (var i = 0; i < this.providersProgramsArray.length; i++) {
          var row = this.providersProgramsArray[i];

          var provider = this.removeDuplicateSpaces(row.provider_name);
          var program = this.removeDuplicateSpaces(row.program_name);

          if (providersPrograms[provider]) {
            providersPrograms[provider].push(program);
          } else {
            providersPrograms[provider] = [program];
          }
        }
      } else {
        providersPrograms = null;
      }

      return providersPrograms;
    },

    requiredColumnLabels() {
      var columns = this.columns;

      var out = this.requiredColumns.map(function (colName) {
        var columnInfo = columns.filter(function (x) {
          return x.dbColumn === colName;
        });

        return columnInfo[0].label;
      });

      return out;
    },

    // sheetData is an object containing all the data from the selected sheet,
    // structured as an array with one element per row. Each row element is an
    // object with keys equal to the Excel column names, and values equal to
    // the Excel value for that row/column.
    // e.g. [{column1: 'value for row 1, column 1', column2: 'value for row 1, column 2'}, ...]
    sheetData() {
      if (this.fileExtension === "xlsx" && this.workbook && this.sheet) {
        var sheet = this.workbook.Sheets[this.sheet];

        return XLSX.utils.sheet_to_json(sheet, {
          header: 1,
          blankrows: false,
          range: this.headersRow - 1,
          defval: "",
        });
      } else if (this.fileExtension === "csv") {
        return this.workbook.slice(this.headersRow - 1, this.workbook.length);
      } else {
        return null;
      }
    },

    surveyTypeCapitalized() {
      return this.surveyType[0].toUpperCase() + this.surveyType.slice(1);
    },

    uploadsData() {
      return {
        tableName: this.surveyType + "_uploads",
        tableData: [
          [
            {
              name: "upload_file_name",
              value: {
                stringValue: this.fileName,
              },
            },
            {
              name: "upload_row_count",
              value: {
                longValue: this.formDataSubmission.length,
              },
            },
            {
              name: "upload_custom_file",
              value: {
                booleanValue: this.customFile,
              },
            },
            {
              name: "upload_sheet_name",
              value: {
                stringValue: this.sheet,
              },
            },
            {
              name: "upload_headers_row",
              value: {
                longValue: parseInt(this.headersRow),
              },
            },
            {
              name: "upload_data_row",
              value: {
                longValue: parseInt(this.dataRow),
              },
            },
            {
              name: "upload_column_mapping",
              value: {
                stringValue: JSON.stringify(
                  this.columns.map(function (x) {
                    var out = {};
                    out[x.dbColumn] = x.customColumn;

                    return out;
                  })
                ),
              },
            },
          ],
        ],
      };
    },

    uploadColumnInfo() {
      // Of the info for each column, only need to send dbColumn, type, and enum to back end
      var columnInfo = this.columns.map(function (x) {
        return (({ dbColumn, type, values, enumName }) => ({
          dbColumn,
          type,
          values,
          enumName,
        }))(x);
      });

      columnInfo.push({
        dbColumn: `${this.surveyType.slice(0, 2)}_survey_number`,
        type: "longValue",
      });

      return columnInfo;
    },
  },
};
</script>

<style>
option:disabled {
  color: #bbbbbb;
}
</style>
