<template>
  <div class="content">
    <div class="container-fluid container-fixed-lg">
      <div class="row">
        <div class="col-12">
          <div class="card card-default">
            <div class="card-header separator">
              <div class="card-title">
                <ol class="breadcrumb no-margin no-padding">
                  <li class="breadcrumb-item">
                    <router-link
                      :to="{ name: 'requseraccounts' }"
                      class="no-margin no-padding"
                      >Grantee User Management</router-link
                    >
                  </li>
                </ol>
                <h3>Invite new grantee user</h3>
                <p>
                  Create a grantee-level user account for
                  <strong>{{ grantee.name }}</strong> by entering their
                  <strong>First name</strong>, <strong>Last name</strong> and
                  <strong>Email</strong> in the fields below. The user will then
                  receive an invitation email with instructions on how to
                  complete the account creation process. Grantee-level accounts
                  have access to all data for this grant. If you wish to create
                  a provider-level account, which only has access to the data
                  for a specific provider, you can do that on the
                  <router-link to="/providers"
                    >Provider Table page.</router-link
                  >
                </p>
              </div>
            </div>
            <div class="card-body">
              <div class="form-horizontal">
                <div class="form-group row">
                  <div class="col-md-12">
                    <!--<div class="btn-group text-center">-->
                    <!--  <button aria-label="" class="btn btn-lg m-b-10" type="button" :class="{'active btn-primary': formAssociations.action == 'new', 'btn-default' : formAssociations.action != 'new'}" @click="formAssociations.action = 'new'">-->
                    <!--    <i class="pg-icon" v-show="formAssociations.action == 'new'">tick</i> Create Grantee Account for New User-->
                    <!--  </button>-->
                    <!--  <button aria-label="" class="btn btn-lg m-b-10" type="button" :class="{'active btn-primary': formAssociations.action == 'association', 'btn-default' : formAssociations.action != 'association'}" @click="formAssociations.action = 'association'">-->
                    <!--    <i class="pg-icon" v-show="formAssociations.action == 'association'">tick</i> Add Existing User as Grantee-->
                    <!--  </button>-->
                    <!--</div>-->
                    <!--existing user-->
                    <div
                      class="form-group"
                      v-if="formAssociations.action == 'association'"
                    >
                      <b-form-select
                        v-model="formAssociations.user.Username"
                        :options="users"
                      >
                      </b-form-select>
                    </div>
                    <!--new user-->
                    <div
                      class="form-group"
                      v-if="formAssociations.action == 'new'"
                    >
                      <div class="row clearfix">
                        <div class="col-xl-6">
                          <label for="acc_firstName">First name</label>
                          <input
                            type="text"
                            class="form-control"
                            name="firstName"
                            id="acc_firstName"
                            required=""
                            aria-required="true"
                            v-model="formAssociations.user.name"
                          />
                        </div>
                        <div class="col-xl-6">
                          <label for="acc_lastName">Last name</label>
                          <input
                            type="text"
                            class="form-control"
                            id="acc_lastName"
                            name="lastName"
                            required=""
                            aria-required="true"
                            v-model="formAssociations.user.family_name"
                          />
                        </div>
                      </div>
                      <div class="row p-t-10">
                        <div class="col-md-12">
                          <label for="acc_email">Email</label>
                          <input
                            type="email"
                            class="form-control"
                            id="acc_email"
                            name="email"
                            required=""
                            aria-required="true"
                            v-model="formAssociations.user.email"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="formAssociations.errorMsg.replace(/\s/g, '').length > 0"
                  class="form-group row"
                >
                  <div class="col-md-12 text-center">
                    {{ formAssociations.errorMsg }}
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12 text-right">
                    <button
                      @click="addGranteeUser()"
                      aria-label="Add Grantee"
                      type="button"
                      class="btn btn-lg btn-primary m-t-5"
                      :class="{
                        disabled: !(
                          (validateEmail(formAssociations.user.email) &&
                            formAssociations.user.name.replace(/\s/g, '')
                              .length > 0 &&
                            formAssociations.user.family_name.replace(/\s/g, '')
                              .length > 0 &&
                            formAssociations.action == 'new') ||
                          (formAssociations.action == 'association' &&
                            formAssociations.user.Username)
                        ),
                      }"
                    >
                      {{
                        formAssociations.action == "new"
                          ? "Create Account"
                          : "Add User Association"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header card-title">
          <h3>Manage Existing Users</h3>
          <p>
            These are all the grantee and provider-level user's associated with
            <strong>{{ grantee.name }}</strong
            >. Delete any existing users who are no longer active members.
          </p>
        </div>
        <div class="card-body">
          <MPRTable
            v-if="
              (grantee.role == 'Grantee' || grantee.role == 'Admin') &&
              granteeUsers
            "
            :items="granteeUsers"
            :headers="granteeHeaders"
            canDeleteItem="true"
            deleteLabel="Delete Association"
            :onClickDelete="confirmDelete"
          >
            <template v-for="user in granteeUsers" v-slot:[user.slotName]>
              <UserAssociations
                :user="user"
                :confirmDelete="confirmDelete"
                :grantee="grantee"
                :setSiteAccess="setSiteAccess"
                :key="user.slotName"
              />
            </template>
          </MPRTable>
        </div>
      </div>
    </div>

    <confirm-modal
      :show="confirmModal"
      headerTitle="Grantee Added"
      :bodyText="confirmBodyText"
      :close="resetForm"
    />

    <b-modal v-model="deleteAssociationModal.show" hide-footer>
      <div class="row" v-if="deleteAssociationModal.user">
        <div class="col-12 text-center">
          <h6>
            Confirm deletion of association for
            {{ deleteAssociationModal.user.role }}
            <b>{{ deleteAssociationModal.association.name }}</b
            >, with role <b>{{ deleteAssociationModal.user.role }}</b
            >, funding stream <b>{{ this.grantee.funding_stream }}</b
            >, cohort <b>{{ this.grantee.cohort }}</b> for user
            <b v-if="deleteAssociationModal.user.name"
              >{{ deleteAssociationModal.user.name }}
              {{ deleteAssociationModal.user.family_name }}</b
            >
            <b v-else>{{ deleteAssociationModal.user.email }}</b>
          </h6>
        </div>
      </div>
      <div class="row" v-if="deleteAssociationModal.errorMsg">
        <div class="col-md-12">
          <p class="error">{{ deleteAssociationModal.errorMsg }}</p>
        </div>
      </div>
      <div class="row m-t-10">
        <div class="col-12 text-center">
          <button
            @click="deleteAssociation()"
            aria-label=""
            type="button"
            class="btn btn-lg btn-primary m-t-5 m-r-10"
          >
            Yes
          </button>
          <button
            @click="clearDeleteAssociationModal()"
            aria-label=""
            type="button"
            class="btn btn-lg btn-primary m-t-5 m-l-10"
          >
            No
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { API } from "aws-amplify";
import mixin from "../mixin";
import { getUserAttributes } from "../helpers.js";
import confirmModal from "../components/confirm-modal.vue";
import MPRTable from "../components/MPR-Table.vue";
import UserAssociations from "../components/UserAssociations.vue";

export default {
  mixins: [mixin],
  components: {
    confirmModal,
    MPRTable,
    UserAssociations,
  },
  data() {
    return {
      confirmModal: false,
      deleteAssociationModal: {
        show: false,
        user: null,
        association: null,
      },
      granteeUsers: null,
      granteeHeaders: [
        {
          label: "First Name",
          key: "name",
          width: "20%",
        },
        {
          label: "Last Name",
          key: "family_name",
          width: "25%",
        },
        {
          label: "Email",
          key: "email",
          width: "40%",
        },
        {
          label: "Role",
          key: "role",

          width: "15%",
        },
      ],
      formAssociations: {
        action: "new",
        role_id: 1,
        provider_id: null,
        errorMsg: "",
        user: {
          name: "",
          family_name: "",
          email: "",
          Username: "",
        },
      },
    };
  },
  mounted: function () {
    this.setGranteeUsers();
  },
  watch: {
    grantee: function () {
      this.setGranteeUsers();
    },
    "this.$store.state.allRoles": function () {
      this.setGranteeUsers();
    },
  },
  computed: {
    users() {
      return this.$store.state.allUsers.map((u) => {
        return {
          value: u.Username,
          text: u.name + " " + u.family_name + " - " + u.email,
        };
      });
    },
    confirmBodyText() {
      return `Grantee ${this.formAssociations.user.name} ${this.formAssociations.user.family_name} with email ${this.formAssociations.user.email} was added successfully.`;
    },
  },
  methods: {
    setGranteeUsers() {
      API.get("webapi", `/users/${this.grantee.id}`).then((response) => {
        this.granteeUsers = response.data
          .filter(
            (user) =>
              (user.fk_roles == 1 || user.fk_roles == 2) && user.user_data
          )
          .reduce((users, current_user) => {
            let roles = this.$store.state.allRoles.reduce((prev, curr) => {
              return {
                ...prev,
                [curr.pk]: curr.role_name,
              };
            }, {});
            if (users[current_user.cognito_id]) {
              users[current_user.cognito_id].associations = [
                ...users[current_user.cognito_id].associations,
                {
                  ...current_user,
                  name:
                    current_user.fk_roles == 1
                      ? this.grantee.name
                      : current_user.provider_name,
                },
              ];
              return users;
            } else {
              return {
                ...users,
                [current_user.cognito_id]: {
                  ...getUserAttributes({
                    ...current_user,
                    ...current_user.user_data,
                    role: roles[current_user.fk_roles],
                  }),
                  slotName: current_user.cognito_id,
                  associations: [
                    {
                      ...current_user,
                      name:
                        current_user.fk_roles == 1
                          ? this.grantee.name
                          : current_user.provider_name,
                    },
                  ],
                },
              };
            }
          }, {});
      });
    },
    confirmDelete(user, association) {
      // this.deleteAssociationModal.show = true
      this.deleteAssociationModal = {
        show: true,
        user: user,
        association,
      };
    },
    clearDeleteAssociationModal() {
      this.deleteAssociationModal = {
        show: false,
        user: null,
        grant: null,
      };
    },
    deleteAssociation() {
      const { association, user } = this.deleteAssociationModal;
      API.del("webapi", "/user-associations/" + association.pk, {
        body: {
          user,
          association,
        },
      }).then((response) => {
        if (response.success) {
          this.setGranteeUsers();
          this.clearDeleteAssociationModal();
          this.$root.$bvToast.toast("User successfully removed.", {
            title: `Success`,
            variant: "success",
            autoHideDelay: 2000,
            solid: true,
          });
        } else {
          this.deleteAssociationModal.errorMsg = response.error.message;
        }
      });
    },
    addGranteeUser() {
      if (this.formAssociations.action == "association")
        this.formAssociations.user = this.$store.state.allUsers.find(
          (u) => u.Username === this.formAssociations.user.Username
        );

      API.post("webapi", "/user-associations", {
        body: {
          ...this.formAssociations,
          has_portal_access: this.grantee.has_portal_access,
          has_dashboard_access: this.grantee.has_dashboard_access,
          fk_grants: this.grantee.id,
          role_name: "Provider",
        },
      }).then((responseUA) => {
        if (responseUA.success) {
          this.confirmModal = true;
          this.$store.dispatch("getAllUserAssociations");
          this.$store.dispatch("getAllUsers");
        } else {
          this.formAssociations.errorMsg = responseUA.error.message;
        }
      });
    },
    resetForm() {
      this.confirmModal = false;
      this.formAssociations = {
        ...this.formAssociations,
        errorMsg: "",
        user: {
          name: "",
          family_name: "",
          email: "",
          Username: "",
        },
      };
    },
    setSiteAccess(user, association, has_portal_access, has_dashboard_access) {
      API.post("webapi", "/user-associations", {
        body: {
          action: "edit",
          update_site_access: true,
          user,
          fk_grants: association.fk_grants,
          provider_id: association.fk_providers,
          role_id: association.fk_roles,
          pk: association.pk,
          has_portal_access,
          has_dashboard_access,
        },
      }).then((responseUA) => {
        if (responseUA.success) {
          this.$store.dispatch("getAllUserAssociations");
          if (user.Username == this.$store.state.user.username) {
            this.$store.dispatch(
              "getUserAssociations",
              this.$store.state.user.username
            );
          }
        } else {
          this.formAssociations.errorMsg = responseUA.error.message;
        }
      });
    },
  },
};
</script>
<style lang="scss">
.subitem-row {
  padding: 8px;
  background: #f5f4f7;
  border-radius: 4px;
}

.association-label {
  font-size: 16px;
}
</style>
