var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 no-padding"},[_c('div',{staticClass:"card card-transparent"},[_c('div',{staticClass:"card-header p-t-5"},[_c('div',{staticClass:"card-title"},[_c('div',{staticClass:"col-xs-12"},[_c('router-link',{staticClass:"btn btn-lg btn-block btn-primary btn-icon-left",attrs:{"to":{ name: 'programs', params: { pid: _vm.provider.id } }}},[_c('i',{staticClass:"pg-icon md-18"},[_vm._v("arrow_left")]),_c('span',[_vm._v("Return to Program Model Table")])])],1)])]),_c('div',{staticClass:"card-body no-padding"},[_c('ul',{staticClass:"nav nav-tabs nav-tabs-simple d-none d-md-flex d-lg-flex d-xl-flex"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"d-flex align-items-center",class:{
                active: _vm.$router.currentRoute.name === 'program-data',
              },attrs:{"to":{
                name: 'program-data',
                params: { pid: _vm.$route.params.pid, pmid: _vm.$route.params.pmid },
              }}},[_c('span',[_vm._v("Program-level measures of structure, cost, and support for program implementation")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"d-flex align-items-center",class:{
                active: _vm.$router.currentRoute.name === 'program-reach',
              },attrs:{"to":{
                name: 'program-reach',
                params: { pid: _vm.$route.params.pid, pmid: _vm.$route.params.pmid },
              }}},[_c('span',[_vm._v("Program-level measures of attendance, reach, and dosage")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"d-flex align-items-center",class:{
                active: _vm.$router.currentRoute.name === 'program-entry-exit',
              },attrs:{"to":{
                name: 'program-entry-exit',
                params: { pid: _vm.$route.params.pid, pmid: _vm.$route.params.pmid },
              }}},[_c('span',[_vm._v("Information about survey administration")])])],1)]),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"tab-pane sm-no-padding slide-left active"},[_c('router-view')],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }