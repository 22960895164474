<template>
  <div class="content" :class="{ 're-render-toogle': dataToogle }">
    <div class="container-fluid container-fixed-lg">
      <div class="row">
        <div class="col-12">
          <div class="card card-default">
            <div class="card-header separator no-print">
              <div class="card-title">
                <ol class="breadcrumb no-margin no-padding">
                  <li class="breadcrumb-item">
                    <router-link
                      :to="{ name: 'providers' }"
                      class="no-margin no-padding"
                      >Validation Report</router-link
                    >
                  </li>
                </ol>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-8">
                  <h3 class="srae-title">Data Submission Validation</h3>
                </div>
                <div class="col-4 p-t-10 no-print">
                  <button
                    v-if="grantee.role == 'Grantee'"
                    v-b-modal.submitButton
                    @click="
                      submitForm();
                      getProviderIssues();
                      getProgramIssues();
                    "
                    :to="{ name: 'programs' }"
                    class="btn btn-lg btn-primary btn-icon-left pull-right"
                  >
                    <i class="pg-icon">send</i> Submit Data
                  </button>
                  <button
                    @click="printScreen()"
                    class="btn btn-lg btn-primary pull-right btn-print"
                  >
                    Print
                  </button>
                </div>
              </div>
              <h4>
                <b class="srae-subtitle">Grantee Name:</b> {{ grantee.name }}
              </h4>
              <h4><b class="srae-subtitle">Cohort:</b> {{ grantee.cohort }}</h4>

              <h4>
                <b class="srae-subtitle">Funding Stream:</b>
                {{ grantee.funding_stream }}
              </h4>
              <h4>
                <b class="srae-subtitle">Report Period:</b>
                {{ $store.state.reportingPeriodStr.gr }}
              </h4>
              <h4>
                <i class="srae-subtitle" style="color: red"
                  >Please print this page for your records. Your data validation
                  report will not be available after the current submission
                  period ends.</i
                >
              </h4>
              <h6 v-if="grantee.role == 'Grantee'">
                <router-link to="/grantee">
                  <span v-bind:class="granteeMessage.newClass">
                    {{ granteeMessage.message }}</span
                  >
                </router-link>
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showSubmitButtonMessage" class="row">
        <div class="col-12">
          <div class="card card-default validation-error-message">
            <div class="card-header">
              <div class="card-title">
                <span class="semi-bold inline pull-left">{{
                  submitButtonMessage()
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div
            v-for="provider in activeProviders"
            :key="provider.id"
            class="card card-default provider-card"
            :class="{
              'card-collapsed':
                collapseToogles['provider-collapse-' + provider.id],
            }"
          >
            <div
              class="card-header"
              role="tab"
              id="headingOne"
              @click="collapseToogle(provider.id)"
            >
              <div class="card-title">
                <span class="semi-bold inline pull-left">{{
                  provider.name
                }}</span>
              </div>
              <div class="card-controls">
                <ul>
                  <li>
                    <span
                      data-toggle="collapse"
                      class="card-collapse"
                      role="button"
                      @keyup.enter="collapseToogle(provider.id)"
                      tabindex="0"
                    >
                      <i class="card-icon card-icon-collapse"></i>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="card-body"
              v-show="
                collapseToogles['provider-collapse-' + provider.id] || printing
              "
            >
              <div class="row">
                <div class="col-12">
                  <h6>
                    <router-link
                      :to="{
                        name: 'provider-data',
                        params: { pid: provider.id },
                      }"
                      class="d-flex align-items-center inline m-r-20"
                    >
                      <span class="">Provider-Level Information </span>
                      <span
                        v-bind:class="providerMessage(provider.id).newClass"
                        >{{ providerMessage(provider.id).message }}</span
                      >
                    </router-link>
                  </h6>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <table
                    class="table table-hover table-condensed no-footer"
                    width="100%"
                  >
                    <tr
                      v-for="program in provider.programs"
                      :key="program.id"
                      role="row"
                      class="odd-even"
                    >
                      <td class="v-align-middle semi-bold">
                        <h6>{{ program.name }}</h6>
                        <router-link
                          v-if="!isWinterSeason"
                          :to="{
                            name: 'program-data',
                            params: { pid: provider.id, pmid: program.id },
                          }"
                          class="d-flex align-items-center"
                        >
                          <span
                            v-bind:class="
                              programMessage(program.id, 'SCS').newClass
                            "
                          >
                            {{ programMessage(program.id, "SCS").message }}
                          </span>
                        </router-link>
                        <router-link
                          :to="{
                            name: 'program-reach',
                            params: { pid: provider.id, pmid: program.id },
                          }"
                          class="align-items-center"
                          v-bind:class="{
                            active:
                              $router.currentRoute.name === 'program-reach',
                          }"
                        >
                          <span
                            v-bind:class="
                              programMessage(program.id, 'ARD').newClass
                            "
                            >{{
                              programMessage(program.id, "ARD").message
                            }}</span
                          >
                        </router-link>

                        <router-link
                          v-if="isMissingISA(program.id)"
                          :to="{
                            name: 'program-entry-exit',
                            params: { pid: provider.id, pmid: program.id },
                          }"
                        >
                          <span :class="[label.blue, 'upload-warning']"
                            >Missing info about survey admin</span
                          >
                        </router-link>

                        <!-- 
                        <router-link
                          v-if="isMissingUpload(program.id, 'entry')"
                          :to="{ name: 'upload-entry' }"
                        > -->
                        <span
                          v-if="
                            grantee.role == 'Grantee' &&
                            isMissingUpload(program.id, 'entry')
                          "
                          :class="[label.red, 'upload-warning']"
                          >Missing entry survey data</span
                        >
                        <!-- </router-link> -->

                        <!-- 
                        <router-link
                          v-if="isMissingUpload(program.id, 'exit')"
                          :to="{ name: 'upload-exit' }"
                        > -->
                        <span
                          v-if="
                            grantee.role == 'Grantee' &&
                            isMissingUpload(program.id, 'exit')
                          "
                          :class="[label.red, 'upload-warning']"
                          >Missing exit survey data</span
                        >
                        <!-- </router-link> -->
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="grantee.role == 'Grantee'" class="card card-body">
        <div class="row">
          <div class="col-12">
            <h6>
              <div class="form-group row">
                <div class="col-12">
                  <label for="formData.gr_additional_info"
                    >Optional comments: In the box below, briefly explain any
                    anomalies in the data submitted or provide any other
                    information that would help interpret the data.</label
                  >
                </div>
                <div class="col-12">
                  <textarea
                    v-model="formData.gr_additional_info"
                    id="formData.gr_additional_info"
                    maxlength="2000"
                    style="width: 100%; margin-top: 1px"
                    rows="5"
                    required=""
                  ></textarea>
                </div>
                <div class="col-12 p-t-10 no-print">
                  <button
                    v-if="grantee.role == 'Grantee'"
                    v-b-modal.optionalComments
                    @click="
                      submitForm();
                      getProviderIssues();
                      getProgramIssues();
                    "
                    class="btn btn-lg btn-primary pull-right"
                  >
                    Save comments
                  </button>
                </div>
              </div>
            </h6>
          </div>
        </div>
      </div>

      <b-modal id="submitButton" ok-only>
        <p>
          {{ submitButtonMessage() }}
        </p>
      </b-modal>
      <b-modal id="optionalComments" ok-only>
        Thank you. Your optional comments have been successfully saved.
      </b-modal>
    </div>
  </div>
</template>

<script>
import mixin from "../mixin";

export default {
  mixins: [mixin],

  data() {
    return {
      collapseToogles: {},
      dataToogle: false,
      formData: {
        gr_additional_info: "",
      },
      label: {
        blue: "label label-info",
        gray: "label label-default",
        green: "label label-success",
        red: "label label-danger",
        yellow: "label label-warning",
      },
      loaded: false,
      printing: false,
    };
  },

  watch: {
    grantee() {
      // get grant data
      this.getGranteeIssues();
      // get provider and program data
      if (this.grantee.providers) {
        this.getProviderIssues();
        this.getProgramIssues();
      }
    },
  },

  created: function () {
    window.onafterprint = () => {
      this.printing = false;
    };

    this.getGranteeIssues();
    this.getFileUploads();

    if (this.grantee.providers) {
      this.getProviderIssues();
      this.getProgramIssues();
    }
  },

  computed: {
    activeProgramIds() {
      return this.$store.state.programSubmissionData
        .filter(
          (program) =>
            this.granteeProgramIds.includes(program.fk_programs) &&
            program.prog_active !== false
        )
        .map((program) => program.fk_programs);
    },

    activeProviderIds() {
      return this.$store.state.providerSubmissionData
        .filter(
          (provider) =>
            this.granteeProviderIds.includes(provider.fk_providers) &&
            provider.prov_active !== false
        )
        .map((provider) => provider.fk_providers);
    },

    activeProviders() {
      return this.grantee.providers
        .filter((provider) => this.activeProviderIds.includes(provider.id))
        .map((provider) => {
          const programs = provider.programs.filter((program) =>
            this.activeProgramIds.includes(program.id)
          );
          return { ...provider, programs };
        });
    },

    entryUploads() {
      return this.$store.state.programEntrySurveyUploads;
    },

    exitUploads() {
      return this.$store.state.programExitSurveyUploads;
    },

    formDataSubmission() {
      return {
        formName: "grantee",
        formData: [
          {
            tableName: "grant_submissions",
            tableData: [
              [
                {
                  name: "gr_additional_info",
                  value: {
                    stringValue: this.formData.gr_additional_info,
                  },
                },
                {
                  name: "gr_submitted",
                  value: {
                    booleanValue: true,
                  },
                },
              ],
            ],
          },
        ],
      };
    },

    granteeIssues() {
      const data = this.$store.state.granteeSubmissionData.filter(
        (grantee) => grantee.fk_grants === this.grantee.id
      );
      if (data.length) {
        return {
          major: data[0].gr_data_issues_major,
          minor: data[0].gr_data_issues_minor,
        };
      }
      return { major: null, minor: null };
    },

    granteeMessage() {
      if (
        this.granteeIssues.major === null &&
        this.granteeIssues.minor === null
      ) {
        return {
          message: "No grantee data have been saved",
          newClass: this.label.gray,
        };
      } else if (this.granteeIssues.major && this.granteeIssues.minor) {
        return {
          message:
            "Major and minor issues present in grantee-level information",
          newClass: this.label.red,
        };
      } else if (this.granteeIssues.major && !this.granteeIssues.minor) {
        return {
          message: "Major issues present in grantee-level information",
          newClass: this.label.red,
        };
      } else if (!this.granteeIssues.major && this.granteeIssues.minor) {
        return {
          message: "Minor issues present in grantee-level information",
          newClass: this.label.blue,
        };
      } else {
        return {
          message: "No issues present in grantee-level information",
          newClass: this.label.green,
        };
      }
    },

    granteeProgramIds() {
      const programIds = [];
      this.grantee.providers.forEach((provider) => {
        provider.programs.forEach((program) => programIds.push(program.id));
      });
      return programIds;
    },

    granteeProviderIds() {
      return this.grantee.providers.map((provider) => provider.id);
    },

    isWinterSeason() {
      return this.$store.state.isWinterSeason;
    },

    majorIssueFlag() {
      const grantee = this.granteeIssues.major;
      const providers = this.providerIssues
        .filter((issue) => issue.isActive || issue.isActive === null)
        .some((issue) => issue.major);
      const programs = this.programIssues
        .filter((issue) => issue.isActive || issue.isActive === null)
        .filter((issue) => this.parentProviderIsActive(issue.pmId))
        .some(
          (issue) =>
            issue.majorARD ||
            (issue.majorSCS && !this.isWinterSeason) ||
            issue.missingEntryUpload ||
            issue.missingExitUpload
        );

      return grantee || providers || programs;
    },

    minorIssueFlag() {
      const grantee = this.granteeIssues.minor;
      const providers = this.providerIssues
        .filter((issue) => issue.isActive || issue.isActive === null)
        .some((issue) => issue.minor || issue.isActive === null);
      const programs = this.programIssues
        .filter((issue) => issue.isActive || issue.isActive === null)
        .filter((issue) => this.parentProviderIsActive(issue.pmId))
        .some(
          (issue) =>
            issue.minorARD ||
            (issue.minorSCS && !this.isWinterSeason) ||
            issue.missingISA ||
            issue.isActive === null
        );

      return grantee || providers || programs;
    },

    programIssues() {
      return this.$store.state.programSubmissionData
        .filter((program) =>
          this.granteeProgramIds.includes(program.fk_programs)
        )
        .map((program) => ({
          isActive: program.prog_active,
          majorARD: program.prog_reach_data_issues_major,
          majorSCS: program.prog_data_issues_major,
          minorARD: program.prog_reach_data_issues_minor,
          minorSCS: program.prog_data_issues_minor,
          missingISA:
            (program.prog_any_youth_begin || program.prog_any_youth_complete) &&
            !(
              program.prog_entry_exit_data_issues_minor === false &&
              program.prog_entry_exit_data_issues_major === false
            ),
          missingEntryUpload:
            program.prog_any_youth_begin &&
            !(
              program.prog_en_tot_surveys_ms === 0 &&
              program.prog_en_tot_surveys_hs === 0
            ) &&
            !this.entryUploads.includes(program.fk_programs),
          missingExitUpload:
            program.prog_any_youth_complete &&
            !(
              program.prog_ex_tot_surveys_ms === 0 &&
              program.prog_ex_tot_surveys_hs === 0
            ) &&
            !this.exitUploads.includes(program.fk_programs),
          pmId: program.fk_programs,
        }));
    },

    programProviderDictionary() {
      let dict = {};
      this.grantee.providers.forEach((provider) => {
        provider.programs.forEach(
          (program) => (dict[program.id] = provider.id)
        );
      });
      return dict;
    },

    providerIssues() {
      return this.$store.state.providerSubmissionData
        .filter((provider) =>
          this.granteeProviderIds.includes(provider.fk_providers)
        )
        .map((provider) => ({
          major: provider.prov_data_issues_major,
          minor: provider.prov_data_issues_minor,
          isActive: provider.prov_active,
          pId: provider.fk_providers,
        }));
    },

    showSubmitButtonMessage() {
      return !!this.majorIssueFlag || !!this.minorIssueFlag;
    },
  },

  methods: {
    collapseToogle(pid) {
      var _collapse = "provider-collapse-" + pid;
      this.$set(
        this.collapseToogles,
        _collapse,
        _collapse in this.collapseToogles
          ? !this.collapseToogles[_collapse]
          : true
      );
    },

    isMissingUpload(programId, type) {
      const programIssues = this.programIssues.find(
        (program) => program.pmId === programId
      );
      if (!programIssues) return false;
      const uploadType =
        type === "entry" ? "missingEntryUpload" : "missingExitUpload";
      return !!programIssues[uploadType];
    },

    isMissingISA(programId) {
      const programIssues = this.programIssues.find(
        (program) => program.pmId === programId
      );
      if (!programIssues) return false;
      return programIssues.missingISA;
    },

    getGranteeIssues() {
      this.$store
        .dispatch("getSubmissionData", {
          path: `/grantees/${this.$store.state.granteeId}/submissions/${this.$store.state.reportingPeriodData.pk}/reduce`,
          target: "grantee",
        })
        .then((response) => {
          if (response.data.length) {
            this.formData.gr_additional_info =
              response.data[0].gr_additional_info;
          }
        })
        .catch((err) => console.error(err));
    },

    getFileUploads() {
      this.$store.dispatch("getFileUploadData");
    },

    getProgramIssues() {
      const providers = this.grantee.providers;
      providers.forEach((provider) => {
        const programs = provider.programs;
        programs.forEach((program) => {
          this.$store
            .dispatch("getSubmissionData", {
              path: `/grantees/${this.grantee.id}/providers/${provider.id}/programs/${program.id}/submissions/${this.$store.state.reportingPeriodData.pk}/reduce`,
              target: "program",
            })
            .catch((err) => console.error(err));
        });
      });
    },

    getProviderIssues() {
      const providers = this.grantee.providers;

      providers.forEach((pro) => {
        this.$store
          .dispatch("getSubmissionData", {
            path: `/providers/${pro.id}/submissions/${this.$store.state.reportingPeriodData.pk}/reduce`,
            target: "provider",
          })
          .catch((err) => console.error(err));
      });
    },

    parentProviderIsActive(programId) {
      return this.activeProviderIds.includes(
        this.programProviderDictionary[programId]
      );
    },

    printScreen() {
      this.printing = true;
      setTimeout(() => window.print(), 250);
    },

    programMessage(pmid, type) {
      const programIssues = this.programIssues.find(
        (issue) => issue.pmId === pmid
      );
      const major = "major" + type;
      const minor = "minor" + type;

      const scs = "Program-Level Structure, Cost, and Support Data";
      const ard = "Attendance, Reach, and Dosage Data";

      const dataType = type === "SCS" ? scs : ard;

      if (
        programIssues === undefined ||
        (programIssues[major] === null && programIssues[minor] === null)
      ) {
        return {
          message: `${dataType}: No data have been saved`,
          newClass: this.label.gray,
        };
      } else if (programIssues[major] && programIssues[minor]) {
        return {
          message: `${dataType}: Major and minor issues present`,
          newClass: this.label.red,
        };
      } else if (programIssues[major] && !programIssues[minor]) {
        return {
          message: `${dataType}: Major issues present`,
          newClass: this.label.red,
        };
      } else if (!programIssues[major] && programIssues[minor]) {
        return {
          message: `${dataType}: Minor issues present`,
          newClass: this.label.blue,
        };
      } else {
        return {
          message: `${dataType}: No issues present`,
          newClass: this.label.green,
        };
      }
    },

    providerMessage(pid) {
      const providerIssues = this.providerIssues.find(
        (issue) => issue.pId === pid
      );

      if (
        providerIssues === undefined ||
        (providerIssues.major === null && providerIssues.minor === null)
      ) {
        return {
          message: "No provider data have been saved",
          newClass: this.label.gray,
        };
      } else if (providerIssues.major && providerIssues.minor) {
        return {
          message:
            "Major and minor issues present in provider-level information",
          newClass: this.label.red,
        };
      } else if (providerIssues.major && !providerIssues.minor) {
        return {
          message: "Major issues present in provider-level information",
          newClass: this.label.red,
        };
      } else if (!providerIssues.major && providerIssues.minor) {
        return {
          message: "Minor issues present in provider-level information",
          newClass: this.label.blue,
        };
      } else {
        return {
          message: "No issues present in provider-level information",
          newClass: this.label.green,
        };
      }
    },

    submitButtonMessage() {
      if (this.majorIssueFlag) {
        return "Your data contain at least one error that requires review and revision prior to submission. See page status notes below. Messages in red indicate pages containing errors that must be corrected prior to submission.";
      } else if (this.minorIssueFlag) {
        return "Thank you. Your data have been submitted, but there is at least one incomplete field or inconsistency within the data. See page status notes below to identify the page(s) that contains issues to review.";
      } else {
        return "Thank you. Your data have been successfully submitted.";
      }
    },

    submitForm(_action) {
      const path = `/grantees/${this.grantee.id}/submissions/${this.$store.state.reportingPeriodData.pk}`;
      const getSubmissionPath = path + "/reduce";
      this.$store
        .dispatch("submitForm", {
          action: _action,
          path,
          getSubmissionPath,
          data: this.formDataSubmission,
          target: "grantee",
        })
        .then(() => {
          this.saved = true;
          this.statusMsg = "Data saved";
        })
        .catch((err) => console.error(err));
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-warning {
  margin-left: 12px;
}
.btn-print {
  margin-right: 10px;
}
.card.card-default.validation-error-message {
  border: 10px solid rgba(234, 44, 85, 0.7);

  span {
    font-style: italic;
  }
}

@media print {
  .page-content-wrapper .content .card .card-body {
    padding: 10px 20px 20px;
  }
}
</style>
