<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-transparent">
        <div class="card-header">
          <div class="pull-left">
            <input
              type="text"
              v-model="search"
              placeholder="Search users.."
              class="form-control"
            />
          </div>
          <div class="pull-right">
            <button
              @click.stop="openModal({}, 'new')"
              aria-label="Add User"
              class="btn btn-lg btn-primary btn-cons"
            >
              <i class="pg-icon">add</i> Add User
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <div
              id="detailedTable_wrapper"
              class="dataTables_wrapper no-footer"
            >
              <table
                class="table table-hover table-condensed table-detailed dataTable no-footer"
                id="detailedTable"
                role="grid"
              >
                <thead>
                  <tr role="row">
                    <!--<th style="width:25%" class="sorting_disabled" rowspan="1" colspan="1">Username</th>-->
                    <th class="sorting_disabled" rowspan="1" colspan="1">
                      First Name
                    </th>
                    <th class="sorting_disabled" rowspan="1" colspan="1">
                      Last Name
                    </th>
                    <th class="sorting_disabled" rowspan="1" colspan="1">
                      Email
                    </th>
                    <th class="sorting_disabled" rowspan="1" colspan="1">
                      Phone
                    </th>
                    <th class="sorting_disabled" rowspan="1" colspan="1">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    role="row"
                    v-for="user in $store.state.allUsers"
                    :key="user.Username"
                    v-show="searchShow(user)"
                  >
                    <td colspan="5" class="even p-b-0">
                      <!--<td class="v-align-middle semi-bold">{{user.Username}}</td>-->
                      <table
                        class="table table-inline"
                        style="margin-top: -35px"
                      >
                        <tbody>
                          <tr
                            role="row"
                            class="user-params-tr"
                            @click.stop="collapseToogle(user)"
                          >
                            <td class="v-align-middle">{{ user.name }}</td>
                            <td class="v-align-middle">
                              {{ user.family_name }}
                            </td>
                            <td class="v-align-middle semi-bold">
                              {{ user.email }}
                            </td>
                            <td class="v-align-middle">
                              {{ user.phone_number }}
                            </td>
                            <td class="v-align-middle text-right help-text">
                              {{ user.UserStatus.toLowerCase() }}
                            </td>
                          </tr>
                          <tr
                            class="row-details"
                            v-if="
                              collapseToogles['user-collapse-' + user.Username]
                            "
                          >
                            <td colspan="5">
                              <table class="table table-inline">
                                <thead v-if="usersAssociations[user.Username]">
                                  <tr role="row">
                                    <th>Role</th>
                                    <th>Grantee</th>
                                    <th>Funding Stream</th>
                                    <th>Cohort</th>
                                    <th>Providers</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-show="usersAssociations[user.Username]"
                                    v-for="grant in usersAssociations[
                                      user.Username
                                    ]"
                                    :key="grant.id"
                                    style="border-bottom: 1px solid grey"
                                  >
                                    <td>
                                      <p>{{ grant.role }}</p>
                                    </td>
                                    <td>
                                      <p>{{ grant.name }}</p>
                                    </td>
                                    <td>
                                      <p>{{ grant.funding_stream }}</p>
                                    </td>
                                    <td>
                                      <p>{{ grant.cohort }}</p>
                                    </td>
                                    <td>
                                      <p
                                        v-for="prov in grant.providers"
                                        :key="prov.id"
                                      >
                                        {{ prov.name }}
                                      </p>
                                    </td>
                                    <td>
                                      <b-button
                                        @click.stop="
                                          showConfirmDeleteAssociation(
                                            user,
                                            grant
                                          )
                                        "
                                        v-b-tooltip.hover
                                        title="Delete association"
                                        class="btn btn-link"
                                      >
                                        <i class="pg-icon">trash_alt</i>
                                      </b-button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="6">
                                      <div class="pull-right">
                                        <button
                                          @click.stop="openModal(user, 'edit')"
                                          class="btn btn-lg btn-primary btn-icon-left m-r-10"
                                        >
                                          <i class="pg-icon">pen</i> Edit User
                                        </button>
                                        <button
                                          @click.stop="
                                            openModal(user, 'password')
                                          "
                                          class="btn btn-lg btn-primary btn-icon-left m-r-10"
                                        >
                                          <i class="pg-icon">shield_lock</i>
                                          Change Password
                                        </button>
                                        <button
                                          @click.stop="
                                            openModal(user, 'association')
                                          "
                                          class="btn btn-lg btn-primary btn-icon-left"
                                        >
                                          <i class="pg-icon">add</i> Add
                                          Association
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="showAssociationsModal"
      v-on:close="closeModal"
      size="lg"
      hide-footer
    >
      <template v-slot:modal-title>
        <span v-if="formAssociations.action === 'new'">Create User </span>
        <span v-if="formAssociations.action === 'association'"
          >Associate User
        </span>
        <span v-if="formAssociations.action === 'edit'">Edit User </span>
        <span class="semi-bold"
          >{{ formAssociations.user.name }}
          {{ formAssociations.user.family_name }}</span
        >
      </template>
      <div class="p-t-20" v-if="!!formAssociations.user">
        <form role="form">
          <div
            v-if="
              formAssociations.action == 'new' ||
              formAssociations.action == 'edit'
            "
          >
            <div class="row">
              <div class="col-md-6">
                <div class="form-group required">
                  <label>
                    First Name
                    <input
                      v-model="formAssociations.user.name"
                      type="text"
                      class="form-control"
                      required=""
                    />
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group required">
                  <label>
                    Last Name
                    <input
                      v-model="formAssociations.user.family_name"
                      type="text"
                      class="form-control"
                      required=""
                    />
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group required">
                  <label>
                    Email
                    <input
                      v-model="formAssociations.user.email"
                      type="text"
                      class="form-control"
                      required=""
                    />
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group required">
                  <label>
                    Phone Number
                    <input
                      v-model="formAssociations.user.phone_number"
                      type="text"
                      class="form-control"
                      required=""
                    />
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group required">
                  <label>
                    MFA preset code
                    <input
                      v-model="formAssociations.user['custom:metadata']"
                      type="text"
                      class="form-control"
                      required=""
                    />
                  </label>
                  <p>
                    (auto generated if left blank or with less than 6 characters
                    entered)
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <p>
                  To disable SMS MFA and force user to use backup preset MFA
                  code, replace +1 with +6.
                </p>
                <p>Country code for Federated States of Micronesia is +691.</p>
              </div>
            </div>
          </div>
          <div v-if="formAssociations.action == 'password'">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group required">
                  <label>
                    New Password
                    <input
                      v-model="formAssociations.user.new_password"
                      type="text"
                      autocomplete="off"
                      class="form-control"
                      required=""
                    />
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group required">
                  <label>
                    Repeat New Password
                    <input
                      v-model="formAssociations.user.new_password_rep"
                      type="text"
                      autocomplete="off"
                      class="form-control"
                      required=""
                    />
                  </label>
                </div>
              </div>
            </div>
            <div
              class="row"
              v-show="
                formAssociations.user.UserStatus.toLowerCase() === 'confirmed'
              "
            >
              <div class="col-md-12">
                <b-form-checkbox
                  v-model="formAssociations.user.new_permanent_password"
                  value="true"
                  unchecked-value="false"
                >
                  New password is permanent
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div
            class="form-group"
            v-if="
              formAssociations.action == 'new' ||
              formAssociations.action == 'association'
            "
          >
            <hr />
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="form_grantee_id">Grant</label>
                  <b-form-select
                    id="form_grantee_id"
                    v-model="selectedGrantId"
                    :options="grantSelectOptions"
                    value-field="pk"
                    text-field="info"
                  >
                  </b-form-select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="form_role_id">Role</label>
                  <b-form-select
                    id="form_role_id"
                    v-model="formAssociations.role_id"
                    :options="$store.state.allRoles"
                    value-field="pk"
                    text-field="role_name"
                  >
                  </b-form-select>
                </div>
              </div>
              <div
                class="col-md-8"
                v-if="
                  $store.state.allRoles.length > 0 &&
                  formAssociations.role_id &&
                  $store.state.allRoles.find(
                    (r) => r.pk === formAssociations.role_id
                  ).role_name === 'Provider'
                "
              >
                <div class="form-group">
                  <label for="form_provider_id">Provider</label>
                  <b-form-select
                    id="form_provider_id"
                    v-model="formAssociations.provider_id"
                    :options="allProviders"
                    value-field="pk"
                    text-field="provider_name"
                  >
                  </b-form-select>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="row">
          <div class="col-md-6">
            <p class="error" v-if="formAssociations.errorMsg != ''">
              {{ formAssociations.errorMsg }}
            </p>
          </div>
          <div class="col-md-6 m-t-10 sm-m-t-10 text-right">
            <button
              v-if="formAssociations.action === 'new'"
              @click.stop="addUserAssociations()"
              aria-label="Add new user"
              type="button"
              class="btn btn-lg btn-primary"
              v-bind:class="{
                disabled:
                  $store.state.allRoles.length > 0 &&
                  formAssociations.role_id &&
                  $store.state.allRoles.find(
                    (r) => r.pk === formAssociations.role_id
                  ).role_name === 'Provider' &&
                  !formAssociations.provider_id,
              }"
            >
              Create User
            </button>
            <button
              v-if="formAssociations.action === 'association'"
              @click.stop="addUserAssociations()"
              aria-label="Add user association"
              type="button"
              class="btn btn-lg btn-primary"
            >
              Add Association
            </button>
            <button
              v-if="formAssociations.action === 'edit'"
              @click.stop="deleteUser()"
              aria-label="Delete user"
              type="button"
              class="btn btn-lg btn-danger m-r-10"
            >
              Delete
            </button>
            <button
              v-if="formAssociations.action === 'edit'"
              @click.stop="updateUser()"
              aria-label="Update user"
              type="button"
              class="btn btn-lg btn-primary"
            >
              Update
            </button>
            <button
              v-if="formAssociations.action === 'password'"
              @click.stop="updatePassword()"
              aria-label="Update user"
              type="button"
              class="btn btn-lg btn-primary"
              :disabled="
                formAssociations.user.new_password !=
                formAssociations.user.new_password_rep
              "
            >
              Change Password
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="showDeleteAssociation" hide-footer>
      <div class="row">
        <div class="col-12 text-center">
          <h6>
            Confirm deletion of grant association for Grantee
            <b>{{ deleteAssociation.grant.name }}</b
            >, with role <b>{{ deleteAssociation.grant.role }}</b
            >, funding stream <b>{{ deleteAssociation.grant.funding_stream }}</b
            >, cohort <b>{{ deleteAssociation.grant.cohort }}</b> for user
            <b
              >{{ deleteAssociation.user.name }}
              {{ deleteAssociation.user.family_name }}</b
            >
          </h6>
        </div>
      </div>
      <div class="row" v-if="deleteAssociation.errorMsg">
        <div class="col-md-12">
          <p class="error">{{ deleteAssociation.errorMsg }}</p>
        </div>
      </div>
      <div class="row m-t-10">
        <div class="col-12 text-center">
          <button
            @click="confirmDeleteAssociation()"
            aria-label=""
            type="button"
            class="btn btn-lg btn-primary m-t-5 m-r-10"
          >
            Yes
          </button>
          <button
            @click="showDeleteAssociation = false"
            aria-label=""
            type="button"
            class="btn btn-lg btn-primary m-t-5 m-l-10"
          >
            No
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="showDeleteUser" hide-footer>
      <div class="row" v-if="!!formAssociations.user">
        <div class="col-12 text-center">
          <h6>
            Confirm deletion of user
            <b
              >{{ formAssociations.user.name }}
              {{ formAssociations.user.family_name }}</b
            >, with email <b>{{ formAssociations.user.email }}</b> and phone
            <b>{{ formAssociations.user.phone_number }}</b>
          </h6>
        </div>
      </div>
      <div class="row" v-if="deleteAssociation.errorMsg">
        <div class="col-md-12">
          <p class="error">{{ formAssociations.errorMsg }}</p>
        </div>
      </div>
      <div class="row m-t-10">
        <div class="col-12 text-center">
          <button
            @click="confirmDeleteUser()"
            aria-label=""
            type="button"
            class="btn btn-lg btn-primary m-t-5 m-r-10"
          >
            Yes
          </button>
          <button
            @click="showDeleteUser = false"
            aria-label=""
            type="button"
            class="btn btn-lg btn-primary m-t-5 m-l-10"
          >
            No
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { API } from "aws-amplify";

export default {
  name: "grantee",
  data() {
    return {
      search: "",
      collapseToogles: {},
      usersAssociations: {},
      showAssociationsModal: false,
      formAssociations: {
        action: "",
        user: null,
        grantee_id: null,
        provider_id: null,
        funding_stream_id: null,
        role_id: null,
        gr_cohort: null,
        errorMsg: "",
        SMSMFA: false,
      },
      showDeleteAssociation: false,
      showDeleteUser: false,
      deleteAssociation: {
        user: {
          name: "",
          family_name: "",
        },
        grant: {
          name: "",
          role: "",
          funding_stream: "",
          cohort: "",
        },
        errorMsg: "",
      },
      selectedGrantId: null,
    };
  },
  computed: {
    allProviders() {
      if (this.selectedGrant) {
        const cohort =
          this.selectedGrant.gr_cohort === "NA"
            ? null
            : this.selectedGrant.gr_cohort;
        return this.$store.state.allProviders.filter(
          (provider) =>
            provider.fk_grantees === this.selectedGrant.fk_grantees &&
            provider.fk_funding_streams ===
              this.selectedGrant.fk_funding_streams &&
            provider.gr_cohort === cohort
        );
      }
      return null;
    },
    grantSelectOptions() {
      const grants = this.$store.state.allGrants;
      return grants.map((grant) => {
        let cohortStream = `${grant.funding_stream_name}, ${grant.gr_cohort}`;
        if (
          grant.gr_cohort === null ||
          grant.gr_cohort.toUpperCase() === "NULL"
        ) {
          cohortStream = `${grant.funding_stream_name}`;
        }
        const allInfo = `${grant.grantee_name}: ${cohortStream}`;
        return { ...grant, info: allInfo };
      });
    },
    selectedGrant() {
      if (this.selectedGrantId) {
        return this.grantSelectOptions.find(
          (grant) => grant.pk === this.selectedGrantId
        );
      }
      return null;
    },
  },
  watch: {
    allProviders() {
      // deselect the selected provider everytime there are changes afecting the filtered providers list
      this.formAssociations.provider_id = null;
    },
  },
  created() {
    this.search = (this.$route.params && this.$route.params.search) || "";
  },
  methods: {
    collapseToogle(user) {
      const cognitoId = user.Username;
      var _collapse = `user-collapse-${cognitoId}`;
      this.$set(
        this.collapseToogles,
        _collapse,
        _collapse in this.collapseToogles
          ? !this.collapseToogles[_collapse]
          : true
      );
      if (this.collapseToogles[_collapse]) {
        this.getUserAssociations(cognitoId);
      }
    },

    getUserAssociations(cognitoId) {
      API.get("webapi", `/user-associations/${cognitoId}`).then((response) => {
        this.$set(this.usersAssociations, cognitoId, response.data);
      });
    },

    addUserAssociations() {
      API.post("webapi", "/user-associations", {
        body: {
          ...this.formAssociations,
          fk_grants: this.selectedGrantId,
          role_name: this.$store.state.allRoles.find(
            (role) => role.pk === this.formAssociations.role_id
          ).role_name,
        },
      }).then((responseUA) => {
        if (responseUA.success) {
          this.$store
            .dispatch("getAllUsers")
            .then(() => {
              console.log("responseUA.data: ", responseUA.data);
              this.getUserAssociations(responseUA.data.cognitoId);
            })
            .catch((resp) => {
              console.error(resp);
            });
          this.closeModal();
        } else {
          this.formAssociations.errorMsg = responseUA.error.message;
        }
      });
    },

    updateUser() {
      const cognitoId = this.formAssociations.user.Username;
      API.put("webapi", `/admin/user/${cognitoId}`, {
        body: this.formAssociations,
      }).then((response) => {
        if (response.success) {
          this.$store
            .dispatch("getAllUsers")
            .then(() => {
              this.getUserAssociations(cognitoId);
            })
            .catch((resp) => {
              console.error(resp);
            });
          this.closeModal();
        } else {
          this.formAssociations.errorMsg = response.error.message;
        }
      });
    },

    updatePassword() {
      API.put(
        "webapi",
        "/admin/user/set-password/" + this.formAssociations.user.Username,
        {
          body: this.formAssociations,
        }
      ).then((response) => {
        if (response.success) {
          this.closeModal();
        } else {
          this.formAssociations.errorMsg = response.error.message;
        }
      });
    },
    deleteUser() {
      this.showDeleteUser = true;
    },
    confirmDeleteUser() {
      API.del("webapi", "/admin/user/" + this.formAssociations.user.Username, {
        body: this.formAssociations,
      }).then((response) => {
        if (response.success) {
          this.$store.dispatch("getAllUsers").catch((resp) => {
            console.error(resp);
          });
          this.showDeleteUser = false;
        } else {
          this.formAssociations.errorMsg = response.error.message;
        }
      });
      this.closeModal();
    },
    openModal(_user, _action) {
      this.formAssociations.action = _action;
      this.formAssociations.user = _user;
      this.showAssociationsModal = true;
    },
    showConfirmDeleteAssociation(_user, _grant) {
      this.deleteAssociation = {
        user: _user,
        grant: _grant,
      };
      this.showDeleteAssociation = true;
    },

    confirmDeleteAssociation() {
      const cognitoId = this.deleteAssociation.user.Username;

      API.del(
        "webapi",
        "/user-associations/" + this.deleteAssociation.grant.pk,
        {
          body: {
            user: this.deleteAssociation.user,
            association: this.deleteAssociation.grant,
          },
        }
      ).then((response) => {
        if (response.success) {
          this.getUserAssociations(cognitoId);
          this.showDeleteAssociation = false;
        } else {
          this.deleteAssociation.errorMsg = response.error.message;
        }
      });
    },

    closeModal() {
      this.showAssociationsModal = false;
      this.formAssociations.user = {
        name: "",
        family_name: "",
        emai: "",
        phone_number: null,
        UserStatus: "",
      };
      this.selectedGrantId = null;
    },
    searchShow(_user) {
      return (
        this.search.replace(/\s/g, "").length == 0 ||
        (this.search.replace(/\s/g, "").length > 0 &&
          (("name" in _user &&
            _user.name.toLowerCase().includes(this.search.toLowerCase())) ||
            ("family_name" in _user &&
              _user.family_name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            ("email" in _user &&
              _user.email.toLowerCase().includes(this.search.toLowerCase())) ||
            ("phone_number" in _user &&
              _user.phone_number
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            ("UserStatus" in _user &&
              _user.UserStatus.toLowerCase().includes(
                this.search.toLowerCase()
              ))))
      );
    },
  },
};
</script>

<style scoped lang="scss">
.user-params-tr td {
  width: 20%;
  text-align: left;
}

label input {
  margin-top: 10px;
}

hr {
  margin-bottom: 24px;
  margin-top: 24px;
}
</style>
