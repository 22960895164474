<template>
  <div class="content">
    <div class="container-fluid container-fixed-lg">
      <div class="row">
        <div class="col-12">
          <div class="card card-default">
            <div class="card-header separator">
              <div class="card-title">
                <ol class="breadcrumb no-margin no-padding">
                  <li class="breadcrumb-item">
                    <router-link
                      :to="{ name: 'providers' }"
                      class="no-margin no-padding"
                      >Providers</router-link
                    >
                  </li>
                </ol>
              </div>
            </div>
            <div class="card-body">
              <h3 class="srae-title">Program Provider Table</h3>
              <h4>
                <b class="srae-subtitle">Grantee Name:</b> {{ grantee.name }}
              </h4>
              <h4><b class="srae-subtitle">Cohort:</b> {{ grantee.cohort }}</h4>

              <h4>
                <b class="srae-subtitle">Funding Stream:</b>
                {{ grantee.funding_stream }}
              </h4>
              <h4>
                <b class="srae-subtitle">Report Period:</b>
                {{ $store.state.reportingPeriodStr.prov }}
                <b-button
                  v-if="!isWinterSeason"
                  v-b-tooltip.hover
                  :title="`All grantees should submit data for the same reporting period—the federal grant year, ${$store.state.reportingPeriodStr.prov}—even if it does not align with the grantee organization’s fiscal year.`"
                  size="sm"
                  >?</b-button
                >
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card card-default">
            <div class="card-header">
              <div class="pull-right">
                <div class="col-xs-12">
                  <b-button
                    v-if="grantee.role == 'Grantee'"
                    @click="openProviderModal()"
                    aria-label="Add Provider"
                    class="btn btn-lg btn-primary btn-cons"
                  >
                    <i class="pg-icon">add</i> Add Provider
                  </b-button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <div
                  id="condensedTable_wrapper"
                  class="dataTables_wrapper no-footer"
                >
                  <table
                    class="table table-hover table-condensed dataTable no-footer"
                    id="condensedTable"
                    role="grid"
                  >
                    <thead>
                      <tr role="row">
                        <th rowspan="1" colspan="3">Provider</th>
                        <!--<th rowspan="1" colspan="1">User</th>-->
                        <th rowspan="1" colspan="1">
                          <div
                            class="d-flex align-items-center justify-content-between"
                          >
                            <span>Provider Active?</span>
                            <b-button
                              v-b-tooltip.hover.right="
                                'If the provider was not active during this reporting period, no other data will be requested for that provider during this reporting period, and the provider will not be listed in the data submission validation.'
                              "
                              size="sm"
                              >?</b-button
                            >
                          </div>
                        </th>
                        <th rowspan="1" colspan="1">
                          Enter/Edit Provider-Level Information
                        </th>
                        <th rowspan="1" colspan="1">
                          Enter/Edit Program Models
                        </th>
                        <th
                          rowspan="1"
                          colspan="1"
                          v-if="grantee.role == 'Grantee'"
                        >
                          Add Provider-Level User
                        </th>
                        <!--<th rowspan="1" colspan="1">Delete Provider</th>-->
                        <!--<th v-if="grantee.role=='Grantee'" rowspan="1" colspan="1" class="text-right">Settings</th>-->
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="provider in grantee.providers"
                        :key="provider.id"
                        role="row"
                        class="odd-even"
                      >
                        <td class="v-align-middle semi-bold" colspan="3">
                          {{ provider.name }}
                        </td>
                        <!--<td class="v-align-middle semi-bold"> </td>-->
                        <td class="v-align-middle text-center">
                          <!--<label>-->
                          <b-form-select
                            v-if="!!providerSubmissionData[provider.id]"
                            v-model="
                              providerSubmissionData[provider.id].prov_active
                            "
                            :id="`checkbox-provider-${provider.id}`"
                            @change="
                              checkIfSettingInactive(
                                provider,
                                providerSubmissionData[provider.id].prov_active
                              )
                            "
                            class="active-selector"
                            :options="[
                              {
                                value: null,
                                text: 'Select Option',
                                disabled: true,
                              },
                              { value: false, text: 'No' },
                              { value: true, text: 'Yes' },
                            ]"
                          >
                          </b-form-select>
                        </td>
                        <td class="v-align-middle text-center">
                          <router-link
                            :to="{
                              name: 'provider-data',
                              params: { pid: provider.id },
                            }"
                            tag="button"
                            :disabled="
                              providerSubmissionData[provider.id] &&
                              (providerSubmissionData[provider.id]
                                .prov_active == false ||
                                providerSubmissionData[provider.id]
                                  .prov_active === null)
                            "
                          >
                            <i class="material-icons">create</i>
                          </router-link>
                        </td>
                        <td class="v-align-middle text-center">
                          <router-link
                            :to="{
                              name: 'programs',
                              params: { pid: provider.id },
                            }"
                            tag="button"
                            :disabled="
                              providerSubmissionData[provider.id] &&
                              (providerSubmissionData[provider.id]
                                .prov_active == false ||
                                providerSubmissionData[provider.id]
                                  .prov_active === null)
                            "
                          >
                            <i class="material-icons">library_books</i>
                          </router-link>
                        </td>

                        <td
                          rowspan="1"
                          colspan="1"
                          v-if="grantee.role == 'Grantee'"
                        >
                          <b-button>
                            <i
                              class="material-icons"
                              @click="openProviderModal(provider)"
                              >create</i
                            >
                          </b-button>
                        </td>
                        <!--<td rowspan="1" colspan="1">-->

                        <!--  <b-button>-->
                        <!--     <i class="material-icons" @click="openDeleteProviderModal( provider.id, provider.name)">delete</i>-->
                        <!--  </b-button>-->
                        <!--</td>-->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div v-if="grantee.role == 'Grantee'" class="footer-msg">
              On the previous Grantee-Level Information page, you indicated that
              you currently have
              <strong>{{ grantee_num_providers_indicated || "no" }}</strong>
              providers.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Add/Edit Provider-->
    <b-modal
      id="modalSlideUp"
      v-model="formAssociations.show"
      :title="`${formAssociations.mode} Provider`"
      hide-footer
    >
      <p v-if="formAssociations.mode == 'Add'">
        Enter the name of the provider
      </p>
      <div class="form-horizontal">
        <div class="form-group row">
          <label for="fname" class="col-md-5 control-label" aria-required="true"
            >Provider Name</label
          >
          <div class="col-md-7">
            <input
              type="text"
              class="form-control"
              required=""
              aria-required="true"
              aria-invalid="true"
              v-model="formAssociations.providerName"
              id="fname"
              :disabled="formAssociations.mode == 'Edit'"
            />
            <!--<label id="fname-error" class="error" for="fname">This field is required.</label>-->
            <!--<span class="help"></span>-->
          </div>
        </div>
        <div class="row">
          <p class="p-l-15 p-t-10 p-b-10 m-b-0 m-t-0">
            SRAE grantees are responsible for ensuring that performance measures
            data are submitted for all providers and programs associated with
            their grants. Grantees can submit all data to the portal directly,
            or they can delegate the submission of some measures to providers.
            Please indicate who will enter data for this provider.
          </p>
        </div>
        <div class="form-group row">
          <div class="d-flex p-l-15" v-if="formAssociations.mode !== 'Edit'">
            <label class="control-label p-r-10" style="font-weight: 600"
              >Assign To</label
            >
            <div class="btn-group text-center">
              <button
                :disabled="
                  formAssociations.mode == 'Edit' &&
                  formAssociations.action == 'provider'
                "
                aria-label=""
                class="btn btn-lg m-b-10"
                type="button"
                :class="{
                  'active btn-primary': formAssociations.action == 'self',
                  'btn-default': formAssociations.action != 'self',
                }"
                @click="formAssociations.action = 'self'"
              >
                <i class="pg-icon" v-show="formAssociations.action == 'self'"
                  >tick</i
                >
                Grantee
              </button>
              <!--<button aria-label="" class="btn btn-lg m-b-10" type="button" :class="{'active btn-primary': formAssociations.action == 'association', 'btn-default' : formAssociations.action != 'association'}" @click="formAssociations.action = 'association'">-->
              <!--  <i class="pg-icon" v-show="formAssociations.action == 'association'">tick</i> Existing User-->
              <!--</button>-->
              <button
                aria-label=""
                class="btn btn-lg m-b-10"
                type="button"
                :class="{
                  'active btn-primary': formAssociations.action == 'provider',
                  'btn-default': formAssociations.action != 'provider',
                }"
                @click="formAssociations.action = 'provider'"
              >
                <i
                  class="pg-icon"
                  v-show="formAssociations.action == 'provider'"
                  >tick</i
                >
                Provider-Level User
              </button>
            </div>
          </div>

          <div
            class="col-md-12"
            v-if="
              formAssociations.mode != 'Edit' &&
              formAssociations.action != 'provider'
            "
          >
            <!--self-->
            <p
              v-if="
                formAssociations.action == 'self' &&
                formAssociations.mode != 'Edit'
              "
            >
              Grantee staff will enter data for this provider.
            </p>
          </div>

          <div
            class="col-md-12"
            v-if="
              formAssociations.mode == 'Edit' ||
              formAssociations.action == 'provider'
            "
          >
            <h5 v-if="formAssociations.users.length > 0">
              Users With Access to Provider
            </h5>
            <!--existing user-->
            <ul
              v-if="formAssociations.users.length > 0"
              class="row flex-column users-list p-l-10"
            >
              <li
                v-for="(user, i) in formAssociations.users"
                :key="user.name + i"
              >
                <b>{{ user.name }} {{ user.family_name }}</b> - {{ user.email }}
              </li>
            </ul>
            <div class="row d-flex align-items-center">
              <div class="form-group col-12">
                <h5>New User</h5>
                <p style="font-style: italic">
                  Enter a user's name and email information below to give them
                  access to
                  <b v-if="formAssociations.providerName.length">{{
                    formAssociations.providerName
                  }}</b
                  ><span v-if="!formAssociations.providerName.length"
                    >this provider</span
                  >.
                </p>
                <div class="row clearfix align-center">
                  <div class="col-xl-6">
                    <label>
                      <span>First name</span>
                      <br />
                      <input
                        type="text"
                        class="form-control"
                        name="name"
                        required=""
                        aria-required="true"
                        v-model="formAssociations.new_user.name"
                      />
                    </label>
                  </div>
                  <div class="col-xl-6">
                    <label>
                      <span>Last name</span>
                      <br />
                      <input
                        type="text"
                        class="form-control"
                        name="family_name"
                        required=""
                        aria-required="true"
                        v-model="formAssociations.new_user.family_name"
                      />
                    </label>
                  </div>
                  <div class="col-xl-12">
                    <b-form-group
                      label="Email Address"
                      label-for="input-1"
                      :invalid-feedback="isExistingGranteeMsg"
                      :state="!isExistingGrantee"
                    >
                      <b-form-input
                        id="input-1"
                        v-model="formAssociations.new_user.email"
                        :state="isExistingGrantee"
                        trim
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-t-10" v-if="formAssociations.errorMsg != ''">
          <div class="col-md-12">
            <p class="error">{{ formAssociations.errorMsg }}</p>
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col-md-4 text-left">
            <button
              @click="formAssociations.show = false"
              aria-label="Cancel Add Provider Modal"
              type="button"
              class="btn btn-lg btn-primary m-t-5"
            >
              Cancel
            </button>
          </div>
          <div class="col-md-8 text-right">
            <button
              @click="upsertProvider()"
              aria-label="Add Provider"
              type="button"
              class="btn btn-lg btn-primary m-t-5"
              :class="{
                disabled:
                  (formAssociations.mode == 'Edit' &&
                    formAssociations.new_user.email.length == 0) ||
                  formAssociations.providerName.length <= 0 ||
                  (formAssociations.action == 'provider' &&
                    formAssociations.new_user.email.length == 0) ||
                  isExistingGrantee,
              }"
            >
              {{
                formAssociations.mode == "Edit"
                  ? "Add User To Provider"
                  : `Add Provider${
                      formAssociations.action == "provider"
                        ? " and Add User"
                        : ""
                    }`
              }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-model="deleteProviderModal.show"
      title="Delete Provider?"
      hide-footer
    >
      <div class="row">
        <div class="col-12 text-center">
          <h6>{{ deleteProviderModal.bodyText }}</h6>
        </div>
        <div class="col-12 d-flex justify-content-between">
          <button
            @click="deleteProviderModal.show = false"
            aria-label="Add Grantee"
            type="button"
            class="btn btn-lg btn-secondary m-t-5"
          >
            Cancel
          </button>
          <button
            @click="deleteProvider()"
            aria-label="Add Grantee"
            type="button"
            class="btn btn-lg btn-primary m-t-5"
          >
            Delete
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-model="confirmSetInactiveModal.show"
      title="Set provider as inactive?"
      hide-footer
    >
      <div class="row">
        <div class="col-12 text-center">
          <h6>Are you sure you wish to mark this provider as inactive?</h6>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 text-left">
          <button
            @click="
              cancelUpdateProviderStatus(confirmSetInactiveModal.provider)
            "
            aria-label="Cancel Set Provider Inactive"
            type="button"
            class="btn btn-lg btn-primary"
          >
            No
          </button>
        </div>
        <div class="col-md-6 text-right">
          <button
            @click="
              updateProviderStatus(confirmSetInactiveModal.provider, false)
            "
            aria-label="Set Provider Inactive"
            class="btn btn-lg btn-primary"
          >
            Yes
          </button>
        </div>
      </div>
    </b-modal>
    <confirmModal
      :show="confirm.show"
      :headerTitle="confirm.headerTitle"
      :bodyText="confirm.bodyText"
      :close="closeConfirmModal"
    />
  </div>
</template>

<script>
import { API } from "aws-amplify";
import mixin from "../../mixin";
import { getUserAttributes } from "../../helpers.js";

import confirmModal from "../../components/confirm-modal.vue";

export default {
  mixins: [mixin],
  components: {
    confirmModal,
  },
  data() {
    return {
      confirm: {
        show: false,
        headerTitle: "Provider Added",
        bodyText: "",
      },
      existing_users: [],
      deleteProviderModal: {
        show: false,
        bodyText: "",
        provider_id: "",
        providerName: "",
      },
      confirmSetInactiveModal: {
        show: false,
        provider: null,
      },
      formAssociations: {
        mode: "Add", // edit or add
        show: false,
        action: "self", //'self' or 'provider'
        providerName: "",
        provider_id: null,
        errorMsg: "",
        role_id: 2,
        users: [
          // {
          //  name, email, username, action: 'edit' or 'new'
          //  }
        ],
        new_user: {
          name: "",
          family_name: "",
          email: "",
          show: false,
        },
      },
    };
  },
  watch: {
    grantee() {
      this.getAllProviderSubmissionData();
      this.getExistingUsers();
    },

    "$store.state.reportingPeriodData": function () {
      this.getAllProviderSubmissionData();
    },
  },
  mounted() {
    this.$store.dispatch("getAllUsers");

    this.getAllProviderSubmissionData();

    this.getExistingUsers();
  },
  computed: {
    isWinterSeason: function () {
      return this.$store.state.isWinterSeason;
    },
    isExistingGranteeMsg: function () {
      if (
        this.existing_users.find(
          (u) =>
            u.fk_roles == 1 && u.email == this.formAssociations.new_user.email
        )
      ) {
        return "User already has grantee-level account";
      } else {
        return "User already associated with this provider";
      }
    },
    isExistingGrantee: function () {
      return (
        this.existing_users.find(
          (u) =>
            u.fk_roles == 1 && u.email == this.formAssociations.new_user.email
        ) ||
        this.existing_users.find(
          (u) =>
            u.fk_roles == 2 &&
            u.fk_providers == this.formAssociations.provider_id &&
            u.email == this.formAssociations.new_user.email
        )
      );
    },
    existingUsersNotInList() {
      if (this.existing_users) {
        let users = this.existing_users
          .map((user) => {
            return {
              ...user,
              full_name: user.name + " " + user.family_name,
              disabled: !!this.formAssociations.users.find(
                (u) => u.Username == user.Username
              ),
            };
          })
          .reduce((list, user) => {
            if (list[user.Username]) {
              return list;
            } else {
              return {
                ...list,
                [user.Username]: user,
              };
            }
          }, {});

        return {
          "": {
            Username: "",
            full_name: "Please select an option",
            disabled: true,
          },
          ...users,
        };
      } else {
        return [];
      }
    },

    grantee_num_providers_indicated: function () {
      // gr_num_providers_funded
      const data = this.$store.state.granteeSubmissionData.filter(
        (grantee) => grantee.fk_grants === this.grantee.id
      );
      if (data.length) {
        return data[0].gr_num_providers_funded;
      } else {
        return 0;
      }
    },
    providerSubmissionData: function () {
      // initialize empty submission data object for all providers,
      // in case some have not been filled out yet
      const providers = this.grantee.providers.reduce((prev, provider) => {
        return {
          ...prev,
          [provider.id]: { prov_active: null },
        };
      }, {});

      this.$store.state.providerSubmissionData.forEach((provider) => {
        providers[provider.fk_providers] = provider;
      });

      return providers;
    },
  },
  created() {
    this.$store.dispatch("getAllUsers");
    this.getAllProviderSubmissionData();
  },
  methods: {
    openDeleteProviderModal(provider_id, providerName) {
      this.deleteProviderModal = {
        ...this.deleteProviderModal,
        show: true,
        bodyText: `Are you sure you wish to delete Provider ${providerName}? This action will remove all Provider-level users associated with this Provider, all Programs associated with this Provider, and all submission data. This action cannot be undone.`,
        provider_id,
        providerName,
      };
    },
    deleteProvider() {
      API.del(
        "webapi",
        "/providers/" + this.deleteProviderModal.provider_id
      ).then((response) => {
        if (response.success == false) {
          this.deleteProviderModal.show = false;
          this.confirm.headerTitle = "Error Deleting Provider";
          this.confirm.bodyText = `Provider <i>${this.deleteProviderModal.providerName}</i> was unable to be deleted. Please try again later.`;
          this.confirm.show = true;
        } else {
          this.$store.dispatch(
            "getUserAssociations",
            this.$store.state.user.username
          );
          this.deleteProviderModal.show = false;
          this.confirm.headerTitle = "Provider Deleted";
          this.confirm.bodyText = `Provider <i>${this.deleteProviderModal.providerName}</i> was removed successfully.`;
          this.confirm.show = true;
        }
      });
    },
    cancelUpdateProviderStatus(provider) {
      this.providerSubmissionData[provider.id].prov_active = true;
      this.confirmSetInactiveModal.show = false;
    },
    checkIfSettingInactive(provider, is_active) {
      let providerSubmissionData = this.providerSubmissionData[provider.id];
      let hasEnteredData =
        Object.keys(providerSubmissionData).length > 1 &&
        Object.keys(providerSubmissionData).find(
          (key) => key.includes("prov_") && providerSubmissionData[key] !== null
        );
      if (hasEnteredData && is_active == false) {
        this.confirmSetInactiveModal = {
          show: true,
          provider,
        };
      } else {
        this.updateProviderStatus(provider, is_active);
      }
    },
    updateProviderStatus(provider, prov_active) {
      this.confirmSetInactiveModal.show = false;
      const submitPath = `/grantees/${this.grantee.id}/providers/${provider.id}/submissions/${this.$store.state.reportingPeriodData.pk}`;
      const getSubmissionPath = `/providers/${provider.id}/submissions/${this.$store.state.reportingPeriodData.pk}/reduce`;
      let data = {
        formName: "provider",
        formData: [
          {
            tableName: "provider_submissions",
            tableData: [
              [
                {
                  name: "provider_name",
                  value: {
                    stringValue: provider.name,
                  },
                },
                {
                  name: "grantee_name",
                  value: {
                    stringValue: this.grantee.name,
                  },
                },
                {
                  name: "prov_active",
                  value: {
                    booleanValue: prov_active,
                  },
                },
              ],
            ],
          },
        ],
      };
      this.$store.dispatch("submitForm", {
        action: "save",
        path: submitPath,
        getSubmissionPath,
        data,
        target: "provider",
      });
    },
    async setProviderAssociations(provider_id, associations) {
      return await Promise.all(
        associations.map((association) => {
          if (association.del) {
            return API.del("webapi", "/user-associations/" + association.pk, {
              association,
            });
          } else {
            let body = {
              user: association,
              action: association.action,
              fk_grants: this.grantee.id,
              provider_id,
              role_name: "Provider",
              role_id: this.formAssociations.role_id,
            };
            return API.post("webapi", "/user-associations", { body });
          }
        })
      );
    },
    upsertProvider() {
      let user = {
        action: "new",
        email: this.formAssociations.new_user.email,
        family_name: this.formAssociations.new_user.family_name,
        name: this.formAssociations.new_user.name,
      };
      // If a user already exists, add their username and let the API know not to create a new user
      let existing_user = this.existing_users.find(
        (u) => u.email == user.email
      );
      if (this.existing_users.find((u) => u.email == user.email)) {
        user = {
          action: "association",
          Username: existing_user.Username,
          email: existing_user.email,
          family_name: existing_user.family_name,
          name: existing_user.name,
        };
      }
      if (this.formAssociations.mode == "Edit") {
        this.setProviderAssociations(this.formAssociations.provider_id, [
          user,
        ]).then(() => {
          this.$store.dispatch(
            "getUserAssociations",
            this.$store.state.user.username
          );
          this.getExistingUsers();
          this.formAssociations.show = false;

          this.confirm.headerTitle = "Provider Added";
          this.confirm.bodyText = `User was successfully added to provider <i>${this.formAssociations.providerName}</i>.`;
          this.confirm.show = true;
        });
      } else {
        // add provider
        API.post("webapi", "/providers", {
          body: {
            grantee_id: this.grantee.id,
            provider_name: this.formAssociations.providerName,
          },
        }).then((responseProvider) => {
          // if error
          if (!responseProvider.success) {
            this.formAssociations.errorMsg = responseProvider.error.message;
            return;
          }
          // if successfull
          if (responseProvider.success) {
            // if associated to self - no need to add association
            if (this.formAssociations.action == "self") {
              this.$store.dispatch(
                "getUserAssociations",
                this.$store.state.user.username
              );
              this.formAssociations.show = false;

              this.confirm.headerTitle = "Provider Added";
              this.confirm.bodyText = `Provider <i>${this.formAssociations.providerName}</i> was added successfully.`;
              this.confirm.show = true;
              // for existing user - add c
            } else {
              this.setProviderAssociations(responseProvider.data[0].pk, [
                user,
              ]).then(() => {
                this.$store.dispatch(
                  "getUserAssociations",
                  this.$store.state.user.username
                );
                this.getExistingUsers();
                this.formAssociations.show = false;

                this.confirm.headerTitle = "Provider Added";
                this.confirm.bodyText = `Provider <i>${this.formAssociations.providerName}</i> was added successfully with user associated.`;
                this.confirm.show = true;
              });
            }
          } else {
            this.formAssociations.errorMsg = responseProvider.error.message;
          }
        });
      }
    },
    getExistingUsers() {
      API.get("webapi", `/users/${this.grantee.id}`).then((response) => {
        let roles = this.$store.state.allRoles.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.pk]: curr.role_name,
          };
        }, {});
        this.existing_users = response.data
          .filter((user) => user.user_data) //todo: add provider and grantee users? user.fk_roles==1 || user.fk_roles==2
          .map((user) =>
            getUserAttributes({
              ...user,
              ...user.user_data,
              role: roles[user.fk_roles],
            })
          );
      });
    },
    openProviderModal(existing_provider) {
      if (!existing_provider) {
        this.formAssociations = {
          ...this.formAssociations,
          mode: "Add", // edit or add
          show: true,
          action: "self", //'self' or 'provider'
          providerName: "",
          provider_id: null,
          errorMsg: "",
          users: [
            // {
            //  name, email, username, action: 'edit' or 'new'
            //  }
          ],
          new_user: {
            name: "",
            family_name: "",
            email: "",
            show: false,
          },
        };
      } else {
        let users = this.existing_users
          .filter((user) => user.fk_providers == existing_provider.id)
          .map((user) => {
            return {
              ...user,
              removable: false,
            };
          });

        this.formAssociations = {
          ...this.formAssociations,
          mode: "Edit", // edit or add
          show: true,
          action: "provider", //'self' or 'provider'
          providerName: existing_provider.name,
          provider_id: existing_provider.id,
          errorMsg: "",
          users,
          new_user: {
            name: "",
            family_name: "",
            email: "",
            show: false,
          },
        };
      }
    },
    closeConfirmModal() {
      this.confirm.show = false;
    },

    getAllProviderSubmissionData() {
      this.$store
        .dispatch("getSubmissionData", {
          path: `/grantees/${this.grantee.id}/submissions/${this.$store.state.reportingPeriodData.pk}/reduce`,
          target: "grantee",
        })
        .catch((err) =>
          console.error("[grantee] getSubmissionData error: ", err)
        );

      const providers = this.grantee ? this.grantee.providers : [];

      providers.forEach((pro) => {
        this.$store
          .dispatch("getSubmissionData", {
            path: `/providers/${pro.id}/submissions/${this.$store.state.reportingPeriodData.pk}/reduce`,
            target: "provider",
          })
          // .then(response => console.log(`[getProviderIssues] response for id: ${pro.id}`, response, this.providerSubmissionData, this.grantee.providers))
          .catch((error) =>
            console.error("[getProviderIssues] error: ", error)
          );
      });
    },
  },
};
</script>

<style scoped lang="scss">
.footer-msg {
  text-align: center;
  margin-bottom: 18px;
  font-size: 18px;
}

.modal .form-group label {
  font-size: 16px !important;
}

label input {
  margin-top: 4px;
}

.table.table-condensed tbody tr td *:not(.dropdown-default) {
  overflow: visible;
}

.table.table-condensed tbody tr td .active-selector {
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}

td button {
  border: none;
  background: none;
  color: #541d76;

  &:hover {
    color: #412354;
  }

  &:disabled,
  &:disabled:hover {
    color: #d3cad9;
    cursor: not-allowed;
  }
}

.users-list {
  list-style: none;

  li {
    padding-bottom: 8px;
    padding-left: 16px;
  }
}
</style>
