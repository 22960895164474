<template>
  <div class="full-height">
    <div class="lock-container" style="width: 800px; margin-top: 15%">
      <div class="full-height sm-p-t-50 align-items-center d-md-flex">
        <div class="row full-width text-center">
          <div class="col-12">
            <div class="card">
              <!--<div class="card-header ">-->
              <!--  <div class="card-title"></div>-->
              <!--</div>-->
              <div v-if="$store.state.grantees[0].id != 0" class="card-body">
                <h3 class="">Select the Grant you would like to access</h3>
                <div class="table-responsive">
                  <div
                    id="basicTable_wrapper"
                    class="dataTables_wrapper no-footer"
                  >
                    <table
                      class="table table-hover dataTable no-footer"
                      id="basicTable"
                      role="grid"
                    >
                      <thead>
                        <tr role="row">
                          <th
                            aria-controls="basicTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Grantee Name"
                          >
                            Grantee Name
                          </th>
                          <th
                            aria-controls="basicTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Role"
                          >
                            My Role
                          </th>
                          <th
                            aria-controls="basicTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Cohort"
                          >
                            Cohort
                          </th>
                          <th
                            aria-controls="basicTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Funding Stream"
                          >
                            Funding Stream
                          </th>
                          <th
                            aria-controls="basicTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Status"
                          >
                            Status
                          </th>
                          <th colspan="1" rowspan="1"></th>
                          <th colspan="1" rowspan="1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          role="row"
                          :class="{ odd: index % 2, even: !(index % 2) }"
                          v-for="(grant, index) in $store.state.grantees"
                          :key="grant.id"
                        >
                          <td class="v-align-middle">
                            <p>{{ grant.name }}</p>
                          </td>
                          <td class="v-align-middle">
                            <p>{{ grant.role }}</p>
                          </td>
                          <td class="v-align-middle">
                            <p>{{ grant.cohort }}</p>
                          </td>
                          <td class="v-align-middle">
                            <p>{{ grant.funding_stream }}</p>
                          </td>
                          <td class="v-align-middle">
                            <p>{{ grant.gr_active ? "Active" : "Inactive" }}</p>
                          </td>
                          <td v-if="grant.has_portal_access">
                            <button
                              id="portal-button"
                              class="btn btn-primary link-button"
                              :class="{
                                disabled: !canAccessPortal || !grant.gr_active,
                              }"
                              @click="selectGrant(grant.id, 'portal')"
                            >
                              Portal <i class="pg-icon">arrow_right</i>
                            </button>
                          </td>
                          <td v-if="grant.has_dashboard_access">
                            <b-button
                              id="dashboard-button"
                              class="btn btn-success link-button"
                              :class="{ disabled: !canAccessDashboard }"
                              @click="selectGrant(grant.id, 'dashboard')"
                            >
                              Dashboard <i class="pg-icon">arrow_right</i>
                            </b-button>
                          </td>
                        </tr>
                        <tr
                          v-if="
                            $store.state.grantees.find(
                              (grantee) => grantee.has_dashboard_access
                            ) && !canAccessDashboard
                          "
                        >
                          <td colspan="4" />
                          <td
                            colspan="2"
                            style="font-size: 12px; font-style: italic"
                          >
                            The dashboard will be available Feb 18th
                          </td>
                        </tr>
                        <tr
                          v-if="
                            $store.state.grantees.find(
                              (grantee) => grantee.has_portal_access
                            ) && !canAccessPortal
                          "
                        >
                          <td
                            colspan="6"
                            style="
                              font-size: 12px;
                              font-style: italic;
                              pointer-events: none;
                            "
                          >
                            The SRAE Performance Measures Portal is currently
                            closed.
                            <br />Additional information on the SRAE Performance
                            Measures is available at
                            <a href="https://sraepas.com"
                              >https://sraepas.com</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div v-if="$store.state.grantees[0].id == 0 || !grantee">
                <div class="col-md-12 text-center">
                  <br />
                  <p class="m-b-10">
                    Welcome back, {{ $store.state.user.full_name }}!
                  </p>
                  <div class="progress-circle-indeterminate"></div>
                  <p class="m-t-10 m-b-0">Loading Your Grants Associations</p>
                  <!--<p v-else class="m-t-10 m-b-0">-->
                  <!--  The SRAE Performance Measures Portal is currently closed.-->
                  <!--  The Portal will reopen in January 2021 for submission of-->
                  <!--  data covering {{ $store.state.reportingPeriodStr.gr }}. <br />Additional-->
                  <!--  information on the SRAE Performance Measures is available at-->
                  <!--  <a href="https://sraepas.com">https://sraepas.com</a>-->
                  <!--</p>-->
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
import helpers from "../helpers";
import mixin from "../mixin";

export default {
  mixins: [mixin],
  created() {
    if (this.grantee) this.autonavigate();
  },
  watch: {
    grantee: function () {
      // get grant data
      this.autonavigate();
    },
  },
  computed: {
    grants() {
      return this.$store.state.grantees;
    },
    canAccessPortal() {
      return helpers.isPortalOpen(this.$store.state.user);
    },
    canAccessDashboard() {
      return (
        !this.$store.state.isDashboardClosed ||
        helpers.isACF(this.$store.state.user) ||
        helpers.isAdmin(this.$store.state.user)
      );
    },
  },
  methods: {
    ...helpers,
    autonavigate() {
      if (helpers.isACF(this.$store.state.user)) {
        this.$router.push({ name: "dashboard" });
      }
      if (
        this.grants.length === 1 &&
        this.grantee &&
        this.grantee.has_dashboard_access &&
        !this.grantee.has_portal_access
      )
        this.selectGrant(this.grants[0].id, "dashboard");
      if (
        this.grants.length === 1 &&
        this.grantee &&
        !this.grantee.has_dashboard_access &&
        this.grantee.has_portal_access
      )
        this.selectGrant(this.grants[0].id, "portal");
    },
    selectGrant(grantId, destination) {
      if (destination === "portal" && !this.canAccessPortal) return;

      this.$store.state.granteeId = grantId;
      this.$cookies.set("granteeId", grantId, -1);

      let route = "dashboard";
      if (destination === "portal") {
        const grant = this.$store.state.grantees.find(
          (grantee) => Number(grantId) === grantee.id
        );
        route = grant.role === "Grantee" ? "grantee" : "providers";
      }

      this.$router.push({ name: route });
    },
  },
};
</script>
<style>
.link-button {
  padding: 16px;
  /* color: white; */
  font-weight: 600;
}
</style>
