<template>
  <b-modal v-model="forceShow" :title="headerTitle" hide-footer>
    <div class="row">
      <div class="col-12 text-center">
        <h6 v-html="bodyText"></h6>
        <button
          @click="close()"
          aria-label="Add Grantee"
          type="button"
          class="btn btn-lg btn-primary m-t-5"
        >
          Close
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "confirmModal",
  data: function () {
    return {
      forceShow: this.show,
    };
  },
  props: {
    headerTitle: {
      type: String,
    },
    bodyText: {
      type: String,
    },
    close: {
      type: Function,
    },
    show: {
      type: Boolean,
    },
  },
  watch: {
    show: function (show) {
      this.forceShow = show;
    },
    forceShow: function (forceShow) {
      if (!forceShow) {
        this.close();
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
